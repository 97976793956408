/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WifaService from 'services/wifa-service';
import { faSave } from '@fortawesome/free-solid-svg-icons';


const WIFAHome = (prop) => {
    const [scenarioName, setScenarioName] = useState('');
    const [scenarioNote, setScenarioNote] = useState('');
    const [isPrimary, setIsPrimary] = useState(false);
    const [validateScenarioName, setValidateScenarioName] = useState(false);
    const [validateScenarioNote,setValidateScenarioNote] = useState(false);

    useEffect(() => {
      var date = new Date().toLocaleString("en-US", { month: "long" })
                + " " 
                + new Date().toLocaleString("en-US", { day : '2-digit'})
                + ", "
                + new Date().getFullYear()
                + new Date().toLocaleString().split(',')[1];

      const profile = JSON.parse(localStorage.getItem('userProfile'));
      var emailSplit = profile.email.split('@');
      var name = emailSplit[0].toUpperCase();
      var scenario = 'WIFA ' + name + ' ' + date;
      setScenarioName(scenario);
      setValidateScenarioName(true);
    }, []);

const onClickPublish = () => {
    const primaryValue = isPrimary ? 'Yes' : 'No';
    WifaService.publishWIFAScenario(prop.wifaSessionId, prop.bloodCenterKey, prop.scenarioKey, scenarioName, scenarioNote, primaryValue);
}

  return (
    <>
        <Card className="h-100">
          <Card.Header>
            To Save and Publish, complete the form below
            
          </Card.Header>
          <Card.Body className="p-0">
          <Row className = "mb-3 mx-4">
              Division: {prop.bloodCenterCode}
              </Row>
          <Row className="mb-3 mx-3">
                <Form.Group as={Col} controlId="dataRuleName">
                  <Form.Label>Scenario Name: </Form.Label>
                  <Form.Control
                    isInvalid={!validateScenarioName}
                    type="text"
                    placeholder="Scenario Name"
                    name="dataRuleName"
                    value={scenarioName}
                    onChange={e => {
                      setScenarioName(e.target.value);
                      setValidateScenarioName(e.target.value ? true : false);
                    }}
                  />
                </Form.Group>
                <Form.Control.Feedback type="invalid">
                  Please fill out this field.
                </Form.Control.Feedback>
                </Row>
                <Row className="mb-3 mx-3">
                <Form.Group as={Col} controlId="threshold">
                  <Form.Label>Scenario Note: </Form.Label>
                  <Form.Control as="textarea" rows={8}
                    isInvalid={!validateScenarioNote}
                    type="text"
                    placeholder="Scenario Note"
                    value={scenarioNote}
                    name="scenario note"
                    onChange={e => {
                      setScenarioNote(e.target.value);
                      setValidateScenarioNote(e.target.value ? true : false);
                    }}
                  />
                </Form.Group>
                <Form.Control.Feedback type="invalid">
                  Please fill out this field.
                </Form.Control.Feedback>
              </Row>
              <Row className="mb-3 mx-3">
              <Form.Label>Is Primary Scenario: </Form.Label>
              <Form.Group as={Col} >
                  <Form.Check
                    inline
                    checked={isPrimary}
                    // defaultChecked={formData.isEnterprise==='Yes'?true:false}
                    type='checkbox'
                    id='isPrimaryYes'
                    label='Yes'
                    onChange={(e)=>e.target.checked? setIsPrimary(true):setIsPrimary(false)}
                  />
                  <Form.Check
                    inline
                    checked={!isPrimary}
                    // defaultChecked={formData.isEnterprise==='Yes'?true:false}
                    type='checkbox'
                    id='isPrimaryNo'
                    label='No'
                    onChange={(e)=>e.target.checked? setIsPrimary(false):setIsPrimary(true)}
                  />
                </Form.Group>
              </Row>
          </Card.Body>
          <Card.Footer>
          <Row className="mb-3 g-3">
              <div className="text-end">
                {/* <button
                  className="btn btn-outline-primary me-1 mb-1"
                  onClick={cancelPage}
                  value="cancel"
                >
                  <FontAwesomeIcon icon={faTimes} /> Cancel
                </button>
                <button
                  className="btn btn-outline-primary me-1 mb-1"
                  onClick={onClickSave}
                  value="save"
                  // disabled={!saveDisabled}
                >
                  <FontAwesomeIcon icon={faSave} /> Save
                </button> */}
                <button
                  className="btn btn-warning me-1 mb-1"
                  onClick={onClickPublish}
                  value="save"
                  disabled={!validateScenarioName || !validateScenarioNote}
                >
                  <FontAwesomeIcon icon={faSave} /> Publish
                </button>
              </div>
            </Row>
          </Card.Footer>
        </Card>
        </>
    )    
}

export default WIFAHome;
