/* eslint-disable prettier/prettier */
import { Card } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import WifaService from 'services/wifa-service';
import { getItemFromStore } from '../../helpers/utils';
import './PlateletDataEdit.css';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Edit,
  Inject,
  Filter,
  Sort
} from '@syncfusion/ej2-react-grids';
import { settings } from '../../config';
import SoftBadge from 'components/common/SoftBadge';


const OtherOverrides = (prop) => {
  const configState = {
    gridRowsCount: getItemFromStore('gridRowsCount', settings.gridRowsCount)
  };
 // const toolbarOptions = ['Edit', 'Update', 'Cancel'];
  const editSettings = { allowEditing: true, mode: 'Normal' };
  const pageSettings = { pageSize: configState.gridRowsCount };
  const [data, setData] = useState([]);
  const [tableName] = useState('Web.PlateletWIFATemp');
  const [isDivisionNYBC, setIsDivisionNYBC] = useState(false);
  const [isDivisionCBC, setIsDivisionCBC] = useState(false);
  const [isDivisionRIBC, setIsDivisionRIBC] = useState(false);
  const [isDivisionIBR, setIsDivisionIBR] = useState(false);
  const [isDivisionBBD, setIsDivisionBBD] = useState(false);

  useEffect(() => {
    WifaService.getReviewScenarios(prop.wifaSessionId, prop.bloodCenterKey, prop.scenarioKey)
    .then((reviewScenario) => {
      setData(reviewScenario);
    });

    if (prop.bloodCenterCode === "NYBC") { setIsDivisionNYBC(true);} else { setIsDivisionNYBC(false);}
    if (prop.bloodCenterCode === "CBC") { setIsDivisionCBC(true);} else { setIsDivisionCBC(false);}
    if (prop.bloodCenterCode === "RIBC") { setIsDivisionRIBC(true);} else { setIsDivisionRIBC(false);}
    if (prop.bloodCenterCode === "IBR") { setIsDivisionIBR(true);} else { setIsDivisionIBR(false);}
    if (prop.bloodCenterCode === "BBD") { setIsDivisionBBD(true);} else { setIsDivisionBBD(false);}
  }, []);

  const actionBegin = args => {
    if (args.requestType === 'save') {
      var setQuery = '';
      var whereQuery = '';

        setQuery =
            setQuery +
            "NYBCImport" +
            " = " +
            args.data['nybcImport']
            + ", CBCImport" +
            " = " +
            args.data['cbcImport']
           + ", IBRImport" +
            " = " +
            args.data['ibrImport']
            + ", RIBCImport" +
            " = " +
            args.data['ribcImport']
            + ", BBDImport" +
            " = " +
            args.data['bbdImport']
            + ", OSSImport" +
            " = " +
            args.data['ossImport'];
   

          whereQuery =
            whereQuery +
            "ControlId" +
            ' = ' +
            args.data['controlId'];

     // setQuery = setQuery.replaceAll("undefined", 0);

      if (setQuery != '') {
        WifaService.updateSelectedTableData(
          tableName,
          setQuery,
          whereQuery,
          prop.bloodCenterKey,
          prop.scenarioKey,
          prop.wifaSessionId
        );
      }
    }
  };

  return (
    <>
      <Card>
        <Card.Header className='pt-1 pb-1'>
        <SoftBadge  bg={'warning'}
          className='rounded-pill'  > Division: {prop.bloodCenterCode}
        </SoftBadge> &nbsp;&nbsp; 
          Provide import estimates for each division. Double click on import columns for input and press enter to save.
        </Card.Header>
        <Card.Body>
          <GridComponent
            dataSource={data}
            //height={315}
            className="bg-200 text-900"
           // toolbar={toolbarOptions}
            actionBegin={actionBegin}
            //allowPaging={true}
            allowFiltering={true}
            editSettings={editSettings}
            pageSettings={pageSettings}
            filterSettings={{ type: 'Menu' }}
            allowSorting={true}
            allowMultiSorting={true}
            allowTextWrap={true}
          >
            <ColumnsDirective>
              <ColumnDirective  key="controlId" field="controlId" headerText="Control Id" width='110' allowEditing={false}/>
              <ColumnDirective  key="controlDateString" field="controlDateString" headerText="Control Date" width='170' allowEditing={false}/>
              {!isDivisionNYBC && (<ColumnDirective  key="nybcImport" field="nybcImport" headerText="NYBC Import" width='110' allowEditing={true} validationRules={{number: true}}/>)}
              {!isDivisionCBC && (<ColumnDirective  key="cbcImport" field="cbcImport" headerText="CBC Import" width='110' allowEditing={true} validationRules={{number: true}}/>)}
              {!isDivisionIBR && (<ColumnDirective  key="ibrImport" field="ibrImport" headerText="IBR Import" width='110' allowEditing={true} validationRules={{number: true}}/>)}
              {!isDivisionRIBC && (<ColumnDirective  key="ribcImport" field="ribcImport" headerText="RIBC Import" width='110' allowEditing={true} validationRules={{number: true}}/>)}
              {!isDivisionBBD && (<ColumnDirective  key="bbdImport" field="bbdImport" headerText="BBD Import" width='110' allowEditing={true} validationRules={{number: true}}/>)}
              <ColumnDirective  key="ossImport" field="ossImport" headerText="OSS Import" width='110' allowEditing={true} validationRules={{number: true}}/>
            </ColumnsDirective>
            <Inject services={[Page, Toolbar, Edit, Filter, Sort]} />
          </GridComponent>
        </Card.Body>
      </Card>
    </>
  );
  //}
};

export default OtherOverrides;
