/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { Nav, Row, Col } from 'react-bootstrap';
// import { getFlatRoutes } from 'helpers/utils';
import NavbarNavLink from './NavbarNavLink';

const ServiceDropdown = ({ items }) => {
  return (
    <Row style={{ width: '300px' }}>
      <Col>
        <Nav className="flex-column">
        <NavbarNavLink label={items.name} title={items.name} />
          {items.children.map(
            
            (route, index) =>
               (
                <div key={index}>
                  <div className="mx-2">                  
                    <NavbarNavLink key={route.name} route={route} />
                  </div>
                </div>
              )
          )}
        </Nav>
      </Col>      
    </Row>
   
  );
};

ServiceDropdown.propTypes = {
  items:{
    name: PropTypes.string, 
    active: PropTypes.bool,
    to: PropTypes.string,
    children: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      name: PropTypes.string,
      to: PropTypes.string,
      permissions: [],
      active: PropTypes.bool
    })    
  )
  }
};

export default ServiceDropdown;
