/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
//import homeService from 'services/home-service';
import Background from 'components/common/Background';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faCircleQuestion, faBookOpenReader, faArrowsSplitUpAndLeft, faTicket } from '@fortawesome/free-solid-svg-icons';
// import { faWikipediaW  } from "@fortawesome/free-brands-svg-icons";

const WelcomeCard = () => {
  const profile = JSON.parse(localStorage.getItem('userProfile'));
  var myDate = new Date();
  var hrs = myDate.getHours();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const SmallScreenSize = 771;
  const MidScreenSize = 986;
  const detectSize = () => { setScreenWidth(window.innerWidth); }
  // const [appointments, setAppointments] = useState([]);
  // const [drivesScheduled, setDrivesScheduled] = useState([]);
  // const [inventorySupply, setInventorySupply] = useState([]);

  useEffect(() => {
    window.addEventListener('resize', detectSize)
    return () => {
      window.removeEventListener('resize', detectSize)
    }
    // const appointmentPromise = homeService.getAppointmentsScheduled();
    // const drivesPromise = homeService.getDrivesScheduled();
    // const inventoryPromise = homeService.getInventorySupply();

    // Promise.all([appointmentPromise, drivesPromise, inventoryPromise])
    //     .then(([appointment, drives, inventory]) => {

    //       setAppointments(appointment);
    //       setDrivesScheduled(drives);
    //       setInventorySupply(inventory);
    //   //localStorage.setItem(Enum.CACHED_HOME_BOOKINGS, JSON.stringify({ asof: new Date().getDate(), data: result}));     
    // }).catch((err) => {
    //   console.log("Landing Page Error:" + err);
    // })
    // .finally(()=>{

    // });
  }, []);

  return (
    <Card className="h-md-100">
      <Background image={bg2} className="p-card bg-card" />
      <Card.Header className="pb-2">
        <div className="position-relative z-index-2">
          <div>
            <h3 className="text-primary mb-1">{hrs < 12 ? 'Good Morning' : (hrs >= 12 && hrs <= 17) ? 'Good Afternoon' : (hrs >= 17 && hrs <= 24) ? 'Good Evening' : 'Good Morning'}, {profile.firstName}!</h3>
            <small>Welcome to Hemetrics!</small>
          </div>
          <Row className="py-1">        
            <Col>
              <FontAwesomeIcon icon={faBookOpenReader} className="me-1" />
              <a href="https://nybc.sharepoint.com/sites/EADS/SitePages/Hemetrics-101.aspx" target="_blank" rel="noopener noreferrer" className="fw-semi-bold fs--1 text-nowrap">
                Hemetrics 101
                </a>
              <br/>
              <small className='text-nowrap'>Learn all the basics of the portal</small>
            </Col>
            <Col>
            <FontAwesomeIcon icon={faDatabase} className="me-1" />
              <a href="https://nybc.sharepoint.com/sites/EADS/SitePages/Enterprise-Data-Governance.aspx" target="_blank" rel="noopener noreferrer" className="fw-semi-bold fs--1 text-nowrap">
                Enterprise Data Governance
              </a>
              <br/>
              <small className='text-nowrap'>Learn about the approach and methodology</small>
            </Col>    
            <Col>
            <FontAwesomeIcon icon={faArrowsSplitUpAndLeft} className="me-1" />
              <a href="https://nybc.sharepoint.com/sites/EADS/Lists/Analytics%20Use%20Cases/AllItems.aspx?viewid=450c0348%2Dfd47%2D4162%2Dbf05%2Dda155eb07758" target="_blank" rel="noopener noreferrer" className="fw-semi-bold fs--1 text-nowrap">
                  EADS Use Case Pipeline
              </a>
              <br/>
              <small className='text-nowrap'>View current queue and prioritization</small>
            </Col>

            {/* {screenWidth >= MidScreenSize && <Col><FontAwesomeIcon icon={faWikipediaW} className="me-1" />
              <a href="https://wiki.capps.nybce.org/en/home" target="_blank" rel="noopener noreferrer" className="fw-semi-bold fs--1 text-nowrap">
                EADS Wiki
              </a>
              <br/>
              <small className='text-nowrap'>Search available documentation</small></Col>}

              {screenWidth >= SmallScreenSize && screenWidth <= MidScreenSize   && <Col className='me-11 pe-6'><FontAwesomeIcon icon={faWikipediaW} className="me-1" />
              <a href="https://wiki.capps.nybce.org/en/home" target="_blank" rel="noopener noreferrer" className="fw-semi-bold fs--1 text-nowrap">
                EADS Wiki
              </a>
              <br/>
              <small className='text-nowrap'>Search available documentation</small></Col>}

              {screenWidth < SmallScreenSize && <div><FontAwesomeIcon icon={faWikipediaW} className="me-1" />
              <a href="https://wiki.capps.nybce.org/en/home" target="_blank" rel="noopener noreferrer" className="fw-semi-bold fs--1 text-nowrap">
                EADS Wiki
              </a>
              <br/>
              <small className='text-nowrap'>Search available documentation</small></div>} */}
            <Col>
            <FontAwesomeIcon icon={faCircleQuestion} className="me-1" />
              <Link to="/faq" className="fw-semi-bold fs--1 text-nowrap">
                Help
              </Link>
              <br/>
              <small className='text-nowrap'>Read frequently asked questions</small>
          </Col>

            {screenWidth >= MidScreenSize && <Col><FontAwesomeIcon icon={faTicket} className="me-1" />
              <a href="https://nybc.service-now.com/sp" target="_blank" rel="noopener noreferrer" className="fw-semi-bold fs--1 text-nowrap">
                Tickets
              </a>
              <br/>
              <small className='text-nowrap'>Submit a request</small></Col>}

              {screenWidth >= SmallScreenSize && screenWidth <= MidScreenSize   && <Col className='me-11 pe-6'><FontAwesomeIcon icon={faTicket} className="me-1" />
              <a href="https://nybc.service-now.com/sp" target="_blank" rel="noopener noreferrer" className="fw-semi-bold fs--1 text-nowrap">
                Tickets
              </a>
              <br/>
              <small className='text-nowrap'>Submit a request</small></Col>}

              {screenWidth < SmallScreenSize && <div><FontAwesomeIcon icon={faTicket} className="me-1" />
              <a href="https://nybc.service-now.com/sp" target="_blank" rel="noopener noreferrer" className="fw-semi-bold fs--1 text-nowrap">
                Tickets
              </a>
              <br/>
              <small className='text-nowrap'>Submit a request</small></div>}
          
          </Row>               
        </div>
      </Card.Header>
            
      {/* <Card.Body>
        <div>
        
        </div>
      </Card.Body> */}
    </Card>
  );
};

export default WelcomeCard;
