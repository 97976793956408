/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import { hemetricsAPIConfig } from "./authConfig";
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import Enum from 'common/enum';
import { MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import userService from 'services/user-service';
export const Login = () => {
  const { instance, inProgress } = useMsal();
  if (inProgress === InteractionStatus.None) {
        instance.loginRedirect(hemetricsAPIConfig);
      }
      return <div className='align-items-center text-center'></div> ;
}

const App = () => {
  const isDashboardLoaded = sessionStorage.getItem(Enum.CACHED_DASHBOARDMENU) !== null;
  
  const { instance } = useMsal();
  useEffect(() => {
    if (localStorage.getItem(Enum.CACHED_USER_PROFILE) === null) {
      const account = instance.getAllAccounts();
      const globalUserId = account[0]?.localAccountId;
      if (!globalUserId) {
      userService.getUserProfile(globalUserId)
      .then(userProfile => {
        localStorage.removeItem(Enum.CACHED_USER_PROFILE);
        localStorage.setItem(Enum.CACHED_USER_PROFILE, JSON.stringify(userProfile));
      })
    }} // end of if (localStorage.getItem(Enum.CACHED_USER_PROFILE) === null)
  }, []);

  return (
    <>
      {/* { isAuthenticated && isDashboardLoaded && (<Router basename={process.env.PUBLIC_URL}><Layout /></Router>) }
      { !isAuthenticated && <Login /> } */}
      { isDashboardLoaded && (
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <Router basename={process.env.PUBLIC_URL}>
          <Layout />
        </Router>
      </MsalAuthenticationTemplate>
      )}
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </>
  );
};
export default App;


