/* eslint-disable prettier/prettier */
import BaseService from './base-service';

class WIFAService extends BaseService {
  getDivisions = () => {
    return this._apiGet({
      path: `/wifa/getDivisions`
    });
  };
  getScenarios = (product, bloodCenterKey, selectedDate) => {
    return this._apiGet({
      path: `/wifa/getScenarios/${product}/${bloodCenterKey}/${selectedDate}`
    });
  };
  getAvailableScenarios = (product, bloodCenterKey) => {
    return this._apiGet({
      path: `/wifa/getAvailableScenarios/${product}/${bloodCenterKey}`
    });
  };

  getBaseScenarios = (wifaSessionId, bloodCenterKey, scenarioKey, selectedDate) => {
    return this._apiGet({
      path: `/wifa/getBaseScenario/${wifaSessionId}/${bloodCenterKey}/${scenarioKey}/${selectedDate}`
    });
  };

  getReviewScenarios = (wifaSessionId, bloodCenterKey, scenarioKey) => {
    return this._apiGet({
      path: `/wifa/getReviewScenario/${wifaSessionId}/${bloodCenterKey}/${scenarioKey}`
    });
  };

  createWifaSession = (wifaSessionId, bloodCenterKey, scenarioKey) => {
    return this._apiPost({
      path: `/wifa/createWifaSession/${wifaSessionId}/${bloodCenterKey}/${scenarioKey}`
    });
  };

  getAvailableSession = () => {
    return this._apiGet({
      path: `/wifa/getAvailableSession`
    });
  };

  deleteWifaSession = (wifaSessionId) => {
    return this._apiDelete({
      path: `/wifa/deleteWifaSession/${wifaSessionId}`
    });
  };

  updateSelectedTableData = (
    tableName,
    setColumns,
    whereCondition,
    bloodCenterKey,
    scenarioKey,
    wifaSessionId
  ) => {
    return this._apiPut({
      path: `/wifa/updateSelectedTableData/${tableName}/${setColumns}/${whereCondition}/${bloodCenterKey}/${scenarioKey}/${wifaSessionId}`
    });
  };

  getBuildModelScenarios = (wifaSessionId, bloodCenterKey, scenarioKey) => {
    return this._apiGet({
      path: `/wifa/getBuildModelScenario/${wifaSessionId}/${bloodCenterKey}/${scenarioKey}`
    });
  };

  publishWIFAScenario = (
    wifaSessionId,
    bloodCenterKey,
    scenarioKey,
    scenarioName,
    scenarioNote,
    isPrimary
  ) => {
    return this._apiGet({
      path: `/wifa/publishWIFAScenario/${wifaSessionId}/${bloodCenterKey}/${scenarioKey}/${scenarioName}/${scenarioNote}/${isPrimary}`
    });
  };
}

export default new WIFAService();
