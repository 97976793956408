/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { Nav, Row, Col } from 'react-bootstrap';
// import { getFlatRoutes } from '../../../helpers/utils';
import NavbarNavLink from './NavbarNavLink';

const DashboardDropdown = ({ items }) => {
// const routes = getFlatRoutes(items);
const half = items.length <= 6 ? items.length : items.length / 2   ;
const oneColumnWidth = {width: '300px'};
const twoColumnWith = {width: '600px'}
  return (    
    <Row style={half === items.length? oneColumnWidth : twoColumnWith}>
      <Col md={half === items.length? 12: 6}>
        <Nav className="flex-column">
        
        {items.map((route, index) => (
          index+1 <= half &&
          <div key={index}>
              <NavbarNavLink label={route.name} title={route.name} />
              <div className= "mx-2"> 
              {route.children.map(link => (
                <NavbarNavLink key={link.name} route={link} />
              ))}
                 </div>
          </div>
        ))}
     
        </Nav>
      </Col>
      {half < items.length && <Col md={6}>
        <Nav className="flex-column">
        {items.map((route, index) => (
          index+1 > half &&
          <div key={index}>
              <NavbarNavLink label={route.name} title={route.name} />
              {route.children.map(link => (
                <NavbarNavLink key={link.name} route={link} />
              ))}
          </div>
        ))}
        </Nav>
      </Col>}
    </Row>
);
};

DashboardDropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      name: PropTypes.string.isRequired,
      to: PropTypes.string,
      children: PropTypes.array
    }).isRequired
  )
};

export default DashboardDropdown;
