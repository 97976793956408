import { Col, Row } from 'react-bootstrap';
import activeDirectoryService from '../../../services/activeDirectory-service';
import authService from '../../../services/auth-service';
// import avatar from '../../../assets/img/team/2.jpg';
import coverSrc from '../../../assets/img/common/NYBCe-Background.png';
import ProfileBanner from '../ProfileBanner';
import React, { useState, useEffect } from 'react';

const userProfile = authService.getCurrentUserProfile();

const Banner = () => {
  const [photo, setPhoto] = useState(null);
  const [initial, setInitial] = useState(null);

  useEffect(() => {
    const initial =
      userProfile.firstName[0].toUpperCase() +
      userProfile.lastName[0].toUpperCase();
    setInitial(initial);
    GetProfilePhoto();
  }, []);

  function GetProfilePhoto() {
    activeDirectoryService.getUserProfilePhoto().then(o => {
      const url = window.URL || window.webkitURL;
      const blobUrl = url.createObjectURL(o.data);
      setPhoto(blobUrl);
    });
  }

  return (
    <ProfileBanner>
      <ProfileBanner.Header avatar={photo} name={initial} coverSrc={coverSrc} />
      <ProfileBanner.Body>
        <Row>
          <Col lg={8}>
            <h4 className="mb-1">
              {userProfile.firstName} {userProfile.lastName}
            </h4>
            <h5 className="fs-0 fw-normal">{userProfile.email}</h5>
            <div className="border-dashed-bottom my-4 d-lg-none" />
          </Col>
          <Col className="ps-2 ps-lg-3"></Col>
        </Row>
      </ProfileBanner.Body>
    </ProfileBanner>
  );
};

export default Banner;
