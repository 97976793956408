/* eslint-disable prettier/prettier */
import authService from "services/auth-service";
export const setSettingsRoutes=(routes) => {
  var result = [];
  routes.children.forEach(route => {    
      const hasPermission = authService.hasOneOfPermissions(route.permissions);
      if ((!route.permissions)
          || (route.permissions && route.permissions.length === 1 && route.permissions[0] === 'all')
          || (route.permissions && route.permissions.length && hasPermission)) {
            const child = {  name: route.name, icon: route.icon, to: route.to, active: route.active, permissions: route.permissions };
            result.push(child);
      }    
  })
  return result;
};

export const setServicesRoutes=(routes) => {
  var result = [];
  routes.children.forEach(node => {
    const topLevel = node
    var item = {   name: topLevel.name, to: topLevel.to, active: topLevel.active, children: []};
    // const temp = {};
    // temp.push(item);
    topLevel.children.forEach(route => {
      const hasPermission = authService.hasOneOfPermissions(route.permissions);
      if ((!route.permissions)
          || (route.permissions && route.permissions.length === 1 && route.permissions[0] === 'all')
          || (route.permissions && route.permissions.length && hasPermission)) {
            const child = {  name: route.name, icon: route.icon, to: route.to, active: route.active, permissions: route.permissions };
            item.children.push(child);
      }}
    )
    if (item.children.length > 0) {
      result.push(item);
    }
  })

  return result;
};

