/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
import userService from 'services/user-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
// import SpinnerCubeGrid from '../common/spinner-cube-grid';
import { Form, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types'; 
import Select from 'react-select';

const UserAssignmentModal = props => {
  const [selectedUserId, setSelectedUserId] = React.useState(null);
  const [users, setUsers] = React.useState([]);
//   const [showModal, setShowModal] = React.useState(props.visible);

  useEffect(() => {
    userService.fetchUsersWithPermissions('bl.reports.edit,bl.reports.approve_reject').then(users => {
        users.sort((a, b) => a.name.localeCompare(b.name));        
        users.forEach(item => {renameKey(item, 'id', 'value'); renameKey(item, 'name', 'label')});
        setUsers(users);
        // setSelectedUserId(users[0].value);
    });
  }, []);
  function renameKey ( obj, oldKey, newKey ) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }

  const assign = () => {
    props.assign(selectedUserId);
  };

  return (
    <Modal show={props.visible} backdrop="static" keyboard={false}>
      <Modal.Header  className="d-flex justify-content-center">
            <Modal.Title>
              <Row><span className='text-center' style={{ fontSize: "68px" }}> <FontAwesomeIcon className="modal-icon" icon={faExclamationCircle} /> </span> </Row>
               <Row>Change User Assignment</Row>                        
            </Modal.Title>
        </Modal.Header>
      <Modal.Body>
        {/* {isLoading && <SpinnerCubeGrid active={true} />} */}
        {users && (
            <Form.Group>
              <Form.Label>Select a user for assignment.</Form.Label>
                <Select
                  // autoFocus={true}
                    //classNamePrefix="react-select"
                    closeMenuOnSelect={true}
                  placeholder='Select...'
                    value={users.find(user => user.id === selectedUserId)}
                    onChange={(e)=>setSelectedUserId(e.value)}
                    options={users}
                />
            
            </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
      <button          className="btn btn-outline-primary me-1 mb-1"  onClick={props.hide}            >
              <FontAwesomeIcon icon={faTimes} /> Cancel
            </button>
            <button className="btn btn-primary me-1 mb-1" onClick={assign} >
                <FontAwesomeIcon icon={faSave} /> Assign
              </button>
            
      </Modal.Footer>
    </Modal>
  );
};

UserAssignmentModal.propTypes = {  
      assign: PropTypes.func,
      selectedUserId: PropTypes.number,
      visible: PropTypes.bool,
      hide: PropTypes.func
    } ;
export default UserAssignmentModal;