import BaseService from './base-service';

class BoldReportService extends BaseService {
  fetchBoldReportToken = () => {
    return this._apiGet({
      path: `/boldReport/getBoldReportToken`
    });
  };

  fetchBoldReportCategoryList = () => {
    return this._apiGet({
      path: `/boldReport/getListOfBoldReportCategory`
    });
  };

  fetchBoldReportListFromCategory = catalogName => {
    return this._apiGet({
      path: `/boldReport/getListOfBoldReportFromCategory/${catalogName}`
    });
  };
}
export default new BoldReportService();
