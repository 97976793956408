/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Modal, Button, Breadcrumb } from 'react-bootstrap';
import userService from '../../services/user-service';
import hemService from '../../services/hem-service';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import {
  faSave,
  faTrashAlt,
  faShareSquare
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import TinymceEditor from 'components/common/TinymceEditor';
import PageHeader from 'components/common/PageHeader';
import FalconCloseButton from 'components/common/FalconCloseButton';
import notificationService from 'services/notification-service';
import Enum from 'common/enum';
import ConfirmationMessage from 'components/common/ConfirmationMessage';
import { Helmet } from 'react-helmet';
const profile = JSON.parse(localStorage.getItem(Enum.CACHED_USER_PROFILE));

const ReleaseEditor = () => {
  let params = useParams();
  const [roles, setRoles] = useState([]);
  const [assignedRoles, setAssignedRoles] = useState([]);
  var [releases, setReleases] = useState([]);
  const [emailNotification, setEmailNotification] = useState(false);
  const [portalNotification, setPortalNotification] = useState(false);
  const [disablePublish , setDisablePublish] = useState(false);
  const [disableSave , setDisableSave] = useState(true);
  const [disable, setDisable] = useState(true);
  const releaseId = params.releaseId;
  const fillMsg = 'Please fill in this field';
  const [validateTitle, setValidateTitle] = useState(true);
  const [validateVersion, setValidateVersion] = useState(true);
  const [validateMsg, setValidateMsg] = useState(fillMsg);
  var tempUsers = [];
  const [users, setUsers] = useState([]);

  useEffect(() => {
    //const userCan = getUserRights();
    const releasePromise = hemService.fetchRelease(releaseId);
    const rolesPromise = userService.fetchAllRoles();
    //const usersPromise = userService.fetchAllAssignedUsers();
    
    Promise.all([releasePromise, rolesPromise]).then(([release, roles]) => {
      roles.forEach(item => {
        renameKey(item, 'id', 'value');
        renameKey(item, 'name', 'label');       
      });
      release.releaseEmailRoleIds.forEach(item => {        
        hemService.fetchAllAssignedUsers(item).then ((user) => {
          user.map(u => {tempUsers.push(u.email);} )
          setUsers(tempUsers);
          //console.log(tempUsers);
        });
      });
      setReleases(release);
      setRoles(roles);
      setAssignedRoles(
        roles.filter(x => release.releaseEmailRoleIds.includes(x.value))
      );
      setEmailNotification(release.emailNotificationsEnabled);
      setPortalNotification(release.portalNotificationsEnabled);

      if(!release.emailNotificationsEnabled && !release.portalNotificationsEnabled)
      {
        setDisablePublish(true);
      }
      else{
        if (release.releaseStatus === 'Draft') {
          setDisablePublish(false);
        }
      }

      if (release.releaseStatus === 'Published') {
        setDisablePublish(true);
        setDisable(false);
      }
      setDisableSave(true);     
    })
    
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    const validate = validateFields();
    if (validate === false) {
      return;
    }

    if (e.target.value == 'save') {
      // assignedRoles.forEach(item => {
      //   renameKey(item, 'value', 'id');
      //   renameKey(item, 'label', 'name');
      // });
      var release = {
        title: releases.title,
        versionId: releases.versionId,
        isMajorRelease: releases.isMajorRelease,
        releaseSummary: releases.releaseSummary,
        releaseNotes: releases.releaseNotes,
        emailNotificationsEnabled: releases.emailNotificationsEnabled,
        portalNotificationsEnabled: releases.portalNotificationsEnabled,
        releaseEmailRoleIds: releases.releaseEmailRoleIds
      };      
      hemService.editRelease(releaseId, release).then(() => {
        // toast('Release Saved Successfully!', {
        //   autoClose: true,
        //   className: 'toast-container success'
        // });
        toast(ConfirmationMessage("Release Saved", "Release Saved Successfully!", "bg-success", "faCheckCircle"), {
          autoClose: true,
          //className: 'transparent',
          //style: {{background-color: transparent !important}},
          position: 'bottom-right',
          hideProgressBar: true,
          closeButton: false,
        });             
      });
      setDisableSave(true);
      if(!portalNotification && !emailNotification )
      {
        setDisablePublish(true);
      }
      else
      {
        setDisablePublish(false);
      }      
      //window.location.href = '/release/releaseBrowser';
    }
    // if (e.target.value == 'publish') {
    //   //value.map(role => roleIds.push(role.value));    
      
    //   //console.log(users);
    //   const notificationRequest = { applicationName:"Hemetrics", messageHeader:releases.title, messageContent: releases.releaseNotes, createdBy:profile.email, recipientEmailList: users, recipientEmailAddress:""};
    //   notificationService.AddNotificationToSpecificUser(notificationRequest);
    //   setDisablePublish(true);
    // }
  };

  const validateFields = () => {
    if (releases.title) {
      setValidateTitle(true);
    } else {
      setValidateMsg(fillMsg);
      setValidateTitle(false);
      return false;
    }
    if (releases.versionId) {
      setValidateVersion(true);
    } else {
      setValidateMsg(fillMsg);
      setValidateVersion(false);
      return false;
    }
    return true;
  };

  function deleteRelease() {
    hemService
      .deleteRelease(releaseId)
      .then(() => {
        window.location.href = '/release/browser';
      })
      .catch(err => console.log(err));
  }

  function renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }

  function handleEmailChange(value) {
    //console.log(value.target.checked);
    setEmailNotification(value.target.checked);
    setReleases({
      ...releases,
      emailNotificationsEnabled: value.target.checked
    });
    // if(!portalNotification && !value.target.checked)
    // {
    //   setDisablePublish(true);
    // }
    // else
    // {
    //   setDisablePublish(false);
    // }
    setDisableSave(false);
    setDisablePublish(true);
  }

  function handlePortalChange(value) {
    //console.log(value.target.checked);
    setPortalNotification(value.target.checked);
    setReleases({
      ...releases,
      portalNotificationsEnabled: value.target.checked
    });
    // if(!value.target.checked && !emailNotification )
    // {
    //   setDisablePublish(true);
    // }
    // else
    // {
    //   setDisablePublish(false);
    // }
    setDisableSave(false);
    setDisablePublish(true);
  }

  function handleRolesChange(value) {
    //console.log(value.target.checked);
    setAssignedRoles(value);
    setReleases({ ...releases, releaseEmailRoleIds: [] });
    var roleIds = [];
    value.map(role => roleIds.push(role.value));
    setReleases({ ...releases, releaseEmailRoleIds: roleIds }); 
    tempUsers = [];
    roleIds.forEach(item => {        
      hemService.fetchAllAssignedUsers(item).then ((user) => {
        user.map(u => {tempUsers.push(u.email);} )
        setUsers(tempUsers);
        //console.log(tempUsers);
      });
    });

    setDisableSave(false);
    setDisablePublish(true);
  }

  //Confirmation Modal
  function onClickPublish(e) {
   e.preventDefault();
   //Portal Notification
   const notificationRequest = { applicationName:"Hemetrics", type:"release", messageHeader:releases.title, messageContent: releases.releaseSummary, createdBy:profile.email, recipientEmailList: users, recipientEmailAddress:""};
   
  //  emailNotification ?
  //  hemService.publishRelease(releaseId).then(() => {
  //     history.back();
  //   })
  //   .catch(err => {
  //     console.log(err);
  //     history.back();
  //   })
  //   :
  
    notificationService.AddNotificationToSpecificUser(notificationRequest).then(() => {
      hemService.publishRelease(releaseId);
    }).then(() => {
      window.location.href = `/release/browser`;
    })
    .catch(err => {
      console.log(err);
      //history.back();
      window.location.href = `/release/browser`;
    })
    setDisablePublish(true);
  }

  function onClickDelete(e) {
    e.preventDefault();
    setShowConfirm(true);
  }
  const [showConfirm, setShowConfirm] = useState(false);
  const onClickCancel = () => setShowConfirm(false);
  const onClickOk = () => {
    setShowConfirm(false);
    deleteRelease();
  };
  return (
    <>
        <Helmet>
          <title>Edit Release</title>
        </Helmet>
        <PageHeader title="Edit Release" className="mb-2">
        <Breadcrumb>  
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/release/browser">Release Manager</Breadcrumb.Item>
        <Breadcrumb.Item active>Edit</Breadcrumb.Item>  
      </Breadcrumb>
      </PageHeader>
      <Card>
        <Card.Body className="bg-light">
          <Form onSubmit={handleSubmit}>
            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={5} controlId="title">
                <Form.Label>
                  Title<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!validateTitle}
                  type="text"
                  placeholder="Title"
                  value={releases.title}
                  name="title"
                  disabled={!disable}
                  onChange={({ target }) => {
                    setDisableSave(false);
                    setReleases({ ...releases, title: target.value });
                    setValidateTitle(target.value ? true : false);
                    setDisablePublish(true);             
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {validateMsg}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} lg={5} controlId="version">
                <Form.Label>
                  Version<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!validateVersion}
                  type="text"
                  placeholder="Version"
                  value={releases.versionId}
                  name="version"
                  disabled={!disable}
                  onChange={({ target }) => {
                    setReleases({ ...releases, versionId: target.value });
                    setValidateVersion(target.value ? true : false);
                    setDisableSave(false);
                    setDisablePublish(true);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {validateMsg}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} lg={2}>
                <Form.Label>
                  <br />
                  <br />
                  <br />
                </Form.Label>
                <Form.Check
                  inline
                  checked={releases.isMajorRelease}
                  //defaultChecked={formData.isEnterprise==='Yes'?true:false}
                  type="checkbox"
                  id="isMajorRelease"
                  label="Is Major Release"
                  disabled={!disable}
                  onChange={e =>
                    {setReleases({
                      ...releases,
                      isMajorRelease: e.target.checked
                    });
                    setDisableSave(false);
                    setDisablePublish(true);
                  }
                  }
                />
              </Form.Group>
            </Row>
            <Row className="mb-2 g-2">
              <Form.Label>Notes</Form.Label>
              <TinymceEditor
                value={releases.releaseNotes}
                handleChange={newValue =>
                  {setReleases({ ...releases, releaseNotes: newValue });
                  setDisableSave(!disable);
                  setDisablePublish(true);
                }
                }
              />
            </Row>

          {disable && <div>
            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={6}>
                <Form.Check
                  inline
                  checked={emailNotification}
                  defaultChecked={releases.emailNotificationsEnabled}
                  type="checkbox"
                  id="isEmail"
                  label="Email Notifications Enabled"
                  onChange={e => handleEmailChange(e)}
                  //onChange={({value}) => setReleases({...releases, emailNotificationsEnabled :value.target.checked})}
                />
              </Form.Group>
              <Form.Group as={Col} lg={6}>
              <Form.Check
                inline
                checked={portalNotification}
                defaultChecked={releases.portalNotificationsEnabled}
                type="checkbox"
                id="isNotification"
                label="Portal Alert Enabled"
                onChange={e => handlePortalChange(e)}
                //onChange={({value}) => setReleases({...releases, emailNotificationsEnabled :value.target.checked})}
              />
            </Form.Group>
            </Row>
            
                <Row className="mb-2 g-2">
                  <Form.Label>Summary</Form.Label>
                  <TinymceEditor
                    value={releases.releaseSummary}
                    handleChange={newValue =>{
                      setReleases({ ...releases, releaseSummary: newValue });
                      setDisableSave(false);
                      setDisablePublish(true);
                    }}
                  />
                </Row>

                <Form.Label>Assign Roles</Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={roles}
                  placeholder="Select Release Roles"
                  isMulti
                  //classNamePrefix="react-select"
                  value={assignedRoles}
                  onChange={value => handleRolesChange(value)}
                ></Select>
        </div>}
            <br />
            <div className="text-end">
              <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={handleSubmit}
                value="save"
                disabled={disableSave}
              >
                <FontAwesomeIcon icon={faSave} /> Save
              </button>
              <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={onClickDelete}
                value="delete"
              >
                <FontAwesomeIcon icon={faTrashAlt} /> Delete
              </button>
              <button
                disabled={disablePublish}
                className="btn btn-outline-primary me-1 mb-1"
                onClick={onClickPublish}
                value="publish"                
              >
                <FontAwesomeIcon icon={faShareSquare} /> Publish
              </button>
            </div>
          </Form>
        </Card.Body>
      </Card>
      <Modal
        show={showConfirm}
        onHide={onClickCancel}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Hemetrics</Modal.Title>
          <FalconCloseButton onClick={onClickCancel} />
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete it?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onClickOk}>
            OK
          </Button>
          <Button variant="secondary" onClick={onClickCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReleaseEditor;
