/* eslint-disable prettier/prettier */
import React from 'react';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor } from 'helpers/utils';
//import { tooltipFormatterDistribution } from './Formatter/tooltipFormatterDistribution';
import { tooltipFormatter } from 'helpers/echart-home-utils';

const getOptionsRBC = (data) => ({
  tooltip: {
    trigger: 'axis',
    // axisPointer: {
    //   type: 'shadow'
    // },
    padding: [7, 10],
    backgroundColor: getColor('100'),
    borderColor: getColor('300'),
    textStyle: { color: getColor('primary') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: tooltipFormatter
  },
  title: {
        text: 'RBC',
        textStyle: {
          fontWeight: 500,
          fontSize: 13,
          fontFamily: 'poppins',
          color: getColor('900')
        }
  },
  yAxis: [
    {
      data: [ 'RBC'
        //data.data.rbcCollected.toLocaleString('en-US')
      ],
      axisLabel: {
        inside: false,
        color: getColor('200'),
        fontWeight: 500,
        fontSize: 11,
        fontFamily: 'poppins'
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      z: 10
    },
    {
      //data: [data.data.rbcShipped.toLocaleString('en-US')],
      data: [Math.round(data.data.rbcPercent * 100) +'%'],
      //data: ['32%'],
      axisLabel: {
        inside: false,
        color: getColor('primary'),
        fontWeight: 500,
        fontSize: 12,
        fontFamily: 'poppins',
        borderRadius: 5,
        backgroundColor: getColor('soft-primary'),
        padding: [6, 16, 6, 16],
        width: 115
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      z: 10
    }
  ],
  xAxis: {
    type: 'value',
    min: 0,
    max: Math.round(data.data.rbcBudget),//data.data.rbcBudget,//807229,
    axisLine: {
      show: false
    },
    splitLine: {
      show: false
    },
    inverse: false,
    axisTick: {
      show: false
    },
    axisLabel: {
      show: false
    }
  },

  series: [
    {
      name: 'Shipped',
      type: 'bar',
      stack: 'total',
      showBackground: true,
      barWidth: 25,
      label: {
        show: true,
        formatter: value => value.value.toLocaleString('en-US'),
        position: 'insideLeft'
      },
      backgroundStyle: {
        color: getColor('200'),
        borderRadius: 5
      },
      itemStyle: {
        color: getColor(Math.round(data.data.rbcPercent * 100) >= 100 ? 'success' : 'primary'),
        borderRadius: 5
      },
      //data: [data.rbcCollected, data.pltCollected]
      //data: [Math.round(data.data.rbcPercent * 100)]//[data.data.rbcCollected]//[265909]
      data: [Math.round(data.data.rbcShipped)]
    },
    {
      name: 'Budget',
      type: 'bar',
      stack: 'total',
      showBackground: true,
      barWidth: 25,
      label: {
        show: false,
        formatter: '{c} %',
        position: 'insideRight'
      },
      backgroundStyle: {
        color: getColor('200'),
        borderRadius: 5
      },
      itemStyle: {
        color: getColor('300'),
        borderRadius: 5
      },
      //data: [data.rbcCollected, data.pltCollected]
      //data: [Math.round(data.data.rbcPercent * 100)]//[data.data.rbcCollected]//[265909]
      data: [Math.round(data.data.rbcBudget)]
    }
  ],
  grid: { right: '80px', left: '5px', bottom: '0', top: '0' }
});

const getOptionsPlatelets = (data) => ({
  tooltip: {
    trigger: 'axis',
    // axisPointer: {
    //   type: 'shadow'
    // },
    padding: [7, 10],
    backgroundColor: getColor('100'),
    borderColor: getColor('300'),
    textStyle: { color: getColor('primary') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: tooltipFormatter
  },
  title: {
    text: 'Platelets',
    textStyle: {
      fontWeight: 500,
      fontSize: 13,
      fontFamily: 'poppins',
      color: getColor('900')
    }
  },
  yAxis: [
    {
      data: [ 'Platelets'
        //data.data.pltCollected.toLocaleString('en-US'),
      ],
      axisLabel: {
        inside: false,
        color: getColor('200'),
        fontWeight: 500,
        fontSize: 11,
        fontFamily: 'poppins'
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      z: 10
    },
    {
      //data: [data.data.pltCollected.toLocaleString('en-US')], 
      data: [Math.round(data.data.pltPercent * 100) +'%'],
      //data: ['33%'],
      axisLabel: {
        inside: false,
        color: getColor('primary'),
        fontWeight: 500,
        fontSize: 12,
        fontFamily: 'poppins',
        borderRadius: 5,
        backgroundColor: getColor('soft-primary'),
        padding: [6, 16, 6, 16],
        width: 115
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      z: 10
    }
  ],
  xAxis: {
    type: 'value',
    min: 0,
    max: Math.round(data.data.pltBudget),//data.data.pltBudget,//179281,
    axisLine: {
      show: false
    },
    splitLine: {
      show: false
    },
    inverse: false,
    axisTick: {
      show: false
    },
    axisLabel: {
      show: false
    }
  },

  series: [
    {
      name: 'Shipped',
      type: 'bar',
      stack: 'total',
      showBackground: true,
      barWidth: 25,
      label: {
        show: true,
        formatter: value => value.value.toLocaleString('en-US'),
        position: 'insideLeft'
      },
      backgroundStyle: {
        color: getColor('200'),
        borderRadius: 5
      },
      itemStyle: {
        color: getColor(Math.round(data.data.pltPercent * 100) >= 100 ? 'success' : 'primary'),
        borderRadius: 5
      },
      data: [Math.round(data.data.pltShipped)]
      //data: [Math.round(data.data.pltPercent * 100)]//[data.data.pltCollected]//[60327]
    },
    {
      name: 'Budget',
      type: 'bar',
      stack: 'total',
      showBackground: true,
      barWidth: 25,
      label: {
        show: false,
        formatter: '{c} %',
        position: 'insideRight'
      },
      backgroundStyle: {
        color: getColor('200'),
        borderRadius: 5
      },
      itemStyle: {
        color: getColor('300'),
        borderRadius: 5
      },
      //data: [data.rbcCollected, data.pltCollected]
      //data: [Math.round(data.data.rbcPercent * 100)]//[data.data.rbcCollected]//[265909]
      data: [Math.round(data.data.pltBudget)]
    }
  ],
  grid: { right: '80px', left: '5px', bottom: '0', top: '0' }

});

const DistributionFunnelChart = (data) => {
  return (
    <>
     <ReactEChartsCore
        echarts={echarts}
        option={getOptionsRBC(data)}
        style={{ height: '4rem' }}
      />

      <ReactEChartsCore
        echarts={echarts}
        option={getOptionsPlatelets(data)}
        style={{ height: '4rem' }}
      />
    </>
  );
};

export default DistributionFunnelChart;
