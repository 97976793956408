/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

const DEFAULT_DATE_FORMAT = 'MM-DD-YYYY HH:mm';

const DateFormat = props => {
  let format = props.format || DEFAULT_DATE_FORMAT;
  let stringDate = '';
  try {
    let guessedTimezone = moment.tz.guess();
    const displayDateOnly = format.toLowerCase().indexOf('hh') === -1;
    stringDate = displayDateOnly
      ? moment(props.date).format(format)
      : moment.utc(props.date).tz(guessedTimezone).format(format);
  } catch (ex) {
    stringDate = props.date;
  }
  if (props.classAttr) {
    return <span className={props.classAttr}>{stringDate}</span>;
  }
  return <span>{stringDate}</span>;
};

DateFormat.protTypes = {
  date: PropTypes.date,
  classAttr: PropTypes.string,
  format: PropTypes.string
};

export default DateFormat;
