/* eslint-disable prettier/prettier */
import BaseService from './base-service';
// import { mockData } from 'pages/blood-loss/mockData';
// import queryString from 'querystring';

class BloodLossService extends BaseService {
  fetchReport = reportId => {
    return this._apiGet({
      path: `/bl/reports/${reportId}`
    });
  };

  fetchReports = () => {
    return this._apiGet({
      path: '/bl/reports' 
    });
  };

  fetchReportDonors = (reportId) => {   
    return this._apiGet({
      path: `/bl/reports/${reportId}/donors` 
    });
  };

  assignReport = (reportId, userId) => {
    const command = {
      userId
    };

    return this._apiPost({
      path: `/bl/reports/${reportId}/assign`,
      data: command
    });
  };

  submitReport = (reportId, command) => {
    return this._apiPost({
      path: `/bl/reports/${reportId}/submit`,
      data: command
    });
  };

  approveReport = (reportId, command) => {
    return this._apiPost({
      path: `/bl/reports/${reportId}/approve`,
      data: command
    });
  };

  rejectReport = (reportId, command) => {
    return this._apiPost({
      path: `/bl/reports/${reportId}/reject`,
      data: command
    });
  };

  fetchDonor = (reportId, donorKey) => {
    // return new Promise((resolve) => {
    //   if (reportId && donorKey) {    
    //     return resolve(mockData);
    //   }
    //  });     
    return this._apiGet({
      path: `/bl/reports/${reportId}/donors/${donorKey}`
    });
  };

  calculateDeferralDate = (reportId, donorCode, excludedDonationCodes) => {
    return this._apiPost({
      path: `/bl/reports/${reportId}/donors/${donorCode}/calculatedate`,
      data: excludedDonationCodes
    });
  };

  saveDonor = (reportId, donorKey, saveDonorCommand) => {
    return this._apiPut({
      path: `/bl/reports/${reportId}/donors/${donorKey}`,
      data: saveDonorCommand
    });
  };

  fetchReportEvents = reportId => {
    return this._apiGet({
      path: `/bl/reports/${reportId}/events`
    });
  };
}

export default new BloodLossService();
