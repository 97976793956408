/* eslint-disable react/jsx-no-undef */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Card, Row, Col, Breadcrumb } from 'react-bootstrap';
import AdvanceTableWrapper from '../../components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../../components/common/advance-table/AdvanceTable';
import { Link } from 'react-router-dom';
// import AdvanceTableSearchBox from '../../components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from '../../components/common/advance-table/AdvanceTableFooter';
import bloodLossService from 'services/blood-loss-service';
import { settings } from '../../config';
import { getItemFromStore } from '../../helpers/utils';
import PageHeader from 'components/common/PageHeader';
import { faHistory, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import  PopoverComponent from 'pages/common/popover';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const columns = [
  {
    accessor: 'date',
    Header: 'Date',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    },
    Cell: rowData => (
      <Link
        to={`report/${rowData.row.original.id}`}
        // to={`report/69397A91-FC27-462C-BB9A-C972460EBCB9`}
        className="text-primary fw-semi-bold"
      >
        {rowData.row.original.date} 
      </Link>
    )
  },
  {
    accessor: 'status',
    Header: 'Status',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'assignedUser',
    Header: 'Assigned To',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'lastModifiedBy',
    Header: 'Last Modified By',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'lastModifiedTimestamp',
    Header: 'Last Modified Date',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  }
];

const BloodLoss = () => {
  const [reports, setReports] = useState([]);
  const configState = {
    gridRowsCount: getItemFromStore('gridRowsCount', settings.gridRowsCount)
  };
  useEffect(() => {
    bloodLossService.fetchReports().then(res => {
        res.forEach(report => {
          report.lastModifiedTimestamp = new Date(report.lastModifiedTimestamp).toLocaleString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true }); 
        });
        setReports(res);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
 
  const bloodLossDesc = "The Blood Loss Monitor identifies donors whose cumulative red blood cell loss or plasma loss from their donations in the past year (e.g., today's date - 1 year) are above specified thresholds so that a temporary deferral can be added to the BECs.";
 
  return (
    <>
      <Helmet>
        <title>NYBC Blood Loss</title>
      </Helmet>
      <PageHeader title="NYBC Blood Loss" className="mb-2"  col="12">
      <Row>
            <Col className="text-start mt-1">
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>
                  NYBC Blood Loss
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col className="text-end mt-n2">
              <PopoverComponent 
               // header = "Enterprise Data Governance"
                body = {bloodLossDesc}
              />
          </Col>
          </Row>
      </PageHeader>
      <AdvanceTableWrapper
        columns={columns}
        data={reports}
        sortable
        pagination
        perPage={configState.gridRowsCount}
      >
        <Card className="h-100">
          <Card.Header>
            <Row className="flex-between-center">
              <Col xs={9} sm={6} lg={3}>
                Reports Available
                {/* <AdvanceTableSearchBoxPlug table placeholder="Search" /> */}
              </Col>
              <Col xs="auto" sm={3} lg={3} className="text-end">
                <Link
                  className="btn btn-outline-primary"
                  to={'/blood-loss/search-donor'}              
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} /> Search Donor
                </Link> &nbsp;
              {/* </Col>
              <Col xs="auto" sm={3} lg={3} className="text-end"> */}
                <Link
                  className="btn btn-outline-primary"
                  to={'/blood-loss/audit'}              
                >
                  <FontAwesomeIcon icon={faHistory} /> Audit
                </Link>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={reports.length}
              table
              rowInfo
              navButtons
            />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default BloodLoss;
