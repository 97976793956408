/* eslint-disable prettier/prettier */
import { Card } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import WifaService from 'services/wifa-service';
import { getItemFromStore } from '../../helpers/utils';
import './PlateletDataEdit.css';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Edit,
  Inject,
  Filter,
  Sort
} from '@syncfusion/ej2-react-grids';
import { settings } from '../../config';
import SoftBadge from 'components/common/SoftBadge';

const ApptOverrides = (prop) => {
  const configState = {
    gridRowsCount: getItemFromStore('gridRowsCount', settings.gridRowsCount)
  };
 // const toolbarOptions = ['Edit', 'Update', 'Cancel'];
  const editSettings = { allowEditing: true, mode: 'Normal' };
  const pageSettings = { pageSize: configState.gridRowsCount };
  const [data, setData] = useState([]);
  const [tableName] = useState('Web.PlateletWIFATemp');

  useEffect(() => {
    WifaService.getReviewScenarios(prop.wifaSessionId, prop.bloodCenterKey, prop.scenarioKey)
    .then((reviewScenario) => {
      setData(reviewScenario);
    });
  }, []);

  const actionBegin = args => {
    if (args.requestType === 'save') {
      var setQuery = '';
      var whereQuery = '';
        if (args.data['apptOverride'] != '') {
          setQuery =
            setQuery +
            "ApptOverride" +
            " = " +
            args.data['apptOverride'];
        } else {
          setQuery =
          setQuery +
          "ApptOverride" +
          " = null ";
        }

          whereQuery =
            whereQuery +
            "controlId" +
            ' = ' +
            args.data['controlId'];

      setQuery = setQuery.replaceAll("'null'", null);

      if (setQuery != '') {
        WifaService.updateSelectedTableData(
          tableName,
          setQuery,
          whereQuery,
          prop.bloodCenterKey,
          prop.scenarioKey,
          prop.wifaSessionId
        );
      }
    }
  };

  return (
    <>
      <Card>
        <Card.Header className='pt-1 pb-1'>
        <SoftBadge  bg={'warning'}
          className='rounded-pill'  > Division: {prop.bloodCenterCode}
        </SoftBadge> &nbsp;&nbsp;  
          Adjust Appointment Bookings by providing override values. A value of "zero" will be considered an override value. Double click on Appointment Override column for input and press enter to save.
        </Card.Header>
        <Card.Body>
          <GridComponent
            dataSource={data}
            //height={315}
            className="bg-200 text-900"
           // toolbar={toolbarOptions}
            actionBegin={actionBegin}
           // allowPaging={true}
            allowFiltering={true}
            editSettings={editSettings}
            pageSettings={pageSettings}
            filterSettings={{ type: 'Menu' }}
            allowSorting={true}
            allowMultiSorting={true}
            allowTextWrap={true}
          >
            <ColumnsDirective>
              <ColumnDirective  key="controlId" field="controlId" headerText="Control Id" width='150' allowEditing={false}/>
              <ColumnDirective  key="controlDateString" field="controlDateString" headerText="Control Date" width='150' allowEditing={false}/>
              {/* <ColumnDirective  key="invStatus" field="invStatus" headerText="Inv Status" width='150' allowEditing={false}/> */}
              <ColumnDirective  key="apptBooked" field="apptBooked" headerText="Appointment Booked" width='150' allowEditing={false}/>
              <ColumnDirective  key="apptOverride" field="apptOverride" headerText="Appointment Override" width='150' allowEditing={true} validationRules={{number: true}}/>
            </ColumnsDirective>
            <Inject services={[Page, Toolbar, Edit, Filter, Sort]} />
          </GridComponent>
        </Card.Body>
      </Card>
    </>
  );
  //}
};

export default ApptOverrides;
