/* eslint-disable prettier/prettier */
import PageHeader from 'components/common/PageHeader';
import { Card, Breadcrumb, Row, Col, Form } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
//import { Link } from 'react-router-dom';
import GovernanceService from 'services/governance-service';
import  PopoverComponent from 'pages/common/popover';
import { Helmet } from 'react-helmet';
//import { truncate } from 'fs';

const activeGovernanceLandPage = () => {
  const [databases, setDatabases] = useState([]);
  const [tables, setTables] = useState([]);
  const [isDataManagement, setIsDataManagement] = useState(false);
  const [isDataRule, setIsDataRule] = useState(false);
  const [DataActionPath, setDataActionPath] = useState('');
  const [tableConfigId, setTableConfigId] = useState(0);
  const [databaseId, setDatabaseId] = useState(0);
  const [validateDatabase, setValidateDatabase] = useState(false);
  const [validateDomain, setValidateDomain] = useState(false);
  const [validateAction, setValidateAction] = useState(false);

  useEffect(() => {
    GovernanceService.fetchConfigDb().then(setDatabases);

  }, []);

  function dbChangeAction(e) {
    if(e.target.value == 'Select') 
    {
      setValidateDatabase(false);
    }
    else
    {
      GovernanceService.fetchConfigTables(e.target.value).then(setTables);
      setDatabaseId(e.target.value);
      setValidateDatabase(true);
    }
  }
  function dataDomainChange(e) {
    if(e.target.value == 'Select') 
    {
      setValidateDomain(false);
    }
    else
    {
      var tableData = tables.filter(x => x.tableConfigId == e.target.value);
      if (tableData.length > 0) {
        if (tableData[0].isEditable == 1) {
          setIsDataManagement(true);
        } else {
          setIsDataManagement(false);
        }

        if (tableData[0].isRulesEnabled == 1) {
          setIsDataRule(true);
        } else {
          setIsDataRule(false);
        }
      } else {
        setIsDataManagement(false);
        setIsDataRule(false);
      }

      setTableConfigId(e.target.value);
      setValidateDomain(true);
    }
  }

  const handleChangeAction = e => {
    if(e.target.value == 'Select') 
    {
      setValidateAction(false);
    }
    else
    {
      if (e.target.value == 'Data Management') {
        setDataActionPath('/governance/dataManagement/');
      } else if (e.target.value == 'Data Rules') {
        //setDataActionPath('/governance/governanceDataRule/');
        setDataActionPath('/governance/dataRulesView/');
      } else {
        setDataActionPath('');
      }
      setValidateAction(true);
    }
  };

  const handleSubmit = (DataActionPath, databaseId, tableConfigId) => {
    if(!validateDatabase || !validateDomain || !validateAction) {
      //setValidateDatabase(false);
      return;
    }
    window.location.href = DataActionPath + databaseId + '/' + tableConfigId;
  }

  const governanceRuleLandDesc = "Enterprise Data Governance module allows for you to create data rules against key data domains as well as manage data groups and classifications for selected dimensions. Choose data domain and appropriate Actions from the list below to get started.";

  return (
    <>
      <Helmet>
        <title>Enterprise Data Governance</title>
      </Helmet>
      <div>
        <PageHeader
          title="Enterprise Data Governance"
          className="mb-2"
          col="12"
        >
          <Row>
            <Col className="text-start mt-1">
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>
                  Enterprise Data Governance
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col className="text-end mt-n2">
              <PopoverComponent 
                header = "Enterprise Data Governance"
                body = {governanceRuleLandDesc}
              />
          </Col>
          </Row>
        </PageHeader>

        <Card className="h-100">
          <Card.Body>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={3}>
                <Form.Label>Database</Form.Label>
                <Form.Select
                  name="Database"
                  size="sm"
                  //value={tables}
                  className={validateDatabase?'me-2':'validation-error me-2'}
                  onChange={dbChangeAction}
                  //className="me-2"
                >
                  <option>Select</option>
                  {databases.map(i => (
                    <option key={i.dbConfigId} value={i.dbConfigId}>
                      {i.dbDisplayName}
                    </option>
                  ))}
                </Form.Select>
                {!validateDatabase && (
                  <span className="validation-error-msg">
                    Please fill out this field.
                  </span>
                )}
              </Form.Group>
              <Form.Group as={Col} lg={4}>
                <Form.Label>Data Domain</Form.Label>
                <Form.Select
                  name="Data Domain"
                  size="sm"
                  //value={tables}
                  onChange={dataDomainChange}
                  className="me-2"
                >
                  <option>Select</option>
                  {tables.map(i => (
                    <option key={i.tableConfigId} value={i.tableConfigId}>
                      {i.tableName}
                    </option>
                  ))}
                </Form.Select>
                {!validateDomain && (
                  <span className="validation-error-msg">
                    Please fill out this field.
                  </span>
                )}
              </Form.Group>

              <Form.Group as={Col} lg={3}>
                <Form.Label>Action</Form.Label>
                <Form.Select
                  name="Action"
                  size="sm"
                  //value={tables}
                  onChange={handleChangeAction}
                  className="me-2"
                >
                  <option>Select</option>
                  {isDataManagement && <option>Data Management </option>}
                  {isDataRule && <option>Data Rules </option>}
                </Form.Select>
                {!validateAction && (
                  <span className="validation-error-msg">
                    Please fill out this field.
                  </span>
                )}
              </Form.Group>
              <Form.Group as={Col} lg={2}>
                <button
                  className="btn btn-outline-primary mt-4"
                  //to={`${DataActionPath}${databaseId}/${tableConfigId}`}
                  onClick= {() => handleSubmit(DataActionPath, databaseId, tableConfigId)}
                >
                  Load
                </button>
              </Form.Group>
            </Row>
          </Card.Body>
          <Card.Footer />
        </Card>
      </div>
    </>
  );
};

export default activeGovernanceLandPage;
