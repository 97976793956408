/*This page comes with Falcon, it is now used for prototying purpose and can be deleted later on*/
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-undef */
import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import activeDirectoryService from 'services/activeDirectory-service';
import Select from 'react-select';
const Starter = () => {
  const [owners, setOwners] = useState([]);
  const [formData, setFormData] = useState({    
    owner:'vkong@nybc.org'    
  });
  function onInputChangeOwner(inputValue) {
    if (inputValue.length > 0) {  
      activeDirectoryService.searchPeople(inputValue).then(res => {
        const validList = res.value.filter(function(item) {return item.userPrincipalName !== null;});
        const users = validList.map(user => { return { label: user.displayName, value: user.userPrincipalName } });
        setOwners(users);
        //console.log(formData.owner);
      });
    }
  }
 
  return (
    <>
      <Row className="mb-3 g-3">
        <Form.Group as={Col} lg={4} controlId="owner">
          <Form.Label>Owner</Form.Label>
          <Select
            // className={validateDomain ? '' : 'validation-error'}
            closeMenuOnSelect={true}
            options={owners}
            placeholder="search"
            //classNamePrefix="react-select"            
            value={owners.find(item => item.value === formData.Owner)}
            onChange={e => setFormData({...formData, owner: e.value})}
            onInputChange={onInputChangeOwner}
          />
        </Form.Group>
      </Row>
    </>
  );
};

export default Starter;
