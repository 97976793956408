/* eslint-disable prettier/prettier */
import React from 'react';
import TableauEmbed from './TableauEmbed';
import SpinnerCubeGrid from '../../components/common/spinner-cube-grid';
import tableauService from '../../services/tableau-service';
import './tableau-dashboard.css';
import { isMobile } from 'react-device-detect';

const isMobileView = width => {
  return isMobile || width <= 768;
};

class PbiDashboardPage extends React.Component {
  constructor(props) {
    super(props);

    this.tabContainer = React.createRef();

    this.state = {
      isLoadingDashboard: true,
      error: null,
      config: {},
      isTabeau: false,
      isPowerBI: false,
      tableauPath: null,
      trustedTicket: null,
      isMobile: isMobileView(window.innerWidth),
      isResponsive: false
    };
  }

  componentDidMount() {
    this.embedDashboard();
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
  
  embedDashboard = () => {
    /* eslint-disable-next-line */
    const username = this.props.tableauUserName;

    tableauService.fetchTableauToken(username).then(trustedToken => {
      if (!trustedToken.data[0].name.includes('-1')) {
        this.setState({
          trustedTicket: trustedToken.data[0].name,
          isTabeau: true,
          /* eslint-disable-next-line */
          tableauPath: this.props.path
        });
    /* eslint-disable-next-line */
        tableauService.getTableauViewHeight(this.props.catalogDefaultReportId).then(height => {
          document.documentElement.style.setProperty('--tabDashboardHeight', height + 'px');
          //document.documentElement.style.setProperty('--tabDashboardHeight', '10px');
        });        
        
      } else {
        window.location.href = '/errors/TableauLicenseError';
      }
    });
    /* eslint-disable-next-line */
    if (this.props.isTableauAutoSized == 'Yes') {
      this.setState({
        isResponsive: true
      });
    } else {
      this.setState({
        isResponsive: false
      });
    }
  };

  render() {
    const {
      isLoadingDashboard,
      isTabeau,
      tableauPath,
      trustedTicket,
      isMobile
    } = this.state;

    return (
      <div
        className={
          this.state.isResponsive ? 'tab-responsive-dashboard' :'tab-dashboard'
        }
      >
        {isLoadingDashboard &&
          isTabeau && <SpinnerCubeGrid active={true} /> && (
            <TableauEmbed
              tableauPath={tableauPath}
              device={isMobile ? 'phone' : 'desktop'}
              trustedTicket={trustedTicket}
            />
          )}
      </div>
    );
  }
}

export default PbiDashboardPage;
