/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'react-bootstrap';
import FalconCloseButton from '../../../components/common/FalconCloseButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import activeDirectoryService from 'services/activeDirectory-service';
import hemService from 'services/hem-service';
import Enum from 'common/enum';
// import { stringify } from 'querystring';
const AskQuestionModal = ({ show, handleClose}) => {
  const profile = JSON.parse(localStorage.getItem(Enum.CACHED_USER_PROFILE));
  const [content, setContent] = useState('');
  
  function onClickSend() {  
    const message = {isHTML: true, subject: 'Hemetrics - FAQ', 
                     content: content.replace(/(?:\r\n|\r|\n)/g, "<br/>"),
                     to: [''], cc: [profile.email], from: profile.email, noReply: false}; // sender must be hemestrics@nybc.org
    hemService.faqEmail(message).then(() => {
      //console.log('email sent');
    }).catch(err => {
      console.log(err);
    });
    handleClose();
  }

  return (
  <Modal show={show} onHide={handleClose} contentClassName="border-0">
    <Modal.Header className="bg-card-gradient light border-bottom-0">
      <Modal.Title as="h5" className="text-white">
        Ask your question
      </Modal.Title>
      <FalconCloseButton noOutline variant="white" onClick={handleClose} />
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label className="fs--1" htmlFor="name">
            Name
          </Form.Label>
          <Form.Control
            type="text"
            id="name"
            value={profile && (profile.firstName + ' ' + profile.lastName)}
            readOnly="readonly"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="fs--1" htmlFor="email">
            Email
          </Form.Label>
          <Form.Control
            type="email"
            id="email"
            value={ profile && profile.email}
            readOnly="readonly"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="fs--1" htmlFor="question">
            Question
          </Form.Label>
          <Form.Control           
           onChange={(e)=>{setContent(e.target.value); }}
           as="textarea" rows={4} id="question" />
        </Form.Group>
        <Button varient="primary" onClick={onClickSend}  size="sm" className="px-4">
          <FontAwesomeIcon icon="paper-plane" className="me-2" />
          Send
        </Button>        
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <h6>Please do not submit data and/or report requests through this form. For all requests, submit a work ticket through the Service Ticket icon.</h6>
    </Modal.Footer>
  </Modal>
)};

AskQuestionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  user: PropTypes.string,
  email: PropTypes.string
};

export default AskQuestionModal;
