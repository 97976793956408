import React, { useContext, useState } from 'react';
import { ButtonGroup, Form, Row } from 'react-bootstrap';
// import defaultModeImg from 'assets/img/generic/falcon-mode-default.jpg';
// import darkModeImg from 'assets/img/generic/falcon-mode-dark.jpg';
// import invertedImg from 'assets/img/generic/inverted.png';
import cardImg from 'assets/img/generic/card.png';
// import vibrantImg from 'assets/img/generic/vibrant.png';
import transparentImg from 'assets/img/generic/default.png';
// import leftArrowFromLeft from 'assets/img/icons/left-arrow-from-left.svg';
import arrowsH from 'assets/img/icons/arrows-h.svg';
import paragraph from 'assets/img/icons/paragraph.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AppContext from '../../../context/Context';
import RadioItem from '../../../components/settings-panel/RadioItem';
import SoftBadge from 'components/common/SoftBadge';
import { Helmet } from 'react-helmet';

const Settings = () => {
  const {
    config: {
      isFluid,
      // isRTL,
      isDark,
      navbarPosition,
      navbarStyle,
      gridRowsCount
    },
    setConfig
  } = useContext(AppContext);

  const [navbars] = useState([
    {
      name: 'transparent',
      image: transparentImg
    },
    // {
    //   name: 'inverted',
    //   image: invertedImg
    // },
    {
      name: 'card',
      image: cardImg
    }
    // {
    //   name: 'vibrant',
    //   image: vibrantImg
    // }
  ]);

  const handleChange = e => {
    setConfig('gridRowsCount', e.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <h5>
        <FontAwesomeIcon icon="palette" className="me-2 fs-0" />
        Settings
      </h5>
      <p className="mb-0 fs--1 opacity-75">Set your own customized style </p>
      <br />
      <h5 className="fs-0">Color Scheme</h5>
      <p className="fs--1">Choose the perfect color mode for your app.</p>
      <Form.Check
        type="radio"
        id="topNav-radio"
        label="Light"
        name="theme-mode"
        checked={!isDark}
        inline
      />

      {/* <ButtonGroup className="btn-group-navbar-style"> */}
      {/* <RadioItem
          name="theme-mode"
          label="light"
           active={!isDark}
          onChange={({ target }) => setConfig('isDark', !target.checked)}
          image={defaultModeImg}
        /> */}
      {/* <RadioItem
          name="theme-mode"
          label="dark"
          active={isDark}
          onChange={({ target }) => setConfig('isDark', target.checked)}
          // image={darkModeImg}
        /> */}
      {/* </ButtonGroup> */}

      <hr />

      {/* <Flex justifyContent="between">
        <img src={leftArrowFromLeft} alt="" width={20} className="me-2 h-100" />
        <div className="flex-1">
          <h5 className="fs-0">RTL Mode</h5>
          <p className="fs--1 mb-0">Switch your language direction </p>
        </div>
        <Form.Check
          type="switch"
          id="rtl-switch"
          checked={isRTL}
          onChange={({ target }) => setConfig('isRTL', target.checked)}
        />
      </Flex>
      <hr /> */}

      <Flex justifyContent="between">
        <img src={arrowsH} alt="" width={20} className="me-2 h-100" />
        <div className="flex-1">
          <h5 className="fs-0">Fluid Layout</h5>
          <p className="fs--1 mb-0">Toggle container layout system</p>
        </div>
        <Form.Check
          type="switch"
          id="fluid-mode-switch"
          checked={isFluid}
          onChange={({ target }) => setConfig('isFluid', target.checked)}
        />
      </Flex>
      <hr />

      <Flex>
        <img src={paragraph} alt="" width={20} className="me-2 h-100" />
        <div>
          <Flex alignItems="center" tag="h5" className="fs-0">
            Navigation Position
            <SoftBadge bg="success" pill className="fs--2 ms-2">
              New
            </SoftBadge>
          </Flex>
          <p className="fs--1 mb-2">
            Select a suitable navigation system for your web application
          </p>
          <Form.Check
            type="radio"
            id="verticalNav-radio"
            label="Vertical"
            name="NavBarPositionRadioButton"
            checked={navbarPosition === 'vertical'}
            onChange={() => setConfig('navbarPosition', 'vertical')}
            inline
          />
          <Form.Check
            type="radio"
            id="topNav-radio"
            label="Top"
            name="NavBarPositionRadioButton"
            checked={navbarPosition === 'top'}
            onChange={() => setConfig('navbarPosition', 'top')}
            inline
          />
          {/* <Form.Check
            type="radio"
            id="combo-radio"
            label="Combo"
            name="NavBarPositionRadioButton"
            checked={navbarPosition === 'combo'}
            onChange={() => setConfig('navbarPosition', 'combo')}
            inline
          /> */}
        </div>
      </Flex>

      <hr />
      <h5 className="fs-0 d-flex align-items-center">Vertical Navbar Style </h5>
      <p className="fs--1">Switch between styles for your vertical navbar</p>
      <ButtonGroup className="btn-group-navbar-style">
        {navbars.slice(0, 2).map(item => (
          <RadioItem
            key={item.name}
            name="navbar-style"
            label={item.name}
            active={navbarStyle === item.name}
            onChange={() => setConfig('navbarStyle', item.name)}
            image={item.image}
          />
        ))}
      </ButtonGroup>
      <ButtonGroup className="btn-group-navbar-style">
        {navbars.slice(2, 4).map(item => (
          <RadioItem
            key={item.name}
            name="navbar-style"
            label={item.name}
            active={navbarStyle === item.name}
            onChange={() => setConfig('navbarStyle', item.name)}
            image={item.image}
          />
        ))}
      </ButtonGroup>

      <hr />

      <h5 className="fs-0 d-flex align-items-center">Data Grid Row Count </h5>
      <Row className="mb-3 g-3">
        <p className="fs--1">
          Setting of rows count of per page of all data grids in the application
        </p>

        <Form.Control
          type="text"
          placeholder="Rows Count"
          value={gridRowsCount}
          name="gridRowCount"
          onChange={handleChange}
        />
      </Row>

      <hr />
    </>
  );
};

export default Settings;
