/* eslint-disable prettier/prettier */
import React from 'react';
import { Row } from 'react-bootstrap';
import { version } from '../../config';

const Footer = () => (
  <footer className="footer">
    <Row className="justify-content-between text-center fs--1 mb-2">
      {/* <Col sm="auto"> */}
      <div className="mb-0 text-600">
        © New York Blood Center 2015 - {new Date().getFullYear()} Hemetrics is
        not an FDA regulated or cleared device and is not to be used in the
        collection, processing, or distribution of blood or blood products. v
        {version}
      </div>
      {/* </Col> */}
    </Row>
  </footer>
);

export default Footer;
