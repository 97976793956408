/* eslint-disable prettier/prettier */
import { faExclamationCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { Modal, Row } from 'react-bootstrap';

const OverrideDeferralDatesModal = props => {

        const validation = {
            plasmaDeferralDate: (value) => {
                if (props.plasmaDeferralDate && !value) {
                    return { isValid: false, message: 'If a plasma deferral date has been calculated it cannot be overridden with a null value.' };
                } else if (props.plasmaDeferralDate && value && props.plasmaDeferralDate > value) {
                    return { isValid: false, message: 'Overridden plasma deferral date must be greater than or equal to the calculated date.' };
                } else {
                    return { isValid: true, message: '' };
                }
            },
            rbcDeferralDate: (value) => {
                if (props.rbcDeferralDate && !value) {
                    return { isValid: false, message: 'If a RBC deferral date has been calculated it cannot be overridden with a null value.' };
                } else if (props.rbcDeferralDate && value && props.rbcDeferralDate > value) {
                    return { isValid: false, message: 'Overridden RBC deferral date must be greater than or equal to the calculated date.' };
                } else {
                    return { isValid: true, message: '' };
                }
            },
            comment: (value) => {
                return value && value.length > 0 && value.length <= 1000 ? { isValid: true, message: '' } : { isValid: false, message: 'The comment field is required and must be less than or equal to 1000 characters.' };
            }
        }
        const [fields, setFields] = React.useState({
            plasmaDeferralDate: props.plasmaDeferralDate, 
            rbcDeferralDate: props.rbcDeferralDate, 
            comment: props.comment
        });

        // const [validation, setValidation] = React.useState(validationModel);
        // const [plasmaDeferralDate, setPlasmaDeferralDate] = React.useState(props.plasmaDeferralDate);
        // const [rbcDeferralDate, setRBCDeferralDate] = React.useState(props.rbcDeferralDate);
        // const [comment, setComment] = React.useState(props.comment);
        const [validationErrors, setValidationErrors] = React.useState({});
    

    const changePlasmaDeferralDate = (plasmaDeferralDate) => {
        // setPlasmaDeferralDate(plasmaDeferralDate);
        setFields({ ...fields, plasmaDeferralDate: plasmaDeferralDate });
        setValidationErrors({ ...validationErrors, plasmaDeferralDate: undefined });
    }

    const changeRBCDeferralDate = (rbcDeferralDate) => {
        // setRBCDeferralDate(rbcDeferralDate);
        setFields({ ...fields, rbcDeferralDate: rbcDeferralDate });
        setValidationErrors({...validationErrors, rbcDeferralDate: undefined});
    }
    const changeComment = (e) => {
        // setComment(comment);
        setFields({ ...fields, comment: e.target.value });
        setValidationErrors({ ...validationErrors, comment: '' });
    }

    const attemptToOverride = () => {
     
        const validationErrors = {};
        Object.keys(validation).forEach(name => {
            const value = fields[name];
            const result = validation[name](value);
            if (!result.isValid) {
                validationErrors[name] = result.message
            }
        })

        if (Object.keys(validationErrors).length > 0) {
            setValidationErrors(validationErrors);
        } else {
            props.override(fields);
        //    props.override(plasmaDeferralDate, rbcDeferralDate, comment);
        }
    }
    return (
        <Modal show={props.visible} backdrop="static" keyboard={false}>
            <Modal.Header  className="d-flex justify-content-center">
            <Modal.Title>
                        <Row><span  className='text-center' style={{ fontSize: "68px" }}> <FontAwesomeIcon className="modal-icon" icon={faExclamationCircle} /> </span> </Row>
                        <Row>Warning!</Row>                        
                    </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Enter values below to override deferral dates. Valid dates must be provided for change to be saved.</p>
                <div className={`form-group${validationErrors["plasmaDeferralDate"] ? ' has-error' : ''}`}>
                    <label className="mb-0">Plasma Deferral Date:</label>
                    <br />
                    <DatePicker
                        selected={fields.plasmaDeferralDate}
                        name="plasmaDeferralDate"
                        className="form-control"
                        onChange={changePlasmaDeferralDate}
                        autoComplete="off" />
                    {validationErrors["plasmaDeferralDate"] && <span className="form-text m-b-none">{validationErrors["plasmaDeferralDate"]}</span>}
                </div>
                <div className={`form-group${validationErrors["rbcDeferralDate"] ? ' has-error' : ''}`}>
                    <label className="mb-0">RBC Deferral Date:</label>
                    <br />
                    <DatePicker
                        selected={fields.rbcDeferralDate}
                        name="rbcDeferralDate"
                        className="form-control"
                        onChange={changeRBCDeferralDate}
                        autoComplete="off" />
                    {validationErrors["rbcDeferralDate"] && <span className="form-text m-b-none">{validationErrors["rbcDeferralDate"]}</span>}
                </div>
                <div className={`form-group${validationErrors["comment"] ? ' has-error' : ''}`}>
                    <label>Comments (additional details/rationale for deferral dates)</label>
                    <textarea className="form-control"
                        name="comment"
                        value={fields.comment}
                        onChange={changeComment}
                        rows="6"
                        style={{ resize: 'none' }}>
                    </textarea>
                    {validationErrors["comment"] && <span className="form-text m-b-none">{validationErrors["comment"]}</span>}
                </div>
            </Modal.Body>
            <Modal.Footer> 
                <button          className="btn btn-outline-primary me-1 mb-1"  onClick={props.hide}            >
              <FontAwesomeIcon icon={faTimes} /> Cancel
            </button>
                <button type="button" className="btn btn-primary" onClick={attemptToOverride}>Override</button>
            </Modal.Footer>
        </Modal>
    );
        
    
}; // end OverrideDeferralDatesModal 

OverrideDeferralDatesModal.propTypes = {
    visible: PropTypes.bool,
    hide: PropTypes.func,
    override: PropTypes.func,
    plasmaDeferralDate: PropTypes.string,
    rbcDeferralDate: PropTypes.string,
    comment: PropTypes.string
}

export default OverrideDeferralDatesModal;