/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
//import { Link } from 'react-router-dom';
import homeService from 'services/home-service';
import Background from 'components/common/Background';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';
//import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';

const BloodProductInventory = () => {
  //const profile = JSON.parse(localStorage.getItem('userProfile'));
  //var myDate = new Date();
  //var hrs = myDate.getHours();
  const profile = JSON.parse(localStorage.getItem('userProfile'));
  var emailSplit = profile.email.split('@');
  //const [inventorySupply, setInventorySupply] = useState([]);
  const NameForTotal = 'NYBCe';
  const [divisions, setDivisions] = useState([]); //['NYBCe','NYBC','IBR','RIBC'];
  const [allData, setAllData] = useState([]);
  const [oneDivision, setOneDivision] = useState(profile.isEnterpriseView ? NameForTotal : emailSplit[1].split('.')[0].toUpperCase());
  const [dataCryo, setDataCryo] = useState(0);
  const [dataRBC, setDataRBC] = useState(0);
  const [dataPlasma, setDataPlasma] = useState(0);
  const [dataPlatelets, setDataPlatelets] = useState(0);
  const sortOrder = [NameForTotal,'NYBC','CBC', 'IBR', 'RIBC', 'BBD'];

  useEffect(() => {
    homeService.getInventorySupply().then(result => {

          //setInventorySupply(result);
          const temp = result.map(a=> { return {cryo: a.cryo, plasma: a.plasma, rbc: a.rbc, platelets: a.platelets} } );

        //sum up all units by phlebotomy and collectionWeek
        const sumOfUnits = Array.from(temp.reduce((acc, {cryo, plasma, rbc, platelets, ...r}) => {
          const key = JSON.stringify(r);
          const current = acc.get(key) || {...r, cryo: 0, plasma: 0, rbc:0, platelets:0 };
          return acc.set(key, {...current, cryo: current.cryo + cryo, plasma: current.plasma + plasma, rbc: current.rbc + rbc, platelets: current.platelets + platelets});
        }, new Map).values());

        // const sumOfUnits = Array.from(
          
        //   return temp.reduce((total, value) => total += value[key], 0);
        
        // );
        const allData = sumOfUnits.map(a => ({ division: NameForTotal, ...a }));
        // console.log(allData);
        allData.map(a=> result.push(a));
        setAllData(result);
        var divisionValues = [...new Set(result.map(a => a.division))];
        divisionValues.sort(
          function(a, b){                
              return sortOrder.indexOf(a) - sortOrder.indexOf(b); 
          }
        );
        setDivisions(divisionValues);      
        //setDivisions([...new Set(result.map(a => a.division))]);   

        setDataCryo(result.filter(a=>a.division === oneDivision)[0].cryo);        
        setDataRBC(result.filter(a=>a.division === oneDivision)[0].rbc);
        setDataPlasma(result.filter(a=>a.division === oneDivision)[0].plasma);
        setDataPlatelets(result.filter(a=>a.division === oneDivision)[0].platelets);

    }).catch((err) => {
      console.log("Landing Page Error:" + err);
    })
  }, []);

  function onChangeDivision (e) {
    setOneDivision(e.target.value);
    const divisionData = allData.filter(a=>a.division === e.target.value);
    // setLabels([...new Set(divisionData.map(a => moment(a.collectionWeek).format('MM-DD')))]);
    // setDataPLT(divisionData.filter(a=>a.phlebotomy === 'Platelets').map(a=>a.units));        
    // setDataRBC([...new Set(divisionData.filter(a=>a.phlebotomy === 'Red Blood Cells').map(a=>a.units))]);
    // setDataPlasma([...new Set(divisionData.filter(a=>a.phlebotomy === 'Plasma').map(a=>a.units))]);
    setDataCryo(divisionData[0].cryo);        
    setDataRBC(divisionData[0].rbc);
    setDataPlasma(divisionData[0].plasma);
    setDataPlatelets(divisionData[0].platelets);
  }

  return (
    <Card className="h-md-100">
      <Background image={bg2} className="p-card bg-card" />
      <div className="position-relative z-index-0">
      <FalconCardHeader
          title={ <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>Shows current inventory stock by days supply for each major product group. Days supply is calculated based on previous 90 days hospital shipments.</Tooltip>
            }
          >
            <span> Inventory Days Supply
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                transform="shrink-1"
                className="ms-1 text-400"
                id="weeklySalesTooltip"
              />
            </span>
          </OverlayTrigger>}
          titleTag="h6"          
          className="flex-center py-2"
          light
          endEl={
            <Flex>
              <Form.Select
                size="sm"
                value={oneDivision}
                onChange={onChangeDivision}
                //className="me-2"
              >
                {divisions.map((label) => (
                  <option value={label} key={label}>
                    {label}
                  </option>
                ))}
              </Form.Select>
            </Flex>
          }
        />
        </div>
      {/* <Card.Header>
          <div>
          <Row className="flex-between-center g-0">
            <Col xs="auto">
                <h6 className="mb-0">Blood Product Inventory</h6>
              </Col>
              <Col xs="auto" className="d-flex">
                <Form.Select
                  size="sm"
                  value={oneDivision}
                  onChange={onChangeDivision}
                  className="me-2"
                >
                  {divisions.map((label) => (
                    <option value={label} key={label}>
                      {label}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
          </div>
        </Card.Header> */}
      <Card.Body className="pb-2">
        <div className="position-relative z-index-2">
        {/* <div>
          <h6 className="mb-2 mt-0">Current Blood Product Inventory</h6>
        </div> */}
          <Row className="py-2">
            <Col className="px-4">
            <h6 className='text-primary'>
            RBC {dataRBC == 0 ? 0 : dataRBC.toLocaleString('en-US')}
              {/* <CountUp
                start={0}
                end={dataRBC}
                duration={2.75}
                //suffix={suffix}
                prefix= 'RBC  '
                separator=","
                decimals={0}
                decimal="."
              /> */}
             {/* {inventorySupply.length > 0 && inventorySupply.filter(a => a.productType == "RBC")[0].daySupply} */}
             </h6>
            </Col>
            <Col className="pe-2">
            <h6 className='text-primary'>
            Plasma {dataPlasma == 0 ? 0 : dataPlasma.toLocaleString('en-US')}
              {/* <CountUp
                start={0}
                end={dataPlasma}
                duration={2.75}
                //suffix={suffix}
                prefix= 'Plasma  '
                separator=","
                decimals={0}
                decimal="."
              /> */}
             {/* {inventorySupply.length > 0 && inventorySupply.filter(a => a.productType == "RBC")[0].daySupply} */}
            </h6>
            </Col>        
          </Row>
          <Row className="pb-2">
            <Col className="px-4">
            <h6 className='pt-2 text-primary'>
            Platelets {dataPlatelets == 0 ? 0 : dataPlatelets.toLocaleString('en-US')}
              {/* <CountUp
                start={0}
                end={dataPlatelets}
                duration={2.75}
                //suffix={suffix}
                prefix= 'Platelets  '
                separator=","
                decimals={0}
                decimal="."
              /> */}
             {/* {inventorySupply.length > 0 && inventorySupply.filter(a => a.productType == "PLT")[0].daySupply} */}
            </h6>
            </Col>
            <Col className="pe-2">
            <h6 className='pt-2 text-primary'>
            Cryo {dataCryo == 0 ? 0 : dataCryo.toLocaleString('en-US')}
              {/* <CountUp
                start={0}
                end={dataCryo}
                duration={2.75}
                //suffix={suffix}
                prefix= 'Cryo  '
                separator=","
                decimals={0}
                decimal="."
              /> */}
             {/* {inventorySupply.length > 0 && inventorySupply.filter(a => a.productType == "PLT")[0].daySupply} */}
            </h6>
            </Col>  
          </Row>
          <div className="text-end">
            <Link to="/dashboards/13057" className="fw-semi-bold fs--1 text-nowrap" size="sm">
              View<FontAwesomeIcon
                icon= {faAngleDoubleRight}
                className="ms-1"
                transform="down-1"
              />
            </Link>
          </div>          
        </div>
      </Card.Body>
            
      {/* <Card.Body>
        <div>
        
        </div>
      </Card.Body> */}
    </Card>
  );
};

export default BloodProductInventory;
