/* eslint-disable prettier/prettier */
import PageHeader from 'components/common/PageHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { Card, Breadcrumb, Row, Col, Form } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import GovernanceService from 'services/governance-service';
import { getItemFromStore } from '../../helpers/utils';
import { settings } from '../../config';
import governanceService from 'services/governance-service';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfirmationMessage from 'components/common/ConfirmationMessage';
import { Helmet } from 'react-helmet';

const DataRuleNotification = () => {
  let params = useParams();
  const databaseId = params.databaseId;
  const tableConfigId = params.tableConfigId;
  const dataRuleId = params.dataRuleId;
  const [data, setData] = useState([]);
  const [roles, setRoles] = useState([]);
  const [ruleName, setRuleName] = useState([]);
  const [isTableDisplay, setIsTableDisplay] = useState(false);
  const [dynamicBreadcrumb, setDynamicBreadcrumb] = useState('');
  const [definedBreadcrumb, setDefinedBreadcrumb] = useState('');
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const configState = {
    gridRowsCount: getItemFromStore('gridRowsCount', settings.gridRowsCount)
  };
  const [notificationTypes, setNotificationTypes] = useState([]);
  var columns = [
    {
      accessor: 'userName',
      Header: 'Name',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start'
      }
    },
    {
      accessor: 'notificationOption',
      Header: 'Notification Option',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start'
      },
      Cell: rowData => (
        <Select
          closeMenuOnSelect={true}
          options={notificationTypes}
          placeholder="Select Notification Types"
          isMulti
          //classNamePrefix="react-select"
          value={getNotificationValue(rowData.row.original.recipient)}
          onChange={e => {
            notificationChanged(rowData.row.original.recipient, e);
          }}
        />
      )
    }
  ];
  const getNotificationValue = email => {
    var options = data.filter(item => item.recipient == email);
    if (options.length > 0) {
      return options[0].notificationTypes;
    } else {
      return [];
    }
  };
  const notificationChanged = (email, e) => {
    setIsSaveDisabled(true);
    setData(current =>
      current.map(obj => {
        if (obj.recipient === email) {
          return {
            ...obj,
            notificationTypes: e,
            notificationType: '',
            dataRuleId: dataRuleId,
            recipient: email
          };
        }
        return obj;
      })
    );
  };
  const cancelPage = () => {
    window.location.href =
      '/governance/governanceDataRule/' + databaseId + '/' + tableConfigId + '/' + dataRuleId;
  };
  const onClickSave = () => {
    setIsSaveDisabled(false);
    governanceService
      .SaveNotificationRecipient(data)
      .then(() => {
        // toast('Notification Recipient Saved Successfully!', {
        //   autoClose: true,
        //   className: 'toast-container success'
        // });
        toast(ConfirmationMessage("Notification Saved", "Notification Recipient Saved Successfully!", "bg-success", "faCheckCircle"), {
          autoClose: true,
          //className: 'transparent',
          //style: {{background-color: transparent !important}},
          position: 'bottom-right',
          hideProgressBar: true,
          closeButton: false,
        });
      })
      .then(() => {
        window.location.href = '/governance/dataRulesView/' + databaseId + '/' + tableConfigId;
      });
  };

  function renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }
  useEffect(() => {
    const selectRoles = GovernanceService.getRoles();
    const selectNotificationTypes = GovernanceService.getNotificationTypes();
    const selectRuleName = GovernanceService.getDataRulebyRuleId(dataRuleId);
    const dynamicBreadcrumb = '/governance/dataRulesView/' + databaseId + '/' + tableConfigId;
    const definedBreadcrumb =
      '/governance/governanceDataRule/' + databaseId + '/' + tableConfigId + '/' + dataRuleId;
    setDynamicBreadcrumb(dynamicBreadcrumb);
    setDefinedBreadcrumb(definedBreadcrumb);
    Promise.all([selectRoles, selectNotificationTypes, selectRuleName])
      .then(([roles, notificationTypes, ruleName]) => {
        setRoles(roles);
        notificationTypes.forEach(item => {
          renameKey(item, 'notificationTypeId', 'value');
          renameKey(item, 'notificationType1', 'label');
          delete item['recCreatedDt'];
          delete item['recModifiedDt'];
          delete item['dataRuleConfigs'];
        });
        setNotificationTypes(notificationTypes);
        setRuleName('Data Rules Notification <' + ruleName.dataRuleName + '>');
        //setTableName('Display Data Rules <' + tableData[0].TableName + '>');
      })
      //.then(() => roleChanged(339));
  }, []);

  const roleChanged = value => {
    GovernanceService.getUsersByRoleId(value, dataRuleId).then(users => {
      setData(users);
      if (users.length > 0) {
        setIsTableDisplay(true);
      } else {
        setIsTableDisplay(false);
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>Data Rules Notification</title>
      </Helmet>
      <PageHeader title={ruleName} className="mb-2">
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href={dynamicBreadcrumb}>
            Rule Management
          </Breadcrumb.Item>
          <Breadcrumb.Item href={definedBreadcrumb}>
            Defined Data Rules
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Data Rules Notification</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>
      <Card>
        <Card.Header>
          Data Rules Notification allows designated data stewards to create an in-portal alert or an email alert for members of the Enterprise Data Steward group.
          <br/>
          <br/>
          <Row className="flex-between-center">
            <Col xs={9} sm={6} lg={3} className="text-start">
              <Form.Label>Configure Recipient(s):</Form.Label>
            </Col>
          </Row>
          <Row className="g-3">
            <Form.Label column="sm" lg={2}>
              Role
            </Form.Label>
            <Col xs="auto" sm={3} lg={3} className="text-start">
              <Form.Select
                name="Role"
                size="sm"
                //value={339}
                onChange={e => roleChanged(e.target.value)}
                //disabled={true}
              >
                <option value="0">Select</option>
                {roles.map(i => (
                  <option key={i.roleId} value={i.roleId}>
                    {i.name}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Card.Header>
        {isTableDisplay && (
          <Card>
            <Card.Body>
              <AdvanceTableWrapper
                columns={columns}
                data={data}
                //selection
                sortable
                pagination
                perPage={configState.gridRowsCount}
                rowCount={data.length}
              >
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    className: 'fs--1 mb-8'
                  }}
                />
              </AdvanceTableWrapper>
            </Card.Body>
          </Card>
        )}
        {isTableDisplay && (
          <Card>
            <Card.Body>
              <Row className="mb-3 g-3">
                <div className="text-end">
                  {/* <Button size="sm" variant={'warning'} onClick={cancelPage}>
                    Cancel
                  </Button> */}
                  <button
                    className="btn btn-outline-primary me-1 mb-1"
                    onClick={cancelPage}
                    value="cancel"
                  >
                    <FontAwesomeIcon icon={faTimes} /> Cancel
                  </button>
                  {/* <Button
                    size="sm"
                    variant={'success'}
                    className="px-4 ms-2"
                    disabled={!isSaveDisabled}
                    onClick={onClickSave}
                    type="submit"
                  >
                    Save
                  </Button> */}
                  <button
                    className="btn btn-outline-primary me-1 mb-1"
                    onClick={onClickSave}
                    value="save"
                    disabled={!isSaveDisabled}
                  >
                    <FontAwesomeIcon icon={faSave} /> Save
                  </button>
                </div>
              </Row>
            </Card.Body>
          </Card>
        )}
      </Card>
    </>
  );
};

export default DataRuleNotification;
