import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const TableauLicenseError = () => {
  return (
    <Card className="text-center">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">900</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          Visualization Access Invalid.
        </p>
        <hr />
        <p>
          Please contact Hemetrics Support to enable your permissions,
          <a
            target="_blank"
            href="https://nybc.service-now.com/sp"
            className="ms-1"
            rel="noreferrer"
          >
            contact us
          </a>
          .
        </p>
        <Link className="btn btn-primary btn-sm mt-3" to="/">
          <FontAwesomeIcon icon={faHome} className="me-2" />
          Take me home
        </Link>
      </Card.Body>
    </Card>
  );
};

export default TableauLicenseError;
