/* eslint-disable prettier/prettier */
import _ from 'lodash';
import Enum from 'common/enum';
// import { PublicClientApplication } from "@azure/msal-browser";
// import { msalConfig } from '../authConfig';

const config = {
    clientId: process.env.REACT_APP_AZURE_APP_ID,
    // clientId: "7a677e06-4bbc-4096-ba74-c3adfdb5f610",
    tenant: process.env.REACT_APP_AZURE_TENANT_ID,
    //   tenant: "3749a395-c740-4801-8489-fcc2a48bb213",
    redirectUri: `${window.location.protocol}//${window.location.hostname}${window.location.port !== 80 ? ':' + window.location.port : ''}`
};
config.postLogoutRedirectUri = config.redirectUri;

class AuthService {
    constructor() {
        this.rootPath = 'https://graph.microsoft.com/v1.0';
        // this.msalInstance = new PublicClientApplication(msalConfig);
        if (navigator.userAgent.indexOf('MSIE') !== -1
            || navigator.appVersion.indexOf('Trident/') > -1) {
            // enable this for IE, as sessionStorage does not work for localhost.
            // Fix reference: https://gist.github.com/tushargupta51/78ce0b6bce998f6851abd02d91eb3a95 & Issue: https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/FAQs#q1-my-app-is-re-loading-every-time-adal-renews-a-token
            config.cacheLocation = 'localStorage';
        }
    }
    cacheCurrentUrl = () => {
        // Check if we have a cached url already, we don't override
        let cachedUrle = window.localStorage.getItem(Enum.CACHED_URL);
        if (!cachedUrle) {
            let href = window.location.href;
            if ([href, href.substring(0, href.length - 1)].includes(config.redirectUri)) {
                //Return to default dashboard
                href = '/';
            }

            window.localStorage.setItem(Enum.CACHED_URL, href);
        }
    }

    getAndRemoveCachedCurrentUrl = () => {
        let url = window.localStorage.getItem(Enum.CACHED_URL);
        window.localStorage.removeItem(Enum.CACHED_URL);
        return url;
    }


    canSearch = (componentType) => {
        let response = false;

        switch (componentType) {
            case 'ih-patient':
                response = this.hasPermission(this.claims.searchOrViewPatientOrSampleCases);
                break;

            default:
                break;
        }

        return response;
    }

    canView = (componentType) => {
        let response = false;

        switch (componentType) {
            case 'data-glossary':
                response = this.hasPermission(this.claims.viewAllGlossaryTerms) || this.hasPermission(this.claims.viewApprovedGlossaryTerms);
                break;

            case 'data-configuration':
                response = this.hasPermission(this.claims.viewDataConfiguration);
                break;

            case 'ih-patient':
                response = this.hasPermission(this.claims.searchOrViewPatientOrSampleCases);
                break;

            case 'ih-sample':
                response = this.hasPermission(this.claims.searchOrViewPatientOrSampleCases);
                break;

            case 'ih-case-reports':
                response = this.hasPermission(this.claims.viewOrDownloadCaseReports)
                break;

            case 'data-dictionaries':
                response = this.hasPermission(this.claims.viewDataDictionaries);
                break;

            case 'users':
                response = this.hasPermission(this.claims.viewUserDetails);
                break;

            default:
                break;
        }

        return response;
    }

    canEdit = (componentType, data) => {
        let response = false;

        switch (componentType) {
            case 'users':
                response = this.hasPermission(this.claims.editUsers);
                break;

            case 'user-profile':
                response = this.validateCurrentUserByGlobalId(data && data.globalId);
                break;

            case 'roles':
                response = this.hasPermission(this.claims.editRoles);
                break;

            case 'data-dictionaries':
                response = this.hasPermission(this.claims.editDataDictionaries);
                break;

            case 'data-glossary':
                response = data && ((
                    (data.glossaryTermStatusCode === 'DRF' || data.glossaryTermStatusCode === 'REJ') && this.hasPermission(this.claims.editGlossaryTerms)) ||
                    (data.glossaryTermStatusCode === 'APR' && this.hasPermission(this.claims.approveOrRejectGlossaryTerms)));
                break;

            case 'data-sources':
                response = this.hasPermission(this.claims.editDataSources);
                break;

            case 'data-configuration':
                response = this.hasPermission(this.claims.editDataConfiguration);
                break;

            case 'catalogs':
                response = this.hasPermission(this.claims.editCatalogs);
                break;



            case 'releases':
                response = this.hasPermission(this.claims.editReleases);
                break;

            default:
                return false;
        }

        return response;
    }

    canCreate = (componentType,) => {
        let response = false;

        switch (componentType) {
            case 'roles':
                response = this.hasPermission(this.claims.createRoles);
                break;

            case 'data-dictionaries':
                response = this.hasPermission(this.claims.createDataDictionaries)
                break;

            case 'data-glossary':
                response = this.hasPermission(this.claims.createGlossaryTerms)
                break;

            case 'data-sources':
                response = this.hasPermission(this.claims.createDataSources)
                break;

            case 'catalogs':
                response = this.hasPermission(this.claims.createCatalogs)
                break;

            case 'ih-case-reports':
                response = this.hasPermission(this.claims.createOrEditCaseReports)
                break;

            case 'ih-comments':
                response = this.hasPermission(this.claims.manageIHComments)
                break;

            case 'releases':
                response = this.hasPermission(this.claims.createReleases)
                break;

            default:
                return false;
        }

        return response;
    }

    canDelete = (componentType) => {
        let response = false;

        switch (componentType) {
            case 'roles':
                response = this.hasPermission(this.claims.deleteRoles);
                break;

            case 'data-dictionaries':
                response = this.hasPermission(this.claims.deleteDataDictionaries);
                break;

            case 'data-glossary':
                response = this.hasPermission(this.claims.deleteGlossaryTerms);
                break;
            case 'users':
                response = this.hasPermission(this.claims.deleteUsers);
                break;
            case 'data-sources':
                response = this.hasPermission(this.claims.deleteDataSources);
                break;

            case 'catalogs':
                response = this.hasPermission(this.claims.deleteCatalogs);
                break;

            case 'ih-case-reports':
                response = this.hasPermission(this.claims.deleteCaseReports);
                break;

            case 'releases':
                response = this.hasPermission(this.claims.deleteReleases);
                break;

            default:
                return false;
        }

        return response;
    }

    canSubmit = (componentType, data) => {
        let response = false;

        switch (componentType) {
            case 'data-glossary':
                response = data && data.glossaryTermStatusCode === 'DRF'
                    && this.hasPermission(this.claims.editGlossaryTerms);
                break;

            // case 'ih-case-reports':
            //     response = data && data.reportStatusCode === REPORT_STATUS_CODES.DRAFT && this.hasPermission(this.claims.submitCaseReports);
            //     break;

            default:
                return false;
        }

        return response;
    }

    canPublish = (componentType, data) => {
        let response = false;
        switch (componentType) {
            case 'ih-case-reports':
                response = data && this.hasPermission(this.claims.publishCaseReports);
                break;
            default:
                return false;
        }
        return response;
    }

    canAssignReportToSelf = (componentType, data) => {
        let response = false;
        switch (componentType) {
            case 'ih-case-reports':
                response = data && this.hasPermission(this.claims.assignCaseReportsToSelf);
                break;

            default:
                return false;
        }
        return response;
    }

    canApproveOrReject = (componentType, data) => {
        let response = false;

        switch (componentType) {
            case 'data-glossary':
                if (data && data.glossaryTermStatusCode === 'RVW') {
                    response = data && data.glossaryTermStatusCode === 'RVW'
                        && data.reviewRequest && data.reviewRequest.listOfIdApprovers
                        && !_.includes(data.reviewRequest.listOfIdApprovers, this.getCurrentUserId())
                        && this.hasPermission(this.claims.approveOrRejectGlossaryTerms);
                }
                else {
                    response = this.hasPermission(this.claims.approveOrRejectGlossaryTerms);
                }

                break;

            // case 'ih-case-reports':
            //     response = data && (data.reportStatusCode === REPORT_STATUS_CODES.UNDER_REVIEW || (data.reportStatusCode === REPORT_STATUS_CODES.APPROVED && this.hasPermission(this.claims.rejectApprovedCaseReports)))
            //         && data.assignedForReviewTo === this.getCurrentUserId() && this.hasPermission(this.claims.approveOrRejectCaseReports);
            //     break;

            default:
                return false;
        }

        return response;
    }

    canRejectApproved = (componentType) => {

        switch (componentType) {
            // case 'ih-case-reports':
            //     response = data && data.reportStatusCode === REPORT_STATUS_CODES.APPROVED && this.hasPermission(this.claims.rejectApprovedCaseReports)
            //     break;
            default:
                return false;
        }


    }

    hasPermission = (requiredPermission) => {
        const currentUserProfile = this.getCurrentUserProfile();
        if (!requiredPermission || !currentUserProfile || (currentUserProfile && currentUserProfile.roles && !(currentUserProfile.roles.length > 0))) {
            return false;
        }

        if (requiredPermission === 'all') {
            return true;
        }

        let allUserPermissions = [];
        _.map(currentUserProfile.roles, (role) => {
            if (role.rolePermissions && role.rolePermissions.length > 0) {
                allUserPermissions.push(...role.rolePermissions)
            }
        });

        return !!_.find(allUserPermissions, { permission: requiredPermission });
    }

    /**
     * requiredPermissionNames list of permission code-names
     */
    hasOneOfPermissions = (requiredPermissionNames) => {
        const currentUserProfile = this.getCurrentUserProfile();
        if (!requiredPermissionNames || requiredPermissionNames.length <= 0 || !currentUserProfile || (currentUserProfile && currentUserProfile.roles && !(currentUserProfile.roles.length > 0))) {
            return false;
        }

        // List of all permission names
        let allUserPermissionNames = [];
        _.map(currentUserProfile.roles, (role) => {
            if (role.rolePermissions && role.rolePermissions.length > 0) {
                role.rolePermissions.forEach(permission => {
                    allUserPermissionNames.push(permission.permission)
                })
            }
        });

        let numberOfMatchedPermissions = requiredPermissionNames.map(permissionName => {
            if (!permissionName) return false;
            return allUserPermissionNames.includes(permissionName);
        });

        return numberOfMatchedPermissions.indexOf(true) > -1;
    }

    _hasRole = () => {
        // const currentuser = this.getCurrentUserProfile();
        return true;
    }

    _isAssignedToUserWithId = (id) => {
        const currentuser = this.getCurrentUser();
        return currentuser.profile.id === id;
    }

    // getCurrentUserGlobalId = () => {
    //     const currentuser = this.getCurrentUser();
    //     const globalUserId = currentuser && currentuser.profile && currentuser.profile.sub;
    //     if (globalUserId) {
    //         return globalUserId;
    //     }

    //     return;
    // }

    getCurrentUserId = () => {
        const currentuser = this.getCurrentUserProfile();
        const userId = currentuser && currentuser.id;

        return userId;
    }

    getCurrentUserProfile = () => {
        let currentUser = localStorage.getItem(Enum.CACHED_USER_PROFILE);
        if (!currentUser) {
            return;
        }
        return JSON.parse(currentUser);
    }

    userCanClaim = (claim) => {
        return this.hasPermission(claim);
    }

    /**
     * Returns true if matches cached globalUserId with formal param globalUserId
     */
    validateCurrentUserByGlobalId = (globalUserId) => {
        let currentUser = this.getCurrentUser();
        return currentUser && currentUser.profile && currentUser.profile.sub === globalUserId;
    }

    // Hemetrics Claim Types
    claims = {

        /* General */

        //Allows all users to view this page, no permission needed
        all: "all",

        /* General End */


        /* Auth */
        // === Users ===
        //Determines whether a user can see all users
        fetchUsers: "auth.users.fetch",
        editUsers: "auth.users.edit",
        viewUserDetails: "auth.users.view_details",

        // === Roles ===
        //Determines whether a user can see all roles
        createRoles: "auth.roles.create",
        deleteRoles: "auth.roles.delete",
        fetchRoles: "auth.roles.fetch",
        editRoles: "auth.roles.edit",
        /* Auth End */


        /* Portal */
        // === Dashboards ===        
        //Determines whether a user can see Dashboard Two
        viewDashboardOne: "portal.dashboards.view.one",
        viewDashboardTwo: "portal.dashboards.view.two",
        /* Portal End */

        /* Data Governance */
        // Enterprise Data Governance
        viewDataGovernance: "dg.View",
        createDataGovernance: "dg.Create",
        editDataGovernance: "dg.Edit",
        deleteDataGovernance: "dg.Delete",

        viewKpi: "dg.ViewKpi",
        createKpi: "dg.CreateKpi",
        editKpi: "dg.EditKpi",
        deleteKpi: "dg.DeleteKpi",
        /* Data Governance */
        // === Data Dictionary ===
        fetchDataDictionaries: "dg.dictionaries.fetch",
        createDataDictionaries: "dg.dictionaries.create",
        editDataDictionaries: "dg.dictionaries.edit",
        deleteDataDictionaries: "dg.dictionaries.delete",
        viewDataDictionaries: "dg.dictionaries.view",

        // === Data Glossary ===
        viewAllGlossaryTerms: "dg.glossaryterms.view_all",
        viewApprovedGlossaryTerms: "dg.glossaryterms.view_approved",
        createGlossaryTerms: "dg.glossaryterms.create",
        editGlossaryTerms: "dg.glossaryterms.edit",
        deleteGlossaryTerms: "dg.glossaryterms.delete",
        approveOrRejectGlossaryTerms: "dg.glossaryterms.approve_reject",

        // === Data Sources ===
        fetchDataSources: "dg.datasources.fetch",
        createDataSources: "dg.datasources.create",
        editDataSources: "dg.datasources.edit",
        deleteDataSources: "dg.datasources.delete",

        // === Catalog ===
        fetchCatalogs: "dg.catalogs.fetch",
        createCatalogs: "dg.catalogs.create",
        editCatalogs: "dg.catalogs.edit",
        deleteCatalogs: "dg.catalogs.delete",
        viewReportStudio: "dg.catalogs.view_report_studio",
        deleteUsers: "auth.Users.delete",

        // === Data Configuration ===
        viewDataConfiguration: "dg.configuration.view",
        editDataConfiguration: "dg.configuration.edit",

        /* Data Governance End */

        /* IH */
        // === Cases (Patient/Sample) ===        
        viewImmunohematology: "immunohematology.view",
        searchOrViewPatientOrSampleCases: 'ih.cases.patient_sample.search_view',
        viewOrDownloadCaseReports: 'ih.cases.reports.view_download',
        createOrEditCaseReports: 'ih.cases.reports.create_edit',
        deleteCaseReports: 'ih.cases.reports.delete',
        approveOrRejectCaseReports: 'ih.cases.reports.approve_reject',
        rejectApprovedCaseReports: 'ih.cases.reports.reject_approved',
        editApprovedCaseReports: 'ih.cases.reports.edit_approved',
        publishCaseReports: 'ih.cases.reports.publish',
        submitCaseReports: 'ih.cases.reports.submit',
        assignCaseReportsToSelf: 'ih.cases.reports.assign_to_self',

        viewGeospatial: 'map.geospatial.view',
        viewIdetic: 'id.unitsearch.view',
        viewJotForm: "jotform.survey.view",
        viewDataRefresh: "dataRefresh.list.view",
        viewBloodLossReports: 'bl.reports.view',
        editBloodLossReports: 'bl.reports.edit',
        approveRejectBloodLossReports: 'bl.reports.approve_reject',
        viewOnDutyStrength: 'bops.ondutystrength.view',
        editOnDutyStrength: 'bops.ondutystrength.edit',

        // View WIFA
        viewWIFA: 'hem.Wifa.View',

        // === IH Comments ===
        manageIHComments: 'ih.comments.manage',
        /* IH End */

        // === FINANCE ===
        viewOrDownloadFinanceCostCenterReports: 'finance.cost_center.reports.view_download',

        // === SETTINGS ===
        viewPortalSettings: 'portal.settings.can_view',
        createPortalDashboards: 'portal.settings.dashboards.can_create',
        editPortalDashboards: 'portal.settings.dashboards.can_edit',
        deletePortalDashboards: 'portal.settings.dashboards.can_delete',

        // === FAQ ===
        editReleases: 'hem.releases.edit',
        publishReleases: 'hem.releases.publish'
    }
}

export default new AuthService();
