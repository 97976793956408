/* eslint-disable prettier/prettier */
import { QueryBuilderComponent } from '@syncfusion/ej2-react-querybuilder';
import GovernanceService from 'services/governance-service';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import React, { useState, useEffect, useRef } from 'react';
import { Card, Breadcrumb, Row, Col, Form } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import { useParams, Link } from 'react-router-dom';
import authService from 'services/auth-service';
import { faSave, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './activeGovernanceDataRule.css';
import { toast } from 'react-toastify';
import ConfirmationMessage from 'components/common/ConfirmationMessage';
import  PopoverComponent from 'pages/common/popover';
import { Helmet } from 'react-helmet';

var columns = [];
const activeGovernanceDataRule = () => {
  const userProfile = authService.getCurrentUserProfile();
  let params = useParams();
  const databaseId = params.databaseId;
  const tableConfigId = params.tableConfigId;
  const dataRuleId = params.dataRuleId;
  const querybuilder = useRef(null);
  const dynamicColumns = [];

  const [ruleSqlColumn, setRuleSqlColumn] = useState([]);
  //const [importRules, setImportRules] = useState({});
  const [tableName, setTableName] = useState('');
  const [dynamicBreadcrumb, setDynamicBreadcrumb] = useState('');
  const [tableNameForTitle, setTableNameForTitle] = useState('');
  const [rawSql, setRawSql] = useState('');
  const [rawJson, setRawJson] = useState('');
  const [outputCount, setOutputCount] = useState('');
  const [outputData, setOutputData] = useState([]);
  const [dataRuleName, setDataRuleName] = useState('');
  const [dataThreshold, setDataThreshold] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [isScheduleEnabled, setIsScheduleEnabled] = useState(false);
  const [schedulePath, setSchedulePath] = useState('');
  const [validateDataRuleName, setValidateDataRuleName] = useState(false);
  const [validateDataThreshold, setValidateDataThreshold] = useState(false);
  const [validateFrequency, setValidateFrequency] = useState(false);
  const [scheduleTypes, setScheduleTypes] = useState([]);
  const [selectedscheduleType, setSelectedscheduleType] = useState('');
  const [frequencyText, setFrequencyText] = useState('');
  const [isVerifyDisable, setIsVerifyDisable] = useState(true);
  const [dataQueryRule, setDataQueryRule] = useState([]);
  const [savedDataThreshold, setSavedDataThreshold] = useState('');
  const [savedScheduleType, setSavedScheduleType] = useState(0);

  function getSql() {
    var sql = querybuilder.current.getSqlFromRules();
    //console.log(querybuilder.current.getSqlFromRules());
    //sql = sql.replace('%', '').replace('%', '');
    setRawSql(sql);

    //Set Json
    var json = querybuilder.current.getRules();
    setRawJson(json);
    // Need to do the call to execute the SQL
    const countSqlPromise = GovernanceService.getSelectedQueryCountForTable(
      databaseId,
      tableName,
      JSON.stringify(sql)
    );
    const selectSqlPromise = GovernanceService.getSelectedQueryOutputForTable(
      databaseId,
      tableName,
      JSON.stringify(sql)
    );

    Promise.all([countSqlPromise, selectSqlPromise]).then(
      ([countSqlData, selectSqlData]) => {
        //console.log(selectSqlData);
        setOutputCount(countSqlData[0].count);
        setOutputData(selectSqlData);
        setSaveDisabled(true);
      }
    );
  }

  function cancelPage() {
    querybuilder.current.reset();
    if (dataRuleId > 0) {
      querybuilder.current.setRules(
        JSON.parse(dataQueryRule)
      );
      setDataThreshold(savedDataThreshold);
      setSelectedscheduleType(savedScheduleType);
    } else {
      setRawSql('');
      setRawJson('');
      setOutputCount(0);
      setOutputData([]);
      //setDataRuleName('');
      setDataThreshold('');
      setIsScheduleEnabled(false);
      setSelectedscheduleType(0);
    }
  }

  function onClickSave(e) {
    e.preventDefault();
    if (
      validateDataRuleName === false ||
      validateDataThreshold === false ||
      validateFrequency === false
    ) {
      return;
    }
    const queryBuilderRuleModel = {
      tableName: tableName,
      useremail: userProfile.email,
      tableConfigId: tableConfigId,
      dataRuleId: dataRuleId,
      dataRuleName: dataRuleName,
      dataThreshold: dataThreshold,
      selectedscheduleType: selectedscheduleType,
      dataRuleQuery: JSON.stringify(rawSql),
      dataRuleJson: JSON.stringify(rawJson)
    };
    //console.log(queryBuilderRuleModel);
    GovernanceService.saveDataRule(queryBuilderRuleModel)
    .then(
      savedDataRule => {
        setSchedulePath(
          `/governance/dataRuleNotification/${databaseId}/${tableConfigId}/${savedDataRule.dataRuleId}`
        );
      }
    ).then(()=>{
      toast(ConfirmationMessage("Data Rules Saved", "Data Rules Saved Successfully!", "bg-success", "faCheckCircle"), {
        autoClose: true,
        //className: 'transparent',
        //style: {{background-color: transparent !important}},
        position: 'bottom-right',
        hideProgressBar: true,
        closeButton: false,
      });
      //ConfirmationMessage(true,"Data Rules Saved Successfully!");
    }).catch(err => {
      console.log(err);
      toast(ConfirmationMessage("Error", err, "bg-danger", "faExclamationCircle"), {
        autoClose: true,
        //className: 'transparent',
        //style: {{background-color: transparent !important}},
        position: 'bottom-right',
        hideProgressBar: true,
        closeButton: false,
      });
    });
    setIsScheduleEnabled(true);
  }

  useEffect(() => {
    const selectedTableData =
      GovernanceService.getSelectedConfigTableData(tableConfigId);
    const selectedTableType = GovernanceService.getDataType(databaseId, tableConfigId);
    const selectScheduleType = GovernanceService.getScheduleType();
    Promise.all([selectedTableData, selectedTableType, selectScheduleType])
      .then(([tableData, dataType, scheduleType]) => {
        const tableName = 'Define Data Rules <' + tableData[0].TableName + '>';
        const tableN = tableData[0].SchemaName + '.' + tableData[0].TableName;
        const dynamicBreadcrumb = '/governance/dataRulesView/'+ databaseId +'/'+ tableConfigId;
        setTableNameForTitle(tableName);
        setTableName(tableN);
        setDynamicBreadcrumb(dynamicBreadcrumb);
        setScheduleTypes(scheduleType);
        columns = [
          // {
          //   accessor: 'D',
          //   Header: 'D',
          //   headerProps: {
          //     className: 'text-start'
          //   },
          //   cellProps: {
          //     className: 'text-start'
          //   }
          // }
        ];
        for (var k in tableData) {
          var dataTypeFilter = dataType.filter(
            f => f.Column_name == tableData[k].ColumnName
          );
          let columnDataType = '';
          if (dataTypeFilter.length > 0) {
            if (
              dataTypeFilter[0].Data_Type == 'int' ||
              dataTypeFilter[0].Data_Type == 'decimal'
            ) {
              columnDataType = 'number';
            } else if (dataTypeFilter[0].Data_Type == 'varchar' || dataTypeFilter[0].Data_Type == 'nvarchar') {
              columnDataType = 'string';
            } else if (dataTypeFilter[0].Data_Type == 'datetime2') {
              columnDataType = 'date';
            } else if (dataTypeFilter[0].Data_Type == 'datetimeoffset') {
              columnDataType = 'date';
            }
            dynamicColumns.push({
              field: tableData[k].ColumnName,
              label: tableData[k].ColumnName,
              type: columnDataType
            });
            setRuleSqlColumn(dynamicColumns);
            columns.push({
              accessor: tableData[k].ColumnName,
              Header: tableData[k].ColumnName
            });
          }
        }

        if (dataRuleId > 0) {
          // Get the Data rule information from Data Rule table
          GovernanceService.getDataRulebyRuleId(dataRuleId).then(dataRule => {
            setDataRuleName(dataRule.dataRuleName);
            setSelectedscheduleType(dataRule.scheduleTypeId);
            setDataThreshold(dataRule.executionThreshold);
            setSavedDataThreshold(dataRule.executionThreshold);
            setSavedScheduleType(dataRule.scheduleTypeId);
            setSchedulePath(
              `/governance/dataRuleNotification/${databaseId}/${tableConfigId}/${dataRuleId}`
            );
            dataRule.scheduleTypeId == 1
              ? setFrequencyText('All Daily 7 AM jobs run at 7:00 AM EST')
              : dataRule.scheduleTypeId == 2
              ? setFrequencyText(
                  'All Monthly jobs run on the last day of each month at 7:00 PM EST'
                )
              : dataRule.scheduleTypeId == 3
              ? setFrequencyText(
                  'All Weekly jobs run on the last day of each week at 7:00 PM EST'
                )
              : dataRule.scheduleTypeId == 7
              ? setFrequencyText(
                  'All Daily 7 PM jobs run at 7:00 PM EST'
                )
              : setFrequencyText('');

            setDataQueryRule(dataRule.dataRuleCondition);
            querybuilder.current.columns = dynamicColumns;
            querybuilder.current.reset();
            querybuilder.current.setRules(
              JSON.parse(dataRule.dataRuleCondition)
            );

            if (dataRule.dataRuleName.length > 1) {
              setValidateDataRuleName(true);
            } else {
              setValidateDataRuleName(false);
            }
            if (dataRule.executionThreshold > 0) {
              setValidateDataThreshold(true);
            } else {
              setValidateDataThreshold(false);
            }
            if (dataRule.scheduleTypeId > 0) {
              setValidateFrequency(true);
            } else {
              setValidateFrequency(false);
            }
          });
          setIsScheduleEnabled(true);
        }
      })
      .then(() => setIsVerifyDisable(false));
  }, []);
  
  const dataRuledesc = " Create a data rule by configuring rules query. Thresholds allow you to specify the upper limit of acceptable violations of the defined rule on each execution after which a notification is generated. Frequency specifies the number of times the rules will be executed. Setup notification after verifying the rule."; 

  return (
    <>
      <Helmet>
        <title>Define Data Rules</title>
      </Helmet>
      <div>
        {tableNameForTitle && (
          <PageHeader title={tableNameForTitle} className="mb-2" col="12">
             <>
            <Row>
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href={dynamicBreadcrumb}>
                    Rule Management
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>{tableNameForTitle}</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col className="text-end mt-n2">
                <PopoverComponent 
                  header = "Governance Data Rule"
                  body = {dataRuledesc}
                />
              </Col>
            </Row>
          </>
          </PageHeader>
        )}
      
        <Card className="h-100 mb-2">          
          <Card.Header>
          {/* Create a data rule by configuring rules query. Thresholds allow you to specify the upper limit of acceptable violations of the defined rule on each execution after which a notification is generated. Frequency specifies the number of times the rules will be executed. Setup notification after verifying the rule. */}
            <Form onSubmit={onClickSave}>
              <Row className="mb-3 g-3">
                <Form.Group as={Col} lg={4} controlId="dataRuleName">
                  <Form.Label>Data Rule Name: </Form.Label>
                  <Form.Control
                    isInvalid={!validateDataRuleName}
                    type="text"
                    placeholder="Data Rule Name"
                    name="dataRuleName"
                    value={dataRuleName}
                    onChange={e => {
                      setDataRuleName(e.target.value);
                      setValidateDataRuleName(e.target.value ? true : false);
                    }}
                  />
                </Form.Group>
                <Form.Control.Feedback type="invalid">
                  Please fill out this field.
                </Form.Control.Feedback>
                <Form.Group as={Col} lg={4} controlId="threshold">
                  <Form.Label>Threshold: </Form.Label>
                  <Form.Control
                    isInvalid={!validateDataThreshold}
                    type="text"
                    placeholder="Threshold"
                    value={dataThreshold}
                    name="threshold"
                    onChange={e => {
                      setDataThreshold(e.target.value);
                      setValidateDataThreshold(e.target.value ? true : false);
                    }}
                  />
                </Form.Group>
                <Form.Control.Feedback type="invalid">
                  Please fill out this field.
                </Form.Control.Feedback>
                <Form.Group as={Col} lg={4} controlId="threshold">
                  <Form.Label>Frequency: </Form.Label>
                  <Form.Select
                    isInvalid={!validateFrequency}
                    name="Frequency"
                    size="sm"
                    value={selectedscheduleType}
                    onChange={e => {
                      setSelectedscheduleType(e.target.value);
                      setValidateFrequency(e.target.value > 0 ? true : false);
                      e.target.value == 1
                        ? setFrequencyText('All Daily 7 AM jobs run at 7:00 AM EST')
                        : e.target.value == 2
                        ? setFrequencyText(
                            'All Monthly jobs run on the last day of each month at 7:00 PM EST'
                          )
                        : e.target.value == 3
                        ? setFrequencyText(
                            'All Weekly jobs run on the last day of each week at 7:00 PM EST'
                          )
                        : e.target.value == 7
                        ? setFrequencyText(
                            'All Daily 7 PM jobs run at 7:00 PM EST'
                          )
                        : setFrequencyText('');
                    }}
                    className="me-2"
                  >
                    <option key="0">Select</option>
                    {scheduleTypes.map(i => (
                      <option key={i.scheduleTypeId} value={i.scheduleTypeId}>
                        {i.scheduleType1}
                      </option>
                    ))}
                  </Form.Select>
                  {frequencyText}
                </Form.Group>
                <Form.Control.Feedback type="invalid">
                  Please fill out this field.
                </Form.Control.Feedback>
               
              </Row>
            </Form>
            Configure Rule Query
          </Card.Header>
          <Card.Body>
            <QueryBuilderComponent
              ref={querybuilder}
              columns={ruleSqlColumn}
              //rule={importRules}
            />
            <div className="e-qb-button text-end">
              {/* <Button
                className="btn-sm mt-2"
                variant="primary"
                onClick={getSql}
              >
                {' '}
                Verify{' '}
              </Button> */}
              <button
                className="btn btn-outline-primary mt-2"
                disabled={isVerifyDisable}
                onClick={getSql}
                type="button"
              >
                Verify
              </button>
            </div>
          </Card.Body>
        </Card>
        <Card className="h-100 mb-2">
          <Card.Header>
            Total Rows Matching condition: {outputCount}
          </Card.Header>
          <Card.Body className="p-0">
            {outputData.length > 0 && (
              <AdvanceTableWrapper
                columns={columns}
                data={outputData}
                //selection
                sortable
                pagination
                perPage={10}
                rowCount={outputData.length}
              >
                <Card>
                  <Card.Header> Sample Output (First 100 Rows): </Card.Header>
                  <Card.Body className="p-0 overflow-auto">
                    <AdvanceTable
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tableProps={{
                        className: 'fs--1 mb-0 overflow-hidden'
                      }}
                    />
                  </Card.Body>
                  <Card.Footer>
                    <AdvanceTableFooter
                      rowCount={outputData.length}
                      table
                      rowInfo
                      navButtons
                    />
                  </Card.Footer>
                </Card>
              </AdvanceTableWrapper>
            )}
          </Card.Body>
        </Card>
        <Card>
          <Card.Body className="p-2 ">
            <Row className="mb-3 g-3">
              <div className="text-end">
                <button
                  className="btn btn-outline-primary me-1 mb-1"
                  onClick={cancelPage}
                  value="Reset"
                >
                  <FontAwesomeIcon icon={faUndoAlt} /> Reset
                </button>
                <button
                  className="btn btn-outline-primary me-1 mb-1"
                  onClick={onClickSave}
                  value="save"
                  disabled={!saveDisabled}
                >
                  <FontAwesomeIcon icon={faSave} /> Save
                </button>
                {isScheduleEnabled && (
                  <Link
                    className="btn btn-outline-primary me-1 mb-1"
                    //variant={'primary'}
                    to={`${schedulePath}`}
                  >
                    {' '}
                    Notification
                  </Link>
                )}
              </div>
            </Row>
          </Card.Body>
        </Card>
      </div>
      {/* <ConfirmationMessage showModal={showConfirm} title={title} message={message} /> */}
    </>
  );
};

export default activeGovernanceDataRule;
