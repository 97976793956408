/* eslint-disable prettier/prettier */
import BaseService from './base-service';
import { getMicrosoftGraphToken, getEmailAPIToken } from '../msalToken';
// import { PublicClientApplication } from "@azure/msal-browser";
import Axios from 'axios';
import { graphConfig, emailAPIConfig } from '../authConfig';
class ActiveDirectoryService extends BaseService {
    constructor() {
        super();          
        // this.msalInstance =  new PublicClientApplication(msalConfig);
    }
    
    getUserProfilePhoto = () => {         
      return getMicrosoftGraphToken().then(token => {  
      return Axios.get(`https://graph.microsoft.com/v1.0/me/photo/$value`, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob'
      })
       });
      };

    getUserProfilePhotoFromEmail = (email) => {         
      return getMicrosoftGraphToken().then(token => {  
        return Axios.get(`https://graph.microsoft.com/v1.0/users/${email}/photo/$value`, {
          headers: { Authorization: `Bearer ${token}` },
          responseType: 'blob'
        })
      });
      };

    getUserProfilePhotoFromEmailDetails = (email) => {         
    return getMicrosoftGraphToken().then(token => {  
      return Axios.get(`https://graph.microsoft.com/v1.0/users/${email}/photo/$value`, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob'
      }).catch(err => {
        console.log(err);
      });
    });
    };

    getUserByEmail = (ownerEmail) => {         
      return getMicrosoftGraphToken().then(token => {    
          const path = `/users/${ownerEmail}` 
          const headers = []; 
          headers.push({Authorization: `Bearer ${token}`}); 
            return this._apiGet({
              headers,
              rootPath: graphConfig.baseURL,
              path
            });
      });
    };
    
    searchPeople = name => {       
      return getMicrosoftGraphToken().then(token => {
          const path = `/me/people/?$search=${encodeURI(name)}`; 
          const headers = [];
          headers.push({Authorization: `Bearer ${token}`});
          return this._apiGet({
            headers,
            rootPath: graphConfig.baseURL,
            path
          });
        });
    };

    sendEmail = (data) => {       
        return getEmailAPIToken().then(token => {
            const headers = [];
            headers.push({Authorization: `Bearer ${token}`});
            return this._apiPost({
              headers,
              rootPath: emailAPIConfig.baseURL,
              path: `/api/email/send`,
              data
            });
          });
    };
  
}
export default new ActiveDirectoryService();
