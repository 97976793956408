/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';

const EADSWiki = () => {

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
      };

    useEffect(() => {
        //console.log("open new url");
        openInNewTab('https://wiki.capps.nybce.org/en/home')
        history.back();
    },[]);

    return (
        <></>
    );
};

export default EADSWiki;