/* eslint-disable prettier/prettier */
import React, { Fragment, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Form, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Fuse from 'fuse.js';
import { Link } from 'react-router-dom';
// import Avatar from '../../../components/common/Avatar';
import { isIterableArray } from '../../../helpers/utils';
import Flex from '../../../components/common/Flex';
import FalconCloseButton from '../../../components/common/FalconCloseButton';
import SoftBadge from '../../../components/common/SoftBadge';
import Enum from 'common/enum';
import searchService from 'services/search-service';

const SearchBox = () => {
  let searchItems = JSON.parse(sessionStorage.getItem(Enum.CACHED_SEARCH));
  if (!searchItems) {
    searchService.getAllItemsForSearch()
    .then(result => {
        sessionStorage.removeItem(Enum.CACHED_SEARCH);
        sessionStorage.setItem(Enum.CACHED_SEARCH, JSON.stringify(result));
        searchItems = result;   
    }); 
  }
  const [dropdownOpen, setDropdownOpen] = useState(true);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [isMobile, SetIsMobile] = useState(false);
  //const [resultItem, setResultItem] = useState(searchItems);
  
  const fuseJsOptions = {
    threshold: 0.3,
    minMatchCharLength: 2,
    keys: ['text', 'breadCrumbTexts', 'key']
  };
  let searchResult = searchItems != null ? new Fuse(searchItems, fuseJsOptions)
    .search(searchInputValue)
    .map(item => item.item) : null;

  const recentlyBrowsed = JSON.parse(localStorage.getItem(Enum.CACHED_RECENTLY_BROWSED)); 
  const recentlyBrowsedItems = recentlyBrowsed? recentlyBrowsed.report.concat(recentlyBrowsed.dashboard.concat(recentlyBrowsed.dataGovernance)) : [];

  const toggle = () => setDropdownOpen(!dropdownOpen);

  useEffect(() => {
    if (searchInputValue) {
      isIterableArray(searchResult)
        ? setDropdownOpen(true)
        : setDropdownOpen(false);
    } 
    if (window.innerWidth < 700) {
      SetIsMobile(true);
    }
  }, [searchInputValue]);
  
  return (
    <Dropdown onToggle={toggle} className="search-box">
      <Dropdown.Toggle as="div" data-toggle="dropdown" aria-expanded={dropdownOpen} bsPrefix="toggle">
        <Form className="position-relative">
          <Form.Control
            type="search"
            placeholder="Search..."
            aria-label="Search"
            className="rounded-pill search-input"
            value={searchInputValue}
            onChange={(e) => {setSearchInputValue(e.target.value)}}            
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
            onClick={() => setDropdownOpen(false)}
          />
          <FontAwesomeIcon icon="search" className="position-absolute text-400 search-box-icon" />
          {searchInputValue && (
            <div className="search-box-close-btn-container"
              // style={{ right: '10px', top: '8px' }}
            >
              <FalconCloseButton size="sm" noOutline onClick={() => setSearchInputValue('')} />
            </div>
          )}
        </Form>
      </Dropdown.Toggle>
      {/* "z-index: unset; !important;" */}
      <Dropdown.Menu style={{ zIndex: isMobile ? '1000' : 'unset'}}>
        <div className="scrollbar py-3" style={{ maxHeight: '24rem' }}>
          {isIterableArray(recentlyBrowsedItems) && (
            <>
              <Dropdown.Header as="h6" className="px-card pt-0 pb-2 fw-medium text-dark">
                Recently Browsed
              </Dropdown.Header>              
              {recentlyBrowsedItems.map(item => (
                <Dropdown.Item as={Link}  to={item.url} className="fs--1 px-card py-1 hover-primary " key={item.id}>
                  <Flex alignItems="center">
                     <FontAwesomeIcon icon="circle" className="me-2 text-300 fs--2" />
                    <div className="fw-normal">
                      {item.breadCrumbTexts.map((breadCrumbText, index) => {
                        return (
                          <Fragment key={breadCrumbText}>
                            {breadCrumbText}
                            {item.breadCrumbTexts.length - 1 > index && (
                              <FontAwesomeIcon icon="chevron-right" className="mx-1 text-500 fs--2" transform="shrink 2" />
                            )}
                          </Fragment>
                        );
                      })}
                    </div>
                  </Flex>
                </Dropdown.Item>
              ))}
              
                <hr className="bg-200 dark__bg-900" />
              
            </>
          )}

            
              <Dropdown.Header as="h6" className="pt-0 pb-2 fw-medium">
                Suggested Filter
              </Dropdown.Header>
              {isIterableArray(searchResult) && (
        
              searchResult.map(item => (
                <Dropdown.Item
                  as={Link}
                  to={item.url}
                  className="fs-0 px-card py-1 hover-primary "
                  key={item.id}
                >
                  <Flex alignItems="center">
                    <SoftBadge
                      bg={item.key === 'Report'? 'success' : item.key === 'Dashboard'? 'info' : item.key === 'KPI'? 'primary' : item.key === 'Analytics'? 'warning' : 'secondary'}
                      className="fw-medium text-decoration-none me-2"
                    >
                      {
                        item.key == "Report" ?
                      item.key.replace('Report', 'Report' + '\u200A'.repeat(37)) :
                      item.key == "Analytics" ? item.key.replace('Analytics', 'Analytics' + '\u200A'.repeat(16)) :
                      item.key.replace('KPI', 'KPI' + '\u200A'.repeat(66))
                      } {' '}
                    </SoftBadge>
                    <div className="flex-1 fs--1">{item.text}</div>
                  </Flex>
                </Dropdown.Item>
              ))
              )}
          {searchResult && searchResult.length === 0 && (
            <p className="fs-1 fw-bold text-center mb-0">No Result Found.</p>
          )}
        </div>        
        <div className='d-flex justify-content-end p-2'>
          <a href='/advanced-search' onClick={toggle}> Advanced Search </a>
          {/* <Link to="/advanced-search" onClick={toggle}> Advanced Search </Link> */}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SearchBox;
