/* eslint-disable prettier/prettier */
import BaseService from './base-service';
//import searchService from 'services/search-service';
import Enum from 'common/enum';
class KpiService extends BaseService {    
    getKpiList = () => {
        return this._apiGet({
            path: `/kpi/getKpi`
        });     
    }
    getKpiById = (kpiId) => {
        return this._apiGet({
            path: `/kpi/getKpi/${kpiId}`
        });     
    }
    getKpiDivisions = () => {
        return this._apiGet({
            path: `/kpi/getKpiDivisions`
        });     
    }
    getKpiClasses = (domainId) => {
        return this._apiGet({
            path: `/kpi/getKpiClasses/${domainId}`
        });     
    }
    getDictionaryTypes = () => {
        return this._apiGet({
            path: `/kpi/getDictionaryTypes`
        });     
    }
    getAllCatalogs = () => {
        return this._apiGet({
            path: `/kpi/getAllCatalogs`
        });     
    }
    getTargetRangeTypes = () => {
        return this._apiGet({
            path: `/kpi/getTargetRangeTypes`
        });     
    }
    saveKpi = (kpi, isNew) => {
        return isNew ? 
         this._apiPost({
             path: `/kpi/create`,
             data: kpi
          }) 
          : 
         this._apiPut({
            path: `/kpi/update`,
            data: kpi
        });     
     }
    deleteKpi = (kpiId) => {
       return this._apiPut({
           path: `/kpi/delete/${kpiId}`
       });
   }
    getKpiByCatalog = (catalogId) => {
        return this._apiGet({
            path: `/kpi/getKpiByCatalog/${catalogId}`
        });
    }
    getCatalogByKpi = (kpiId) => {
        return this._apiGet({
            path: `/kpi/getCatalogByKpi/${kpiId}`
        });
    }
    refreshKpiCache = () => {
        sessionStorage.removeItem(Enum.CACHED_SEARCH);
        // searchService.getAllItemsForSearch()
        //     .then(result => {
        //       sessionStorage.removeItem(Enum.CACHED_SEARCH);
        //       sessionStorage.setItem(Enum.CACHED_SEARCH, JSON.stringify(result));            
        //     });
    }
}

export default new KpiService();