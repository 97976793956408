/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Breadcrumb, Spinner } from 'react-bootstrap';
import AdvanceTableWrapper from '../../components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../../components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBoxPlug from '../../components/common/advance-table/AdvanceTableSearchBoxPlug';
import AdvanceTableFooter from '../../components/common/advance-table/AdvanceTableFooter';
import service from 'services/catalog-service';
import { settings } from '../../config';
import { getItemFromStore } from 'helpers/utils';
import PageHeader from 'components/common/PageHeader';
import SoftBadge from 'components/common/SoftBadge';
import './report-browser.scss';
import  PopoverComponent from 'pages/common/popover';
import { Helmet } from 'react-helmet';

const ReportBrowser = () => {
  const [catalogs, setCatalogs] = useState([]);
  const configState = {
    gridRowsCount: getItemFromStore('gridRowsCount', settings.gridRowsCount)
  };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    service
      .fetchReports()
      .then(res => {
        setCatalogs(res);
        setIsLoading(false);
      })
      .catch(err => {        
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  const reportBrowserdesc = "Browse for reports in the table below based on your current permissions profile. You can use the sort feature next to column names or search for reports directly.";

  return (
    <>
      <Helmet>
        <title>Reports</title>
      </Helmet>
      <PageHeader title="Reports" className="mb-2" col="12">
      <Row>
            <Col className="text-start mt-1">
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>
                  Reports
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col className="text-end mt-n2">
              <PopoverComponent 
                header = "Enterprise Data Governance"
                body = {reportBrowserdesc}
              />
          </Col>
          </Row>
      </PageHeader>
      <AdvanceTableWrapper
        columns={columns}
        data={catalogs}
        sortable
        pagination
        perPage={configState.gridRowsCount}
      >
        <Card className="h-100">
          <Card.Header>
            <Row className="flex-between-center">
              <Col xs={9} sm={6} lg={3}>
                <AdvanceTableSearchBoxPlug table placeholder="Search" />
              </Col>
              <Col xs="auto" sm={3} lg={3} className="text-end marginTop">
                
              </Col>
            </Row>
          </Card.Header>
          {
            isLoading ? <div className='text-center'><Spinner animation="border" /></div> :
            <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle"
              tableProps={{
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
            </Card.Body>
          }
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={catalogs.length}
              table
              rowInfo
              navButtons
            />
          </Card.Footer>
        </Card>
        <br />
      </AdvanceTableWrapper>
    </>
  );
};

const columns = [
  // {
  //   accessor: 'catalogId',
  //   Header: 'ID',
  //   headerProps: {
  //     className: 'text-start'
  //   },
  //   cellProps: {
  //     className: 'text-start'
  //   }
  // },
  {
    accessor: 'catalogName',
    Header: 'Name',
    Cell: rowData => (
      <a
        href={`${window.location.origin}/boldReports/${rowData.row.original.catalogId}`}
        className="text-primary fw-semi-bold"
      >
        {rowData.row.original.catalogName}
      </a>
    )
  },
  {
    accessor: 'domainName',
    Header: 'Domain',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'subDomain',
    Header: 'Sub-Domain',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'catalogTextDesc',
    Header: 'Description',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start cell-description'
    }
  },
  {
    accessor: 'lastModified',
    Header: 'Last Modified',
    Cell: rowData => (   
     <> {new Date(rowData.row.original.lastModified).toLocaleString('en-US').split(',')[0]}</>
    )
    // headerProps: {
    //   className: 'text-start'
    // },
    // cellProps: {
    //   className: 'text-start'
    // }
  },
  {
    accessor: 'views',
    Header: 'Views',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'owner',
    Header: 'Developed By',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'reportStatus',
    Header: 'Status',
    Cell: rowData => (
      <SoftBadge
      bg={rowData.row.original.reportStatus === 'Released'? 'success' : 'warning'}
      className='rounded-pill'
      // className="fw-medium text-decoration-none me-1 "
    >     
     <big> {rowData.row.original.reportStatus}</big>
    </SoftBadge>
    )
    // headerProps: {
    //   className: 'text-start'
    // },
    // cellProps: {
    //   className: 'text-start cell-status'
    // }
  }
];

export default ReportBrowser;
