/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
// import WIFAScenarioDashboard from 'pages/dashboards/wifa-scenario-dashboard';
import KPIScenarioViewer from 'pages/kpi/wifa-scenario-viewer';

const WIFAHistory = () => {

    useEffect(() => {
      }, []);
            
      return (
        // <WIFAScenarioDashboard dashboardId={dashboardId} />
        <KPIScenarioViewer />
        )   
}

export default WIFAHistory;
