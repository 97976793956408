import queryString from 'querystring';

class StringParser {
  replacePathParams(path, params) {
    const segments = path.split('/');
    return segments
      .map(segment => {
        const offset = segment.indexOf(':') + 1;
        if (!offset) return segment;

        const key = segment.slice(offset);
        if (params[key]) return params[key];
        return segment;
      })
      .join('/');
  }

  /**
   * Returns parsed query and tries to parse every "potential" number string to int.
   *
   * Note:
   * Every param will tries to convert to int if possible otherwise returns as is.
   *
   * Example:
   * string "123" will be converted to int
   * string "123a" or "a123" will stay as is.
   *
   * @param query query (string) (will remove '?' if included)
   * @returns Parsed query params.
   */
  getParsedQueryParams = query => {
    if (query.length <= 1) return '';

    query = query.indexOf('?') >= 0 ? query.substr(1, query.length) : query;
    let parsedQuery = queryString.parse(query);

    Object.keys(parsedQuery).forEach(key => {
      parsedQuery[key] = this.attemptToConvertToInt(parsedQuery[key]);
    });

    return parsedQuery;
  };

  /**
   * Returns parsed query and tries to parse every "potential" number string to int.
   *
   * Example of attempt to convert to int process (if not in exclude list):
   * string "123" will be converted to int.
   * string "123a" or "a123" will stay as is.
   *
   * @param query query (string) (will remove '?' if included)
   * @param excludeFromParsingToInt (Array) exclude params from "attempt to convert to int".
   * Parsing query params to int uses a method to try to parse every
   */
  getParsedQueryParamsWithExcludedIntConvertions = (
    query,
    excludeFromParsingToInt
  ) => {
    if (query.length <= 1) return '';
    if (!Array.isArray(excludeFromParsingToInt))
      throw new Error(
        'Excluded params from int conversion should be of type array!'
      );

    query = query.indexOf('?') >= 0 ? query.substr(1, query.length) : query;
    let parsedQuery = queryString.parse(query);

    Object.keys(parsedQuery).forEach(key => {
      if (!excludeFromParsingToInt.includes(key)) {
        parsedQuery[key] = this.attemptToConvertToInt(parsedQuery[key]);
      }
    });

    return parsedQuery;
  };

  attemptToConvertToInt = stringInput => {
    if (/^\d+$/.test(stringInput)) {
      return parseInt(stringInput, 10);
    }

    return stringInput;
  };

  prepareQueryString(object) {
    return object && Object.keys(object).length > 0
      ? '?' + queryString.stringify(object)
      : '';
  }

  /**
   * Prepares an object for sending as query params by removing
   * empty or null or undefined properties from object
   * @param {*} client Client object
   */
  prepareObjectForQueryParams(queryObject) {
    if (!queryObject || Object.keys(queryObject).length === 0) return;

    let updatedQueryObject = {};
    for (var property in queryObject) {
      if (
        queryObject[property] !== '' &&
        queryObject[property] !== null &&
        queryObject[property] !== undefined
      ) {
        updatedQueryObject[property] = queryObject[property];
      }
    }

    return updatedQueryObject;
  }
}

export default new StringParser();
