import BaseService from './base-service';

class HemService extends BaseService {
  fetchReleases = () => {
    return this._apiGet({
      path: '/hem/releases'
    });
  };

  fetchRelease = releaseId => {
    return this._apiGet({
      path: `/hem/releases/${releaseId}`
    });
  };

  createRelease = createReleaseCommand => {
    return this._apiPost({
      path: '/hem/releases/create',
      data: createReleaseCommand
    });
  };

  editRelease = (releaseId, editReleaseCommand) => {
    return this._apiPatch({
      path: `/hem/releases/${releaseId}`,
      data: editReleaseCommand
    });
  };

  deleteRelease = releaseId => {
    return this._apiDelete({
      path: `/hem/releases/${releaseId}`
    });
  };

  publishRelease = releaseId => {
    return this._apiPut({
      path: `/hem/releases/${releaseId}/publish`
    });
  };

  fetchAllAssignedUsers = roleId => {
    return this._apiGet({
      path: `/users/notificationUsers/${roleId}`
    });
  };
  faqEmail = data => {
    return this._apiPost({
      path: '/hem/releases/faqEmail',
      data
    });
  };
}

export default new HemService();
