/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Modal, Button, Breadcrumb } from 'react-bootstrap';
import userService from '../../services/user-service';
import { useParams } from "react-router-dom";
import Select from 'react-select';
import { faSave, faTrashAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import catalogService from 'services/catalog-service';
import notificationService from 'services/notification-service';
import PageHeader from '../../components/common/PageHeader';
import './admin.scss';
import activeDirectoryService from 'services/activeDirectory-service';
import boldReportService from 'services/boldReport-service';
import tableauService from 'services/tableau-service';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { toast } from 'react-toastify';
import Enum from 'common/enum';
import biService from 'services/bi-service';
import ConfirmationMessage from 'components/common/ConfirmationMessage';
import kpiService from 'services/kpi-service';
import TinymceEditor from 'components/common/TinymceEditor';
import { Helmet } from 'react-helmet';

const profile = JSON.parse(localStorage.getItem('userProfile'));
// const toastOptions = {
//   // position: "bottom-left",
//   hideProgressBar: false,
//   autoClose: true,
//   className: 'toast-container success'  // toast.success background doesn't reflect the color of the main theme
// }
const CatalogEditor = () => {
    let params = useParams();
    const [formData, setFormData] = useState({
      id: '',
      name: '',
      typeId: '',
      ownerEmail:'',
      ownerName: '',
      ownerGlobalUserId: '',
      description: '',
      domain: [],
      domainId: '',
      subdomainId: '',
      hostId: '',
      path: '',
      mobilePath: '',
      roles: [],      
      catalogStewards: [],
      isEnterprise: '',
      isRegulatory: '',
      CatalogGroupId: '',
      CatalogReportId: '',
      catalogMobileReportId: '',
      tableauWorkbookId: '',
      tableauProjectId: '',
      tableauReportId: '',
      tableauReportName: '',
      tableauWorkbookName: '',
      isTableauAutoSized: '',
      tableauDashboardHeight: 0,
      boldReportCategoryName: '',
      boldReportReportName: '',
      reportStatus: '',
    });
    const roleProperties = {defaultCatalogId:0,domainId:0, isDefault: false, isPrimary: false, rolePermissions: null,users: null};
    const [disable, setDisable] = useState(false);
    const [domain, setDomains] = useState([]);
    const [subdomain, setSubdomains] = useState({
      parentId: '',
      domains: []
    });
    //const [isLoading, setIsLoading] = useState(true);
    const [owners, setOwners] = useState([]);
    const [stakeholders, setStakeholder] = useState([]);
    const [hosts, setHosts] = useState([]);
    const [hostOptions, setHostOptions] = useState([]);
    // const [hostValue, setHostValue] = useState('');
    const [type, setType] = useState([]);
    const [allRole, setAllRole] = useState([]);
    const [allKpi, setAllKpi] = useState([]);
    const [assignedKpi, setAssignedKpi] = useState([]);
    const [powerBIReport, setPowerBIReport] = useState([]);
    const [powerBIReportGroup, setPowerBIReportGroup] = useState([]);
    const [tableauWorkbook, setTableauWorkbook] = useState([]);
    const [tableauProject, setTableauProject] = useState([]);
    const [tableauReport, setTableauReport] = useState([]);
    const [boldReportCategory, setBoldReportCategory] = useState([]);
    const [boldReportPath, setBoldReportPath] = useState([]);
    // const [showSaveSuccess, setShowSaveSuccess] = useState(false);
    var tempUsers = [];
    const [recipientEmails, setRecipientEmails] = useState([]);
    // const [subdomainValue, setSubdomainValue] = useState(null);

    useEffect(() => {
      setPowerBIReport([{value: 1, label: ''}]);
      setPowerBIReportGroup([{value: 1, label: ''}]);
      setTableauWorkbook([{value: 1, label: ''}]);
      setTableauProject([{value: 1, label: ''}]);
      setTableauReport([{value: 1, label: ''}]);

      const catalogId = params.id ? params.id : -1;
      var typePromise = JSON.parse(window.localStorage.getItem(Enum.CACHED_CATALOG_TYPES));
      if(!typePromise){
        typePromise = catalogService.fetchCatalogType().then((type)=>{
          setType(type);
          window.localStorage.setItem(Enum.CACHED_CATALOG_TYPES, JSON.stringify(type));
        });
      }
      else
      {
        setType(typePromise);  
      }
      //const hostPromise = catalogService.fetchCatalogHost();
      var hostPromise = JSON.parse(window.localStorage.getItem(Enum.CACHED_CATALOG_HOSTS));
      if(!hostPromise){
        hostPromise = catalogService.fetchCatalogHost().then((hosts)=>{
          setHosts(hosts);
          setHostOptions(hosts);
          window.localStorage.setItem(Enum.CACHED_CATALOG_HOSTS, JSON.stringify(hosts));
        });
      }
      else
      {
        setHosts(hostPromise);
        setHostOptions(hostPromise);
      }
      const allKpiPromise = kpiService.getKpiList();
      const allRolePromise = userService.fetchAllRole();
      const domainPromise = catalogService.fetchCatalogDomain();

      tableauService.fetchProjects().then((tableauProjects) => {
        tableauProjects.data.forEach(item => {renameKey(item, 'id', 'value'); renameKey(item, 'name', 'label');});
        setTableauProject(tableauProjects.data);
      });
      const assignedKpiPromise = kpiService.getKpiByCatalog(catalogId);
      Promise.all([domainPromise, allRolePromise, allKpiPromise, assignedKpiPromise])
      .then(([domain, allRole, allKpi, assignedKpi]) => {
        allKpi.forEach(item => {renameKey(item, 'dictionaryId', 'value'); renameKey(item, 'dictionaryName', 'label')});
        setDomains(domain);
        setAllRole(allRole);
        setAllKpi(allKpi);
        setAssignedKpi(assignedKpi);
      })
      .then(() => {
        if (catalogId !== -1) {
            catalogService.fetchCatalog(catalogId)
            .then(catalog => {
              return catalog;
            })
            .then((catalog) => {
                    const roles = catalog.roles;
                    roles.forEach(item => {renameKey(item, 'id', 'value'); renameKey(item, 'name', 'label')});
                    roles.forEach(item => {removeProperty(item, roleProperties)});
                    const domainId = catalog.domain.domainParentId? catalog.domain.domainParentId : catalog.domain.id;
                    const subdomainId = catalog.domain.domainParentId? catalog.domain.id : '';
                    const catalogStewards = catalog.catalogStewards.map(s => { return { label: s.fullName, value: s.email, id: s.globalUserId } });
                    setStakeholder(catalogStewards);
                    setFormData({
                      catalogId: catalog.catalogId,
                      name: catalog.catalogName,
                      typeId: catalog.catalogTypeId,
                      ownerEmail: catalog.catalogOwnerEmail,
                      description: catalog.catalogDesc,
                      domain: catalog.domain,
                      domainId: domainId,
                      subdomainId: subdomainId,
                      hostId: catalog.catalogHostId,
                      path: catalog.path,
                      mobilePath: catalog.mobilePath,
                      isEnterprise: catalog.isEnterprise,
                      isRegulatory: catalog.isRegulatory,
                      CatalogGroupId: catalog.catalogGroupId,
                      catalogMobileReportId: catalog.catalogMobileReportId,
                      CatalogReportId: catalog.catalogReportId,
                      tableauWorkbookId: catalog.catalogWorkbookId,
                      tableauProjectId: catalog.catalogProjectId,
                      tableauReportId: catalog.catalogDefaultReportId,
                      tableauReportName: catalog.tableauReportName,
                      tableauWorkbookName: catalog.tableauWorkbookName,
                      isTableauAutoSized: catalog.isTableauAutoSized,
                      tableauDashboardHeight: catalog.tableauDashboardHeight,
                      reportStatus: catalog.reportStatus,
                      boldReportCategoryId: catalog.boldReportCategoryId,
                      boldReportReportId: catalog.boldReportReportId,
                      boldReportCategoryName: catalog.boldReportCategoryName,
                      roles: roles,
                      catalogStewards
                    });

                    //onChangeHost({value: catalog.catalogHostId});
                    // if (catalog.catalogHostId === 2)
                    // {
                    //   tableauService.fetchProjects().then((tableauProjects)=>{
                    //     tableauProjects.data.forEach(item => {renameKey(item, 'id', 'value'); renameKey(item, 'name', 'label');});
                    //     setTableauProject(tableauProjects.data);
                    //   });
                    // }
                    if (catalog.catalogHostId === 2 && catalog.catalogProjectId)
                    { 
                      tableauService.fetchWorkbooks(catalog.catalogProjectId)
                      .then(workbook => {
                        workbook.data.forEach(item => {renameKey(item, 'id', 'value'); renameKey(item, 'name', 'label');});
                        setTableauWorkbook(workbook.data);
                        tableauService.fetchWorkbookViews(catalog.catalogWorkbookId)
                        .then(views => {
                          views.data.forEach(item => {renameKey(item, 'id', 'value'); renameKey(item, 'name', 'label');});
                          setTableauReport(views.data);
                        });
                      });
                      
                    }
                    //Power BI
                    if (catalog.catalogHostId === 1 && catalog.catalogGroupId)
                    {
                        biService.fetchGroups().then((powerBI)=> {
                          powerBI.forEach(item => {renameKey(item, 'id', 'value'); renameKey(item, 'name', 'label');});
                          setPowerBIReportGroup(powerBI);
                        });
                        biService.fetchReports(catalog.catalogGroupId).then(reports => {
                          reports.forEach(item => {renameKey(item, 'id', 'value'); renameKey(item, 'name', 'label');});
                          setPowerBIReport(reports);
                        });                                            
                    }                   

                    if (catalog.catalogHostId === 6)
                    { 
                      const categoryPath = catalog.path;
                      const categoryName = catalog.path.substr(0, categoryPath.lastIndexOf("/")).replace("/", "");

                      boldReportService.fetchBoldReportCategoryList().then((boldReportCategory)=>{
                        boldReportCategory.forEach(item => {renameKey(item, 'id', 'value'); renameKey(item, 'name', 'label');});
                        setBoldReportCategory(boldReportCategory);
                      });

                      boldReportService.fetchBoldReportListFromCategory(categoryName)
                      .then(report => {
                         report.forEach(item => {renameKey(item, 'id', 'value'); renameKey(item, 'name', 'label');});
                          setBoldReportPath(report);
                      });                      
                    }

                    if (catalog.catalogOwnerEmail) {SetOwnerDropdownByEmail(catalog.catalogOwnerEmail);}
                    //if (catalog.catalogStewards) {setStakeholderOption(catalog.catalogStewards);}
                    if (subdomainId)
                    {
                        catalogService.fetchCatalogSubdomainDropdown(subdomainId)
                        .then(subdomain => {
                          setSubdomains(subdomain);
                          //setIsLoading(false);
                        })
                        .catch(err => console.log(err)); // end of subdomain
                    }
                    else
                    {
                        catalogService.fetchCatalogSubdomainByParent(domainId)
                        .then(subDomain => {
                          setSubdomains(subDomain);
                          //setIsLoading(false);
                        })
                        .catch(err => console.log(err)); // end of subdomain
                    }
            })
            .catch(err => console.log(err)) ; // end of fetchCatalog
        } // end if
        // else {
        //   setIsLoading(false);
        // }
      })
      .catch(err => console.log(err)); // end of Promise.all
      }, []);    // end of useEffect

      function removeProperty(target, source) {
        Object.keys(source).forEach(key => {
          delete target[key];
        });
      }
      function renameKey ( obj, oldKey, newKey ) {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
      }
      function resetHostOptionsByType(typeId) {
        setHostOptions([]);
        if (typeId === 3) {
          setHostOptions(hosts);
        }
        else if (typeId === 2 || typeId === 4) {
          const options = [];
          options.push(hosts.find(item => item.value === 1));
          options.push(hosts.find(item => item.value === 2));
          setHostOptions(options);
        }
        else if (typeId === 1) {
          const options = [];
          options.push(hosts.find(item => item.value === 3));
          options.push(hosts.find(item => item.value === 4));
          options.push(hosts.find(item => item.value === 6));
          setHostOptions(options);
        }
      }
      function onChangeType(e) {
        setFormData({...formData, typeId: e.value});
        setValidateType(true);
        resetHostOptionsByType(e.value);
        // setHostValue(null);
      }
      function onChangeHost(e) {
        setFormData({...formData, hostId: e.value});

        if(e.value == 1)
        {
          biService.fetchGroups().then((powerBI)=> {
            powerBI.forEach(item => {renameKey(item, 'id', 'value'); renameKey(item, 'name', 'label');});
            setPowerBIReportGroup(powerBI);
          });
        }
        // else if(e.value == 2)
        // {
        //   tableauService.fetchProjects().then((tableauProjects)=>{
        //     tableauProjects.data.forEach(item => {renameKey(item, 'id', 'value'); renameKey(item, 'name', 'label');});
        //     setTableauProject(tableauProjects.data);
        //   });
        // }        
        else if(e.value == 6)
        {
          boldReportService.fetchBoldReportCategoryList().then((boldReportCategory)=>{
            boldReportCategory.forEach(item => {renameKey(item, 'id', 'value'); renameKey(item, 'name', 'label');});
            setBoldReportCategory(boldReportCategory);
          });
        }
        
      }
      function onChangeDomain(e) {
        catalogService.fetchCatalogSubdomainByParent(e.value)
        .then(subdomain => {
            setSubdomains(subdomain);
        });
        setValidateDomain(true);
        setFormData({...formData, domainId: e.value, subdomainId: ''});
        // setSubdomainValue(null);
      }
      function onChangeBoldReportCategory(e) {   
          boldReportService.fetchBoldReportListFromCategory(e.label)
          .then(report => {
            report.forEach(item => {renameKey(item, 'id', 'value'); renameKey(item, 'name', 'label');});
              setBoldReportPath(report);
          });
          setValidateBoldReportCategory(true);
          setValidateBoldReport(false);
          setFormData({...formData, boldReportCategoryName: e.label, CatalogGroupId: e.value});      
      }

      function onChangeBoldReportPath(e) {
        setValidateBoldReport(true);
        const pathString = '/' + formData.boldReportCategoryName + '/' + e.label;
        setFormData({...formData, CatalogReportId: e.value, path: pathString});
      }

      function onChangeBoldReportMobilePath(e) {
        const mobilePathString = '/' + formData.boldReportCategoryName + '/' + e.label;
        setFormData({...formData, catalogMobileReportId: e.value, mobilePath: mobilePathString});
      }

      function onChangeTableauReportProjects(e) {
        tableauService.fetchWorkbooks(e.value)
        .then(workbook => {
          workbook.data.forEach(item => {renameKey(item, 'id', 'value'); renameKey(item, 'name', 'label');});
          setTableauWorkbook(workbook.data);
        });
        setValidateTableauProjectId(true);
        setValidateTableauWorkbookId(false);
        setValidateTableauReportId(false);

        setFormData({...formData, tableauProjectId: e.value});
      }

      function onChangeTableauReportWorkbook(e) {
        tableauService.fetchWorkbookViews(e.value)
        .then(views => {
          views.data.forEach(item => {renameKey(item, 'id', 'value'); renameKey(item, 'name', 'label');});
          setTableauReport(views.data);
        });
        setValidateTableauWorkbookId(true);
        setValidateTableauReportId(false);

        setFormData({...formData, tableauWorkbookId: e.value, tableauWorkbookName: e.label});
      }

      function onChangeTableauReport(e) {
        setValidateTableauReportId(true);
        const pathString = '/views/' + formData.tableauWorkbookName.replace('&', '').replace(/ /g,'') + '/' + e.label.replace('&', '').replace(/ /g,'') + '?:embed=yes&:showShareOptions=false&:alerts=no&:refresh=yes';
        setFormData({...formData, tableauReportId: e.value, tableauReportName: e.label,  path: pathString})
      }

      function onChangeSubdomain(e) {
        // setValidateSubdomain(true);

      //  setSubdomainValue(subdomain.domains.find(item => item.value === formData.subdomainId));
        setFormData({...formData, subdomainId: e.value});
      }
      // Delete catalog
      function deleteCatalog() {
        setDisable(true);
        catalogService.deleteCatalog(formData.catalogId)
        .then(() => {
          catalogService.refreshCatalogCache()
          .then(() => {
            location.href = `/admin/catalogs`;
          })
          .catch(err => console.log(err));
        })
        .catch(err => console.log(err));
      }

      function handleCancel  (e) {        
        e.preventDefault();
        location.href = `/admin/catalogs`;
      };
      
      // Save catalog
      function onClickSave(e) {
        e.preventDefault();
        const validate = validateFields();
        if (validate===false) {
          return;
        } ;
        setDisable(true);
        const roles = JSON.parse(JSON.stringify(formData.roles));//deep copy with number and string only
        roles.forEach(item => {renameKey(item, 'value', 'id'); renameKey(item, 'label', 'name');});
        const assignedKpis = JSON.parse(JSON.stringify(assignedKpi));//deep copy with number and string only
        assignedKpis.forEach(item => {renameKey(item, 'value', 'dictionaryId'); renameKey(item, 'label', 'dictionaryName');});
        const catalogStewards = formData.catalogStewards.map(t=> {return {globalUserId: t.id, email: t.value, fullName: t.label}}) ;
        const catalog = {
          catalogId: formData.catalogId,
          catalogName: formData.name,
          catalogTypeId: formData.typeId,
          catalogOwnerEmail: formData.ownerEmail,
          catalogStewards,
          ownerGlobalUserId: formData.ownerGlobalUserId,
          ownerName: formData.ownerName,
          catalogTextDesc: convertToPlain(formData.description),
          catalogDesc: formData.description,
          domainId: formData.subdomainId? formData.subdomainId : formData.domainId,
          // subdomainId: formData.subdomainId,
          catalogHostId: formData.hostId,
          path: formData.path,
          mobilePath: formData.mobilePath,
          isEnterprise: formData.isEnterprise,
          isRegulatory: formData.isRegulatory,
          catalogGroupId: formData.CatalogGroupId,
          catalogMobileReportId: formData.catalogMobileReportId,
          catalogReportId: formData.CatalogReportId,
          catalogWorkbookId: formData.tableauWorkbookId,
          tableauWorkbookName: formData.tableauWorkbookName,
          catalogProjectId: formData.tableauProjectId,
          tableauReportName: formData.tableauReportName,
          catalogDefaultReportId: formData.tableauReportId,
          isTableauAutoSized: formData.isTableauAutoSized,
          tableauDashboardHeight: formData.tableauDashboardHeight,
          boldReportCategoryId: formData.boldReportCategoryId,
          boldReportReportId: formData.boldReportReportId,
          reportStatus: formData.reportStatus,
          boldReportCategoryName: formData.boldReportCategoryName,
          roles: roles,
          CatalogKpis:assignedKpis
        };
        const isNew = params.id?false:true;
        catalogService.saveCatalog(catalog, isNew).then(() => {
          //toast('The catalog is saved successfully!   ', toastOptions);
          toast(ConfirmationMessage("Catalog Saved", "The catalog is saved successfully!", "bg-success", "faCheckCircle"), {
            autoClose: true,
            //className: 'transparent',
            //style: {{background-color: transparent !important}},
            position: 'bottom-right',
            hideProgressBar: true,
            closeButton: false,
          });
          setDisable(false);
          //if (isNew) {        
                const notificationRequest = { applicationName:"Hemetrics", type: "catalog", messageHeader:formData.name, messageContent: formData.description, createdBy:profile.email, recipientEmailList: recipientEmails, recipientEmailAddress:""};
                notificationService.AddNotificationToSpecificUser(notificationRequest).catch(err => console.log(err));
                catalogService.refreshCatalogCache()
                .then(()=>{
                  location.href = `/admin/catalogs`;
                })
                .catch(err => console.log(err));
                
           // };            
          } // end if isNew
        )
        .catch((err) => {
          setDisable(false);
          console.log(err.errors);
        }); // end of saveCatalog
      } // end onClickSave

      // Validation
      const fillMsg = "Please fill in this field";
      const [validateCatalogId, setValidateCatalogId] = useState(true);
      const [validateCatalogIdMsg, setValidateCatalogIdMsg] = useState(fillMsg);
      const [validateName, setValidateName] = useState(true);
      const [validateDomain, setValidateDomain] = useState(true);
      const [validateType, setValidateType] = useState(true);
      const [ValidateOwnerEmail, setValidateOwnerEmail] = useState(true);
      const [ValidateStakeholder, setValidateStakeholder] = useState(true);
      const [validateHost, setValidateHost] = useState(true);
      const [validatePath, setValidatePath] = useState(true);
      // const [validateMobilePath, setValidateMobilePath] = useState(true);
      const [validatePowerBIReportGroupId, setValidatePowerBIReportGroupId] = useState(true);
      const [validatePowerBIReportId, setValidatePowerBIReportId] = useState(true);
      const [validateTableauWorkbookId, setValidateTableauWorkbookId] = useState(true);
      const [validateTableauProjectId, setValidateTableauProjectId] = useState(true);
      const [validateTableauReportId, setValidateTableauReportId] = useState(true);
      const [validateBoldReportCategory, setValidateBoldReportCategory] = useState(true);
      const [validateBoldReport, setValidateBoldReport] = useState(true);
      //const [validatePrimaryStakeholders, setValidatePrimaryStakeholders] = useState(true);
      const [validateRoles, setValidateRoles] = useState(true);
      const validateFields = () => {
        if (formData.catalogId) { setValidateCatalogId(true);} else { setValidateCatalogIdMsg(fillMsg);  setValidateCatalogId(false); return false;}
        if (formData.name) { setValidateName(true);} else { setValidateName(false); return false;}
        if (formData.typeId) { setValidateType(true);} else { setValidateType(false); return false;}
        if (formData.domainId) { setValidateDomain(true);} else { setValidateDomain(false); return false;}
        if (formData.ownerEmail) { setValidateOwnerEmail(true);} else { setValidateOwnerEmail(false); return false;}
        
        if (formData.catalogStewards) { setValidateStakeholder(true);} else { setValidateStakeholder(false); return false;}
        if (formData.hostId) { setValidateHost(true);} else { setValidateHost(false); return false;}
        if (formData.hostId == 3 || formData.hostId == 4) {
          if (formData.path) { setValidatePath(true);} else { setValidatePath(false); return false;}
        }
        else if (formData.hostId == 1) {
          if (formData.CatalogGroupId) { setValidatePowerBIReportGroupId(true);} else { setValidatePowerBIReportGroupId(false); return false;}
          if (formData.CatalogReportId) { setValidatePowerBIReportId(true);} else { setValidatePowerBIReportId(false); return false;}
        }
        else if (formData.hostId == 2) {
          if (formData.tableauWorkbookId) { setValidateTableauWorkbookId(true);} else { setValidateTableauWorkbookId(false); return false;}
          if (formData.tableauProjectId) { setValidateTableauProjectId(true);} else { setValidateTableauProjectId(false); return false;}
          if (formData.tableauReportId) { setValidateTableauReportId(true);} else { setValidateTableauReportId(false); return false;}
        }

        if (formData.roles.length>0) { setValidateRoles(true);} else { setValidateRoles(false); return false;}
        return true;
      } // end validateFields
      function onBlurCatalogId(e) {
        if (e.target.value) {
          // check for duplicate catalogId
          const catalogs = JSON.parse(sessionStorage.getItem(Enum.CACHED_CATALOG_ID));
          // const catalogs = JSON.parse(sessionStorage.getItem(Enum.CACHED_SEARCH));
          // const find = catalogs.find((item) => item.id == e.target.value);
          const find = catalogs.find(a => a == e.target.value);
          if (find) {
            setValidateCatalogIdMsg("Catalog ID already exists");
            setValidateCatalogId(false);
           }
          else {
              setValidateCatalogId(true);
            }
        }
        else {
          setValidateCatalogIdMsg(fillMsg);
          setValidateCatalogId(false);
        }
      }// end onBlurCatalogId
      //Confirmation Modal
      function onClickDelete(e) {
        e.preventDefault();
        setShowConfirm(true);
      }
      function onChangePowerBIReport (e) {
        setFormData({...formData, CatalogReportId: e.value});
        setValidatePowerBIReportId(true);
      }
      
      function onChangePowerBIGroup(e) {       
        setFormData({...formData, CatalogGroupId: e.value});
        setValidatePowerBIReportGroupId(true);
        biService.fetchReports(e.value).then(reports => {
          reports.forEach(item => {renameKey(item, 'id', 'value'); renameKey(item, 'name', 'label');});
          setPowerBIReport(reports);
          setValidatePowerBIReportId(false);
        });
        
      }
      function SetOwnerDropdownByEmail(email) {
        activeDirectoryService.getUserByEmail(email)
        .then((user) => {
          const owner = [{ label: user.displayName, value: user.userPrincipalName }];
          setOwners(owner);
        });
      }
      // function setStakeholderOption(catalogStewards) {        
      //   const stewards = catalogStewards.map(s => { return { label: s.fullName, value: s.email, id: s.globalUserId } });
      //   setStakeholder(stewards);
       
      // }
      function onInputChangeOwner(inputValue) {
        if (inputValue.length > 0) {
          activeDirectoryService.searchPeople(inputValue).then(res => {
            const validList = res.value.filter(function(item) {return item.userPrincipalName !== null;});
            const owner = validList.map(user => { return { label: user.displayName, value: user.userPrincipalName, id: user.id } });            
            setOwners(owner);
            // console.log(formData.ownerEmail);
          });
        }
      }
      function onInputChangeStakeholder(inputValue) {
        if (inputValue.length > 0) {
          activeDirectoryService.searchPeople(inputValue).then(res => {
            const validList = res.value.filter(function(item) {return item.userPrincipalName !== null;});
            const searchResults = validList.map(user => { return { label: user.displayName, value: user.userPrincipalName, id: user.id } });            
            setStakeholder(searchResults);
          });
        }
      }
      function onChangeStakeholder(e)
      {
        //   var stakeholder = [];
        //   e.forEach(t =>
        //   {
        //     stakeholder.push({globalUserId: t.id, email: t.value, fullName: t.label});
        //   })
        // setFormData({...formData, catalogStewards: stakeholder}); 
        setFormData({...formData, catalogStewards: e});
        setValidateStakeholder(true);
      }
      const [showConfirm, setShowConfirm] = useState(false);
      const onClickCancel = () => setShowConfirm(false);
      const onClickOk = () => { setShowConfirm(false); deleteCatalog(); }

      function handleRolesChange(e) {
        setFormData({...formData, roles: e});
        setValidateRoles(e.length>0?true:false)
        tempUsers = [];
        e.forEach(item => {
          catalogService.fetchAllAssignedUsers(item.value).then ((user) => {
            user.map(u => {tempUsers.push(u.email);} )
            setRecipientEmails(tempUsers);
          });
        });
        setDisable(false);
      }

      function handleKpiChange(e) {
        setAssignedKpi(e);
        // setValidateRoles(e.length>0?true:false)        
        setDisable(false);
      }

      function convertToPlain(html){
        // Create a new div element
        var tempDivElement = document.createElement("div");    
        // Set the HTML content with the given value
        tempDivElement.innerHTML = html;    
        // Retrieve the text property of the element 
        return tempDivElement.textContent || tempDivElement.innerText || "";
      }

      return(
      <>
      <Helmet>
        <title>{params.id ? 'Edit Catalog' : 'New Catalog'}</title>
      </Helmet>
      <PageHeader title={params.id? "Catalog: " + formData.name : "New Catalog"} className="mb-2">
      <Breadcrumb>  
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/admin/catalogs">Manage Catalogs</Breadcrumb.Item>
        <Breadcrumb.Item active>Edit</Breadcrumb.Item>  
      </Breadcrumb>
      </PageHeader>
      <Card>     
        {/* <FalconCardHeader title={"Catalog: " + formData.name} />         */}
        <Card.Body className="bg-light">
          {/* {isLoading?  : */}
          {/* {isLoading && <div className='text-center'><Spinner animation="border" /></div>} */}
            <Form onSubmit={onClickSave}>
              <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6} >
                    <Form.Check
                      inline
                      checked={formData.isEnterprise==='Yes'?true:false}
                      type='checkbox'
                      id='isEnterprise'
                      label='Is Enterprise'
                      onChange={(e)=>e.target.checked? setFormData({...formData, isEnterprise: 'Yes'}):setFormData({...formData, isEnterprise: 'No'})}
                    />
                    <Form.Check
                      inline
                      checked={formData.isRegulatory==='Yes'?true:false}
                      type='checkbox'
                      id='isRegulatory'
                      label='Is Regulatory'
                      onChange={(e)=>e.target.checked? setFormData({...formData, isRegulatory: 'Yes'}):setFormData({...formData, isRegulatory: 'No'})}
                    />
                    <Form.Check
                      inline
                      checked={params.id ? (formData.reportStatus==='In Validation'?true:false) : true}
                      type='checkbox'
                      id='isInValidation'
                      label='Is In Validation'
                      onChange={(e)=>e.target.checked? setFormData({...formData, reportStatus: 'In Validation'}):setFormData({...formData, reportStatus: 'No'})}
                    />
                  </Form.Group>
              </Row>
            {!params.id &&
              <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg={4} controlId="catalogId">
                    <Form.Label>ID</Form.Label>
                    <Form.Control
                      isInvalid={!validateCatalogId}
                      type="text"
                      name="catalogId"
                      value={formData.catalogId ?? ""}
                      onChange={(e)=> {setFormData({...formData, catalogId: e.target.value}); setValidateCatalogId(e.target.value?true:false) }}
                      onBlur={onBlurCatalogId}
                    />
                    <Form.Control.Feedback type="invalid">{validateCatalogIdMsg}</Form.Control.Feedback>
                    {/* {!validateDuplicateCatalogId && (<span className='validation-error-msg'>This ID already exists.</span>)} */}
                  </Form.Group>
              </Row>}
              <Row className="mb-3 g-3">
                <Form.Group as={Col} lg={4} controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    isInvalid={!validateName}
                    type="text"
                    value={formData.name}
                    name="name"
                    onChange={(e)=>{setFormData({...formData, name: e.target.value}); setValidateName(e.target.value?true:false)}}
                  />
                  <Form.Control.Feedback type="invalid" >Please fill out this field.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} lg={4} controlId="type">
                  <Form.Label>Type</Form.Label>
                  <Select controlId="selectType"
                    className={validateType?'':'validation-error'}
                    closeMenuOnSelect={true}
                    options={type}
                    placeholder='Select...'
                    //classNamePrefix="react-select"
                    value={type.find(item => item.value === formData.typeId)}
                    onChange={onChangeType}
                    // onChange={(e)=>{setFormData({...formData, typeId: e.value}); setValidateType(true)}}
                  />
                {!validateType && (<span className='validation-error-msg'>Please fill out this field.</span>)}
                </Form.Group>
                <Form.Group as={Col} lg={4} controlId="host">
                  <Form.Label>Host</Form.Label>
                  <Select
                    className={validateHost?'':'validation-error'}
                    closeMenuOnSelect={true}
                    options={hostOptions}
                    placeholder='Select...'
                    //classNamePrefix="react-select"
                    // value={hostValue}
                    onChange={onChangeHost}
                    value={hostOptions.find(item => item.value === formData.hostId)?hostOptions.find(item => item.value === formData.hostId):null}
                    // onChange={(e)=>setFormData({...formData, hostId: e.value})}
                  />
                  {!validateHost && (<span className='validation-error-msg'>Please fill out this field.</span>)}
                </Form.Group>
              </Row>
              <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={4} controlId="domain">
                  <Form.Label>Domain</Form.Label>
                  <Select
                    className={validateDomain?'':'validation-error'}
                    closeMenuOnSelect={true}
                    options={domain}
                    placeholder='Select...'
                    //classNamePrefix="react-select"
                    value={domain.find(item => item.value === subdomain.parentId)}
                    onChange={onChangeDomain}
                  />
                {!validateDomain && (<span className='validation-error-msg'>Please fill out this field.</span>)}
                </Form.Group>
                <Form.Group as={Col} lg={4} controlId="subdomain">
                  <Form.Label>Sub Domain</Form.Label>
                  <Select
                    // className={validateSubdomain?'':'validation-error'}
                    closeMenuOnSelect={true}
                    options={subdomain.domains}
                    placeholder='Select...'
                    //classNamePrefix="react-select"
                    // value={subdomainValue}
                    value={subdomain.domains.find(item => item.value === formData.subdomainId)??null}
                    onChange={onChangeSubdomain}
                  />
                  {/* {!validateSubdomain && (<span className='validation-error-msg'>Please fill out this field.</span>)} */}
                </Form.Group>
                <Form.Group as={Col} lg={4} controlId="owner">
                  <Form.Label>Developed by</Form.Label>
                  <Select
                    controlId="selectOwner"
                    closeMenuOnSelect={true}
                    options={owners}
                    placeholder='search...'
                    className={ValidateOwnerEmail?'':'validation-error'}
                    value={owners.find(item => item.value === formData.ownerEmail)}
                    onChange={e => {setFormData({...formData, ownerEmail: e.value, ownerName: e.label, ownerGlobalUserId: e.id}); setValidateOwnerEmail(true)}}
                    onInputChange={onInputChangeOwner}
                  />
                  {!ValidateOwnerEmail && (<span className='validation-error-msg'>Please fill out this field.</span>)}
                </Form.Group>
              </Row>
              <Row className="mb-3 g-3">
                <Form.Group as={Col} lg={12} controlId="stakeholder">
                  <Form.Label>Data Steward(s)</Form.Label>
                  <Select
                    controlId="selectStakeholder"
                    closeMenuOnSelect={true}
                    options={stakeholders}
                    placeholder='search...'
                    className={ValidateStakeholder?'':'validation-error'}
                    value={formData.catalogStewards}
                    // value={stakeholders.find(item => item.value == formData.catalogStewards)}
                    onChange={onChangeStakeholder}
                    onInputChange={onInputChangeStakeholder}
                    isMulti
                  />
                {!ValidateStakeholder && (<span className='validation-error-msg'>Please fill out this field.</span>)}
                </Form.Group>
              </Row>
              <Row className="mb-3 g-3">
                  <Form.Label>Description</Form.Label>                
                  <TinymceEditor
                    value={formData.description}
                    handleChange={(value)=>{setFormData({...formData, description: value});}}
                  />
              </Row>
              { (formData.hostId === 1) &&
              <Row className="mb-3 g-3">
                <Form.Group as={Col} lg={6} controlId="CatalogGroupId">
                  <Form.Label>Power BI Report Group</Form.Label>
                  <Select
                    className={validatePowerBIReportGroupId?'':'validation-error'}
                    closeMenuOnSelect={true}
                    options={powerBIReportGroup}
                    placeholder='Select...'
                    //classNamePrefix="react-select"
                    value={powerBIReportGroup.find(item => item.value === formData.CatalogGroupId)}
                    onChange={onChangePowerBIGroup}
                  />
                  {!validatePowerBIReportGroupId && (<span className='validation-error-msg'>Please fill out this field.</span>)}
                </Form.Group>
                <Form.Group as={Col} lg={6} controlId="CatalogReportId">
                  <Form.Label>Power BI Report</Form.Label>
                  <Select
                    className={validatePowerBIReportId?'':'validation-error'}
                    closeMenuOnSelect={true}
                    options={powerBIReport}
                    placeholder='Select...'
                    //classNamePrefix="react-select"
                    value={powerBIReport.find(item => item.value === formData.CatalogReportId)}
                    onChange={onChangePowerBIReport}
                  />
                  {!validatePowerBIReportId && (<span className='validation-error-msg'>Please fill out this field.</span>)}
                </Form.Group>
              </Row>
              }
              { (formData.hostId === 2) &&
              <>
              <Row className="mb-3 g-3">
                <Form.Group as={Col} lg={4}>
                  <Form.Label>Tableau Report Projects</Form.Label>
                  <Select
                    className={validateTableauProjectId?'':'validation-error'}
                    closeMenuOnSelect={true}
                    options={tableauProject}
                    placeholder='Select...'
                    //classNamePrefix="react-select"
                    value={tableauProject.find(item => item.value === formData.tableauProjectId)}
                    // onChange={(e)=>setFormData({...formData, tableauProjectID: e.value})}
                    onChange={onChangeTableauReportProjects}
                  />
                  {!validateTableauProjectId && (<span className='validation-error-msg'>Please fill out this field.</span>)}
                </Form.Group>
                <Form.Group as={Col} lg={4} controlId="tableauWorkbookId">
                  <Form.Label>Tableau Workbook</Form.Label>
                  <Select
                    className={validateTableauWorkbookId?'':'validation-error'}
                    closeMenuOnSelect={true}
                    options={tableauWorkbook}
                    placeholder='Select...'
                    //classNamePrefix="react-select"
                    value={tableauWorkbook.find(item => item.value === formData.tableauWorkbookId)}
                    onChange={onChangeTableauReportWorkbook}
                  />
                  {!validateTableauWorkbookId && (<span className='validation-error-msg'>Please fill out this field.</span>)}
                </Form.Group>
                <Form.Group as={Col} lg={4} controlId="tableauReportId">
                  <Form.Label>Tableau Report</Form.Label>
                  <Select
                    className={validateTableauReportId?'':'validation-error'}
                    closeMenuOnSelect={true}
                    options={tableauReport}
                    placeholder='Select...'
                    //classNamePrefix="react-select"
                    value={tableauReport.find(item => item.value === formData.tableauReportId)}
                    onChange={onChangeTableauReport}
                  />
                  {!validateTableauReportId && (<span className='validation-error-msg' >Please fill out this field.</span>)}
                </Form.Group>
              </Row>
              <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={2}>
                <Form.Label>
                  <br />
                  <br />
                  <br />
                </Form.Label>
                <Form.Check
                  inline
                  checked={formData.isTableauAutoSized==='Yes'?true:false}
                  type="checkbox"
                  id="isTableauAutoSized"
                  label="Is Auto Size Dashboard"
                  onChange={(e)=>e.target.checked? setFormData({...formData, isTableauAutoSized: 'Yes'}):setFormData({...formData, isTableauAutoSized: 'No'})}
                />
              </Form.Group>
              <Form.Group as={Col} lg={2}>
                <Form.Label>
                  <br />
                  <br />
                  <br />
                </Form.Label>
                <Form.Check
                  inline
                  checked={formData.isTableauAutoSized.trim()==='No'?true:false}
                  type="checkbox"
                  id="isTableauAutoSized"
                  label="Is Fixed Size Dashboard"
                  onChange={(e)=>e.target.checked? setFormData({...formData, isTableauAutoSized: 'No'}):setFormData({...formData, isTableauAutoSized: 'Yes'})}
                />
              </Form.Group>
              {/* {formData.isTableauAutoSized==='No ' &&
              <Form.Group as={Col} lg={2} controlId="title">
                <Form.Label>
                  Dashboard Height
                </Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={formData.tableauDashboardHeight}
                  onChange={(e)=>{setFormData({...formData, tableauDashboardHeight: e.target.value});}}
                />
              </Form.Group>
              }         */}
              </Row>
              </>
              }
            { (formData.hostId === 4 || formData.hostId === 3) &&
              <Row className="mb-3 g-3">
                <Form.Group as={Col} lg={6} controlId="path">
                  <Form.Label>Path</Form.Label>
                  <Form.Control
                    isInvalid={!validatePath}
                    type="text"
                    value={formData.path}
                    name="path"
                    onChange={(e)=>{setFormData({...formData, path: e.target.value}); setValidatePath(e.target.value?true:false)}}
                  />
                  <Form.Control.Feedback type="invalid">Please fill out this field.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} lg={6} controlId="Mobile Path">
                  <Form.Label>Mobile Path</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.mobilePath}
                    name="mobilePath"
                    onChange={(e)=>{setFormData({...formData, mobilePath: e.target.value})}}
                  />
                  </Form.Group>
              </Row>
              }
                  { (formData.hostId === 6) &&
              <Row className="mb-3 g-3">
                <Form.Group as={Col} lg={4} >
                  <Form.Label>Bold Report Category</Form.Label>
                  <Select
                    className={validateBoldReportCategory?'':'validation-error'}
                    closeMenuOnSelect={true}
                    options={boldReportCategory}
                    placeholder='Select...'
                    value={boldReportCategory.find(item => item.value === formData.CatalogGroupId)}
                    //value= {label: [{boldReportCategory.find(item => item.value === formData.CatalogGroupId)}], value:1}
                    onChange={onChangeBoldReportCategory}
                  />
                  {!validateBoldReportCategory && (<span className='validation-error-msg'>Please fill out this field.</span>)}
                </Form.Group>
                <Form.Group as={Col} lg={4} >
                  <Form.Label>Bold Report</Form.Label>
                  <Select
                    className={validateBoldReport?'':'validation-error'}
                    closeMenuOnSelect={true}
                    options={boldReportPath}
                    placeholder='Select...'
                    value={boldReportPath.find(item => item.value === formData.CatalogReportId)}
                    onChange={onChangeBoldReportPath}  //{(e)=>setFormData({...formData, boldReportReportName: e.label, path: '/' + formData.boldReportCategoryName + '/'+ e.label})}
                  />
                  {!validateBoldReport && (<span className='validation-error-msg'>Please fill out this field.</span>)}
                </Form.Group>
                <Form.Group as={Col} lg={4} >
                  <Form.Label>Bold Report (Mobile)</Form.Label>
                  <Select
                    closeMenuOnSelect={true}
                    options={boldReportPath}
                    placeholder='Select...'
                    value={boldReportPath.find(item => item.value === formData.catalogMobileReportId)}
                    onChange={onChangeBoldReportMobilePath}  
                  />
                </Form.Group>
              </Row>
              }
              <Row className="mb-3 g-3">
                <Form.Group as={Col} lg={12} controlId="role">
                  <Form.Label>Assigned Roles</Form.Label>
                  <Select
                    className={validateRoles?'':'validation-error'}
                    closeMenuOnSelect={false}
                    options={allRole}
                    placeholder='Select...'
                    isMulti
                    value={formData.roles}
                    onChange={(e)=> handleRolesChange(e)}
                  />
                {!validateRoles && (<span className='validation-error-msg'>Please fill out this field.</span>)}
                </Form.Group>
              </Row>
              <Row className="mb-3 g-3">
                <Form.Group as={Col} lg={12} controlId="role">
                  <Form.Label>Assigned Data Dictionaries</Form.Label>
                  <Select
                    closeMenuOnSelect={false}
                    options={allKpi}
                    placeholder='Select...'
                    isMulti
                    value={assignedKpi}
                    onChange={(e)=> handleKpiChange(e)}
                  />
                </Form.Group>
              </Row>
              <br/>
              <Row className="mb-3 g-3">              
              <div className="text-end">
              <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={handleCancel}
                value="cancel"
              >
                <FontAwesomeIcon icon={faTimes} /> Cancel
              </button>
                <button className="btn btn-outline-primary me-1 mb-1" disabled={disable} type="submit">
                  <FontAwesomeIcon icon={faSave} /> Save
                </button>
                {params.id &&
                <button className="btn btn-outline-primary me-1 mb-1" disabled={disable} onClick={onClickDelete} type="button">
                <FontAwesomeIcon icon={faTrashAlt} /> Delete
                </button>}
              </div>
              {/* </FormGroup> */}
              </Row>
            </Form>
          {/* } */}
        </Card.Body>
      </Card>
      <Modal show={showConfirm} onHide={onClickCancel} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Hemetrics</Modal.Title>
          <FalconCloseButton onClick={onClickCancel}/>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete it?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onClickOk}>
            OK
          </Button>
          <Button variant="secondary" onClick={onClickCancel} >Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
    ); // return
} // CatalogForm
export default CatalogEditor;