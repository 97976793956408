/* eslint-disable prettier/prettier */
import React, { useContext, useState, useEffect } from 'react';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
// import { Nav, Tooltip } from 'react-bootstrap';
import ProfileDropdown from '../../../components/navbar/top/ProfileDropdown';
import NotificationDropdown from '../../../components/navbar/top/NotificationDropdown';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from '../../../context/Context';
// import NineDotMenu from './NineDotMenu';
import Favorites from './Favorites';
// import service_now from 'assets/img/common/service_now.png';
import PropTypes from 'prop-types';
import { faTicket, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchBox from './SearchBox';
const TopNavRightSideNavItem = (props) => {
  const SmallScreenSize = 753; 
  const {
    config: { navbarPosition, searchIconVisible },
    setConfig
  } = useContext(AppContext);

  const className =  navbarPosition === 'vertical' || props.isSmallScreen? "navbar-nav-icons ms-auto flex-row align-items-center" :  "navbar-nav-icons flex-row align-items-center";
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const detectSize = () => { setScreenWidth(window.innerWidth); }
  
  useEffect(() => {
    window.addEventListener('resize', detectSize)
    return () => {
      window.removeEventListener('resize', detectSize)
    }
  }, []);

  const handleToggle = () => {
    setConfig("searchIconVisible", !searchIconVisible);
  };

  return (          
    <Nav navbar className={className} as="ul">
      {navbarPosition === 'vertical' &&  screenWidth >= SmallScreenSize && 
        <div style={{width: '360px'}} className='d-flex justify-content-center'>
          <SearchBox />
        </div>
      }
      { screenWidth < SmallScreenSize && <div className="theme-control-toggle-label px-2">
        <FontAwesomeIcon  icon={faMagnifyingGlass} onClick={handleToggle} className="fs-1" />
        </div>}
      <Nav.Item as={'li'}>
        <Nav.Link className="px-2" href="https://nybc.service-now.com/sp" target="_blank">    
            <OverlayTrigger placement={'left'}
              overlay={
                <Tooltip id="serviceNow">
                  Service Now Ticket
                </Tooltip>
              }
            >                  
            
          <div>
            <FontAwesomeIcon  icon={faTicket} className="fs-1" />
          </div>
            {/* <img src={service_now} width={25} alt="Service Now" /> */}
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>
      <Favorites />
      <NotificationDropdown />
      <ProfileDropdown />
    </Nav>
  );
};

TopNavRightSideNavItem.propTypes = {
  isSmallScreen: PropTypes.bool.isRequired
};

export default TopNavRightSideNavItem;
