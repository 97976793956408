/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Modal, Button, Breadcrumb } from 'react-bootstrap';
import service from 'services/catalog-service';
import PageHeader from 'components/common/PageHeader';
import  PopoverComponent from 'pages/common/popover';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrashAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import TinymceEditor from 'components/common/TinymceEditor';
import tableauService from 'services/tableau-service';
import activeDirectoryService from 'services/activeDirectory-service';
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import ConfirmationMessage from 'components/common/ConfirmationMessage';
import FalconCloseButton from 'components/common/FalconCloseButton';

    const CatalogEmailNotification = () => {
      let params = useParams();
      const [formData, setFormData] = useState({
        catalogEmailNotificationId: 0,
        tableauWorkbookName: '',
        tableauReportName: '',
        tableauProjectId: '',
        tableauWorkbookId: '',
        tableauReportId: '',
        recipients: [],
        notificationSubject: '',
        notificationSchedule: '',
        dayOfWeek:'',
        notificationScheduleMessage: '',
        catalogScheduleTypeId: 0,
        scheduleDay: '',
        tableauImageUrl: ''
      });
      const [tableauWorkbook, setTableauWorkbook] = useState([]);
      const [tableauProject, setTableauProject] = useState([]);
      const [tableauReport, setTableauReport] = useState([]);
     // const [tableauViewId, setTableauViewId] = useState([]);
      const [recipientEmail, setRecipientEmail] = useState([]);
      const [schedules, setSchedules] = useState([]);
      const [scheduleDays, setScheduleDays] = useState([]);
      const [showConfirm, setShowConfirm] = useState(false);
      const [validateTableauWorkbookId, setValidateTableauWorkbookId] = useState(true);
      const [validateTableauProjectId, setValidateTableauProjectId] = useState(true);
      const [validateTableauReportId, setValidateTableauReportId] = useState(true);
      const [validateRecipients, setValidateRecipients] = useState(true);
      const [validateNotificationSubject, setValidateNotificationSubject] = useState(true);
      const [validateNotificationMessage, setValidateNotificationMessage] = useState(true);
      const [validateScheduleDay, setValidateScheduleDay] = useState(true);
      const [disable, setDisable] = useState(false);
      const onClickCancel = () => setShowConfirm(false);
      const onClickOk = () => { setShowConfirm(false); deleteCatalogEmailNotification(); }

      useEffect(() => {
        setTableauWorkbook([{value: 1, label: ''}]);
        setTableauProject([{value: 1, label: ''}]);
        setTableauReport([{value: 1, label: ''}]);
        tableauService.fetchProjects().then((tableauProjects) => {
          tableauProjects.data.forEach(item => {renameKey(item, 'id', 'value'); renameKey(item, 'name', 'label');});
          setTableauProject(tableauProjects.data);
        });

        service.fetchCatalogSchedule().then((schedule) => {
          setSchedules(schedule);
        });
        const catEmailNotificationId = params.id ? params.id : -1;
        if (catEmailNotificationId !== -1) {
          service.fetchCatalogEmailNotification(catEmailNotificationId)
          .then((catalog) => {
                  // const domainId = catalog.domain.domainParentId? catalog.domain.domainParentId : catalog.domain.id;
                  // const subdomainId = catalog.domain.domainParentId? catalog.domain.id : '';
                  if (catalog.catalogScheduleTypeId == 1) {
                    setScheduleDays([
                                    {'value': 1, 'label': 'Weekday'},
                                    {'value': 2, 'label': 'All'} 
                                  ]);
                  } else if (catalog.catalogScheduleTypeId == 2) {
                    setScheduleDays([
                      {'value': 1, 'label': 'Monday'},
                      {'value': 2, 'label': 'Tuesday'},
                      {'value': 3, 'label': 'Wednesday'},
                      {'value': 4, 'label': 'Thursday'},
                      {'value': 5, 'label': 'Friday'},
                      {'value': 6, 'label': 'Saturday'},
                      {'value': 7, 'label': 'Sunday'}    
                    ]);
                  } else {
                    setScheduleDays([
                      {'value': 1, 'label': 'Start Of Month'},
                      {'value': 2, 'label': 'End Of Month'} 
                    ]);
                  }
                  const recipients = catalog.recipients.map(s => { return { label: s.fullName, value: s.email, id: s.globalUserId } });
                  setRecipientEmail(recipients);
                  setFormData({
                    catalogEmailNotificationId: catalog.catalogEmailNotificationId,
                    tableauWorkbookName: catalog.tableauWorkbook,
                    tableauReportName: catalog.tableauWorkSheet,
                    tableauProjectId: catalog.tableauProjectId,
                    tableauWorkbookId: catalog.tableauWorkbookId,
                    tableauReportId: catalog.tableauReportId,
                    recipients,
                    notificationSubject: catalog.notificationSubject,
                    notificationScheduleTime: '',
                    notificationScheduleMessage: catalog.notificationMessage,
                    catalogScheduleTypeId: catalog.catalogScheduleTypeId,
                    scheduleDay: catalog.dayOfWeek,
                    tableauImageUrl: catalog.tableauImageUrl
                  });

                  tableauService.fetchWorkbooks(catalog.tableauProjectId)
                  .then(workbook => {
                    workbook.data.forEach(item => {renameKey(item, 'id', 'value'); renameKey(item, 'name', 'label');});
                    setTableauWorkbook(workbook.data);
                    tableauService.fetchWorkbookViews(catalog.tableauWorkbookId)
                    .then(views => {
                      views.data.forEach(item => {renameKey(item, 'id', 'value'); renameKey(item, 'name', 'label');});
                      setTableauReport(views.data);
                    });
                  });
                })
              }
      }, []);

      const validateFields = () => {
        if (formData.tableauProjectId) { setValidateTableauProjectId(true);} else { setValidateTableauProjectId(false); return false;}
        if (formData.tableauWorkbookId) { setValidateTableauWorkbookId(true);} else { setValidateTableauWorkbookId(false); return false;}
        if (formData.tableauReportId) { setValidateTableauReportId(true);} else { setValidateTableauReportId(false); return false;}
        if (formData.notificationSubject) { setValidateNotificationSubject(true);} else { setValidateNotificationSubject(false); return false;}
        if (formData.recipients.length > 0) { setValidateRecipients(true);} else { setValidateRecipients(false); return false;}
        if (formData.notificationScheduleMessage) { setValidateNotificationMessage(true);} else { setValidateNotificationMessage(false); return false;}
        if (formData.scheduleDay) { setValidateScheduleDay(true);} else { setValidateScheduleDay(false); return false;}
        return true;
      } // end validateFields

       // Delete catalog Email Notification
       function deleteCatalogEmailNotification() {
        setDisable(true);
        service.deleteCatalogEmailNotification(formData.catalogEmailNotificationId)
        .then(() => {
          toast(ConfirmationMessage("Email Notification Deleted", "The email notification has been deleted successfully!", "bg-success", "faCheckCircle"), {
            autoClose: true,
            //className: 'transparent',
            //style: {{background-color: transparent !important}},
            position: 'bottom-right',
            hideProgressBar: true,
            closeButton: false,
          });
            location.href = `/admin/catalogEmailNotification`;
        })
        .catch(err => console.log(err));
      }

      function handleCancel  (e) {        
        e.preventDefault();
        location.href = `/admin/catalogEmailNotification`;
      };

      function renameKey ( obj, oldKey, newKey ) {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
      }

      function onChangeTableauReportProjects(e) {
        tableauService.fetchWorkbooks(e.value)
        .then(workbook => {
          workbook.data.forEach(item => {renameKey(item, 'id', 'value'); renameKey(item, 'name', 'label');});
          setTableauWorkbook(workbook.data);
        });
        setValidateTableauProjectId(true);
        setValidateTableauWorkbookId(false);
        setValidateTableauReportId(false);

        setFormData({...formData, tableauProjectId: e.value});
      }
    
      function onChangeTableauReportWorkbook(e) {
        tableauService.fetchWorkbookViews(e.value)
        .then(views => {
          views.data.forEach(item => {renameKey(item, 'id', 'value'); renameKey(item, 'name', 'label');});
          setTableauReport(views.data);
        });
        setValidateTableauWorkbookId(true);
        setValidateTableauReportId(false);

        setFormData({...formData, tableauWorkbookId: e.value, tableauWorkbookName: e.label});
      }

      function onChangeTableauReport(e) {
       setValidateTableauReportId(true);
        const pathString = '/views/' + formData.tableauWorkbookName.replace('&', '').replace(/ /g,'') + '/' + e.label.replace('&', '').replace(/ /g,'') + '.png?:size=1920,1080';
        setFormData({...formData, tableauReportId: e.value, tableauReportName: e.label, tableauImageUrl: pathString})
      }

      function onChangeRecipients(e)
      { 
        setFormData({...formData, recipients: e});
        setValidateRecipients(true);
      }

      function onInputChangeRecipients(inputValue) {
        if (inputValue.length > 0) {
          activeDirectoryService.searchPeople(inputValue).then(res => {
            const validList = res.value.filter(function(item) {return item.userPrincipalName !== null;});
            const searchResults = validList.map(user => { return { label: user.displayName, value: user.userPrincipalName, id: user.id } });            
            setRecipientEmail(searchResults);
          });
        }
      }

      function onChangeSchedule (e) {
        setFormData({...formData, catalogScheduleTypeId: e.value})
        if (e.value == 1) {
          setScheduleDays([
                          {'value': 1, 'label': 'Weekday'},
                          {'value': 2, 'label': 'All'} 
                        ]);
        } else if (e.value == 2) {
          setScheduleDays([
            {'value': 1, 'label': 'Monday'},
            {'value': 2, 'label': 'Tuesday'},
            {'value': 3, 'label': 'Wednesday'},
            {'value': 4, 'label': 'Thursday'},
            {'value': 5, 'label': 'Friday'},
            {'value': 6, 'label': 'Saturday'},
            {'value': 7, 'label': 'Sunday'}    
          ]);
        } else {
          setScheduleDays([
            {'value': 1, 'label': 'Start Of Month'},
            {'value': 2, 'label': 'End Of Month'} 
          ]);
        }
      }

        // Save catalog
  function onClickSave(e) {
    e.preventDefault();
    const validate = validateFields();
    if (validate===false) {
      return;
    } ;
    const recipients = formData.recipients.map(t=> {return {globalUserId: t.id, email: t.value, fullName: t.label}}) ;
    const order = {
      catalogEmailNotificationId: formData.catalogEmailNotificationId,
      catalogScheduleTypeId: formData.catalogScheduleTypeId,
      tableauWorkbook: formData.tableauWorkbookName,
      tableauWorkSheet: formData.tableauReportName,
      tableauProjectId: formData.tableauProjectId,
      tableauWorkbookId: formData.tableauWorkbookId,
      tableauReportId: formData.tableauReportId,
      tableauImageUrl: formData.tableauImageUrl,
      dayOfWeek: formData.scheduleDay,
      notificationSubject: formData.notificationSubject,
      notificationMessage: formData.notificationScheduleMessage,
      recipients
    }
    const isNew = params.id?false:true;
    service.saveCatalogEmailNotification(order, isNew).then(() => {
      //toast('The catalog is saved successfully!   ', toastOptions);
      toast(ConfirmationMessage("Email Notification Saved", "The email notification is saved successfully!", "bg-success", "faCheckCircle"), {
        autoClose: true,
        //className: 'transparent',
        //style: {{background-color: transparent !important}},
        position: 'bottom-right',
        hideProgressBar: true,
        closeButton: false,
      });
      location.href = `/admin/catalogEmailNotification`;
    } // end if isNew
    )
    .catch((err) => {
     // setDisable(false);
      console.log(err.errors);
    }); // end of saveCatalog

  }

  //Delete Catalog
  function onClickDelete(e) {
    e.preventDefault();
    setShowConfirm(true);
  }

   //   const reportBrowserdesc = "Patient Search";
      
      return (
        <>
          <Helmet>
            <title>Catalog Email Notification</title>
          </Helmet>
            
      <PageHeader title="Catalog Email Notification" className="mb-2" col="12">
        <Row>
            <Col className="text-start mt-1">
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/admin/catalogEmailNotification">Manage Catalog Email Notification</Breadcrumb.Item>
                <Breadcrumb.Item active>
                Catalog Email Notification
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col className="text-end mt-n2">
              <PopoverComponent 
                header = "Enterprise Data Governance"
                // body = {reportBrowserdesc}
              />
          </Col>
          </Row>
          </PageHeader>
          <Card className="h-100">
          <Card.Header>
          <Form onSubmit={onClickSave}>
            <Row className="mb-3 g-3">
                <Form.Group as={Col} lg={4}>
                  <Form.Label>Tableau Projects<span className="text-danger">*</span></Form.Label>
                  <Select
                    className={validateTableauProjectId?'':'validation-error'}
                    closeMenuOnSelect={true}
                    options={tableauProject}
                    placeholder='Select...'
                    //classNamePrefix="react-select"
                    value={tableauProject.find(item => item.value === formData.tableauProjectId)}
                    // onChange={(e)=>setFormData({...formData, tableauProjectID: e.value})}
                    onChange={onChangeTableauReportProjects}
                  />
                   {!validateTableauProjectId && (<span className='validation-error-msg'>Please fill out this field.</span>)}
                </Form.Group>
                <Form.Group as={Col} lg={4} controlId="tableauWorkbookId">
                  <Form.Label>Tableau Workbook<span className="text-danger">*</span></Form.Label>
                  <Select
                    className={validateTableauWorkbookId?'':'validation-error'}
                    closeMenuOnSelect={true}
                    options={tableauWorkbook}
                    placeholder='Select...'
                    //classNamePrefix="react-select"
                    value={tableauWorkbook.find(item => item.value === formData.tableauWorkbookId)}
                    onChange={onChangeTableauReportWorkbook}
                  />
                 {!validateTableauWorkbookId && (<span className='validation-error-msg'>Please fill out this field.</span>)}
                </Form.Group>
                <Form.Group as={Col} lg={4} controlId="tableauReportId">
                  <Form.Label>Tableau Report<span className="text-danger">*</span></Form.Label>
                  <Select
                    className={validateTableauReportId?'':'validation-error'}
                    closeMenuOnSelect={true}
                    options={tableauReport}
                    placeholder='Select...'
                    //classNamePrefix="react-select"
                    value={tableauReport.find(item => item.value === formData.tableauReportId)}
                    onChange={onChangeTableauReport}
                  />
                  {!validateTableauReportId && (<span className='validation-error-msg' >Please fill out this field.</span>)}
                </Form.Group>
            </Row>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={12} controlId="usersToBeNotified">
                <Form.Label>
                Recipients<span className="text-danger">*</span>
                </Form.Label>
                <Select
                    controlId="selectRecipient"
                    closeMenuOnSelect={true}
                    options={recipientEmail}
                    placeholder='search...'
                    className={validateRecipients?'':'validation-error'}
                    value={formData.recipients}
                    // value={stakeholders.find(item => item.value == formData.catalogStewards)}
                    onChange={onChangeRecipients}
                    onInputChange={onInputChangeRecipients}
                    isMulti
                  />
                {!validateRecipients && (<span className='validation-error-msg'>Please fill out this field.</span>)}
              </Form.Group>
              </Row>
              <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6} controlId="notificationSubject">
                <Form.Label>
                Notification Subject<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!validateNotificationSubject}
                  type="text"
                  placeholder="Notification Subject"
                  value={formData.notificationSubject}
                  name="notificationSubject"
                  onChange={(e)=>{setFormData({...formData, notificationSubject: e.target.value}); setValidateNotificationSubject(true);}}
                />
                 <Form.Control.Feedback type="invalid" >Please fill out this field.</Form.Control.Feedback>
              </Form.Group>
             
              <Form.Group as={Col} lg={3} controlId="schedule">
                <Form.Label>
                Schedule
                </Form.Label>
                <Select
                   // className={validateSchedule?'':'validation-error'}
                    closeMenuOnSelect={true}
                    options={schedules}
                    placeholder='Select...'
                    //classNamePrefix="react-select"
                    value={schedules.find(item => item.value === formData.catalogScheduleTypeId)}
                    onChange={onChangeSchedule}
                  />
              </Form.Group>
              <Form.Group as={Col} lg={3} controlId="scheduleTime">
                <Form.Label>
                Schedule Day<span className="text-danger">*</span>
                </Form.Label>
                <Select
                    className={validateScheduleDay?'':'validation-error'}
                    closeMenuOnSelect={true}
                    options={scheduleDays}
                    placeholder='Select...'
                    //classNamePrefix="react-select"
                    value={scheduleDays.find(item => item.label === formData.scheduleDay)}
                    onChange={(e)=>{setFormData({...formData, scheduleDay: e.label}); setValidateScheduleDay(true);}}
                  />
                   {!validateScheduleDay && (<span className='validation-error-msg' >Please fill out this field.</span>)}
                </Form.Group>
            </Row>
            <Row className="mb-3 g-3">
                  <Form.Label>Notification Message</Form.Label>                
                  <TinymceEditor
                    value={formData.notificationScheduleMessage}
                    handleChange={(value)=>{setFormData({...formData, notificationScheduleMessage: value});}}
                  />
                    {!validateNotificationMessage && (<span className='validation-error-msg' >Please fill out this field.</span>)}
              </Row>
              <br />
            <div className="text-end">
            <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={handleCancel}
                value="cancel"
              >
                <FontAwesomeIcon icon={faTimes} /> Cancel
              </button>
              <button
                className="btn btn-outline-primary me-1 mb-1"
                //onClick={handleSubmit}
                //value="save"
                //disabled={disable}
                type="submit"
              >
                <FontAwesomeIcon icon={faSave} /> Save
              </button>
              <button className="btn btn-outline-primary me-1 mb-1" disabled={disable} onClick={onClickDelete} type="button">
                <FontAwesomeIcon icon={faTrashAlt} /> Delete
                </button>
            </div>
            </Form>
            </Card.Header>
        </Card>
        <Modal show={showConfirm} onHide={onClickCancel} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Hemetrics</Modal.Title>
          <FalconCloseButton onClick={onClickCancel}/>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete it?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onClickOk}>
            OK
          </Button>
          <Button variant="secondary" onClick={onClickCancel} >Cancel</Button>
        </Modal.Footer>
      </Modal>
      </>
      );
     
}

export default CatalogEmailNotification;