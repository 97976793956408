/* eslint-disable react/jsx-no-undef */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Breadcrumb } from 'react-bootstrap';
import AdvanceTableWrapper from '../../components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../../components/common/advance-table/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from '../../components/common/advance-table/AdvanceTableFooter';
import bloodLossService from 'services/blood-loss-service';
import { settings } from '../../config';
import { getItemFromStore } from '../../helpers/utils';
import PageHeader from 'components/common/PageHeader';
import { faUsersCog, faCheckCircle, faExclamationCircle, faHistory, faThumbsUp, faThumbsDown, faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReportStatusPill from './ReportStatusPill';
import UserAssignmentModal from './UserAssignmentModal';
import ReportApprovalModal from './ReportApprovalModal';
import ReportRejectModal from './ReportRejectModal';
import authService from 'services/auth-service';
import ReportSubmitModal from './ReportSubmitModal';
import { Helmet } from 'react-helmet';

const BloodLossReport = () => {
  let { id } = useParams();
  const reportId = id;
  const [report, setReport] = useState({});  
  const [donors, setDonors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [assignModalVisible, setAssignModalVisible] = useState(false);
  const [approveModalVisible, setApproveModalVisible] = useState(false);
  const [rejectModalVisible, setRejectModalVisible] = useState(false);
  const [submitModalVisible, setSubmitModalVisible] = useState(false);
  const [reportDate, setReportDate] = useState('');
  const [isStatusAllSigned, setIsStatusAllSigned] = useState(false);
  // const [errorMessages, setErrorMessages] = useState('');
  const [userCan, setUserCan] = useState({
    edit: false,
    approve_reject: false,
    assign: false
  });
  const configState = {
    gridRowsCount: getItemFromStore('gridRowsCount', settings.gridRowsCount)
  }; 
 

  useEffect(() => {
    setIsLoading(true);
    const reportPromise = bloodLossService.fetchReport(reportId);
    const donorPromise = bloodLossService.fetchReportDonors(reportId);
    Promise.all([reportPromise, donorPromise]).then(([report, donors]) => {      
      const result = getUserPermissions(report.assignedUserId);
      setUserCan(result);
      setReport(report);
      setReportDate(new Date(report.date).toLocaleDateString());
      donors.forEach(item => {
        item.plasmaEligibilityDate = item.plasmaEligibilityDate? new Date(item.plasmaEligibilityDate).toLocaleDateString() : null;
        item.rbcEligibilityDate = item.rbcEligibilityDate? new Date(item.rbcEligibilityDate).toLocaleDateString() : null;
        item.plasmaDeferralDate = item.plasmaDeferralDate? new Date(item.plasmaDeferralDate).toLocaleDateString() : null;
        item.rbcDeferralDate = item.rbcDeferralDate? new Date(item.rbcDeferralDate).toLocaleDateString() : null;
        item.sendLetter = item.sendLetter === true ? 'Yes' : item.sendLetter === false ? 'No' : '';
        if (!item.signedOffBy) {
          setIsStatusAllSigned(true);
        }

      });
      setDonors(donors);
      setIsLoading(false);
    }
    ).catch(error => {
      console.log(error);
      setIsLoading(false);
    });

  }, []);
 
  
  const getUserPermissions = (assignedUserId) => {    
   // const currentUserId = authService.getCurrentUserId();
    // if (authService.hasPermission(authService.claims.editBloodLossReports) &&
    //     currentUserId === assignedUserId) {
    //     userCan.edit = true;
    // }
    if (assignedUserId !== null) {
      userCan.edit = true;
    }
    if (authService.hasPermission(authService.claims.approveRejectBloodLossReports)) {
        userCan.approve_reject = true;
    }
    if (authService.hasPermission(authService.claims.editBloodLossReports) ||
        authService.hasPermission(authService.claims.approveRejectBloodLossReports)) {
        userCan.assign = true;
    }
    return userCan;
  } 

  const assign = (userId) => {
    setAssignModalVisible(false);
    if (userId === report.assignedUserId) {       
        return;
    }
    bloodLossService.assignReport(report.id, userId)
    .then(bloodLossReport => {
        setReport(bloodLossReport);
        // setErrorMessages('');
    })
    .catch(error => {
        console.log(error);
        // setErrorMessages(error.message || 'Failed to assign blood loss report');
    });
  }
  const  approve = (comment) => {
    const approveCommand = {
        comment
    };

    bloodLossService.approveReport(report.id, approveCommand)
        .then(bloodLossReport => {
            setReport(bloodLossReport);
            setApproveModalVisible(false);
            // setErrorMessages('');
        });
  }
  const submit = (comment) => {
    const submitCommand = {
        comment
    };

    bloodLossService.submitReport(report.id, submitCommand)
        .then(bloodLossReport => {
            setReport(bloodLossReport);
            setSubmitModalVisible(false);
            // setErrorMessages('');
        });
  }
  const  reject = (comment) => {
    const rejectCommand = {
        comment
    };

    bloodLossService.rejectReport(report.id, rejectCommand)
        .then(bloodLossReport => {
            setReport(bloodLossReport);
            setRejectModalVisible(false);
            // setErrorMessages('');
        });
}

  return (
    <>
      <Helmet>
        <title>NYBC Blood Loss Report</title>
      </Helmet>
      <PageHeader title="NYBC Blood Loss" className="mb-2">
      <Breadcrumb>  
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/blood-loss">NYBC Blood Loss</Breadcrumb.Item>        
        <Breadcrumb.Item active>Report</Breadcrumb.Item>
      </Breadcrumb>
      </PageHeader>
      
        <Card className="h-100">
          <Card.Header>
            <Row className="flex-between-center">
              <Col xs={9} sm={6} lg={6}>
                Donors selected on {reportDate}
                {/* <AdvanceTableSearchBoxPlug table placeholder="Search" /> */}
              </Col>
              <Col xs="auto" sm={3} lg={3} className="text-end">
                    <div className="ml-auto" style={{ marginBottom: '7px' }}>
                            <ReportStatusPill status={report.status} />
                    </div>
              </Col>
            </Row>
            <hr />
            {donors.length > 0 &&  <>             
            
            <Row>
              <span className='mb-3'>A user must be assigned in order to review, approve or override system calculated deferral dates. Once all donors have been reviewed, a report may be submitted for approval. Upon submission Blood Loss admins will be notified to conduct a final review and either approve or reject the report.</span>                       
            </Row>
            <Row>
                <Col>               
                {!isLoading && report.status !== "Approved" &&                
                    <div>
                    <span>Assigned User: {report.assignedUser} </span>
                      {userCan.assign && <button className="btn btn-warning ml-2"
                          type="button"
                          onClick={()=> setAssignModalVisible(true)}>
                          <FontAwesomeIcon icon={faUsersCog} />&nbsp;Change</button>}
                    </div>                                                                   
                }
                </Col>
            </Row>            
            </>}
            
            {donors.length === 0 &&
             <Row><Col><span>Donors not found.</span></Col></Row> 
             }
          </Card.Header>
          <AdvanceTableWrapper
                columns={columns}
                data={donors}
                sortable
                pagination
                perPage={configState.gridRowsCount}
              > 
          <Card.Body className="p-0">
            <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{ className: 'fs--1 mb-0 overflow-hidden' }}
                  />
          </Card.Body>
          <Card.Footer>
          <AdvanceTableFooter
              rowCount={donors.length}
              table
              rowInfo
              navButtons
            />
          </Card.Footer>          
          </AdvanceTableWrapper>
            <div className="d-flex justify-content-center mb-3"> 
                    {
                      userCan.approve_reject && report.status === 'Submitted' && 
                        <button className="btn btn-w-m btn-primary m-2"
                                    type="button"
                                    onClick={()=>setApproveModalVisible(true)}>
                                    <FontAwesomeIcon icon={faThumbsUp} />&nbsp;Approve</button>}
                    {
                    userCan.approve_reject && report.status === 'Submitted' && 
                        <button className="btn btn-w-m btn-danger m-2"
                                    type="button"
                                    onClick={()=>setRejectModalVisible(true)}>
                                    <FontAwesomeIcon icon={faThumbsDown} />&nbsp;Reject</button>}
                    {
                    userCan.edit && report.status !== 'Approved' && report.status !== 'Submitted' && !isStatusAllSigned &&
                        <button className="btn btn-w-m btn-primary m-2"
                                    type="button"
                                    onClick={()=>setSubmitModalVisible(true)}>
                                    <FontAwesomeIcon icon={faGlobeAmericas} />&nbsp;Submit</button>
                    }
                  <Link  className="btn btn-outline-primary m-2"  to={`/blood-loss/report/${id}/audit/${reportDate.replaceAll('/','-')}`}  >
                      <FontAwesomeIcon icon={faHistory} /> History
                  </Link>
            </div>
        </Card>     
        
      <div className="text-right mt-2">
            <ReportApprovalModal 
                visible={approveModalVisible}
                approve={approve}
                hide={()=>setApproveModalVisible(false)} />
                    <ReportRejectModal visible={rejectModalVisible}
                        reject={reject}
                        hide={()=>setRejectModalVisible(false)} />
                    <ReportSubmitModal visible={submitModalVisible}
                        submit={submit}
                        hide={()=>setSubmitModalVisible(false)} />
            <UserAssignmentModal 
               visible={assignModalVisible}
               assign={assign}
               hide={()=>setAssignModalVisible(false)}
                     />
        </div>
    </>
  );
};

const columns = [
  {
    accessor: 'donorCode',
    Header: 'Donor Code',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    },
    Cell: rowData => (
      <Link
        to={`donor/${rowData.row.original.donorKey}`}
        className="text-primary fw-semi-bold"
      >
        {rowData.row.original.donorCode} 
      </Link>
    )
  },
  {
    accessor: 'totalPlasmaLoss',
    Header: 'Plasma Loss',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'totalRBCLoss',
    Header: 'RBC Loss',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'plasmaEligibilityDate',
    Header: 'Plasma Eligibility Date',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },  
  {
    accessor: 'rbcEligibilityDate',
    Header: 'RBC Eligibility Date',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'plasmaDeferralDate',
    Header: 'Plasma Deferral Date',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    },
    Cell: rowData => (    
      <div>
          {rowData.row.original.signedOffPlasmaDeferralDate? new Date(rowData.row.original.signedOffPlasmaDeferralDate).toLocaleDateString('en-US') :
           rowData.row.original.PlasmaDeferralDate? new Date(rowData.row.original.PlasmaDeferralDate).toLocaleDateString('en-US') : '' }
      </div>
    )
  },
  {
    accessor: 'rbcDeferralDate',
    Header: 'RBC Deferral Date',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    },
    Cell: rowData => (
      <div>
      {rowData.row.original.signedOffRBCDeferralDate? new Date(rowData.row.original.signedOffRBCDeferralDate).toLocaleDateString('en-US') :
       rowData.row.original.rbcDeferralDate? new Date(rowData.row.original.rbcDeferralDate).toLocaleDateString('en-US') : '' }
    </div>

      // <DateFormat format="MM/DD/YYYY" date={rowData.row.original.signedOffRBCDeferralDate? rowData.row.original.signedOffRBCDeferralDate : rowData.row.original.rbcDeferralDate? rowData.row.original.rbcDeferralDate : null } />
    )
  },
  {
    accessor: 'sendLetter',
    Header: 'Send Letter',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'status',
    Header: 'Status',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    },
    Cell: rowData => (
      rowData.row.original.signedOffBy ? 
        <span style={{ color: "#1ab394" }}><FontAwesomeIcon icon={faCheckCircle} /></span> :
        <span className="text-danger"><FontAwesomeIcon icon={faExclamationCircle} /></span>
    )
  }
];

// BloodLossDonor.propTypes = {
//   donors: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.number.isRequired,
//       date: PropTypes.string.isRequired,
//       status: PropTypes.string.isRequired,
//       assignedUser: PropTypes.string,
//       lastModifiedBy: PropTypes.string.isRequired,
//       lastModifiedTimestamp: PropTypes.string.isRequired,
//     })
//   ),
//   perPage: PropTypes.number
// };

export default BloodLossReport;
