/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
// import { Card } from 'react-bootstrap';
// import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
// import AdvanceTable from 'components/common/advance-table/AdvanceTable';
// import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import WifaService from 'services/wifa-service';
// import { getItemFromStore } from '../../helpers/utils';
// import { settings } from '../../config';
import WIFASessionViewer from 'pages/wifaScenarioBoldReport/wifa-scenario-viewer';
import SoftBadge from 'components/common/SoftBadge';

const WIFAReview = (prop) => {
  // const configState = {
  //   gridRowsCount: getItemFromStore('gridRowsCount', settings.gridRowsCount)
  // };
   // const [reviewScenarioData, setReviewScenarioData] = useState([]);
   // const [dynamicColumns, setDynamicColumns] = useState([]);
    const [wifaParam] = useState('WIFASessionId');
    const [wifaPath] = useState('/Inventory/WIFA Projection Scenario');
    const [wifaSessionId] = useState(prop.wifaSessionId);

      useEffect(() => {
        // Get Data for Review Scenario
        WifaService.getBuildModelScenarios(prop.wifaSessionId, prop.bloodCenterKey, prop.scenarioKey)
              .then(() => {
               // setReviewScenarioData(reviewScenario);
              });
       // setDynamicColumns(reviewColumns);
      }, []);

  return (
    <>
    <SoftBadge  bg={'warning'}
    className='rounded-pill'  > Division: {prop.bloodCenterCode}
  </SoftBadge> <br/>
          <WIFASessionViewer 
            wifaParamId = {wifaSessionId}
            wifaPath = {wifaPath}
            wifaParam = {wifaParam}
          />
          </>
    )    
}

export default WIFAReview;
