/* eslint-disable prettier/prettier */
import { getColor, rgbaColor } from 'helpers/utils';
//import { chartJsDefaultTooltip } from 'helpers/chartjs-utils';
import { Button, Card, Col, Form, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import React, { useState, useEffect, useRef } from 'react';
//import { Line } from 'react-chartjs-2';
import homeService from 'services/home-service';
//import FalconLink from 'components/common/FalconLink';
//import Spinner from 'react-bootstrap/Spinner';
// import PropTypes from 'prop-types';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment-timezone';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';

//import { returningCustomerData } from 'data/dashboard/ecom';
import { CanvasRenderer } from 'echarts/renderers';
//import dayjs from 'dayjs';
import { tooltipFormatter } from 'helpers/echart-utils';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);


const WeeklyCollectionTrendsRP = () => {
    const profile = JSON.parse(localStorage.getItem('userProfile'));
    var emailSplit = profile.email.split('@');
    const NameForTotal = 'NYBCe';
    const [divisions, setDivisions] = useState([]); //['NYBCe','NYBC','IBR','RIBC'];
    const [allData, setAllData] = useState([]);
    const [oneDivision, setOneDivision] = useState(profile.isEnterpriseView ? NameForTotal : emailSplit[1].split('.')[0].toUpperCase());
    const [dataPLT, setDataPLT] = useState([]);
    const [dataRBC, setDataRBC] = useState([]);
    //const [dataPlasma, setDataPlasma] = useState([]);
    const [labels, setLabels] = useState([]);
    const sortOrder = [NameForTotal,'NYBC','CBC', 'IBR', 'RIBC', 'BBD'];
    const [isPlatelets, setPlatelets] = useState(true);
    const [isRedBloodCells, setIsRedBloodCells] = useState(true);
    //const [isPlasma, setIsPlasma] = useState(true);
    // const [gradientPLT, setGradientPLT] = useState();    
    // const [gradientRBC, setGradientRBC] = useState();
    //const [isLoading, setIsLoading] = useState();
    //const [month, setMonth] = useState(0);
    const chartRef = useRef(null);
    useEffect(() => {
      //setIsLoading(true);
      homeService.getCollectionsWeeklyTrends().then(result => {
        const temp = result.sort((a, b) => a.collectionWeek.localeCompare(b.collectionWeek)).map(a=> { return {collectionWeek: a.collectionWeek, phlebotomy: a.phlebotomy, units: a.units} } );
        //sum up all units by phlebotomy and collectionWeek
        const sumOfUnits = Array.from(temp.reduce((acc, {units, ...r}) => {
          const key = JSON.stringify(r);
          const current = acc.get(key) || {...r, units: 0};  
          return acc.set(key, {...current, units: current.units + units});
        }, new Map).values());
        const allData = sumOfUnits.map(a => ({ division: NameForTotal, ...a }));
        // console.log(allData);
        allData.map(a=> result.push(a));
        setAllData(result);
        var divisionValues = [...new Set(result.map(a => a.division))];
        divisionValues.sort(
          function(a, b){                
              return sortOrder.indexOf(a) - sortOrder.indexOf(b); 
          }
        );
        setDivisions(divisionValues);      
        //setDivisions([...new Set(result.map(a => a.division))]);       

        setLabels([...new Set(allData.map(a => moment(a.collectionWeek).format('YYYY-MM-DD')))]);
        setDataPLT(result.filter(a=>a.phlebotomy === 'Platelets' && a.division === oneDivision).map(a=>a.units));        
        setDataRBC(result.filter(a=>a.phlebotomy === 'Red Blood Cells' && a.division === oneDivision).map(a=>a.units));
        //setDataPlasma(result.filter(a=>a.phlebotomy === 'Plasma' && a.division === oneDivision).map(a=>a.units));
       
        // var ctx = document.getElementById('chartCollection').getContext('2d');
        // var gradient = ctx.createLinearGradient(0, 0, 0, 300);
        // gradient.addColorStop(0, 'rgba(243, 32, 11, 0.2)');
        // gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
        // setGradientRBC(gradient);
        // gradient = ctx.createLinearGradient(0, 0, 0, 300);
        // gradient.addColorStop(0, 'rgba(243, 153, 11, 0.2)');
        // gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
        // setGradientPLT(gradient);
        
      }).finally(()=>{
        //setIsLoading(false);
      })
    }, []);

    const handleLegend = seriesName => {
      seriesName === 'Platelets' && setPlatelets(!isPlatelets);
      seriesName === 'Red Blood Cells' && setIsRedBloodCells(!isRedBloodCells);
      //seriesName === 'Plasma' && setIsPlasma(!isPlasma);
      chartRef.current.getEchartsInstance().dispatchAction({
        type: 'legendToggleSelect',
        name: seriesName
      });
    };

    function onChangeDivision (e) {
      setOneDivision(e.target.value);
      const divisionData = allData.filter(a=>a.division === e.target.value);
      setLabels([...new Set(divisionData.map(a => moment(a.collectionWeek).format('YYYY-MM-DD')))]);
      setDataPLT(divisionData.filter(a=>a.phlebotomy === 'Platelets').map(a=>a.units));        
      setDataRBC(divisionData.filter(a=>a.phlebotomy === 'Red Blood Cells').map(a=>a.units));
      //setDataPlasma(divisionData.filter(a=>a.phlebotomy === 'Plasma').map(a=>a.units));
    }

    const kFormatter = (num) => {
      return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
    }

    // const dates = month => {
    //   return getDates(
    //     dayjs().month(month).date(1),
    //     dayjs()
    //       .month(Number(month) + 1)
    //       .date(0),
    //     1000 * 60 * 60 * 24 * 3
    //   );
    // };

    const getOptions = (platelet, redBloodCell) => ({
      // title: {
      //   text: 'Customers',
      //   textStyle: {
      //     fontWeight: 500,
      //     fontSize: 13,
      //     fontFamily: 'poppins',
      //     color: getColor('900')
      //   }
      // },
      legend: {
        show: false,
        data: ['Platelets', 'Red Blood Cells'],
        // formatter: function(value) {
        //   return (<FontAwesomeIcon
        //     icon="circle"
        //     className="text-primary fs--2 me-1"
        //   />);
        // }
      },
      
      tooltip: {
        trigger: 'axis',
        padding: [7, 10],
        backgroundColor: getColor('100'),
        borderColor: getColor('100'),
        color: getColor('dark'),
        // textStyle: { color: getColor('dark') },
        borderWidth: 1,
        formatter: tooltipFormatter
      },
      xAxis: {
        // name: 'Weeks',
        // nameLocation: 'middle',
        // nameTextStyle: {
        //   color: '#808080',
        // },
        //nameGap: 33,
        type: 'category',
        //text: 'Week', 
        data: labels,
        //axisLabel : 90,
        //display: true,
        // position: 'bottom',
        // title: {
        //   text: 'Week',
        //   display: true
        // },
        boundaryGap: false,
        axisPointer: {
          lineStyle: {
            color: getColor('300')
          }
        },
        axisLine: {
          lineStyle: {
            color: getColor('300'),
            type: 'solid'
          }
        },
        axisTick: { show: false },
        axisLabel: {
          color: getColor('400'),
          // formatter(value, index) {
          //   if (index === 0) {
          //     return `${dayjs(value).format('MMM DD')}`;
          //   }
          //   return `${dayjs(value).format('DD')}`;
          // },
          margin: 15,
          rotate: 60,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: getColor('300'),
            type: 'dashed'
          }
        }
      },
      yAxis: {
        type: 'value',
        // name: 'Units',
        // nameLocation: 'middle',
        // nameTextStyle: {
        //   color: '#808080',
        // },
        //nameGap: 35,
        splitLine: {
          lineStyle: {
            color: getColor('300')
          }
        },
        // title: {
        //   text: 'Units',
        //   display: true,
        // },
        axisLabel: {
          color: getColor('400'),
          margin: 15,
          formatter: function(value) {
            return kFormatter(Number(value));
          }
        }
      },
      series: [
        {
          name: 'Platelets',
          type: 'line',
          data: platelet,          
          lineStyle: { color: getColor('warning') },
          itemStyle: {
            borderColor: getColor('warning'),
            borderWidth: 2
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: rgbaColor(getColor('primary'), 0.2)
                },
                {
                  offset: 1,
                  color: rgbaColor(getColor('primary'), 0.01)
                }
              ]
            }
          },
          symbol: 'none',
          emphasis: {
            scale: true
          }
        },
        {
          name: 'Red Blood Cells',
          type: 'line',
          data: redBloodCell,
          lineStyle: { color: getColor('danger') },
          itemStyle: {
            borderColor: getColor('danger'),
            borderWidth: 2
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: rgbaColor(getColor('warning'), 0.2)
                },
                {
                  offset: 1,
                  color: rgbaColor(getColor('warning'), 0.01)
                }
              ]
            }
          },
          symbol: 'none',
          emphasis: {
            scale: true
          }
        }//,
        // {
        //   name: 'Plasma',
        //   type: 'line',
        //   data: plasma,
        //   lineStyle: { color: getColor('primary') },
        //   itemStyle: {
        //     borderColor: getColor('primary'),
        //     borderWidth: 2
        //   },
        //   areaStyle: {
        //     color: {
        //       type: 'linear',
        //       x: 0,
        //       y: 0,
        //       x2: 0,
        //       y2: 1,
        //       colorStops: [
        //         {
        //           offset: 0,
        //           color: rgbaColor(getColor('success'), 0.2)
        //         },
        //         {
        //           offset: 1,
        //           color: rgbaColor(getColor('success'), 0.01)
        //         }
        //       ]
        //     }
        //   },
        //   symbol: 'none',
        //   emphasis: {
        //     scale: true
        //   }
        // }
      ],
    
      grid: { right: 10, left: 10, bottom: 0, top:10, containLabel: true }
    });

    return (
      <>
      <Card className="h-100">
        <FalconCardHeader
          title= { <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>Shows reported collection for previous 14 completed weeks. Estimate includes incomplete procedures. Week starts on Monday and ends on Sunday. Click on product name to filter the chart.</Tooltip>
            }
          >
            <span> Weekly Collections
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                transform="shrink-1"
                className="ms-1 text-400"
                id="weeklySalesTooltip"
              />
            </span>
          </OverlayTrigger>}
          titleTag="h6"          
          className="flex-center py-2"
          light
          endEl={
            <Flex>
              <Form.Select
                size="sm"
                value={oneDivision}
                onChange={onChangeDivision}
                className="me-2"
              >
                {divisions.map((label) => (
                  <option value={label} key={label}>
                    {label}
                  </option>
                ))}
              </Form.Select>
            </Flex>
          }
        />
        <Card.Body> 
        <ReactEChartsCore
          ref={chartRef}
          echarts={echarts}
          option={getOptions(dataPLT, dataRBC)}
          //style={{ height: '20rem' }}
        />
        </Card.Body>
        <Card.Footer className="border-top py-2">
        <Row className="g-0 align-items-center">
          <Col xs="auto me-2">
            <Button
              size="sm"
              id="newMonth"
              className={classNames('align-items-center p-0 shadow-none', {
                'opacity-50': !isPlatelets
              })}
              variant="default"
              onClick={() => handleLegend('Platelets')}
            >
              <FontAwesomeIcon
                icon="circle"
                className="text-warning fs--2 me-1"
              />
              Platelets
            </Button>
          </Col>
          <Col xs="auto me-2">
            <Button
              size="sm"
              id="returningMonth"
              className={classNames('align-items-center p-0 shadow-none', {
                'opacity-50': !isRedBloodCells
              })}
              variant="default"
              onClick={() => handleLegend('Red Blood Cells')}
            >
              <FontAwesomeIcon
                icon="circle"
                className="text-danger fs--2 me-1"
              />
              Red Blood Cells
            </Button>
          </Col>
          {/* <Col xs="auto">
            <Button
              size="sm"
              id="newMonth"
              className={classNames('align-items-center p-0 shadow-none', {
                'opacity-50': !isPlasma
              })}
              variant="default"
              onClick={() => handleLegend('Plasma')}
            >
              <FontAwesomeIcon
                icon="circle"
                className="text-primary fs--2 me-1"
              />
              Plasma
            </Button>
          </Col> */}
          <Col className="text-end">
            {/* <FalconLink title="View" className="px-0" /> */}
            <Link to="/dashboards/14319" className="fw-semi-bold fs--1 text-nowrap" size="sm">
              View<FontAwesomeIcon
                icon= {faAngleDoubleRight}
                className="ms-1"
                transform="down-1"
              />
            </Link>
          </Col>
        </Row>
      </Card.Footer>
      </Card>
      </>
    );
}

export default WeeklyCollectionTrendsRP;