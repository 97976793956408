/* eslint-disable prettier/prettier */
import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthSimpleLayout from './AuthSimpleLayout';
import is from 'is_js';
import MainLayout from './MainLayout';
// import SettingsToggle from 'components/settings-panel/SettingsToggle';
import SettingsPanel from 'components/settings-panel/SettingsPanel';
import Notification from 'pages/notification/Notification';
import ErrorLayout from './ErrorLayout';
// import WizardAuth from 'components/authentication/wizard/WizardAuth';
// import Landing from 'pages/landing/Landing';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';
import  UserBrowser  from '../../src/pages/admin/user-browser';
import Starter from 'pages/Starter';
// import AnimatedIcons from 'components/doc-components/AnimatedIcons';
// import DatePicker from 'components/doc-components/DatePicker';
import FontAwesome from 'components/doc-components/FontAwesome';
// import Changelog from 'components/documentation/change-log/ChangeLog';
// import Analytics from 'components/dashboards/analytics';
// import Crm from 'components/dashboards/crm';
// import Saas from 'components/dashboards/saas';
import Profile from 'pages/user/profile/Profile';
import BlobFileShare from '../BlobFileShare';
import BoldReports from 'pages/BoldReports/BoldReports';
import Settings from 'pages/user/settings/Settings';
import FaqAccordion from 'pages/faq/faq-accordion/FaqAccordion';
import LineCharts from 'components/doc-components/charts-example/echarts/line-charts';
import BarCharts from 'components/doc-components/charts-example/echarts/bar-charts';
import CandlestickCharts from 'components/doc-components/charts-example/echarts/candlestick-charts';
import GeoMaps from 'components/doc-components/charts-example/echarts/geo-map';
import ScatterCharts from 'components/doc-components/charts-example/echarts/scatter-charts';
import PieCharts from 'components/doc-components/charts-example/echarts/pie-charts';
import RadarCharts from 'components/doc-components/charts-example/echarts/radar-charts/Index';
import HeatmapCharts from 'components/doc-components/charts-example/echarts/heatmap-chart';
import Chartjs from 'components/doc-components/charts-example/chartjs';
import HowToUse from 'components/doc-components/charts-example/echarts/HowToUse';
import ReactIcons from 'components/doc-components/ReactIcons';
import Error404 from '../components/errors/Error404';
import Error500 from '../components/errors/Error500';
import Error501x from '../components/errors/Error501x';
import TableauLicenseError from '../components/errors/TableauLicenseError';
import SimpleLogout from 'components/authentication/simple/Logout';
import SimpleLockScreen from 'components/authentication/simple/LockScreen';
import Logout from 'components/authentication/card/Logout';
import CardLockScreen from 'components/authentication/card/LockScreen';
import Home from 'pages/home/home';
import ReportBrowser from 'pages/reports/report-browser';
import SplitLogin from 'components/authentication/split/Login';
import SplitLogout from 'components/authentication/split/Logout';
import SplitLockScreen from 'components/authentication/split/LockScreen';
import CatalogBrowser from 'pages/admin/catalog-browser';
import CatalogEditor from 'pages/admin/catalog-editor';
import CatalogEmailNotificationEditor from 'pages/admin/catalog-email-notification';
import CatalogEmailNotificationBrowser from 'pages/admin/catalog-email-notification-browser';
import HemetricsSop from 'pages/sop/hemetricsSop';
import CustomerMDMSop from 'pages/sop/customerMDMSop';
import BloodLossSop from 'pages/sop/bloodLossSop';
import DataGovernanceSop from 'pages/sop/dataGovernanceSOP';
import TableauIPhoneInstructionsSop from 'pages/sop/TableauInstructionOnIPhone';
import EADSWiki from 'pages/wiki/eadsWiki';
// import Wizard from 'components/wizard/Wizard';

import AppContext from 'context/Context';
// import Authorization from 'common/authorization';
// import AuthService from 'services/auth-service';
import UserEditor from 'pages/admin/user-editor';
import UserRolesBrowser from 'pages/admin/user-role-browser';
import UserRoleEditor from 'pages/admin/user-role-editor';
import UserRoleNew from 'pages/admin/user-role-new';
import ReleaseBrowser from 'pages/release/releaseBrowser';
import ReleaseEditor from 'pages/release/releaseEditor';
import ReleaseNew from 'pages/release/releaseNew';
import ReleaseView from 'pages/release/releaseView';
/* eslint-disable-next-line */
import NotificationView from 'pages/notification/NotficationView';
import Dashboards from 'pages/dashboards/dashboard-land-page';
import DataManagement from 'pages/governance/dataManagement';
import DataRulesView from 'pages/governance/dataRulesView';
import DataRuleNotification from 'pages/governance/dataRuleNotification';
import EnterpriseDataGovernance from 'pages/governance/activeGovernanceLandPage';
import EnterpriseDataGovernanceRule from 'pages/governance/activeGovernanceDataRule';
import DataRefresh from 'pages/dataRefresh/dataRefresh';
import NotificationDetail from 'pages/notification/NotificationDetail';
import AdvancedSearch from 'pages/search/advanced-search';
import BloodLoss from 'pages/blood-loss/blood-loss';
import BloodLossHistoryReport from 'pages/blood-loss/history-report';
import BloodLossAuditReport from 'pages/blood-loss/audit-report';
import BloodLossDonorSearchReport from 'pages/blood-loss/search-donor';
import BloodLossReport from 'pages/blood-loss/blood-loss-report';
import DonationHistoryWorksheet from 'pages/blood-loss/donor-history-worksheet';
import DataGlossary from 'pages/governance/dataGlossaryHome';
import KPIBrowser from 'pages/kpi/kpi-browser';
import KPIEditor from 'pages/kpi/kpi-editor';
import KPIViewer from 'pages/kpi/kpi-viewer';
import WIFAHome from 'pages/plateletWIFA/WIFAHome';
import WIFAHistory from 'pages/plateletWIFA/WIFAHistory';
import Projections from 'pages/plateletWIFA/WIFAWizardLanding';


const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>            
        {/* <Route path="landing" element={<Landing />} /> */}
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />          
          <Route path="errors/501x" element={<Error501x />} />
          <Route path="errors/TableauLicenseError" element={<TableauLicenseError />} />
        </Route>
        
        {/*- ------------- simple ---------------------------  */}
        <Route element={<AuthSimpleLayout />}>
    
          <Route
            path="authentication/simple/logout"
            element={<SimpleLogout />}
          />
        
          <Route
            path="authentication/simple/lock-screen"
            element={<SimpleLockScreen />}
          />
        </Route>

     
        <Route path="authentication/card/logout" element={<Logout />} />
     
        <Route
          path="authentication/card/lock-screen"
          element={<CardLockScreen />}
        />

        {/*- ------------- Split ---------------------------  */}

        <Route path="authentication/split/login" element={<SplitLogin />} />
        <Route path="authentication/split/logout" element={<SplitLogout />} />
       
        <Route
          path="authentication/split/lock-screen"
          element={<SplitLockScreen />}
        />


        <Route path="signout-oidc" element={<Navigate replace to="/" />} />   
        {/* //--- MainLayout Starts  */}

        <Route element={<MainLayout />}>
          {/*Home page*/}
           <Route path="/" element={<Home />} /> 
          {/* <Route path="dashboard/analytics" element={<Analytics />} /> */}
         <Route path="advanced-search" element={<AdvancedSearch />} />
         
         <Route path="/report/browser" element={<ReportBrowser />} />
          {/*icons*/}
          <Route path="icons/font-awesome" element={<FontAwesome />} />
          <Route path="icons/react-icons" element={<ReactIcons />} />

          <Route path="pages/starter" element={<Starter />} />
          <Route path="user/profile" element={<Profile />} />
          <Route path="user/settings" element={<Settings />} />
          <Route path="BlobFileShare" element={<BlobFileShare />} />
          <Route path="boldReports/:id" element={<BoldReports />} />
          
          {/* <Route path="miscellaneous/associations" element={<Associations />} /> */}
          <Route path="blood-loss" element={<BloodLoss />} />
          <Route path="blood-loss/audit" element={<BloodLossAuditReport />} />
          <Route path="blood-loss/search-donor" element={<BloodLossDonorSearchReport />} />         
          <Route path="blood-loss/report/:id" element={<BloodLossReport />} />  
          <Route path="blood-loss/report/:id/audit/:reportDate" element={<BloodLossHistoryReport />} />
          <Route path="blood-loss/report/:id/donor/:donorKey" element={<DonationHistoryWorksheet />} />
           <Route path="/admin/catalogs" element={<CatalogBrowser />} />
         <Route path="/admin/catalogs/new" element={<CatalogEditor />} />
         <Route path="/admin/catalogs/:id" element={<CatalogEditor />} />
          <Route path="faq" element={<FaqAccordion />} />
          <Route path="hemetricsSop" element={<HemetricsSop />} />
          <Route path="customerMDMSop" element={<CustomerMDMSop />} />
          <Route path="bloodLossSop" element={<BloodLossSop />} />
          <Route path="dataGovernanceSop" element={<DataGovernanceSop />} />
          <Route path="tableauIPhoneInstructionsSop" element={<TableauIPhoneInstructionsSop />} />
          <Route path="wiki" element={<EADSWiki />} />
          <Route         path="admin/UserBrowser"        element={<UserBrowser  />}      />

          <Route path="/admin/UserBrowser/:userId" element={<UserEditor  />} />
          <Route    path="/admin/UserRoles"      element={<UserRolesBrowser  />}     />
         <Route path="/admin/UserRoles/:roleId"     element={<UserRoleEditor  />}   />
          <Route  path="/admin/NewUserRoles/" element={<UserRoleNew  />}  />
          {/* Relase Manager*/}
          <Route
                    path="/release/browser/"
                    element={<ReleaseBrowser  />}                 
                    />
          <Route
                    path="/release/editor/:releaseId"
                    element={<ReleaseEditor />}                 
                    />
          <Route
                    path="/release/new/"
                    element={<ReleaseNew />}                 
                    />
          <Route
                    path="/release/view/:releaseId"
                    element={<ReleaseView />}                 
                    />

          <Route path="/admin/catalogEmailNotification/new" element={<CatalogEmailNotificationEditor />} />
          <Route path="/admin/catalogEmailNotification/:id" element={<CatalogEmailNotificationEditor />} />
          <Route path="/admin/catalogEmailNotification" element={<CatalogEmailNotificationBrowser />} />
         
          {/* Dynamic Dashboard */}

          <Route path = "/dashboards/:dashboardId" element ={<Dashboards />} />
          <Route path = "/governance/dataManagement/:tableConfigId" element ={<DataManagement />} />
          <Route path = "/governance/dataManagement/:databaseId/:tableConfigId" element ={<DataManagement />} />
          <Route path = "/governance/dataRulesView/:tableConfigId" element ={<DataRulesView />} />
          <Route path = "/governance/dataRulesView/:databaseId/:tableConfigId" element ={<DataRulesView />} />
          <Route path = "/governance/dataRuleNotification/:tableConfigId/:dataRuleId" element ={<DataRuleNotification />} />
          <Route path = "/governance/dataRuleNotification/:databaseId/:tableConfigId/:dataRuleId" element ={<DataRuleNotification />} />
          <Route path = "/governance/activeGovernanceLandPage" element ={<EnterpriseDataGovernance />} />
          <Route path = "/governance/governanceDataRule/:tableConfigId/:dataRuleId" element ={<EnterpriseDataGovernanceRule />} />  
          <Route path = "/governance/governanceDataRule/:databaseId/:tableConfigId/:dataRuleId" element ={<EnterpriseDataGovernanceRule />} />  
          <Route path = "/governance/dataGlossaryHome" element ={<DataGlossary />} />          
          <Route path = "/dataRefresh/dataRefresh" element ={<DataRefresh />} />
          <Route path="/kpi/kpi" element={<KPIBrowser />} />
          <Route path="/kpi/kpi/new" element={<KPIEditor />} />
          <Route path="/kpi/kpi/:id" element={<KPIEditor />} />
          <Route path="/kpi/kpiViewer/:id" element={<KPIViewer />} />
          <Route path="/plateletWIFA/WIFAWizardLanding" element={<Projections />} />
          <Route path="/plateletWIFA/WIFAHome" element={<WIFAHome />} />
          <Route path="/plateletWIFA/WIFAHistory" element={<WIFAHistory />} />

          {/* Notification*/}
            <Route
            path="/notification/Notification/"
            element={<Notification />}
          />
          <Route
            path="/notification/NotificationView/:messageId"
            element={<NotificationDetail />}
          />   
          <Route path="charts/chartjs" element={<Chartjs />} />
          <Route path="charts/echarts/line-charts" element={<LineCharts />} />
          <Route path="charts/echarts/bar-charts" element={<BarCharts />} />
          <Route            path="charts/echarts/candlestick-charts"            element={<CandlestickCharts />}          />
          <Route path="charts/echarts/geo-map" element={<GeoMaps />} />
          <Route            path="charts/echarts/scatter-charts"            element={<ScatterCharts />}          />
          <Route path="charts/echarts/pie-charts" element={<PieCharts />} />
          <Route path="charts/echarts/radar-charts" element={<RadarCharts />} />
          <Route            path="charts/echarts/heatmap-charts"            element={<HeatmapCharts />}          />
          <Route path="charts/echarts/how-to-use" element={<HowToUse />} />
        </Route>

        {/* //--- MainLayout end  */}

        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      {/* <SettingsToggle /> */}
      <SettingsPanel />
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
