/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import Select from 'react-select';
import catalogService from 'services/catalog-service';
import './advanced-search.scss';

const SearchFilter = (prop) => {
  const filter = {
    isEnterprise: false,
    isRegulatory: false,
    isDashboards: false,
    isReports: false,
    isKPi: false,
    isVocabulary: false,
    domainId: '',
    domainName: '',
    subdomainId: '',
    subdomainName: '',
  }
  const [criteria, setCriteria] = useState(filter);
    
  const [domains, setDomains] = useState([]);
  const [subdomains, setSubdomains] = useState([]);

  function onChangeDomain(e) {
    catalogService.fetchCatalogSubdomainByParent(e.value)
    .then(subdomain => {
        setSubdomains(subdomain.domains);
    });
    setCriteria({...criteria, domainName: e.label, domainId: e.value, subdomainId: '', subdomainName: ''});
  }

  useEffect(() => {
    if (Object.keys(prop.criteria).length > 0) {
      setCriteria(prop.criteria);
      if (prop.criteria.domainId) {
        catalogService.fetchCatalogSubdomainByParent(prop.criteria.domainId)
        .then(subdomain => {
            setSubdomains(subdomain.domains);
        });
      }
    }

  } ,[]);

  useEffect(() => {
    catalogService.fetchCatalogDomain().then(data => {
        setDomains(data);
    });

  }, []);

  function onClickReset() {
    setCriteria(filter);
    if (prop.onClickReset) {
      prop.onClickReset(filter);
    }
  }
  function onClickApply() {
    setCriteria(criteria);
    if (prop.onClickApply) {
      prop.onClickApply(criteria);
    }
  }

  return (
    <>        
      <Row className='mb-3'>
        <b>Filters</b>
        {/* </Row>
        <Row className='gy-3'> */}
        <Form.Group className='d-flex flex-column gy-3'>
            <Form.Check
                    inline
                    checked={criteria.isEnterprise}
                    defaultChecked={false}
                    type='checkbox'
                    id='isEnterprise'
                    label='Enterprise'
                    onChange={(e)=>e.target.checked? setCriteria({...criteria, isEnterprise: true}):setCriteria({...criteria, isEnterprise: false})}
            />
            <Form.Check
                    inline
                    checked={criteria.isRegulatory}
                    defaultChecked={false}
                    type='checkbox'
                    id='isRegulatory'
                    label='Regulatory'
                    onChange={(e)=>e.target.checked? setCriteria({...criteria, isRegulatory: true}):setCriteria({...criteria, isRegulatory: false})}
            />
            <Form.Check
            inline
            checked={criteria.isReports}
            defaultChecked={false}
            type='checkbox'
            id='isReports'
            label='Reports'
            onChange={(e)=>e.target.checked? setCriteria({...criteria, isReports: true}):setCriteria({...criteria, isReports: false})}
            />
            <Form.Check
            inline
            checked={criteria.isDashboards}
            defaultChecked={false}
            type='checkbox'
            id='isDashboards'
            label='Dashboards'
            onChange={(e)=>e.target.checked? setCriteria({...criteria, isDashboards: true}): setCriteria({...criteria, isDashboards: false})}
            />
            <Form.Check
            inline
            checked={criteria.isKpi}
            defaultChecked={false}
            type='checkbox'
            id='isKpi'
            label='KPI'
            onChange={(e)=>e.target.checked? setCriteria({...criteria, isKpi: true}):setCriteria({...criteria, isKpi: false})}
            />
            <Form.Check
            inline
            checked={criteria.isVocabulary}
            defaultChecked={false}
            type='checkbox'
            id='isVocabulary'
            label='Vocabulary'
            onChange={(e)=>e.target.checked? setCriteria({...criteria, isVocabulary: true}):setCriteria({...criteria, isVocabulary: false})}
            />
        </Form.Group>
        {/* </Row>
        <Row className='mb-3 gy-3'> */}
            <b>Browse Reports & Dashboards</b>
        {/* </Row>
        <Row className='mb-3 gy-3'> */}
        <Form.Group className='d-flex flex-column gy-3'>
            <Form.Label>Domains</Form.Label>
            <Select
            options={domains}
            placeholder="select..."
            closeMenuOnSelect={true}
            //classNamePrefix="react-select"
            // aria-label="Domain"
            className="rounded-pill mb-3 g-3"
            value={domains.find(item => item.value === criteria.domainId)??null}
            onChange={onChangeDomain}
            />
            <Form.Label>Sub Domains</Form.Label>
            <Select
            options={subdomains}
            placeholder="select..."
            closeMenuOnSelect={true}
            //classNamePrefix="react-select"
            className="rounded-pill"
            value={subdomains.find(item => item.value === criteria.subdomainId)??null}
            onChange={e => setCriteria({ ...criteria, subdomainId: e.value, subdomainName: e.label })}
            />
        </Form.Group>
      </Row>
      <Row>
        <Col className="d-flex">
            <button className="btn btn-link m-0 p-0" onClick={onClickReset}>Reset</button>
        </Col>
        <Col className="d-flex justify-content-end">
            <button className="btn btn-primary button-width" onClick={onClickApply}>Apply</button>
            </Col>
      </Row>        
    </>
 );
}

export default SearchFilter;