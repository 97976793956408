/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React from 'react';
// import { Helmet } from 'react-helmet';
import SpinnerCubeGrid from '../../components/common/spinner-cube-grid';
// import PbiDashboard from './pbi-dashboard';
import TableauDashboard from './tableau-dashboard';
import authService from '../../services/auth-service';
import catalogService from '../../services/catalog-service';
// import common from 'common/common';
import { setRecentlyBrowsed } from 'helpers/utils';
import { Card } from 'react-bootstrap';
import activeDirectoryService from 'services/activeDirectory-service';
import PbiDashboardPage from './pbi-dashboard';
import DashboardReportPageHeader from '../common/dashboard-report-pageheader';
import { Helmet } from 'react-helmet';

const MODES = {
  INIT: 'initializing',
  LOADING: 'loading',
  READY: 'ready',
  ERROR: 'error'
};
const currentUserProfile = authService.getCurrentUserProfile();
class DashboardPage extends React.Component {
  noPermissionToast = null;

  state = {
    user: {},
    mode: MODES.INIT,
    dashboardParams: {},
    isLoadingDashboard: true,
    tableauUserName: null,
    isFavorite:false,
    reportStatusVariant:'',
    informationModalVisible: false,
    isMobile: false,
    isPad: false,
    developer:'',
    stakeholder:[]
  };

  componentDidMount() {
    this._loadData();   
  }

  componentDidUpdate = prevProps => {
    /* eslint-disable-next-line */
    if (this.props.dashboardId === prevProps.dashboardId) return;
    this._loadData();
  };

  MarkAsFavorite = () => {
    if (!this.state.isFavorite) {
      //Do an api call to mark isFavorite
      catalogService.InsertFavoritesReports(currentUserProfile.id, this.state.catalog.id);
      this.setState({
        isFavorite : true
      });
    } else {
      //Do an api call to mark as not favorite
      catalogService.DeleteFavoritesReports(currentUserProfile.id, this.state.catalog.id);
      this.setState({
        isFavorite : false
      });
    }
  }

  render() {
    switch (this.state.mode) {
      case MODES.INIT:
      case MODES.LOADING:
        return <SpinnerCubeGrid active={true} />;

      case MODES.READY:
        // if (this.state.catalog.catalogHostId === 1) {
        //     return <PbiDashboardPage {...this.state.catalog} tableauUserName = {this.state.tableauUserName}/>
        // } 
        // else
        //  if (this.state.catalog.catalogHostId === 2) {
          return (
          <>
          <div>
          <Helmet>
              <title>{this.state.catalog.catalogName}</title>
          </Helmet>
          <DashboardReportPageHeader
            isMobile = {this.state.isMobile}
            isPad = {this.state.isPad}
            catalogName = {this.state.catalog.catalogName}
            reportStatus = {this.state.catalog.reportStatus}
            reportStatusVariant = {this.state.reportStatusVariant}
            stakeholder={this.state.stakeholder}
            developer = {this.state.developer}
            description = {this.state.catalog.description}
            type = {this.state.catalog.type}
            lastModified = {this.state.catalog.lastModified}
            roles = {this.state.catalog.roles}
            catalogId = {this.state.catalog.id}
            favoriteFlag = {this.state.isFavorite}
          />
          
          <Card className="h-100">
            <Card.Body className="p-0">
              { this.state.catalog.catalogHostId === 1 &&
                  <PbiDashboardPage {...this.state.catalog} tableauUserName = {this.state.tableauUserName}/>
              }
              { this.state.catalog.catalogHostId === 2 &&
                  <TableauDashboard {...this.state.catalog} tableauUserName={this.state.tableauUserName} />
             }
            </Card.Body>
          </Card>
      </div>
            </>
          );

      case MODES.ERROR:
      default:
        return (
          <div className="row">
            <div className="col-sm-12">
              <div className="alert alert-danger">
                {(this.state.error && this.state.error.message) ||
                  'Could not load dashboard'}
              </div>
            </div>
          </div>
        );
    }
  }

  _loadData = () => {    
    if (!currentUserProfile) return;

    this.setState(
      {
        error: null,
        mode: MODES.LOADING
      },
      () => {
        catalogService
          /* eslint-disable-next-line */
          .findUserReport(currentUserProfile.id, this.props.dashboardId)
          .then(catalog => {
            this.setState({
              catalog,
              mode: MODES.READY,
              tableauUserName: currentUserProfile.email,
              isFavorite: catalog.isFavorite == 1 ? true : false
            });
            setRecentlyBrowsed('Dashboard', catalog.catalogName, `/dashboards/${this.props.dashboardId}`);
       if (catalog.reportStatus.includes('In Valid')) {
            this.setState({
              reportStatusVariant :'warning'
            });
          } else {
            this.setState({
              reportStatusVariant :'success'
            });
          }
          if (window.innerWidth < 1050 && window.innerWidth > 700) {
            this.setState ({
              isPad: true
            });
          }
          if (window.innerWidth < 700) {
            this.setState ({
              isMobile: true
            });
          }
          if (catalog.stakeholder) {
            const stakeholder = catalog.stakeholder.split(';');
            this.setState({              
              stakeholder
            })
          }
          else {
            this.setState({              
              stakeholder: []
            })
          }

          if (catalog.catalogOwnerEmail) {
            activeDirectoryService
              .getUserByEmail(catalog.catalogOwnerEmail)
              .then(user => {
                this.setState({
                  developer : user.displayName
                });
              });
          }

          })
          .catch(error => {
            console.error(error);
            this.setState({
              error: error,
              mode: MODES.ERROR
            });
            window.location.href = '/errors/404x';
          });
      }
    );
  };
}

export default DashboardPage;