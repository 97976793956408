import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PopoverComponent = ({ body }) => {
  const popoverContent = (
    <Popover id="popover-basic">
      {/* <Popover.Header as="h3">{header}</Popover.Header> */}
      <Popover.Body> {body} </Popover.Body>
    </Popover>
  );

  return (
    <>
      <OverlayTrigger placement={'bottom'} overlay={popoverContent}>
        <span
          style={{
            cursor: 'pointer'
          }}
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            transform="shrink-4"
            className="fs-3"
          />
        </span>
      </OverlayTrigger>
    </>
  );
};

PopoverComponent.propTypes = {
  // header: PropTypes.string,
  body: PropTypes.string
};

export default PopoverComponent;
