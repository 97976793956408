/* eslint-disable prettier/prettier */
import { faExclamationCircle, faTimes, faGlobeAmericas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import authService from "../../services/auth-service";
import PropTypes from 'prop-types'; 
import { Modal, Row } from 'react-bootstrap';

const VALIDATION = {
    fullName: (value) => {
        return value && value.length > 0 && value === GetUserName() ? { isValid: true, message: '' } : { isValid: false, message: `The name field is required and must match your name. (${GetUserName()})` }
    },
    todaysDate: (value) => {
        return value && value.length > 0 && value === new Date(Date.now()).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) ? { isValid: true, message: '' } : { isValid: false, message: `The date field is required and must match today's date.  (${new Date(Date.now()).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })})` }
    }
}

function GetUserName() {
    const profile = authService.getCurrentUserProfile();
    return `${profile.firstName} ${profile.lastName}`;
}

const ESignatureModal = props => {
    const [validationErrors, setValidationErrors] = React.useState({});
    // const [fullName, setFullName] = React.useState('');
    // const [todaysDate, setTodaysDate] = React.useState(null);
    const [fields, setFields] = React.useState({
        fullName: GetUserName(),
        todaysDate: null
    });

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setFields({ ...fields, [name]: value });
    }

    const onBlur = (event) => {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        const validationMethod = VALIDATION[name];
        if (validationMethod) {
            const result = validationMethod(value);
            if (!result.isValid) {
                setValidationErrors({ ...validationErrors, [name]: result.message });
            } else {
                setValidationErrors({ ...validationErrors, [name]: undefined });
            }
        }
    }

    const attemptToSubmit = () => {
        const validationErrors = {}
        Object.keys(VALIDATION).forEach(name => {            
            const result = VALIDATION[name](fields[name]);
            if (!result.isValid) {
                validationErrors[name] = result.message
            }
        })

        if (Object.keys(validationErrors).length > 0) {
            setValidationErrors(validationErrors);
        } else {
            props.submit();
        }
    }

        return (            
            <Modal show={props.visible} backdrop="static" keyboard={false}>
                <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title>
                        <Row><span  className='text-center' style={{ fontSize: "68px" }}> <FontAwesomeIcon className="modal-icon" icon={faExclamationCircle} /> </span> </Row>
                        <Row>Warning!</Row>                        
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="no-margins">{props.content}</p>
                    <div className={`form-group${validationErrors["fullName"] ? ' has-error' : ''}`}>
                        <label>Enter your name. (*First name* *Last name*)</label>
                        <input type="text"
                            name="fullName"
                            onChange={handleChange}
                            onBlur={onBlur}
                            value={fields.fullName || ''}
                            className="form-control" />
                        {validationErrors["fullName"] && <span className="form-text m-b-none">{validationErrors["fullName"]}</span>}
                    </div>
                    <div className={`form-group${validationErrors["todaysDate"] ? ' has-error' : ''}`}>
                        <label>Enter today's date. (MM/DD/YYYY)</label>
                        <input type="text"
                            name="todaysDate"
                            onChange={handleChange}
                            onBlur={onBlur}
                            value={fields.todaysDate || ''}
                            className="form-control" />
                        {validationErrors["todaysDate"] && <span className="form-text m-b-none">{validationErrors["todaysDate"]}</span>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <button className="btn btn-outline-primary me-1 mb-1"  onClick={props.hide}            >
                    <FontAwesomeIcon icon={faTimes} /> Cancel
                </button>
                    <button type="button" className="btn btn-primary" onClick={attemptToSubmit}>
                        <FontAwesomeIcon icon={faGlobeAmericas} />&nbsp;Save
                    </button>
                </Modal.Footer>
            </Modal>
        );
    
}
ESignatureModal.propTypes = {
    content: PropTypes.string,
    hide: PropTypes.func,
    visible: PropTypes.bool.required,
    submit: PropTypes.func,
}

export default ESignatureModal;