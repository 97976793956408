/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Card, Row, Form, Col, Breadcrumb } from 'react-bootstrap';
import hemService from '../../services/hem-service';
import { useParams } from 'react-router-dom';
import PageHeader from 'components/common/PageHeader';
import { Helmet } from 'react-helmet';
const ReleaseView = () => {
  let params = useParams();
  var [releases, setReleases] = useState([]);
  const releaseId = params.releaseId;

  useEffect(() => {
    hemService
      .fetchRelease(releaseId)
      .then(release => {
        setReleases(release);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <Helmet>
        <title>Release Details</title>
      </Helmet>
      <PageHeader title="Release Details" className="mb-2">
        <Breadcrumb>  
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/release/browser">Release Manager</Breadcrumb.Item>
          <Breadcrumb.Item active>Details</Breadcrumb.Item>  
        </Breadcrumb>
      </PageHeader>
      <Card>
        <Card.Body className="bg-light">
          <Form>
            <Row className="flex-between-center">
              <Col xs="auto" sm={6} lg={2}>
                <h5 className="mb-0 text-nowrap py-2 py-xl-0">
                  {releases.title}-{releases.versionId}
                </h5>
              </Col>
            </Row>
            <hr />
            <Form.Text>
              <div
                dangerouslySetInnerHTML={{ __html: releases.releaseNotes }}
              />
            </Form.Text>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default ReleaseView;
