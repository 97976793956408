/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Card, Dropdown, ListGroup, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { isIterableArray } from 'helpers/utils';
//import FalconCardHeader from 'components/common/FalconCardHeader';
import Notification from 'components/notification/Notification';
//import {NotificationApi, myNewNotifications} from 'components/documentation/Notification'
import * as signalR from '@microsoft/signalr';
import team1 from 'assets/img/team/1.jpg';
import notificationService from 'services/notification-service';
import DateFormat from '../../../components/common/date-format';
import moment from 'moment-timezone';
import catalogService from 'services/catalog-service';
// import searchService from 'services/search-service';
import userService from 'services/user-service';
import { toast } from 'react-toastify';
import ConfirmationMessage from 'components/common/ConfirmationMessage';
// import Enum from 'common/enum';
const profile = JSON.parse(localStorage.getItem('userProfile'));

export const myNewNotifications = [];

function messageCreator(message) {
  var newNotification = [
    {
      id: message.messageId,
      createdBy: message.createdBy,
      initial: message.createdBy.substring(0, 2),
      avatar: {
        src: team1,
        size: '2xl'
      },
      children: message.messageHeader,
      time: <DateFormat format="MM-DD-YYYY HH:mm" date={message.createdOn} />,
      emoji: '💬',
      className: 'rounded-0 border-x-0 border-300 border-bottom-0',
      to: `/notification/NotificationView/${message.messageId}`,
      unread: true
    }
  ];
  myNewNotifications.push(newNotification);
  return myNewNotifications;
}

const NotificationDropdown = () => {
  
  const [isOpen, setIsOpen] = useState(false);
  const [isAllRead, setIsAllRead] = useState(false);
  // const [lastMessageId, setLastMessageId] = useState(0);
  const hubConnection = new signalR.HubConnectionBuilder()
    .withUrl(notificationService.getHubConnectionToUser(profile.email), {
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets
    })
    .build();

    hubConnection.start().then(function () {
      //document.getElementById(“user”).innerHTML = “UserId: ” + userId;
      hubConnection.invoke('GetConnectionId').then(function () { //connectionId) {
        //document.getElementById('signalRConnectionId').innerHTML=connectionId;
        //console.log('ConnectionId-' + connectionId);
      });
    });

  var list = [];
  const [notificationsList, setnotificationsList] = useState([]);
  
  useEffect(() => {
    //fetch(`http://localhost:59064/api/notification/GetNotifications`)
    //.then(response => response.json())
    var applicationName = 'Hemetrics';
    var userEmail = profile.email;
    notificationService
      .fetchUnreadNotification(applicationName, userEmail)
      .then(setnotificationsList)
      .then( () => {
        notificationsList.length == 0 ? setIsAllRead(true) : setIsAllRead(false);
        //console.log("Test1");
        hubConnection.on('Hemetrics', message => {
          toast(ConfirmationMessage("Notification", message.messageHeader, "bg-primary", "faInfoCircle"), {
            autoClose: true,
            //className: 'transparent',
            //style: {{background-color: transparent !important}},
            position: 'top-right',
            hideProgressBar: true,
            closeButton: false,
          });
        });
      }
      );

  }, []);
  
  const Messages = messageProps => {
     //const [temp, setTemp] = useState();
     const [date, setDate] = useState(new Date());
    useEffect(() => {
      let isMounted = true;
      messageProps.HubConnection.on('Hemetrics', message => {
      if (isMounted) {
        list.push(message);
        list.length == 0 ? setIsAllRead(true) : setIsAllRead(false);
        setDate(new Date());
        //isMounted && console.log(temp);
        
        console.log(date);
      }
        if (message.type === "catalog") {
          catalogService.refreshCatalogCache();
        } 
        else if (message.type === "role") {
          userService.refreshUserProfileCache().then(() => {
            catalogService.refreshCatalogCache();
          });
        }
      });

      return () => {
        isMounted = false;
        list.map(message => {
          messageCreator(message);
        });
      };
    }, [list.length]);
  
    return (
      <>
        {list.map((message, index) => (
          //<p key={`message${index}`} className= 'mb-1 rounded-0 border-x-0 border-300 border-bottom-0'>{ message}</p>
          <ListGroup.Item key={index} onClick={handleToggle}>
            <Notification
              {...{
                id: message.messageId,
                createdBy: message.createdBy,
                initial: message.createdBy.substring(0, 2),
                size: '2xl',
                children: message.messageHeader,
                time: (
                  <DateFormat
                    format="MM-DD-YYYY HH:mm"
                    date={date}
                  />
                ),
                emoji: '💬',
                className: 'rounded-0 border-x-0 border-300 border-bottom-0',
                to: `/notification/NotificationView/${message.messageId}`,
                unread: true
              }}
              flush
            />
          </ListGroup.Item>
        ))}
      </>
    );
  };

  const SendMessage = () => {
    //const [message, setMessage] = useState('');

    list.map(message => {
      messageCreator(message);
    });
    return (
      <>
      {list.map((message, index) => (
          //<p key={`message${index}`} className= 'mb-1 rounded-0 border-x-0 border-300 border-bottom-0'>{ message}</p>
          <ListGroup.Item key={index} onClick={handleToggle}>
            <Notification
              {...{
                id: message.messageId,
                createdBy: message.createdBy,
                initial: message.createdBy.substring(0, 2),
                size: '2xl',
                children: message.messageHeader,
                time: (
                  <DateFormat
                    format="MM-DD-YYYY HH:mm"
                    date={message.createdOn}
                  />
                ),
                emoji: '💬',
                className: 'rounded-0 border-x-0 border-300 border-bottom-0',
                to: `/notification/NotificationView/${message.messageId}`,
                unread: true
              }}
              flush
            />
          </ListGroup.Item>
        ))}
        {/* {list.map(message => {
          message;         
        })} */}
      </>
    );
  };


  const handleToggle = () => {
    // fetch(`http://localhost:59064/api/notification/GetNotifications`)
    // .then(response => response.json())
    var applicationName = 'Hemetrics';
    var userEmail = profile.email;
    notificationService
      .fetchUnreadNotification(applicationName, userEmail)
      .then(setnotificationsList)
      .then(
        notificationsList.length == 0 ? setIsAllRead(true) : setIsAllRead(false)
      );

    setIsOpen(!isOpen);
  };

  useEffect(() => {   
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });    
    notificationsList.length == 0 ? setIsAllRead(true) : setIsAllRead(false);

  }, [notificationsList.length]);

  const handleMarkAllAsRead = () => {
    notificationService.UpdateAllNotificationAsync(profile.email);
    setIsOpen(!isOpen);
    setIsAllRead(true);
  };

  return (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className={classNames('px-0 nav-link', {
          'notification-indicator notification-indicator-primary': !isAllRead
        })}
      >
        <OverlayTrigger
          placement={'left'}
          overlay={<Tooltip id="notifications">Notifications</Tooltip>}
        >
        <div className="theme-control-toggle-label">
          <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
       </div>
        </OverlayTrigger>
       </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret-bg">
        <Card className="dropdown-menu-notification shadow-none py-2">
          
          <Row>
            <Col>
              <Dropdown.Header
                as="h6"
                className="px-card pb-2 fw-medium text-dark"
              >
                NOTIFICATIONS
              </Dropdown.Header>
            </Col>
            <Col style={{ textAlign: 'center' }}>
              <Link
                className="card-link fw-normal"
                to="#!"
                onClick={handleMarkAllAsRead}
              >
                Mark all as read
              </Link>
            </Col>
          </Row>

          <ListGroup
            variant="flush"
            className="fw-normal fs--1 scrollbar"
            style={{ maxHeight: '19rem' }}
          >
            <div className="list-group-title">NEW</div> <SendMessage />
            <Messages HubConnection={hubConnection}></Messages>
            {isIterableArray(notificationsList) &&
              notificationsList
                .filter(
                  date =>
                    moment(date.createdOn).toDate() >=
                    moment(new moment().subtract(1, 'days')).toDate()
                )
                .map(notification => (
                  //.slice(0,3)
                  <ListGroup.Item
                    key={notification.messageId}
                    onClick={handleToggle}
                  >
                    <Notification
                      {...{
                        id: notification.messageId,
                        createdBy: notification.createdBy,
                        initial: notification.createdBy.substring(0, 2),
                        size: '2xl',
                        children: notification.messageHeader,
                        time: (
                          <DateFormat
                            format="MM-DD-YYYY HH:mm"
                            date={notification.createdOn}
                          />
                        ),
                        emoji: '💬',
                        className:
                          'rounded-0 border-x-0 border-300 border-bottom-0',
                        to: `/notification/NotificationView/${notification.messageId}`,
                        unread: true
                      }}
                    />
                  </ListGroup.Item>
                ))}
            <div className="list-group-title">EARLIER</div>
            {isIterableArray(notificationsList) &&
              notificationsList
                .filter(
                  date =>
                    moment(date.createdOn).toDate() <=
                    moment(new moment().subtract(1, 'days')).toDate()
                )
                .map(notification => (
                  <ListGroup.Item
                    key={notification.messageId}
                    onClick={handleToggle}
                  >
                    <Notification
                      {...{
                        id: notification.messageId,
                        createdBy: notification.createdBy,
                        initial: notification.createdBy.substring(0, 2),
                        size: '2xl',
                        children: notification.messageHeader,
                        time: (
                          <DateFormat
                            format="MM-DD-YYYY HH:mm"
                            date={notification.createdOn}
                          />
                        ),
                        emoji: '💬',
                        className:
                          'rounded-0 border-x-0 border-300 border-bottom-0',
                        to: `/notification/NotificationView/${notification.messageId}`,
                        unread: false
                      }}
                    />
                  </ListGroup.Item>
                ))}
          </ListGroup>
          <div
            className="card-footer text-center border-top"
            onClick={handleToggle}
          >
            <Link className="card-link d-block" to="/notification/Notification">
              View all
            </Link>
          </div>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationDropdown;
