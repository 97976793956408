/* eslint-disable prettier/prettier */
import './globals'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import './helpers/initFA';
// import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './authConfig';
import userService from 'services/user-service';
import catalogService from 'services/catalog-service';
import searchService from 'services/search-service';
import Enum from 'common/enum';
import { registerLicense } from '@syncfusion/ej2-base';
import { faPieChart, faChartLine } from '@fortawesome/free-solid-svg-icons';

// Registering Syncfusion license key
registerLicense('ORg4AjUWIQA/Gnt2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0dhW35edHZXRmNYWUc=');

msalInstance
  .handleRedirectPromise()
  .then(resp => {
    if (resp) {
        const globalUserId = resp.uniqueId; 
        if (!globalUserId) {
            console.error("Couldn't get current logged in user!");
            return;
        }        
        userService.getUserProfile(globalUserId)
              .then(userProfile => {
                localStorage.removeItem(Enum.CACHED_USER_PROFILE);
                localStorage.setItem(Enum.CACHED_USER_PROFILE, JSON.stringify(userProfile));
              })
              .then(() => {
                catalogService.fetchDashboardMenu()
                  .then(catalogs => {
                      catalogs.dashboardRoutes[0].icon=faPieChart;
                      catalogs.dashboardRoutes[1].icon = faChartLine;
                      sessionStorage.removeItem(Enum.CACHED_DASHBOARDMENU);
                      sessionStorage.removeItem(Enum.CACHED_ANALYTICSMENU);
                      sessionStorage.setItem(Enum.CACHED_DASHBOARDMENU, JSON.stringify(catalogs.dashboardRoutes[0])); 
                      sessionStorage.setItem(Enum.CACHED_ANALYTICSMENU, JSON.stringify(catalogs.dashboardRoutes[1]));  
                      searchService.getAllItemsForSearch()
                      .then(result => {
                          sessionStorage.removeItem(Enum.CACHED_SEARCH);
                          sessionStorage.setItem(Enum.CACHED_SEARCH, JSON.stringify(result));
                          location.reload();   
                      }); 
                               
                  }) 
                  .catch(error => {
                      console.error(error);           
                  });        
              })
              .catch(error => {
                  console.error(error);
              }); // end of getUserProfile        
    } // end of if (resp)
  }) 
  .catch(e => {
    console.error(e);
  }); // end of handleRedirectPromise
  

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Main>
        {/* <div onClick={captureClick}> */}
        <App />
        {/* </div> */}
      </Main>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('main')
  
);
