/* eslint-disable prettier/prettier */
import PageHeader from 'components/common/PageHeader';
import { Card, Breadcrumb, Row, Col, Modal, Button, Form } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import GovernanceService from 'services/governance-service';
import { getItemFromStore } from '../../helpers/utils';
import './active.css';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Edit,
  Inject,
  Filter,
  Sort
} from '@syncfusion/ej2-react-grids';
import { settings } from '../../config';
import  PopoverComponent from 'pages/common/popover';
import { Helmet } from 'react-helmet';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTableWrapper from '../../components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../../components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from '../../components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from '../../components/common/advance-table/AdvanceTableFooter';
import FalconCloseButton from 'components/common/FalconCloseButton';

var columns = [];

const dataManagement = () => {
  const mdmColumns = [      
    {
      accessor: 'bloodCenterCode',
      Header: 'Division'
    },
    {
      accessor: 'customerAccountNo',
      Header: 'Customer Account No'   
    }, 
    {
      accessor: 'customerAccountName',
      Header: 'Customer Account Name'
    },
    {
      accessor: 'definitiveName',
      Header: 'Hospital',    
    },
    {
      accessor: 'mdmCustomName',
      Header: 'Non-Hospital',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start'
      }
    },
    {
      accessor: 'accountType',
      Header: 'Account Type',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start'
      }
    },
    {
      accessor: 'mapStatus',
      Header: 'Map Status'
    },
    {
      accessor: 'Edit',
      Header: 'Edit',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start'
      },
      Cell: rowData => (
        // <Link to={`/release/editor/${rowData.row.original.releaseId}`}>
        //   <FontAwesomeIcon icon={faEdit} />
        // </Link>
        <Button type="button" variant="outline-primary" className="me-1 mb-1" size="sm"  
        onClick={() => onClickLaunch(
          rowData.row.original.customerUnmappedRecordKey,
          rowData.row.original.definitiveId,
          rowData.row.original.mdmCustomId,
          rowData.row.original.accountTypeId,
          rowData.row.original.customerAccountName,
          rowData.row.original.city,
          rowData.row.original.state
        )}
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
      )
    }
  ];
  const configState = {
    gridRowsCount: getItemFromStore('gridRowsCount', settings.gridRowsCount)
  };
  const toolbarOptions = ['Edit', 'Update', 'Cancel'];
  const editSettings = { allowEditing: true, mode: 'Normal' };
  //const editparams = { params: { popupHeight: '300px' } };
  //const validationRules = { required: true };
  //const orderidRules = { required: true, number: true };
  const pageSettings = { pageSize: configState.gridRowsCount };
  //const filterSettings = { type: 'Menu' };
  //const inlineStyle = { flex:1, width: '500px'};

  let params = useParams();
  const databaseId = params.databaseId;
  const tableConfigId = params.tableConfigId;

  //const [tables, setTables] = useState([]);
  const [data, setData] = useState([]);
  const [tableName, setTableName] = useState('');
  const [tableNameWithoutSchema, setTableNameWithoutSchema] = useState('');
  const [tableData, setTableData] = useState([]);
  const [mdmGridView, setMdmGridView] = useState(false);
  const [informationModalVisible, setInformationModalVisible] = useState(false);
  const [key, setKey] = useState(false);
  const [customerAccountName, setCustomerAccountName] = useState(false);
  const [city, setCity] = useState(false);
  const [state, setState] = useState(false);
  const [accountTypesData , setAccountTypesData] = useState([]);
  const [definitiveData , setDefinitiveData] = useState([]);
  //const [customData , setcustomData] = useState([]);
  const [selectedAccountTypeId, setSelectedAccountTypeId] = useState();
  const [selectedDefinitiveId, setSelectedDefinitiveId] = useState();
  const [selectedCustomId, setSelectedCustomId] = useState();
  //const [disableDefinitiveHealthDD, setDisableDefinitiveHealthIdDD] = useState(false);
  //const [disableCustomMDMId, setDisableCustomMDMId] = useState(false);
  const [customerDetails, setCustomerDetails] = useState([]);
  const Unknown = 'Unknown';
  const [validateAccountType, setValidateAccountType] = useState(false);

  useEffect(() => {
    //GovernanceService.fetchTables().then(setTables);
    //const definitivesPromise = GovernanceService.getAllDefinitives();

    const selectedTableData =
      GovernanceService.getSelectedConfigTableData(tableConfigId);
    Promise.all([selectedTableData]).then(([tableData]) => {
      const tableN = tableData[0].SchemaName + '.' + tableData[0].TableName;
      setTableName(tableN);
      setTableNameWithoutSchema(tableData[0].TableName);
      setTableData(tableData);
      
      //setDefinitiveData(definitives);
      columns = [];

      for (var k in tableData) {
        columns.push(
          tableData[k]
          // <ColumnDirective
          //   field={tableData[k].ColumnName}
          //   //headerText={tableData[k].ColumnName}
          //   //toolTip={tableData[k].ColumnName}
          //   width={150}
          //   //validationRules={this.orderidRules}
          //   // isPrimaryKey={tableData[k].IsPrimary}
          //   // allowEditing={tableData[k].IsEditable}
          //   // editType={tableData[k].AllowFreeText ? 'textedit' : 'dropdownedit'}
          // ></ColumnDirective>
        );
      }
      //console.log(columns);
      if (tableData[0].TableName === "CustomerUnmappedRecords")
      {
        setMdmGridView(true);
        GovernanceService.getMdmTableData().then(setData);
      }
      else
      {
        setMdmGridView(false);
        GovernanceService.getSelectedTableData(databaseId, tableN).then(setData);
      }
      
    });
    //console.log(columns);
  }, []);

  const onClickLaunch = (key, DefinitiveHealthId, MDMCustomId, AccountTypeId, customerAccountName, city, state) => {
    // if (DefinitiveHealthId === 0) {
    //   setDisableCustomMDMId(false); 
    // } else {
    //   setDisableCustomMDMId(true);
    // }
    // if ((MDMCustomId === "") || (MDMCustomId === "null") || (MDMCustomId === null)) {
    //   setDisableDefinitiveHealthIdDD(false);
    // } else {
    //   setDisableDefinitiveHealthIdDD(true);
    // }
    setInformationModalVisible(true);
    setKey(key);
    setCustomerAccountName(customerAccountName);
    setSelectedDefinitiveId(DefinitiveHealthId);
    setSelectedCustomId(MDMCustomId);
    setSelectedAccountTypeId(AccountTypeId);
    setCity(city);
    setState(state);
    setValidateAccountType(false);
    
    if(AccountTypeId){
      GovernanceService.getAllAccountTypesById(AccountTypeId).then(data=> {
        //const validList = data.filter(function(item) {return item.customerFullName !== null;});
        const accData = data.map(def => { return { label: def.accountType, value: def.accountTypeId, id: def.accountTypeId } });
        setAccountTypesData(accData);
      });
    }
    
    if(DefinitiveHealthId || MDMCustomId){
      GovernanceService.getAllDefinitivesById(DefinitiveHealthId == null ? 0 : DefinitiveHealthId, (MDMCustomId == "NULL" ? null : MDMCustomId)).then(data=> {
        //const validList = data.filter(function(item) {return item.customerFullName !== null;});
        const defData = data.map(def => { return { label: def.customerFullName + (def.city == null ? '' : (', ' + def.city + ', ')) + (def.state == null ? '' : def.state), value: def.definitiveId == 0 ? def.mdmCustomId : def.definitiveId, id: def.definitiveId } });
        setDefinitiveData(defData);
      }).then(() => {
        DefinitiveHealthId == null ? 
        GovernanceService.getCustomerMasterKey(0, MDMCustomId).then(setCustomerDetails)
        :
        GovernanceService.getCustomerMasterKey(DefinitiveHealthId, null).then(setCustomerDetails)
      });
    }

    // if(MDMCustomId){
    //   GovernanceService.getAllCustomsById(MDMCustomId).then((data)=>{
    //     const cusData = data.map(cus => { return { label: cus.customerFullName, value: cus.mdmCustomId, id: cus.mdmCustomId } }); 
    //     setcustomData(cusData);
    //   });
    // }
  }

  const clearData = () =>{
    const nullData = [{ label: 'search...', value: null, id: null }];
    setSelectedAccountTypeId(null);
    setAccountTypesData(nullData);

    setSelectedDefinitiveId(0);
    setDefinitiveData(nullData);

    setSelectedCustomId(null);
    //setcustomData([{ label: 'search...', value: '', id: null }]);

    //setDisableDefinitiveHealthIdDD(false);
    //setDisableCustomMDMId(false); 
  }

  const saveDefinitiveData = (definitiveId, customId, accountTypesId) => {

    var setQuery = '';

    if(accountTypesId == null)
    {
      setValidateAccountType(true);
      return;
    }
    //const customId = '';
    if(definitiveId == 0 && (customId == null || customId == "" || customId == "null"))
    {
      setQuery = "DefinitiveId = NULL , MDMCustomId = NULL," + " MapStatusId = 1, " + " AccountTypeId = " + accountTypesId;
    }
    else
    {
      setQuery = 'DefinitiveId = ' + (customerDetails.length > 0 ? customerDetails[0].definitiveId : null) + ", MDMCustomId = " + (customerDetails.length > 0 ? (customerDetails[0].mdmCustomId == null ? null : "'" + customerDetails[0].mdmCustomId + "'") : null) + ", " + "CustomerMasterKey = " + (customerDetails.length > 0 ? customerDetails[0].customerMasterKey : null) + ", MapStatusId = 2, " + " AccountTypeId = " + accountTypesId;
    }
    var whereQuery = 'CustomerUnmappedRecordKey = ' + key;

    GovernanceService.updateSelectedTableData(
      databaseId,
      tableName,
      //setQuery.substring(0, setQuery.length - 1),
      setQuery,
      whereQuery
    ).then(() => {
      setInformationModalVisible(false);
      GovernanceService.getMdmTableData().then(setData);
    });    
  }
  
  const onChangeAccountTypes = (e) => {
    setSelectedAccountTypeId(e.value);
  }

  const onInputChangeAccountTypes = (e) => {
    if(e){
      GovernanceService.getAllAccountTypes(e).then(data=> {
        //const validList = data.filter(function(item) {return item.customerFullName !== null;});
        const accData = data.map(def => { return { label: def.accountType, value: def.accountTypeId, id: def.accountTypeId } });
        setAccountTypesData(accData);
      });    
    }
    else
    {
      const blank ="-";
      GovernanceService.getAllAccountTypes(blank).then(data=> {
        //const validList = data.filter(function(item) {return item.customerFullName !== null;});
        const accData = data.map(def => { return { label: def.accountType, value: def.accountTypeId, id: def.accountTypeId } });
        setAccountTypesData(accData);
      });
    }
  }

  const onChangeDefinitive = (e) => {

    if(String(e.value).includes('MDM'))
    {
      GovernanceService.getCustomerMasterKey(0, e.value).then(setCustomerDetails);
      setSelectedCustomId(e.value);
      setSelectedDefinitiveId(0);
      setValidateAccountType(false);
    }
    else if(e.value == null)
    {
      //empty
    }
    else
    {
      GovernanceService.getCustomerMasterKey(e.value, null).then(setCustomerDetails);
      setSelectedDefinitiveId(e.value);
      setSelectedCustomId(null);
      setValidateAccountType(false);
    }
    // if (e.value === null) {
    //   setDisableCustomMDMId(false); 
    // } else {
    //   setDisableCustomMDMId(true);      
    //   GovernanceService.getCustomerMasterKey(e.value).then(setCustomerMasterKey);
    // }
  }
  const onInputChangeDefinitive = (e) => {
    //API Call
    //const definitives = definitiveData.filter(a => a.CustomerFullName like).map(def => { return { label: def.CustomerFullName, value: def.DefinitiveId } });
    //setDefinitiveData(definitives);
    if(e){
    GovernanceService.getAllDefinitives(e).then(data=> {
      //const validList = data.filter(function(item) {return item.customerFullName !== null;});
      const defData = data.map(def => { return { label: def.customerFullName +  (def.city == null ? '' : (', ' + def.city + ', ')) + (def.state == null ? '' : def.state), value: def.definitiveId == null ? def.mdmCustomId : def.definitiveId, id: def.definitiveId } });
      setDefinitiveData(defData);
    });
  }
  }

  // const onChangeCustom = (e) => {
  //   setSelectedCustomId(e.value);
  //   if ((e.value === "") || (e.value === "null") || (e.value === null)) {
  //     setDisableDefinitiveHealthIdDD(false);
  //   } else {
  //     setDisableDefinitiveHealthIdDD(true);
  //   }
  // }

  // const onInputChangeCustom = (e) => {
  //   //API Call
  //   //const Customs = CustomData.map(cus => { return { label: cus.CustomerFullName, value: cus.ERPCustomId } });
  //   //setcustomData(Customs);
  //   if(e){
  //   GovernanceService.getAllCustoms(e).then((data)=>{
  //     const cusData = data.map(cus => { return { label: cus.customerFullName, value: cus.mdmCustomId, id: cus.mdmCustomId } }); 
  //     //setcustomData(cusData);
  //   });
  // }
  //}

  const actionBegin = args => {
    //console.log(args.requestType);

    if (args.requestType === 'save') {
      //console.log(args.data);
      //update query
      var setQuery = '';
      var whereQuery = '';

      tableData.forEach(element => {
        if (element.IsEditable) {
          setQuery =
            setQuery +
            element.ColumnName +
            " = '" +
            args.data[element.ColumnName] +
            "',";
        }

        if (element.IsPrimary) {
          whereQuery =
            whereQuery +
            element.ColumnName +
            ' = ' +
            args.data[element.ColumnName];
        }
      });
      setQuery = setQuery.replaceAll("'null'", null);
      //console.log(setQuery.substring(0, setQuery.length - 1));
      //console.log(whereQuery);

      GovernanceService.updateSelectedTableData(
        databaseId,
        tableName,
        setQuery.substring(0, setQuery.length - 1),
        whereQuery
      );
    }
  };
  const dataManagementdesc = "Data Management allows designated data stewards to manage Enterprise Data Warehouse dimensions. To modify a record value, select the desired record and click Edit to activate the Edit Panel. Alternatively, double-click on the desired row to activate Edit Panel. Upon entering new value, press the Enter key on your keyboard or click Update button.";

  return (
    <>
      <Helmet>
        <title>Data Management</title>
      </Helmet>
      <PageHeader
        title={`Data Management <${tableNameWithoutSchema}>`}
        className="mb-2" col="12"
      >
         <Row>
          <Col className="text-start mt-1">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/governance/activeGovernanceLandPage">
                Enterprise Data Governance
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Data Management</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="text-end mt-n2">
            <PopoverComponent 
              header = "Data Management"
              body = {dataManagementdesc}
            />
          </Col>
        </Row>
      </PageHeader>

      <Card>
        <Card.Body>
          {mdmGridView ? 
          <AdvanceTableWrapper
            columns={mdmColumns}
            data={data}
            sortable
            pagination
            perPage={configState.gridRowsCount}
          >
            <Card className="h-100">
              <Card.Header>
                <Row className="flex-between-center">
                  <Col xs={9} sm={6} lg={3}>
                    <AdvanceTableSearchBox table placeholder="Search" />
                  </Col>
                  <Col xs="auto" sm={3} lg={3} className="text-end marginTop">
                    {/* <Link className="btn btn-outline-primary" to={'/release/new'}>
                      <FontAwesomeIcon icon={faPlus} /> New
                    </Link> */}
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTableFooter
                  rowCount={data.length}
                  table
                  rowInfo
                  navButtons
                />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
          :      
          <GridComponent
            dataSource={data}
            //height={315}
            className="bg-200 text-900"
            toolbar={toolbarOptions}
            actionBegin={actionBegin}
            allowPaging={true}
            allowFiltering={true}
            editSettings={editSettings}
            pageSettings={pageSettings}
            filterSettings={{ type: 'Menu' }}
            allowSorting={true}
            allowMultiSorting={true}
            allowTextWrap={true}
          >
            <ColumnsDirective>
              {columns.map(colName => (
                <ColumnDirective
                  key={colName.ColumnName}
                  field={colName.ColumnName}
                  headerText={colName.DisplayName}
                  width={colName.DisplayName.length * 15 < 200 ? 200 : colName.DisplayName.length * 15}
                  validationRules={colName.ColumnDataType == "int" ? {number: true} : {}}
                  isPrimaryKey={colName.IsPrimary}
                  allowEditing={colName.IsEditable}
                  editType={colName.AllowFreeText ? 'textedit' : 'dropdownedit'}
                />
              ))}
              <ColumnDirective field="" />
              {/* <ColumnDirective field='BloodCenterKey' width='150'/>
              <ColumnDirective field='DataSourceKey' width='150'/>
              <ColumnDirective field='IsRejectReasonActive' width='150'/>
              <ColumnDirective field='RecCreatedDTM' width='150'/>
              <ColumnDirective field='RecModifiedDTM' width='150'/>
              <ColumnDirective field='RejectReasonCategory' width='150'/>
              <ColumnDirective field='RejectReasonCode' width='150'/>
              <ColumnDirective field='RejectReasonKey' width='150'/>
              <ColumnDirective field='RejectReasonName' width='150'/>
              <ColumnDirective field='RejectReasonSubCategory' width='150'/> */}
            </ColumnsDirective>
            <Inject services={[Page, Toolbar, Edit, Filter, Sort]} />
          </GridComponent>}
        </Card.Body>
      </Card>

      <Modal size="md" show={informationModalVisible}>
        <Modal.Header>
          <h5 className="modal-title">{customerAccountName != null ? (customerAccountName + ',' +city + ',' + state) : Unknown} </h5> <br/>
          {/* <p>Created By: {publishedBy} | Created On: {publishedOn} </p> */}
          <FalconCloseButton onClick={() => setInformationModalVisible(false)} />
        </Modal.Header>
        <Modal.Body>
        <Form>
          <Form.Group as={Col} lg={8} controlId="account">
            <Form.Label>Account Type</Form.Label>              
              <Select
              controlId="selectAccount"
              closeMenuOnSelect={true}
              options={accountTypesData}
              disabled={true}
              placeholder='search...'
              //className={ValidateOwnerEmail?'':'validation-error'}
              value={accountTypesData.find(item => item.value == selectedAccountTypeId)}
              onChange={e => onChangeAccountTypes(e)}
              onInputChange={e => onInputChangeAccountTypes(e)}
              //classNamePrefix="react-select"
            />   
          </Form.Group>
          {validateAccountType && (
                <span className='text-danger'>Please select an account type.  <br/></span>
            )}
          <br/>
          <Form.Group as={Col} lg={8} controlId="definitive">
              <Form.Label>Customer Name</Form.Label>
                <Select
                controlId="selectDefinitive"
                closeMenuOnSelect={true}
                options={definitiveData}
                disabled={true}
                placeholder='search...'
                //className={ValidateOwnerEmail?'':'validation-error'}
                value={definitiveData.find(item => item.value == (selectedDefinitiveId == 0 ? selectedCustomId : selectedDefinitiveId))}
                onChange={e => onChangeDefinitive(e)}
                onInputChange={e => onInputChangeDefinitive(e)}
                //classNamePrefix="react-select"
              />                 
            </Form.Group>
            {/* {disableDefinitiveHealthDD && (
                <span className='text-danger'>Hospital selection is not available. Please clear the value on Non-Hospital to select a value.  <br/></span>
            )} */}
            {/* <br/>
            <Form.Group as={Col} lg={8} controlId="customId">
              <Form.Label>Non-Hospital</Form.Label>
              {!disableCustomMDMId && 
              <Select
                controlId="selectCustomId"
                closeMenuOnSelect={true}
                options={customData}
                placeholder='search...'
                //className={ValidateOwnerEmail?'':'validation-error'}
                value={customData.find(item => item.value == selectedCustomId)}
                onChange={e => onChangeCustom(e)}
                onInputChange={e => onInputChangeCustom(e)}
                //classNamePrefix="react-select"
              />
            }              
            </Form.Group>
            {disableCustomMDMId && (
                <span className='text-danger'>Non-Hospital selection is not available. Please clear the value on Hospital to select a value.</span>
            )} */}
          </Form> 
        </Modal.Body>
        <Modal.Footer>
        <Button
            type="button"
            className="btn btn-primary"
            onClick={() => clearData()}
          >
            Clear
          </Button>
          <Button
            type="button"
            className="btn btn-primary"
            onClick={() => saveDefinitiveData(selectedDefinitiveId, selectedCustomId, selectedAccountTypeId)}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
  //}
};

export default dataManagement;
