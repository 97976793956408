import BaseService from './base-service';

class DataRefreshService extends BaseService {
  fetchListOfTriggers() {
    return this._apiGet({
      path: `/azureDataFactory/triggerList`
    });
  }
  fetchListOfTriggersNew() {
    return this._apiGet({
      path: `/azureDataFactory/triggerListNew`
    });
  }
  startTriggers(triggerName, pipelineDetails) {
    return this._apiPost({
      path: `/azureDataFactory/startTrigger/${triggerName}`,
      data: pipelineDetails
    });
  }

  stopTriggers(triggerName) {
    return this._apiPost({
      path: `/azureDataFactory/stopTrigger/${triggerName}`
    });
  }
}

export default new DataRefreshService();
