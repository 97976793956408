import React from 'react';
import { useParams } from 'react-router-dom';
import DashboardPage from './dashboard-page';

const DashboardLandPage = () => {
  let params = useParams();
  const dashboardId = params.dashboardId;

  return <DashboardPage dashboardId={dashboardId} />;
};
export default DashboardLandPage;
