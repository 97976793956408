/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Breadcrumb, Spinner } from 'react-bootstrap';
import AdvanceTableWrapper from '../../components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../../components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBoxPlug from '../../components/common/advance-table/AdvanceTableSearchBoxPlug';
import AdvanceTableFooter from '../../components/common/advance-table/AdvanceTableFooter';
import service from 'services/governance-service';
import { settings } from '../../config';
import { getItemFromStore } from 'helpers/utils';
import PageHeader from 'components/common/PageHeader';
import  PopoverComponent from 'pages/common/popover';
import { Helmet } from 'react-helmet';

const ReportBrowser = () => {
  const [glossaryItems, setGlossaryItems] = useState([]);
  const configState = {
    gridRowsCount: getItemFromStore('gridRowsCount', settings.gridRowsCount)
  };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    service
      .getAllItemsForDataGlossary()
      .then(res => {
        setGlossaryItems(res);
        setIsLoading(false);
      })
      .catch(err => {        
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  const dataGlossaryDesc = "Search for data attributes currently available in the Enterprise Data Warehouse.";
  return (
    <>
      <Helmet>
        <title>Data Glossary</title>
      </Helmet>
      <PageHeader title="Data Glossary" className="mb-2" col="12">
        <Row>
          <Col className="text-start mt-1">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Data Glossary</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="text-end mt-n2">
            <PopoverComponent 
              header = "Data Glossary"
              body = {dataGlossaryDesc}
            />
          </Col>
        </Row>
      </PageHeader>
      <AdvanceTableWrapper
        columns={columns}
        data={glossaryItems}
        sortable
        pagination
        perPage={configState.gridRowsCount}
      >
        <Card className="h-100">
          <Card.Header>
            <Row className="flex-between-center">
              <Col xs={9} sm={6} lg={3}>
                <AdvanceTableSearchBoxPlug table placeholder="Search" />
              </Col>
              <Col xs="auto" sm={3} lg={3} className="text-end marginTop">
                
              </Col>
            </Row>
          </Card.Header>
          {
            isLoading ? <div className='text-center'><Spinner animation="border" /></div> :
            <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle"
              tableProps={{
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
            </Card.Body>
          }
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={glossaryItems.length}
              table
              rowInfo
              navButtons
            />
          </Card.Footer>
        </Card>
        <br />
      </AdvanceTableWrapper>
    </>
  );
};

const columns = [
  {
    accessor: 'columnName',
    Header: 'Column Name'
  },
  {
    accessor: 'columnDescription',
    Header: 'Column Description',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start cell-description'
    }
  },
  {
    accessor: 'tableDomains',
    Header: 'Table Domains',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  }
];

export default ReportBrowser;
