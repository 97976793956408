/* eslint-disable prettier/prettier */
import { faCalendarAlt, faGlobeAmericas, faExclamationCircle, faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Row, Col, Breadcrumb, Form } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import authService from '../../services/auth-service';
import bloodLossService from '../../services/blood-loss-service';
// import { getItemFromStore } from '../../helpers/utils';
// import { settings } from '../../config';
import OverrideDeferralDatesModal from './OverrideDeferralDatesModal';
// import PropTypes from 'prop-types';
// import AdvanceTableFooter from '../../components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from '../../components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../../components/common/advance-table/AdvanceTable';
import ESignatureModal from './ESignatureModal';
import { Helmet } from 'react-helmet';

const DonationHistoryWorksheet =() => {
    let { id, donorKey } = useParams();
    // const [isLoading, setIsLoading] = useState(true);
    const [comment, setComment] = useState(null);
    const [donor, setDonor] = useState(null);
    const [userCan, setUserCan] = useState({
        edit: true,
        approve_reject: false,
        assign: false
    });
    const [buttonDisabled, setButtonDisabled] = useState('');
    
    // const [errorMessages, setErrorMessages] = useState([]);
    const [overrideDeferralDatesModalVisible, setOverrideDeferralDatesModalVisible] = useState(false);
    const [overridePlasmaDeferralDate, setOverridePlasmaDeferralDate] = useState(null);
    const [overrideRBCDeferralDate, setOverrideRBCDeferralDate] = useState(null);
    const [isOverridden, setIsOverridden] = useState(false);
    const [saveModalVisible, setSaveModalVisible] = useState(false);
    const [sendLetter, setSendLetter] = useState(null);
    const [sendLetterValidation, setSendLetterValidation] = useState(false);

    useEffect(() => {
        bloodLossService.fetchDonor(id, donorKey).then(response => {
            setUserPermissions(response.assignedUserId, response.reportStatus);
            if (response.donations) {
                response.donations.sort((a, b) => {
                    return new Date(a.donationDate) < new Date(b.donationDate) ? -1 : 1;
                });
            }
            // setIsLoading(false);
            setDonor(response);
            setSendLetter(response.sendLetter);      
            if (response.assignedUserId === null || response.sendLetter === null) { setButtonDisabled('disabled'); } else { setButtonDisabled(''); }
            if (response.sendLetter === null) {setSendLetterValidation(true); } else { setSendLetterValidation(false);}
          }).catch(error => {
            console.log(error);
            // setIsLoading(false);
            // setErrorMessages(error.response.data.errors);
        })
    }, []);

    const setUserPermissions = (assignedUserId, reportStatus) => {
        // let userCan = {
        //     edit: false,
        //     approve_reject: false
        // };

        if (authService.hasPermission(authService.claims.editBloodLossReports) &&
            (reportStatus === 'Draft' || reportStatus === 'Rejected') &&
            authService.getCurrentUserId() === assignedUserId) {
            // userCan.edit = true;
            setUserCan({...userCan, edit: true});
        }

       // return userCan;
    }

    const overrideDeferralDates = ({plasmaDeferralDate, rbcDeferralDate, comment}) => {
        setOverridePlasmaDeferralDate(plasmaDeferralDate);
        setOverrideRBCDeferralDate(rbcDeferralDate);
        setComment(comment);
        setOverrideDeferralDatesModalVisible(false);
        setIsOverridden(true);
        
    }
    const goBackToReport = () => {
        // const { history } = this.props;
        // history.push(`/blood-loss/report/${id}`);
        window.location.href = `/blood-loss/report/${id}`;
    }   

    const save = () => {
        // const { history } = this.props;
        const saveDonorCommand = {
            plasmaDeferralDate: overridePlasmaDeferralDate ? overridePlasmaDeferralDate : donor.plasmaDeferralDate,
            rbcDeferralDate: overrideRBCDeferralDate ? overrideRBCDeferralDate : donor.rbcDeferralDate,
            comment,
            sendLetter
        }

        bloodLossService.saveDonor(id, donorKey, saveDonorCommand)
            .then(() => {
                setSaveModalVisible(false);
                // history.push(`/blood-loss/report/${id}`);
            })
            .catch(error => {
                setSaveModalVisible(false);
                console.log(error);                
            })
    }
console.log(buttonDisabled);
    return (
            <div>
              <Helmet>
                <title>Donor Blood Loss Calculation Worksheet</title>
              </Helmet>
               <PageHeader title="Donor Blood Loss Calculation Worksheet" className="mb-2">
                <Breadcrumb>  
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/blood-loss">NYBC Blood Loss</Breadcrumb.Item>  
                    <Breadcrumb.Item href={`/blood-loss/report/${id}`}>Report</Breadcrumb.Item>        
                    <Breadcrumb.Item active>Donor Blood Loss Calculation Worksheet</Breadcrumb.Item>
                </Breadcrumb>
                </PageHeader>
                {donor && <>
                 
                <Card className="h-100 mb-3">
                    <Card.Header>
                        <Row className="flex-between-center">
                        <Col xs={9} sm={6} lg={3}>
                            Donors Code: {donor.donorCode}
                        </Col>
                        </Row>
                        <hr />
                        <Row>
                        <span className='mb-3'>
                            Donations for the previous 365 days from the report run date are shown below. Blood Loss for plasma and RBC is displayed by donation along with the running total and delta. Donations excluded in order to calculate new deferral date are marked.
                        </span>                       
                        </Row>                            
                    </Card.Header>                
                        <AdvanceTableWrapper   columns={columns}   data={donor.donations}  sortable    > 
                        <Card.Body className="p-0">
                            <AdvanceTable
                                    table
                                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                                    rowClassName="align-middle white-space-nowrap"
                                    tableProps={{ className: 'fs--1 mb-0 overflow-hidden' }}
                                />
                        </Card.Body>
                        <Card.Footer>
                        {/* <AdvanceTableFooter   table  /> */}
                        </Card.Footer>          
                        </AdvanceTableWrapper>                        
                </Card>     
                
                    <Card>                   
                        <Card.Body>      
                            <Row>     
                            <span className='mb-2'><b>Deferral Dates</b></span>
                            </Row>
                            <hr/>
                            {userCan.edit && 
                            <Row>
                                <p>Review the calculated deferral dates below. Click Override to manually modify deferral dates. Click Save to complete review.</p>
                            </Row>}             
                            <Row className='mb-2'>
                                <Col lg={3} md={6} sm={6}>Plasma Eligibility Date:</Col>
                                <Col lg={3} md={6} sm={6}>{getFormattedDateFromString(donor.plasmaEligibilityDate)}</Col>
                            </Row>
                            <Row  className='mb-2'>
                                <Col lg={3} md={6} sm={6}>RBC Eligibility Date:</Col>
                                <Col lg={3} md={6} sm={6}>{getFormattedDateFromString(donor.rbcEligibilityDate)}</Col>
                            </Row>
                            {donor.plasmaDeferralDate && 
                            <Row  className='mb-2'>
                                <Col lg={3} md={6} sm={6}>Plasma Calculated Deferral Date:</Col>
                                <Col lg={3} md={6} sm={6}>{getFormattedDateFromString(donor.plasmaDeferralDate)}</Col>
                            </Row>}
                            {donor.rbcDeferralDate && 
                            <Row className='mb-2'>
                                <Col lg={3} md={6} sm={6}>RBC Calculated Deferral Date:</Col>
                                <Col lg={3} md={6} sm={6}>{getFormattedDateFromString(donor.rbcDeferralDate)}</Col>
                            </Row>}                                 
                            {isOverridden && <> 
                               {overridePlasmaDeferralDate && 
                                        <Row className='mb-2'>
                                            <Col lg={3} md={6} sm={6} className="text-warning">Overridden Plasma Deferral Date:</Col>
                                            <Col lg={3} md={6} sm={6} className="text-warning">{overridePlasmaDeferralDate.toLocaleDateString('en-US')}</Col>
                                        </Row>}
                              {overrideRBCDeferralDate && 
                                        <Row className='mb-2'>
                                            <Col lg={3} md={6} sm={6} className="text-warning">Overridden RBC Deferral Date:</Col>
                                            <Col lg={3} md={6} sm={6} className="text-warning">{overrideRBCDeferralDate.toLocaleDateString('en-US')}</Col>
                                        </Row>}
                              <Row className='mb-2'>
                                            <Col lg={3} md={6} sm={6} className="text-warning">Override Comments:</Col>
                                            <Col lg={3} md={6} sm={6} className="text-warning">{comment}</Col>
                            </Row>          
                            </>}
                            {donor.signedOffPlasmaDeferralDate && 
                            <Row className='mb-2'>
                                            <Col lg={3} md={6} sm={6}>Signed Off Plasma Deferral Date:</Col>
                                            <Col lg={3} md={6} sm={6}>{getFormattedDateFromString(donor.signedOffPlasmaDeferralDate)}</Col>
                            </Row>}
                            {donor.signedOffRBCDeferralDate && 
                                        <Row className='mb-2'>
                                            <Col lg={3} md={6} sm={6}>Signed Off RBC Deferral Date:</Col>
                                            <Col lg={3} md={6} sm={6}>{getFormattedDateFromString(donor.signedOffRBCDeferralDate)}</Col>
                            </Row>}
                            {donor.signedOffBy && 
                                        <Row className='mb-2'>
                                            <Col lg={3} md={6} sm={6}>Signed Off By:</Col>
                                            <Col lg={3} md={6} sm={6}>{donor.signedOffBy}</Col>
                            </Row>}
                            {donor.comment && 
                                        <Row className='mb-2'>
                                            <Col lg={3} md={6} sm={6}>Comments:</Col>
                                            <Col lg={3} md={6} sm={6}>{donor.comment}</Col>
                            </Row>}                                  
                           
                          <Row className="mb-3 pt-2">
                            <Form.Label>Send Letter (does this donor require a notification): </Form.Label>
                            <br/>
                              {sendLetterValidation && (
                                <Row>
                                  <Col>
                                    <span style = {{fontSize: '15px'}} className='validation-error-msg'>Please check send letter</span>
                                  </Col>
                                </Row>
                              )
                              }
                            <Form.Group as={Col} >
                                <Form.Check
                                  inline
                                  checked={sendLetter}
                                  type='checkbox'
                                  id='sendLetterYes'
                                  label='Yes'
                                  onChange={(e)=>{setSendLetter(e.target.checked?true:null); 
                                                  setButtonDisabled((e.target.checked && donor.assignedUserId !== null) ?'': 'disabled');
                                                  setSendLetterValidation(e.target.checked?false:true);
                                                }}
                                />
                                <Form.Check
                                  inline
                                  checked={sendLetter === false}
                                  type='checkbox'
                                  id='sendLetterNo'
                                  label='No'
                                  onChange={(e)=>{setSendLetter(e.target.checked?false:null); 
                                                  setButtonDisabled((e.target.checked && donor.assignedUserId !== null)? '': 'disabled');
                                                  setSendLetterValidation(e.target.checked?false:true);
                                                }}
                                />
                            </Form.Group>
                          </Row>
                            {userCan.edit &&
                                <div className="text-right">
                                    <button type="button" disabled={buttonDisabled} className="btn btn-w-m btn-warning me-1 mb-1" onClick={()=>setOverrideDeferralDatesModalVisible(true)}>
                                        <FontAwesomeIcon icon={faCalendarAlt} />&nbsp;Override
                                    </button>
                                    <button className="btn btn-w-m btn-primary me-1 mb-1"
                                        disabled={buttonDisabled}
                                        type="button"
                                        onClick={()=>setSaveModalVisible(true)}>
                                    <FontAwesomeIcon icon={faGlobeAmericas} />&nbsp;Save
                                    </button>
                                    
                                    <button className="btn btn-outline-primary me-1 mb-1" 
                                        type="button"
                                        onClick={goBackToReport}>
                                        <FontAwesomeIcon icon={faTimes} />&nbsp;Close
                                    </button>
                                </div>}
                        </Card.Body>                        
                    </Card>
                      
                    <OverrideDeferralDatesModal
                            plasmaDeferralDate={donor.plasmaDeferralDate ? new Date(donor.plasmaDeferralDate) : null}
                            rbcDeferralDate={donor.rbcDeferralDate ? new Date(donor.rbcDeferralDate) : null}
                            comment={comment}
                            visible={overrideDeferralDatesModalVisible}
                            hide={()=>setOverrideDeferralDatesModalVisible(false)}
                            override={overrideDeferralDates} />
                    <ESignatureModal
                                       content="Please enter details below to confirm that you want to save this Donation History Worksheet."
                                       visible={saveModalVisible}
                                       hide={()=>setSaveModalVisible(false)}
                                       submit={save}
                       
                    /> 
                 </>}
                </div>            
        ) // end return    
} // end of DonorHistoryWorksheet 

function getFormattedDateFromString(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US');
}

function getDiffIcon(isBase, isExclusion) {
    if (isBase) {
        return <span style={{ color: "#1ab394" }}><FontAwesomeIcon icon={faCheckCircle} /></span>
    } else if (isExclusion) {
        return <span className="text-danger"><FontAwesomeIcon icon={faExclamationCircle} /></span>
    } else {
        return null;
    }
}
const columns = [
   
    {
      accessor: 'unitNo',
      Header: 'Unit Number',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start'
      }
    },
    {
      accessor: 'donationDT',
      Header: 'Donation Date',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start'
      },
      Cell: row => {
        return <div>{getFormattedDateFromString(row.value)}</div>
      }
    },
    {
      accessor: 'phlebotomyCode',
      Header: 'Phlebotomy Code',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start'
      }
    },    
    {
      accessor: 'plasmaLoss',
      Header: 'Plasma Loss',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start'
      }
    },
    {
      accessor: 'plasmaLossRunning',
      Header: 'Plasma Loss Running',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start'
      }
    },
    {
      accessor: 'plasmaLossDiff',
      Header: 'Plasma Loss Difference',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start'
      }, 
      Cell: rowData => (
        <div>
          {rowData.row.original.plasmaLossDiff.toLocaleString()} {getDiffIcon(rowData.row.original.plasmaBase, rowData.row.original.plasmaExclusion)}
        </div>
      )
    },
    {
      accessor: 'rbcLoss',
      Header: 'RBC Loss',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start'
      }
    },
    {
      accessor: 'rbcLossRunning',
      Header: 'RBC Loss Running',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start'
      }
    },
    {
        accessor: 'rbcLossDiff',
        Header: 'RBC Loss Difference',
        headerProps: {
          className: 'text-start'
        },
        cellProps: {
          className: 'text-start'
        }, 
        Cell: rowData => (
          <div>
            {rowData.row.original.rbcLossDiff.toLocaleString()} {getDiffIcon(rowData.row.original.rbcBase, rowData.row.original.rbcExclusion)}
          </div>
        )        
    }
  ];


export default DonationHistoryWorksheet;