/* eslint-disable prettier/prettier */
import { Card } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import WifaService from 'services/wifa-service';
import { getItemFromStore } from '../../helpers/utils';
import './PlateletDataEdit.css';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Edit,
  Inject,
  Filter,
  Sort
} from '@syncfusion/ej2-react-grids';
import { settings } from '../../config';
import SoftBadge from 'components/common/SoftBadge';

const ReviewNotes = (prop) => {
  const configState = {
    gridRowsCount: getItemFromStore('gridRowsCount', settings.gridRowsCount)
  };
 // const toolbarOptions = ['Edit', 'Update', 'Cancel'];
  const editSettings = { allowEditing: true, mode: 'Normal' };
  const pageSettings = { pageSize: configState.gridRowsCount };
  const [data, setData] = useState([]);
  const [tableName] = useState('Web.PlateletWIFATemp');

  useEffect(() => {
    WifaService.getReviewScenarios(prop.wifaSessionId, prop.bloodCenterKey, prop.scenarioKey)
    .then((reviewScenario) => {
      setData(reviewScenario);
    });
   // setData(prop.reviewScenario);
  }, []);

  const actionBegin = args => {
    if (args.requestType === 'save') {
      var setQuery = '';
      var whereQuery = '';

          setQuery =
            setQuery +
            "DailyNotes" +
            " = '" +
            args.data['dailyNotes'] + "'";

          whereQuery =
            whereQuery +
            "controlId" +
            ' = ' +
            args.data['controlId'];

      setQuery = setQuery.replaceAll("'null'", null);

      if (setQuery != '') {
        WifaService.updateSelectedTableData(
          tableName,
          setQuery,
          whereQuery,
          prop.bloodCenterKey,
          prop.scenarioKey,
          prop.wifaSessionId
        );
      }
    }
  };

  return (
    <>
      <Card>
        <Card.Header className='pt-1 pb-1'>
        <SoftBadge  bg={'warning'}
          className='rounded-pill'  > Division: {prop.bloodCenterCode}
        </SoftBadge> &nbsp;&nbsp; 
          Provide notes for each override. Double click on DailyNotes columns for input and press enter to save.
        </Card.Header>
        <Card.Body>
          <GridComponent
            dataSource={data}
            //height={315}
            className="bg-200 text-900"
           // toolbar={toolbarOptions}
            actionBegin={actionBegin}
           // allowPaging={true}
            allowFiltering={true}
            editSettings={editSettings}
            pageSettings={pageSettings}
            filterSettings={{ type: 'Menu' }}
            allowSorting={true}
            allowMultiSorting={true}
            allowTextWrap={true}
          >
            <ColumnsDirective>
              <ColumnDirective  key="controlId" field="controlId" headerText="Control Id" width='110' allowEditing={false}/>
              <ColumnDirective  key="controlDateString" field="controlDateString" headerText="Control Date" width='170' allowEditing={false}/>
              <ColumnDirective  key="invStatus" field="invStatus" headerText="Inventory Status" width='150' allowEditing={false}/>
              <ColumnDirective  key="dailyNotes" field="dailyNotes" headerText="Daily Notes" width='110' allowEditing={true}/>
            </ColumnsDirective>
            <Inject services={[Page, Toolbar, Edit, Filter, Sort]} />
          </GridComponent>
        </Card.Body>
      </Card>
    </>
  );
  //}
};

export default ReviewNotes;
