/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React from 'react';
import biService from 'services/bi-service';
//import { Helmet } from 'react-helmet';
// import { withRouter } from 'react-router-dom';
import * as pbi from 'powerbi-client';
// import SpinnerCubeGrid from '../../components/common/spinner-cube-grid';
import './pbi-dashboard.scss';


const Layout = Object.freeze({
    Deskop: 1,
    Mobile: 2
});

const getLayoutFromWidth = (width) => {
    return width >= 1550 ? Layout.Deskop : Layout.Mobile;
}

class PbiDashboardPage extends React.Component {
    constructor(props) {
        super(props);

        this.pbiContainer = React.createRef();
        this.powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);

        this.state = {
            isLoadingDashboard: true,
            error: null,
            config: {},
            layout: getLayoutFromWidth(window.innerWidth),
            isTabeau: false,
            isPowerBI: false,
            tableauPath: null,
            trustedTicket: null
        };
    }

    componentDidMount() {
        this.embedDashboard();
        window.addEventListener("resize", this.updateLayout);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateLayout);

        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.catalogGroupId !== this.props.catalogGroupId ||
            prevProps.catalogReportId !== this.props.catalogReportId ||
            prevState.layout !== this.state.layout) {
            this.embedDashboard();
        }
    }

    updateLayout = () => {
        const oldLayout = this.state.layout;
        var newLayout = getLayoutFromWidth(window.innerWidth);
        if (newLayout !== oldLayout) {
            this.powerbi.reset(this.pbiContainer.current);
            this.setState({
                isLoadingDashboard: true,
                layout: newLayout
            });
        }
    }

    embedDashboard = () => {
        if (!this.props.catalogGroupId || !this.props.catalogReportId) {
            this.setState({
                isLoadingDashboard: false,
                error: { message: "The dashboard is missing configuration for 'Catalog Group' or 'Catalog Report', please contact your administrator!" }
            });
            return;
        }

        biService.getEmbeddedReport(this.props.catalogGroupId, this.props.catalogReportId)
            .then(embeddedReport => {
                const models = pbi.models;
                const displayOption = this.state.layout === Layout.Deskop ? models.DisplayOption.ActualSize : models.DisplayOption.FitToWidth;
                const config = {
                    type: 'report',
                    tokenType: models.TokenType.Embed,
                    accessToken: embeddedReport.embedToken.token,
                    embedUrl: embeddedReport.embedUrl,
                    id: embeddedReport.id,
                    permissions: models.Permissions.Read,
                    settings: {
                        filterPaneEnabled: true,
                        navContentPaneEnabled: true,
                        layoutType: models.LayoutType.Custom,
                        customLayout: {
                            displayOption: displayOption
                        }
                    }
                };

                var report = this.powerbi.embed(this.pbiContainer.current, config);
                // Report.off removes a given event handler if it exists.
                //report.off("error");
                report.on("error", (event) => {
                    const error = event.detail;
                    this.setState({
                        error,
                        isLoadingDashboard: false
                    });
                });

                var currentTime = Date.now();
                var expiration = Date.parse(embeddedReport.embedToken.expiration);
                var safetyInterval = 2 * 60 * 1000; /* 2 minute safety interval */

                var interval = expiration - currentTime - safetyInterval;
                this.timeout = setTimeout(() => this.embedDashboard(), interval);

                this.setState({
                    isLoadingDashboard: false,
                    isPowerBI: true
                });
            })
            .catch(error => {
                this.setState({
                    error: error || "Could not retrieve the default dashboard, please try again later. If the problem persists, contact your administrator",
                    isLoadingDashboard: false
                });
            });
}

    render() {
        const { isLoadingDashboard, error } = this.state;
        const containerClass = isLoadingDashboard || error ? "pbi-container hide" : "pbi-container";
        // const pbiWidth = {width: '100%'}
        // const background_color = {'background-color': 'white'}
        return (
                <>
                    {(error && error.message) && (
                        <div className="alert alert-danger">
                            Error: {(error.message) ? error.message : 'Something went wrong!'}
                        </div>
                    )}
                    {/* {isLoadingDashboard && isPowerBI  && <SpinnerCubeGrid active={true} />} */}
                 
                    <div                    
                     ref={this.pbiContainer} className={containerClass}>
                    </div>
                </>
        )
    }
}

export default PbiDashboardPage;
