/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import { Card, Col, Row } from 'react-bootstrap';
import Notification from 'components/notification/Notification';
import { Link } from 'react-router-dom';
import notificationService from '../../services/notification-service';
import { toast } from 'react-toastify';
import DateFormat from '../../components/common/date-format';
import ConfirmationMessage from 'components/common/ConfirmationMessage';

const profile = JSON.parse(localStorage.getItem('userProfile'));
const ViewNotification = () => {
  const hubConnection = new signalR.HubConnectionBuilder()
    .withUrl(notificationService.getHubConnectionToUser(profile.email), {
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets
    })
    .build();

  hubConnection.start().then(function () {
    //document.getElementById(“user”).innerHTML = “UserId: ” + userId;
    hubConnection.invoke('GetConnectionId').then(function () { //connectionId) {
      //document.getElementById('signalRConnectionId').innerHTML=connectionId;
      //console.log('ConnectionId-' + connectionId);
    });
  });

  var list = [];
  const [notificationsList, setnotificationsList] = useState([]);
  useEffect(() => {
    // fetch(`http://localhost:59064/api/notification/GetNotifications`)
    // .then(response => response.json())
    var applicationName = 'Hemetrics';
    var userEmail = profile.email;
    notificationService
      .fetchNotification(applicationName, userEmail)
      .then(setnotificationsList);
  }, []);

  const Messages = messageProps => {
    // const [temp, setTemp] = useState();
    const [date, setDate] = useState();
    useEffect(() => {
      let isMounted = true;
      messageProps.HubConnection.on('Hemetrics', message => {
        list.push(message);
        setDate(new Date());
        isMounted && console.log(date);
        // if (isMounted) setTemp('');
         console.log(date);
        // if (message.type === "catalog") {
        //   catalogService.refreshCatalogCache().then(() => { window.location.reload(); });
        // } 
        // else if (message.type === "role") {
        //   userService.refreshUserProfileCache();
        // }
      });
      return () => {
        isMounted = false;
      };
    }, []);

    return (
      <>
        {list.map(message => (
          //<p key={`message${index}`} className= 'mb-1 rounded-0 border-x-0 border-300 border-bottom-0'>{ message}</p>

          <Notification
            key={message.messageId}
            {...{
              id: message.messageId,
              createdBy: message.createdBy,
              initial: message.createdBy.substring(0, 2),
              size: 'xl',
              children: message.messageHeader,
              time: (
                <DateFormat
                  format="MM-DD-YYYY HH:mm"
                  date={message.createdOn}
                />
              ),
              emoji: '💬',
              className: 'rounded-0 border-x-0 border-300 border-bottom-0',
              to: `/notification/NotificationView/${message.messageId}`,
              unread: message.readIndicator ? false : true
            }}
          />
        ))}
      </>
    );
  };

  const SendMessage = () => {
    return (
      <>
        {notificationsList &&
          notificationsList.map(notification => {
            //return <li> {notification.data} </li>;
            return (
              <Notification
                key={notification.messageId}
                {...{
                  id: notification.messageId,
                  createdBy: notification.createdBy,
                  initial: notification.createdBy.substring(0, 2),
                  size: 'xl',
                  children: notification.messageHeader,
                  time: (
                    <DateFormat
                      format="MM-DD-YYYY HH:mm"
                      date={notification.createdOn}
                    />
                  ),
                  emoji: '💬',
                  className: 'rounded-0 border-x-0 border-300 border-bottom-0',
                  to: `/notification/NotificationView/${notification.messageId}`,
                  unread: notification.readIndicator ? false : true
                }}
              />
            );
          })}
      </>
    );
    //return <>{list.map((message, index) => <p key={`message${index}`}>{message}</p>)}</>
  };

  const NotificationHeader = () => {
    // const [header, setHeader] = useState("");
    // const [message, setMessage] = useState("");

    // const headerChange = (event) => {
    //   if (event && event.target) {
    //     setHeader(event.target.value);
    //   }
    // }

    // const messageChange = event => {
    //   if (event && event.target) {
    //     setMessage(event.target.value);
    //   }
    // };

    // const messageSubmitToAll = (event) => {
    //    if (event) {

    //   //const listOfRecipients = ["vkong@nybc.org", "smallick@nybc.org", "srehmani@nybc.org", "tsaxena@nybc.org"]
    //   const listOfRecipients = ["tsaxena@nybc.org", "smallick@nybc.org"]
    //   const notificationRequest = { applicationName:"Hemetrics", messageHeader:header, messageContent: message, createdBy:profile.email, recipientEmailList: listOfRecipients, recipientEmailAddress:""};
    //   notificationService.AddNotification(notificationRequest);
    //   //setMessage("");
    //   }
    // };
    // const messageSubmitToSpecificUser = (event) => {
    //    if (event) {
    //   //const listOfRecipients = ["vkong@nybc.org", "smallick@nybc.org", "srehmani@nybc.org", "tsaxena@nybc.org"]
    //   const listOfRecipients = ['tsaxena@nybc.org', 'smallick@nybc.org']
    //   const notificationRequest = {
    //      applicationName:"Hemetrics",
    //      messageHeader:header,
    //      messageContent: message,
    //      createdBy:profile.email,
    //      recipientEmailList: listOfRecipients,
    //      recipientEmailAddress: ''
    //     };
    //     notificationService.AddNotificationToSpecificUser(notificationRequest);
    //     //setMessage("");
    //  }
    // };

    const handleMarkAllAsRead = () => {
      notificationService.UpdateAllNotificationAsync(profile.email).then(() => {
        // toast('Mark All As Read Successfully!', {
        //   autoClose: true,
        //   className: 'toast-container success'
        // });
        toast(ConfirmationMessage("Notification", "Mark All As Read Successfully!", "bg-success", "faCheckCircle"), {
          autoClose: true,
          //className: 'transparent',
          //style: {{background-color: transparent !important}},
          position: 'bottom-right',
          hideProgressBar: true,
          closeButton: false,
        });
      });
    };
    return (
      <>
        {/* <label>Header:</label>
      <input type="text" onChange={headerChange} value={header} />
      <label>Content:</label>
      <input type="text" onChange={messageChange} value={message} />
      <button onClick={messageSubmitToAll}>Send Notification to All</button>
      <button onClick={messageSubmitToSpecificUser}>Send Notification To Specific User</button>  
    <br/> */}

        <Card.Header className="bg-light">
          <Row className="flex-between-center">
            <Col sm="auto">
              <h5 className="mb-1 mb-md-0">Your Notifications</h5>
            </Col>
            <Col sm="auto" className="fs--1">
              <Link
                to="#!"
                onClick={handleMarkAllAsRead}
                className="font-sans-serif ms-2 ms-sm-3"
              >
                Mark all as read
              </Link>
              <div className="font-sans-serif ms-2 ms-sm-3"></div>
            </Col>
          </Row>
        </Card.Header>
      </>
    );
  };

  return (
    <>
      <Card>
        <NotificationHeader />
        <Card.Body className="p-0">
          <Messages HubConnection={hubConnection}></Messages>
          <SendMessage />
        </Card.Body>
      </Card>
    </>
  );
};

export default ViewNotification;
