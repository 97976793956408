/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';

const CustomerMDMSop = () => {

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
      };

    useEffect(() => {
        //console.log("open new url");
        openInNewTab('https://nybc.sharepoint.com/sites/EADS/EADS%20SOP/Forms/AllItems.aspx?id=%2Fsites%2FEADS%2FEADS%20SOP%2FPortal%20PDF%2FEW%2DIT%2DSOP%2DCustomer%20MDM%2Epdf&viewid=9546c87e%2D3604%2D4a36%2D81af%2D6332811aa17d&parent=%2Fsites%2FEADS%2FEADS%20SOP%2FPortal%20PDF')
        history.back();
    },[]);

    return (
        <></>
    );
};

export default CustomerMDMSop;