/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import PageHeader from '../../../components/common/PageHeader';
import { Accordion, Card, Breadcrumb } from 'react-bootstrap';
import FaqAccordionItem from './FaqAccordionItem';
import { faqAccordions as faqsData } from '../../../data/faqs';
import AskQuestionModal from './AskQuestionModal';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const FaqAccordion = () => {
  const [faqs] = useState(faqsData);
  
  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);
 
  return (
    <>
      <Helmet>
        <title>FAQ</title>
      </Helmet>
      <PageHeader
        title="Frequently Asked Question (FAQ)"
        className="mb-2"
      >
       <Breadcrumb>  
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>FAQ</Breadcrumb.Item>  
      </Breadcrumb>
      </PageHeader>
      <Card>
        <Card.Body>
          <Accordion
            id="accordionFaq"
            className="border rounded overflow-hidden"
          >
            {faqs.map((faq, index) => (
              <FaqAccordionItem
                key={faq.id}
                faq={faq}
                isFirst={index === 0}
                isLast={index === faqs.length - 1}
              />
            ))}
          </Accordion>
        </Card.Body>
        <Card.Footer className="py-3">
        <div className="text-center">
          <h6 className="fs-0 fw-normal">Have more questions?</h6>
          <Button variant="falcon-primary" size="sm" onClick={handleModalShow}>
            Ask us anything
          </Button>
        </div>
        <AskQuestionModal show={showModal} handleClose={handleModalClose} />
      </Card.Footer>
      </Card>
    </>
  );
};

export default FaqAccordion;
