import React from 'react';

const SpinnerCubeGrid = props => {
  // eslint-disable-next-line react/prop-types
  if (props.active) {
    return (
      <div className="spiner-example">
        <div className="sk-spinner sk-spinner-cube-grid">
          <div className="sk-cube nybc-theme-color"></div>
          <div className="sk-cube nybc-theme-color"></div>
          <div className="sk-cube nybc-theme-color"></div>
          <div className="sk-cube nybc-theme-color"></div>
          <div className="sk-cube nybc-theme-color"></div>
          <div className="sk-cube nybc-theme-color"></div>
          <div className="sk-cube nybc-theme-color"></div>
          <div className="sk-cube nybc-theme-color"></div>
          <div className="sk-cube nybc-theme-color"></div>
        </div>
      </div>
    );
  }

  return null;
};

export default SpinnerCubeGrid;
