/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Breadcrumb } from 'react-bootstrap';
import AdvanceTableWrapper from '../../components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../../components/common/advance-table/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableSearchBox from '../../components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from '../../components/common/advance-table/AdvanceTableFooter';
import HemService from 'services/hem-service';
import { settings } from '../../config';
import { getItemFromStore } from '../../helpers/utils';
import DateFormat from '../../components/common/date-format';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../admin/admin.scss';
import PageHeader from 'components/common/PageHeader';
import { Helmet } from 'react-helmet';

const columns = [
  {
    accessor: 'releaseDate',
    Header: 'Release Date',
    Cell: rowData => (
      <DateFormat format="MM-DD-YYYY" date={rowData.row.original.releaseDate} />
    )
  },
  {
    accessor: 'title',
    Header: 'Title',
    Cell: rowData => (
      <Link
        to={`/release/view/${rowData.row.original.releaseId}`}
        className="text-primary fw-semi-bold"
      >
        {rowData.row.original.title}
      </Link>
    )
  },
  {
    accessor: 'versionId',
    Header: 'Version',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'isMajorRelease',
    Header: 'Major Release',
    Cell: rowData => <div>{rowData.row.original.isMajorRelease.toString()}</div>
  },
  {
    accessor: 'releaseStatus',
    Header: 'Status',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'edit',
    Header: 'Edit',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    },
    Cell: rowData => (
      <Link to={`/release/editor/${rowData.row.original.releaseId}`}>
        <FontAwesomeIcon icon={faEdit} />
      </Link>
    )
  }
];
const ReleaseBrowser = () => {
  const [releases, setReleases] = useState([]);
  const configState = {
    gridRowsCount: getItemFromStore('gridRowsCount', settings.gridRowsCount)
  };

  useEffect(() => {
    HemService.fetchReleases()
      .then(releases => {
        //const userCan = this.getUserRights();
        setReleases(releases);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Release Manager</title>
      </Helmet>
      <PageHeader title="Release Manager" className="mb-2">
      <Breadcrumb>  
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Release Manager</Breadcrumb.Item>
      </Breadcrumb>
      </PageHeader>
      <AdvanceTableWrapper
        columns={columns}
        data={releases}
        sortable
        pagination
        perPage={configState.gridRowsCount}
      >
        <Card className="h-100">
          <Card.Header>
            <Row className="flex-between-center">
              <Col xs={9} sm={6} lg={3}>
                <AdvanceTableSearchBox table placeholder="Search" />
              </Col>
              <Col xs="auto" sm={3} lg={3} className="text-end marginTop">
                <Link className="btn btn-outline-primary" to={'/release/new'}>
                  <FontAwesomeIcon icon={faPlus} /> New
                </Link>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={releases.length}
              table
              rowInfo
              navButtons
            />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

ReleaseBrowser.propTypes = {
  releases: PropTypes.arrayOf(
    PropTypes.shape({
      releaseDate: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      versionId: PropTypes.string.isRequired,
      isMajorRelease: PropTypes.string.isRequired,
      releaseStatus: PropTypes.string.isRequired,
      edit: PropTypes.object
    })
  ),
  perPage: PropTypes.number
};

export default ReleaseBrowser;
