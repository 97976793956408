/* eslint-disable prettier/prettier */
import BaseService from './base-service';

class GovernanceService extends BaseService {
  fetchTables = () => {
    return this._apiGet({
      path: `/dataGovernance/getTables`
    });
  };
  fetchConfigDb = () => {
    return this._apiGet({
      path: `/dataGovernance/getConfigDb`
    });
  };
  fetchConfigTables = databaseId => {
    return this._apiGet({
      path: `/dataGovernance/getConfigTables/${databaseId}`
    });
  };
  getSelectedConfigTableData = tableConfigId => {
    return this._apiGet({
      path: `/dataGovernance/getSelectedConfigTableData/${tableConfigId}`
    });
  };
  getSelectedTableData = (databaseId, tableName) => {
    return this._apiGet({
      path: `/dataGovernance/getSelectedTableData/${databaseId}/${tableName}`
    });
  };
  updateSelectedTableData = (databaseId, tableName, setColumns, whereCondition) => {
    return this._apiPut({
      path: `/dataGovernance/updateSelectedTableData/${databaseId}/${tableName}/${setColumns}/${whereCondition}`
    });
  };
  getSelectedQueryCountForTable = (databaseId, tableName, countQuery) => {
    return this._apiPost({
      path: `/dataGovernance/getSelectedQueryCountForTable/${databaseId}/${tableName}`,
      data: countQuery
    });
  };
  getSelectedQueryOutputForTable = (databaseId, tableName, selectQuery) => {
    return this._apiPost({
      path: `/dataGovernance/getSelectedQueryOutputForTable/${databaseId}/${tableName}`,
      data: selectQuery
    });
  };
  // saveDataRule = (
  //   tableName,
  //   useremail,
  //   tableConfigId,
  //   dataRuleName,
  //   dataRuleQuery,
  //   queryBuilderRuleModel
  // ) => {
  //   return this._apiPost({
  //     path: `/dataGovernance/saveDataRule/${tableName}/${useremail}/${tableConfigId}/${dataRuleName}/${dataRuleQuery}`,
  //     data: queryBuilderRuleModel
  //   });
  // };
  saveDataRule = queryBuilderRuleJson => {
    return this._apiPost({
      path: `/dataGovernance/saveDataRule`,
      data: queryBuilderRuleJson
    });
  };
  getDataType = (databaseId, tableConfigId) => {
    return this._apiGet({
      path: `/dataGovernance/getDataType/${databaseId}/${tableConfigId}`
    });
  };
  getDataRules = tableConfigId => {
    return this._apiGet({
      path: `/dataGovernance/getDataRules/${tableConfigId}`
    });
  };
  getDataRulebyRuleId = dataRuleId => {
    return this._apiGet({
      path: `/dataGovernance/getDataRuleByRuleId/${dataRuleId}`
    });
  };
  getScheduleType = () => {
    return this._apiGet({
      path: `/dataGovernance/getScheduleType`
    });
  };
  deleteRule = (dataRuleId, statusId) => {
    return this._apiDelete({
      path: `/dataGovernance/deleteRole/${dataRuleId}/${statusId}`
    });
  };
  getRoles = () => {
    return this._apiGet({
      path: `/dataGovernance/getRoles`
    });
  };
  getUsersByRoleId = (roleId, dataRuleId) => {
    return this._apiGet({
      path: `/dataGovernance/getUsersByRoleId/${roleId}/${dataRuleId}`
    });
  };
  getNotificationTypes = () => {
    return this._apiGet({
      path: `/dataGovernance/getNotificationTypes`
    });
  };
  SaveNotificationRecipient = notificationRecipient => {
    return this._apiPost({
      path: `/dataGovernance/saveNotificationRecipient`,
      data: notificationRecipient
    });
  };
  getAllItemsForDataGlossary = notificationRecipient => {
    return this._apiGet({
      path: `/dataGovernance/getAllItemsForDataGlossary`,
      data: notificationRecipient
    });
  };
  getMdmTableData = () => {
    return this._apiGet({
      path: `/dataGovernance/getMdmTableData`
    });
  }  
  getAllDefinitives = (key) => {
    return this._apiGet({
      path: `/dataGovernance/getAllDefinitives/${key}`
    });
  }

  getAllCustoms = (key) => {
    return this._apiGet({
      path: `/dataGovernance/getAllCustoms/${key}`
    });
  }

  getAllAccountTypes = (key) => {
    return this._apiGet({
      path: `/dataGovernance/getAllAccountTypes/${key}`
    });
  }

  getAllAccountTypesById = (id) => {
    return this._apiGet({
      path: `/dataGovernance/getAllAccountTypesById/${id}`
    });
  }

  getAllDefinitivesById = (definitiveId, customId) => {
    return this._apiGet({
      path: `/dataGovernance/getAllDefinitivesById/${definitiveId}/${customId}`
    });
  }

  getAllCustomsById = (id) => {
    return this._apiGet({
      path: `/dataGovernance/getAllCustomsById/${id}`
    });
  }

  getCustomerMasterKey = (definitiveId, customId) => {
    return this._apiGet({
      path: `/dataGovernance/getCustomerMasterKey/${definitiveId}/${customId}`
    });
  }
  
}

export default new GovernanceService();
