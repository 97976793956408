import React, { Fragment, useState, useEffect } from 'react';
import PageHeader from 'components/common/PageHeader';
import { Card, Col, Row, Breadcrumb, Table, Spinner } from 'react-bootstrap';
import AzureDataFactoryService from 'services/dataRefresh-service';
import SpinnerCubeGrid from 'components/common/spinner-cube-grid';
import SimpleBarReact from 'simplebar-react';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopoverComponent from 'pages/common/popover';
import { Helmet } from 'react-helmet';

const dataRefreshView = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [triggerList, setTriggerList] = useState([]);

  useEffect(() => {
    // Similar as ComponentDidMount
    // Get List the Triggers
    AzureDataFactoryService.fetchListOfTriggers().then(listOfTriggers => {
      setTriggerList(listOfTriggers);
      setIsLoading(false);
    });
  }, [isLoading]);

  // Start the Trigger
  const operationTriggers = (triggerName, listOfPipeline) => {
    return AzureDataFactoryService.startTriggers(
      triggerName,
      listOfPipeline
    ).then(loading => {
      setIsLoading(loading);
    });
  };

  // Refresh the Pipeline Status
  // const refreshStatus = () => {
  //   return setIsLoading(true);
  // };
  const dataRefreshDesc =
    'Data & Report Refresh allows to copy files from TM1 to Hemetrics FileShare based on your current permissions profile. Job status displays the current status of the job. If necessary click on the play button to start the job.';
  return (
    <>
      <Helmet>
        <title>Data Refresh</title>
      </Helmet>
      <PageHeader title="Data & Report Refresh" className="mb-2" col="12">
        <Row>
          <Col className="text-start mt-1">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Data & Report Refresh</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="text-end mt-n2">
            <PopoverComponent
              // header = "Enterprise Data Governance"
              body={dataRefreshDesc}
            />
          </Col>
          {/* <Col className="text-start mt-1">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Data & Report Refresh</Breadcrumb.Item>
            </Breadcrumb>
          </Col> */}
        </Row>
      </PageHeader>
      <Card className="h-100">
        <Card.Body className="p-0">
          <SimpleBarReact>
            <Table className="fs--1 mb-0 overflow-hidden">
              <thead className="bg-200 text-900 text-nowrap align-middle">
                <tr className="align-middle white-space-nowrap">
                  <th scope="col">Name</th>
                  <th scope="col" width="50%">
                    Description
                  </th>
                  <th scope="col">Job Status</th>
                  <th scope="col">Job Action</th>
                </tr>
              </thead>
              <tbody>
                <Fragment>
                  {isLoading ? (
                    <tr>
                      <td colSpan={5}>
                        <SpinnerCubeGrid active={true} />
                      </td>
                    </tr>
                  ) : triggerList.data.length > 0 ? (
                    triggerList.data.map((trigger, index) => (
                      <tr key={index}>
                        <td>{trigger.triggerAnnotations}</td>
                        <td>{trigger.triggerDescription}</td>
                        <td>{trigger.listOfPipeline[0].pipelineStatus}</td>
                        <td>
                          {trigger.listOfPipeline[0].pipelineStatus ===
                          'InProgress' ? (
                            <div>
                              <Spinner animation="border" />
                            </div>
                          ) : (
                            <button
                              type="submit"
                              onClick={() => {
                                operationTriggers(
                                  trigger.triggerName,
                                  trigger.listOfPipeline
                                );
                              }}
                              className="btn"
                            >
                              <FontAwesomeIcon
                                color={'blue'}
                                size={'lg'}
                                icon={faPlay}
                                title={'Start Now'}
                              />
                            </button>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      {' '}
                      <td colSpan={5} className="text-center">
                        No trigger found
                      </td>
                    </tr>
                  )}
                </Fragment>
              </tbody>
            </Table>
          </SimpleBarReact>
        </Card.Body>
        <Card.Footer />
      </Card>
    </>
  );
};

export default dataRefreshView;
