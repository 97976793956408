import BaseService from './base-service';

class TableauService extends BaseService {
  fetchProjects() {
    return this._apiGet({
      path: `/tableau/projects`
    });
  }

  fetchWorkbooks(projectID) {
    return this._apiGet({
      path: `/tableau/workbooks/${projectID}`
    });
  }
  fetchWorkbookViews(workbookid) {
    return this._apiGet({
      path: `/tableau/workbookView/${workbookid}`
    });
  }

  fetchTableauToken(username) {
    return this._apiGet({
      path: `/tableau/trustedAuth/${username}`
    });
  }

  getTableauEmbeddedReport(catalogId) {
    return this._apiGet({
      path: `/bi/tableauEmbed/${catalogId}`
    });
  }

  getTableauViewHeight(viewId) {
    return this._apiGet({
      path: `/tableau/imageProperty/${viewId}`
    });
  }
}

export default new TableauService();
