/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Select from 'react-select';
import { Row, Col, Form, Modal, Button } from 'react-bootstrap';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

  const DynamicKPITarget = (prop) => {
    const [showConfirmTarget, setShowConfirmTarget] = useState(false);
    const [validateTargetRange, setValidateTargetRange] = useState(false);
    const [validateTargetEnd, setValidateTargetEnd] = useState(false);
    const [dynamicTarget, setDynamicTarget] = useState({
      dynamicTargetIdentifier: '',
      dynamicRangeType: 0,
      dynamicTargetStart: 0.0,
      dynamicTargetEnd: 0.0
    });

    let ifExist = false;
    //Find if existing record
    if (prop.kpiTargetId > 0) {
      ifExist = true;
    } else if (prop.targetIdentifier != '' || prop.targetStart != '' || prop.targetEnd != '' || prop.targetRange) {
      ifExist = true;
    } else {
      ifExist = false;
    }
    useEffect(() => {
     setDynamicTarget ({...dynamicTarget, dynamicTargetIdentifier: prop.targetIdentifier, dynamicRangeType: prop.targetRange, dynamicTargetStart: prop.targetStart, dynamicTargetEnd: prop.targetEnd});
     setValidateTargetRange(prop.validateTargetRange);
     setValidateTargetEnd(prop.validateTargetEnd);
     if (prop.targetRange < 0) {
      setValidateTargetRange(false);
     } else {
      setValidateTargetRange(true);
     }
     if (prop.targetEnd < 0) {
      setValidateTargetEnd(false);
     } else {
      setValidateTargetEnd(true);
     }
    }, []);
    const onClickTargetCancel = () => setShowConfirmTarget(false);
    const onClickTargetOk = () => { setShowConfirmTarget(false); deleteAssignedTarget(); }

  function onClickTargetIdentifierChange(e) {
    setDynamicTarget ({...dynamicTarget, dynamicTargetIdentifier: e.target.value});
    if (ifExist) {  // if existing records
      if (prop.onClickTargetIdentifierChange) {
        prop.onClickTargetIdentifierChange(prop.serialId, e.target.value);
      }
    } else { // If new record
      if (prop.onClickTargetIdentifierChange) {
        prop.onClickTargetIdentifierChange(0, e.target.value);
      }
    }

  }
  function onClickTargetChange(e) {
    setDynamicTarget ({...dynamicTarget, dynamicTargetStart: e.target.value});
    // if (e.target.value > 0) {
    //   setValidateTargetStart(true);
    // } else {
    //   setValidateTargetStart(false);
    // }
    if (ifExist) {  // if existing records
      if (prop.onClickTargetChange) {
        prop.onClickTargetChange(prop.serialId, e.target.value);
      }
    } else { // If new record
      if (prop.onClickTargetChange) {
        prop.onClickTargetChange(0, e.target.value);
      }
    }
  }
  function onClickTargetEndChange(e) {
    setDynamicTarget ({...dynamicTarget, dynamicTargetEnd: e.target.value});
    if (ifExist) {  // if existing records
      if (prop.onClickTargetEndChange) {
        prop.onClickTargetEndChange(prop.serialId, e.target.value);
      }
    } else { // If new record
      if (prop.onClickTargetEndChange) {
        prop.onClickTargetEndChange(0, e.target.value);
      }
    }
  }
  function dynamicTargetRangeChange (e) {
    setDynamicTarget ({...dynamicTarget, dynamicRangeType: e.value});
    if (ifExist) {  // if existing records
      if (prop.dynamicTargetRangeChange) {
        prop.dynamicTargetRangeChange(prop.serialId, e.value);
      }
    } else { // If new record
      if (prop.dynamicTargetRangeChange) {
        prop.dynamicTargetRangeChange(0, e.value);
      }
    }
  }
  function onClickRemoveTarget (e) {
    e.preventDefault();
    setShowConfirmTarget(true);
  }
  function deleteAssignedTarget() {
    if (prop.onClickRemoveTarget) {
      prop.onClickRemoveTarget(prop.serialId);
    }
  }

    return (
      <>
            <Row>
                <Form.Group as={Col} lg={4} controlId="name">
                  <Form.Label>Identifier</Form.Label>
                  <Form.Control
                    // isInvalid={!validateName}
                    type="text"
                    value={dynamicTarget.dynamicTargetIdentifier}
                    name="targetIdentifier"
                    onChange={(e)=>{onClickTargetIdentifierChange(e)}}
                  />
                  <Form.Control.Feedback type="invalid" >Please fill out this field.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} lg={3} controlId="targetRange">
                  <Form.Label>Range</Form.Label>
                  <Select
                    closeMenuOnSelect={true}
                    className={validateTargetRange?'':'validation-error'}
                    options={prop.range}
                    placeholder='Select...'
                    //classNamePrefix="react-select"
                    value={prop.range.find(item => item.value === dynamicTarget.dynamicRangeType)??null}
                   onChange={dynamicTargetRangeChange}
                  />
                  {!validateTargetRange && (<span className='validation-error-msg'>Please fill out this field.</span>)}
                </Form.Group>
                <Form.Group as={Col} lg={2} controlId="name">
                  <Form.Label>Target Start</Form.Label>
                  <Form.Control
                   // isInvalid={!validateTargetStart}
                    type="text"
                    value={dynamicTarget.dynamicTargetStart}
                    name="target"
                    onChange={(e)=>{onClickTargetChange(e)}}
                  />
                  <Form.Control.Feedback type="invalid" >Please fill out this field.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} lg={2} controlId="name">
                  <Form.Label>Target End</Form.Label>
                  <Form.Control
                    isInvalid={!validateTargetEnd}
                    type="text"
                    value={dynamicTarget.dynamicTargetEnd}
                    name="targetEnd"
                    onChange={(e)=>{onClickTargetEndChange(e)}}
                  />
                  <Form.Control.Feedback type="invalid" >Please fill out this field.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} lg={1} controlId="name">
                <br />
                {/* {showSave && (
                  <button className="btn btn-outline-primary me-1 mb-1 mt-2" type="button" onClick={onClickTargetSave} >
                  <FontAwesomeIcon icon={faSave} />
                  </button>)
                } */}
                <button className="btn btn-outline-primary me-1 mb-1 mt-2" type="button" onClick={onClickRemoveTarget} >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
                </Form.Group>

            </Row>

        <Modal show={showConfirmTarget} onHide={onClickTargetCancel} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Hemetrics</Modal.Title>
          <FalconCloseButton onClick={onClickTargetCancel}/>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this assigned Target?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onClickTargetOk}>
            OK
          </Button>
          <Button variant="secondary" onClick={onClickTargetCancel} >Cancel</Button>
        </Modal.Footer>
        </Modal>
  </>
    )
}

  export default DynamicKPITarget;
