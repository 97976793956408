/* eslint-disable react/jsx-key */
/* eslint-disable prettier/prettier */


import React, { Fragment, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import PageHeader from 'components/common/PageHeader';
import { Form, Breadcrumb, Row, Col, Card, CardGroup, Container } from 'react-bootstrap';
// import Table from 'react-bootstrap/Table';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Fuse from 'fuse.js';
import { Link } from 'react-router-dom';
// import Avatar from '../../components/common/Avatar';
import { isIterableArray } from '../../helpers/utils';
import SoftBadge from 'components/common/SoftBadge';

import searchService from 'services/search-service';
import Enum from 'common/enum';
import SearchFilter from './search-filter';
import './advanced-search.scss';

const AdvancedSearch = () => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const [openFilter, setOpenFilter] = useState(true);
  const [searchItems, setSearchItems] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [criteria, setCriteria] = useState({});
  const minFilterHeight = 500;
  const searchBreakpoint =765;
  if (searchItems.length === 0) {

    let cached = JSON.parse(sessionStorage.getItem(Enum.CACHED_ADVANCED_SEARCH));
    if (cached) {
      setSearchItems(cached);
    } else {
      searchService.getAllItemsForAdvancedSearch().then(res => {
      sessionStorage.removeItem(Enum.CACHED_ADVANCED_SEARCH);
      sessionStorage.setItem(Enum.CACHED_ADVANCED_SEARCH, JSON.stringify(res));

      setSearchItems(res);
      }).catch(err => {
        console.log(err);
      });
    }
  }


  const fuseJsOptions = {
      threshold: 0.3,
      minMatchCharLength: 2,
      keys: ['text', 'description', 'key']
    };
    // const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const detectSize = () => { 
      setScreenWidth(window.innerWidth); 
      // setScreenHeight(window.innerHeight);       
    }
    
    useEffect(() => {
      window.addEventListener('resize', detectSize)
      return () => {
        window.removeEventListener('resize', detectSize)
      }
    }, []);



  function onClickReset(filter) {
    setCriteria(filter);
    setSearchResult([]);
  }
  function onChangeSearchInput(e) {
    setSearchInputValue(e.target.value);
    const fuseResult = new Fuse(searchItems, fuseJsOptions)
    .search(searchInputValue)
    .map(item => item.item);
    setSearchResult(fuseResult);
  }

  function onClickApply(criteria) {
    let filterResult = searchItems;
    // enterprise and regulatory
    if (criteria.isEnterprise === true && criteria.isRegulatory === true) {
      filterResult = (criteria.isEnterprise === true? searchItems.filter(item=> {return item.isEnterprise === true || item.isRegulatory === true}) : searchItems);
    }
    else {
      filterResult = (criteria.isEnterprise === true? searchItems.filter(item=> {return item.isEnterprise === true}) : searchItems);
      filterResult = (criteria.isRegulatory === true? filterResult.filter(item=> {return item.isRegulatory === true}) : filterResult);
    }
    // reports, dashboard and data governance
    if (criteria.isDashboards === true && criteria.isReports === true && criteria.isKpi=== true && criteria.isVocabulary=== true) {
      filterResult = filterResult.filter(item=> {return item.key === 'Dashboard' || item.key === 'Analytics' || item.key === 'Report' || item.key === 'KPI' || item.key === 'Vocabulary'});
    }
    else if (criteria.isDashboards === true && criteria.isReports === true) {
      filterResult = filterResult.filter(item=> {return item.key === 'Dashboard' || item.key === 'Analytics' || item.key === 'Report'});
    }
    else if (criteria.isDashboards === true && criteria.isKpi === true) {
      filterResult = filterResult.filter(item=> {return item.key === 'Dashboard' || item.key === 'Analytics' || item.key === 'KPI'});
    }
    else if (criteria.isReports === true && criteria.isKpi === true) {
      filterResult = filterResult.filter(item=> {return item.key === 'Report' || item.key === 'KPI'});
    }
    else if (criteria.isDashboards === true && criteria.isVocabulary === true) {
      filterResult = filterResult.filter(item=> {return item.key === 'Dashboard' || item.key === 'Analytics' || item.key === 'Vocabulary'});
    }
    else if (criteria.isReports === true && criteria.isVocabulary === true) {
      filterResult = filterResult.filter(item=> {return item.key === 'Report' || item.key === 'Vocabulary'});
    }
    else if (criteria.isKpi === true && criteria.isVocabulary === true) {
      filterResult = filterResult.filter(item=> {return item.key === 'KPI' || item.key === 'Vocabulary'});
    }
    else {
      filterResult = (criteria.isDashboards === true? filterResult.filter(item=> {return item.key === 'Dashboard' || item.key === 'Analytics'}) : filterResult);
      filterResult = (criteria.isReports === true? filterResult.filter(item=> {return item.key === 'Report'}) : filterResult);
      filterResult = (criteria.isKpi === true? filterResult.filter(item=> {return item.key === 'KPI'}) : filterResult);
      filterResult = (criteria.isVocabulary === true? filterResult.filter(item=> {return item.key === 'Vocabulary'}) : filterResult);
    }
    // domain and subdomain
    // if (criteria.domainId !== '' && criteria.subdomainId !== '') {
    //   filterResult = filterResult.filter(item=> {return item.domain === criteria.domainName && item.subdomainName === criteria.subdomainName});
    // }
    // else {
      filterResult = (criteria.domainId !== ''? filterResult.filter(item=> {return item.domain === criteria.domainName}) : filterResult);
      filterResult = (criteria.subdomainId !== ''? filterResult.filter(item=> {return item.subdomain === criteria.subdomainName}) : filterResult);    
    //}
    setSearchResult(filterResult);
    setOpenFilter(false);
    setCriteria(criteria);
  }
  // const toggle = () => setDropdownOpen(!dropdownOpen);

          // { minHeight: `${window.innerHeight/16-15.5}rem`, maxHeight: `${window.innerHeight/16-15.5}rem` }
  return (    
    <>
    <PageHeader title="Advanced Search" className="mb-2">
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Advanced Search</Breadcrumb.Item>
      </Breadcrumb>
    </PageHeader>
    <CardGroup>
    <Card>
      <Card.Body
       
      style={
        
        // { minHeight: `505px`, maxHeight: `${window.innerHeight/16-15.5}rem` }
          { minHeight: `${minFilterHeight}px` }
       }
       >
        <Container fluid>
          <Row className='mb-3 gx-3'>
          <Col xs={12} sm={12} md={7} lg={8} xl={9}>
            <Row className='mb-3 gx-3'>
                <Form className="position-relative">        
                  <Form.Control
                    type="search"
                    placeholder="Search..."
                    className="search-input"
                    value={searchInputValue}
                    onClick={()=> { 
                      setOpenFilter(true);
                      }}                    
                    onChange={onChangeSearchInput}
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                  />
                </Form>              
              </Row>
     
            {screenWidth <= searchBreakpoint && openFilter && !searchInputValue &&
              <Row className='mb-3 gx-3'>
                <SearchFilter criteria={criteria} onClickApply={onClickApply} onClickReset={onClickReset} />
              </Row>  
            }
              <Row className='g-0'>
                <div className="scrollbar py-3" 
                style={{minHeight: `${minFilterHeight-90}px`, maxHeight: `${window.innerHeight/16 - 15.5 - 6}rem` }}
                >
                  {isIterableArray(searchResult) && searchResult.length > 0 &&
                    searchResult.map((item, index) => (
                      <div key={index}>
                        <div>
                          <font size="4"><Link to={item.url}>{item.text}</Link></font>&nbsp;&nbsp;&nbsp; 
                          <SoftBadge
                      bg={item.key === 'Report'? 'success' : item.key === 'Dashboard'? 'info' : item.key === 'KPI'? 'primary'  : item.key === 'Analytics'? 'warning': 'secondary'}
                      className="fw-medium text-decoration-none me-2"
                    >
                      {
                        item.key 
                      } {' '}
                    </SoftBadge>
                          
                           <br />
                          <span><font color="grey">{item.description}</font></span><br />                          
                          <span>
                            <font color="grey">Domain: </font><font color="green">{item.domain}</font>
                            {item.subdomain && <> | <font color="grey">Sub-Domain: </font><font color="green">{item.subdomain}</font></>}
                          </span>
                          {/* &nbsp;&nbsp;&nbsp;&nbsp; <span><font color="grey">Type: </font>
                            {item.key === "Dashboard" && <font color="blue">{item.key}</font>} 
                            {item.key === "Report" && <font color="green">{item.key}</font>} 
                            {item.key === "Analytics" && <font color="orange">{item.key}</font>} 
                            </span> */}
                          <br />
                          <span><font color="grey">Owner: </font><font color="grey">{item.owner}</font></span> 
                        </div>
                        <hr />
                      </div>
                    ))
                  }
                </div>
                  
            </Row>
          </Col>
          {screenWidth > searchBreakpoint && 
          <Col>
              <SearchFilter style={{minHeight:`${window.innerHeight/16}rem` }} criteria={criteria} onClickApply={onClickApply} onClickReset={onClickReset} />
          </Col>}
       
          </Row>
        </Container>
      </Card.Body>
    </Card>
    </CardGroup>
      </>
  );
};


export default AdvancedSearch;