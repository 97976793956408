/* eslint-disable prettier/prettier */
//Report Viewer source
import '@boldreports/javascript-reporting-controls/Scripts/bold.report-viewer.min';

import '@boldreports/javascript-reporting-controls/Scripts/bold.report-designer.min';
import '@boldreports/javascript-reporting-controls/Content/material/bold.reports.all.compatibility.min.css';
import '@boldreports/javascript-reporting-controls/Content/material/bold.reportdesigner.compatibility.min.css';

//Data-Visualization
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.bulletgraph.min';
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.chart.min';
//Reports react base
import '@boldreports/react-reporting-components/Scripts/bold.reports.react.min';


import boldReportService from '../../services/boldReport-service';
import { Card, Breadcrumb, Row, Col } from 'react-bootstrap';
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import PageHeader from 'components/common/PageHeader';
import kpiService from 'services/kpi-service';
import { setRecentlyBrowsed } from 'helpers/utils';
import '../BoldReports/BoldReport.css';
import  PopoverComponent from 'pages/common/popover';

const BoldReports = () => {
  let params = useParams();
  const kpiId = params.id;

  const isFirstRender = useRef(true);
  const [boldReportToken, setBoldReportToken] = useState('');
  //const [kpiData, setKpiData] = useState([]);
  const [kpiName, setKpiName] = useState("");

  useEffect(() => {
    const boldReportTokenPromise = boldReportService.fetchBoldReportToken();
    const kpiDataPromise = kpiService.getKpiById(kpiId);

    if (isFirstRender.current) {
      isFirstRender.current = false;
      Promise.all([boldReportTokenPromise, kpiDataPromise])
        .then(([boldReportTokenResponse, kpiDataReponse]) => {
          setBoldReportToken(boldReportTokenResponse.token);
          //setKpiData(kpiDataReponse);
          setKpiName(kpiDataReponse.kpiListModel[0].dictionaryName);
          setRecentlyBrowsed(
            'KPI',
            kpiDataReponse.kpiListModel[0].dictionaryName,
            `kpi/kpiViewer/${kpiId}`
          );
        })
        // .catch(error => {
        //   console.error(error.status);
        //   // if (error.status !== 200) {
        //   window.location.href = '/errors/501x';
        //   // }
        // });
    } else {
      window.location.reload();
    }
  }, [kpiId]);

  const reportServiceUrl =
    'https://hemetrics-report.azurewebsites.net/reporting/reportservice/api/Viewer';
  const reportServerUrl =
    'https://hemetrics-report.azurewebsites.net/reporting/api/site/hemprod';
    const reportPathKpi = '/Data Governance/13616 KPI Viewer';
    var parameters = [{
      Name: 'DictionaryId',
      Values: [kpiId]
      }];
    const kpiViewerDesc = "Dictionary Viewer allows to view all the information of a selected dictionary."
  return (
    <>
     <PageHeader title={"Dictionary Viewer: " + kpiName} className="mb-2" col="12">
        <Row>
            <Col className="text-start mt-1">
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Dictionary Viewer</Breadcrumb.Item> 
              </Breadcrumb>
            </Col>
            <Col className="text-end mt-n2">
              <PopoverComponent 
                header = "Enterprise Data Governance"
                body = {kpiViewerDesc}
              />
          </Col>
        </Row>
      </PageHeader>
      <div>
      
        {boldReportToken && (
          <Card className="h-100">
            <Card.Body className="p-0">
              {/* eslint-disable-next-line */}
             <BoldReportViewerComponent
                id="reportviewer-container"
                reportServiceUrl={reportServiceUrl}
                reportServerUrl={reportServerUrl}
                serviceAuthorizationToken={boldReportToken}
                reportPath={reportPathKpi}
                parameters = {parameters}
              />
            </Card.Body>
          </Card>
        )}
      </div>
    </>
  );
};

export default BoldReports;
