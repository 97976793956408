/* eslint-disable prettier/prettier */
import { faInfoCircle, faWrench, faUserCog, faPoll } from "@fortawesome/free-solid-svg-icons";
import AuthService from "services/auth-service";
export const reportsRoutes = {
  label: 'Reports',
  icon: faPoll,
  children: [   
    {
      name: 'Reports',
      to: '/report/browser',
      active: true,      
      permissions: [AuthService.claims.all]
    }
  ]
};

export const servicesRoutes = {
  label: 'Services',
  icon: faUserCog,
  children: [
    {
      name: 'Applications',
      // to:"/",
      active: true,      
      children: [
        {
          name: 'File Share',
          to: '/BlobFileShare',
          active: true,                
          permissions: [AuthService.claims.all]
          // permissions: [AuthService.claims.viewReportStudio]
        },
        {
          name: 'NYBC Blood Loss', 
          to: '/blood-loss',
          active: true,
          permissions: [AuthService.claims.viewBloodLossReports]
        },
        {
          name: 'Inventory Projection Model', 
          to: '/plateletWIFA/WIFAHome',
          active: true,
          permissions: [AuthService.claims.viewWIFA]
        } 
      ]
    },
    {
      name: 'Data Governance',
      to:"/",
      active: true,
      children: [
        {
          name: 'Data Glossary',   
          to: '/governance/dataGlossaryHome',
          active: true,          
          permissions: [AuthService.claims.viewDataGovernance]
        },
        {
          name: 'Enterprise Data Governance',
          to: '/governance/activeGovernanceLandPage',
          active: true,
          
          // permissions: [AuthService.claims.all]
          permissions: [AuthService.claims.viewDataGovernance]
        },
        {
          name: 'Data & Report Refresh', 
          to: '/dataRefresh/dataRefresh',
          active: true,
          permissions: [AuthService.claims.viewDataRefresh]
        },
        {
          name: 'Manage Data Dictionary',
          to: '/kpi/kpi',
          active: true,
          permissions: [AuthService.claims.viewKpi]
        }  
      ]
    },
  ]
};


export const settingsRoutes = {
  label: 'Settings',
  icon: faWrench,
  children: [    
    {
      name: 'Manage Users',
      
      to: '/admin/UserBrowser',
      active: true,
      //permissions: [AuthService.claims.all]
      permissions: [AuthService.claims.fetchUsers, AuthService.claims.viewUserDetails]
    },
    {
      name: 'Manage Roles',
      
      to: '/admin/UserRoles',
      active: true,
      //permissions: [AuthService.claims.all]
      permissions: [AuthService.claims.fetchRoles] 
    },
    {
      name: 'Manage Catalogs',
      
      to: '/admin/catalogs',
      active: true,
      permissions: [AuthService.claims.fetchCatalogs]
    },
    {
      name: 'Catalog Email Notification',
      
      to: '/admin/catalogEmailNotification',
      active: true,
      permissions: [AuthService.claims.fetchCatalogs]
    },
    // {
    //   name: 'Catalog Email Notification',
      
    //   to: '/admin/catalogEmailNotification',
    //   active: true,
    //   permissions: [AuthService.claims.fetchCatalogs]
    // },
    {
      name: 'Release Manager',
      
      to: 'release/browser',
      active: true,
      permissions: [AuthService.claims.editReleases]
    },
   
  ]
};

export const documentationRoutes = {
  label: 'documentation',
  icon: faInfoCircle,
  children: [    
    // {
    //   name: 'EADS Wiki',
    
    //   to: '/wiki',
    //   active: true,
    //   permissions: [AuthService.claims.all]
    // },
    {
      name: 'FAQ',
    
      to: '/faq',
      active: true,
      permissions: [AuthService.claims.all]
    },
  {
      name: 'Master Control',
      active: true,
      permissions: [AuthService.claims.all],
      children: [
        {
          name: 'Hemetrics',
          to: '/hemetricsSop',
          active: true,
          permissions: [AuthService.claims.all]
        } ,
        // {
        //   name: 'Hemetrics Administration',
        //   to: '/sop',
        //   active: true,
        //   permissions: [AuthService.claims.all]
        // },
        {
          name: 'NYBC Blood Loss',
          to: '/bloodLossSop',
          active: true,
          permissions: [AuthService.claims.all]
        },
        {
          name: 'Customer MDM',
          to: '/customerMDMSop',
          active: true,
          permissions: [AuthService.claims.all]
        },
        {
          name: 'Data Governance',
          to: '/dataGovernanceSop',
          active: true,
          permissions: [AuthService.claims.all]
        }   
      ]
    }
   
  ]
};

export default [
  reportsRoutes,
  // fileShareRoutes,
  // dataGovernanceRoutes,
  servicesRoutes,
  settingsRoutes,
  documentationRoutes
];
