/* eslint-disable prettier/prettier */
import BaseService from './base-service';
import { powerBiAPIConfig } from 'authConfig'
import { getPowerBiAPIToken } from '../msalToken';

const rootPath = powerBiAPIConfig.baseURL; // 'https://powerbi-qa-api.azurewebsites.net/api';

// const rootPath = 'https://localhost:44334/api';

class BIService extends BaseService {
    fetchGroups() {
        return getPowerBiAPIToken().then(token => {
            const headers = [];
            headers.push({Authorization: `Bearer ${token}`});                
            return this._apiGet({
                headers,
                rootPath,
                path: `/bi/groups`
            });
          });          
    }

    fetchReports(groupId) {
        return getPowerBiAPIToken().then(token => {
            const headers = [];
            headers.push({Authorization: `Bearer ${token}`});                    
            return this._apiGet({
                headers,
                rootPath,
                path: `/bi/groups/${groupId}/reports`
            });
        });    
    }

    getEmbeddedReport(groupId, reportId) {
        return getPowerBiAPIToken().then(token => {
            const headers = [];
            headers.push({Authorization: `Bearer ${token}`});         
            return this._apiGet({
                headers,
                rootPath,
                path: `/bi/groups/${groupId}/reports/${reportId}/embedded`
            });
        });    
    }
}

export default new BIService();