/* eslint-disable prettier/prettier */
import React, {useState, useLayoutEffect } from 'react';
//import WeeklySales from 'components/dashboards/default/WeeklySales';
import { Row, Col } from 'react-bootstrap';
import WelcomeCard from './WelcomeCard';
//import QuickLinksCard from './QuickLinksCard';
import RecentActivityCard from './RecentActivityCard';
//import WeeklyCollectionTrends from './WeeklyCollectionTrends';
import WeeklyCollectionTrendsRP from './WeeklyCollectionTrendsRP';
import BookedAppointments from './BookedAppointments';
import BloodProductInventory from './BloodProductInventory';
import ProjectLocation from './project-location/ProjectLocation';
//import {markers} from 'data/dashboard/projectManagement';
import homeService from 'services/home-service';
// import { saleItems } from 'data/dashboard/ecom';
// import EcomStat from 'components/dashboards/e-commerce/EcomStat';
import CollectionBudget from './CollectionBudget';
import DistributionBudget from './DistributionBudget';
import { Helmet } from 'react-helmet';

const Home = () => {
  const [drivesData, setDrivesData] = useState([]);
  const [drivesDetails, setDrivesDetails] = useState([]);
  //const [collectionBudget, setCollectionBudget] = useState([]);
  var driveData =[];
  useLayoutEffect (() => {
    // id: 1,
    // lat: 52.958332,
    // long: -1.080278,
    // name: 'Diana Meyer',
    // street: 'Slude Strand 27',
    // location: '1130 Kobenhavn'
    var drivesLocationPromise = homeService.getDrivesLocation();

    var drivesScheduledPromise = homeService.getDrivesScheduled();

    //var collectionBudgetPromise = homeService.getCollectionBudget();

    Promise.all([drivesLocationPromise, drivesScheduledPromise])
      .then(([drivesLocation, drivesScheduled]) => {
       
        drivesLocation.map(a=> { driveData.push({
          id: a.driveKey,
          division: a.division,
          lat: a.siteLatitude,
          long: a.siteLongitude,
          name: a.siteName,
          street: a.siteStreetAddress1,
          location: a.siteStreetAddress2,
          driveType: a.driveType,
          drbc: a.drbcProjected,
          wb: a.wbProjected,
          plt: a.pltProjected,
          pls: a.plsProjected
    
        }) } );
        setDrivesData(driveData);

        setDrivesDetails(drivesScheduled);
        //setCollectionBudget(collectionBudget);

      }).catch((err) => {
        console.log("Landing Page Error:" + err);
      });
  }, []);

  return (
    <>
    <Helmet>
      <title>Hemetrics Home</title>
    </Helmet>
    <Row className="g-3 mb-3">
        <Col md={12} xxl={12}>
          <WelcomeCard />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={6} xxl={6}>
          <Row className="g-3 mb-3">
              <Col md={6} xxl={6}>
                <BookedAppointments/>
              </Col>
              <Col md={6} xxl={6}>
                <BloodProductInventory/>
              </Col>       
            
          </Row>
          <Row className="g-3 mb-0">
            {/* <EcomStat data={saleItems} /> */}
            <Col md={6} xxl={6}>
                <CollectionBudget/>
              </Col> 
              <Col md={6} xxl={6}>
                <DistributionBudget/>
              </Col> 
          </Row>
        </Col>
        {/* <Col md={4} xxl={4}>
          <BloodProductInventory/>
        </Col> */}
        <Col md={6} xxl={6}>
          <WeeklyCollectionTrendsRP />
        </Col>
        {/* <Col md={6} xxl={6}>
          <WeeklyCollectionTrends />
        </Col> */}
      </Row>
      <Row className="g-3 mb-3">
        <Col md={6} xxl={6}>
            <ProjectLocation data={drivesData} drivesDetails={drivesDetails} />
          </Col>
        <Col md={6} xxl={6}>
          <RecentActivityCard />
        </Col>
      </Row>
    </>
  );
};

export default Home;
