/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
//import { Link } from 'react-router-dom';
import homeService from 'services/home-service';
import Background from 'components/common/Background';
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
//import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';

const BookedAppointments = () => {

  const profile = JSON.parse(localStorage.getItem('userProfile'));
  var emailSplit = profile.email.split('@');
  const NameForTotal = 'NYBCe';
  const [divisions, setDivisions] = useState([]); //['NYBCe','NYBC','IBR','RIBC'];
  const [allData, setAllData] = useState([]);
  const [oneDivision, setOneDivision] = useState(profile.isEnterpriseView ? NameForTotal : emailSplit[1].split('.')[0].toUpperCase());
  const [dataWB, setDataWB] = useState(0);
  const [dataDRBC, setDataDRBC] = useState(0);
  const [dataPlasma, setDataPlasma] = useState(0);
  const [dataPlatelets, setDataPlatelets] = useState(0);
  // const [dataOthers, setDataOthers] = useState(0);
  const sortOrder = [NameForTotal,'NYBC','CBC', 'IBR', 'RIBC', 'BBD'];
  useEffect(() => {
    homeService.getAppointmentsScheduled().then(result => {
        //setAppointments(result);
        const temp = result.map(a=> { return {procedureGroup: a.procedureGroup, appointments: a.appointments} } );

          //sum up all units by phlebotomy and collectionWeek
        const sumOfUnits = Array.from(temp.reduce((acc, {appointments, ...r}) => {
          const key = JSON.stringify(r);
          const current = acc.get(key) || {...r, appointments: 0 };
          return acc.set(key, {...current, appointments: current.appointments + appointments});
        }, new Map).values());

        const allData = sumOfUnits.map(a => ({ division: NameForTotal, ...a }));
        // console.log(allData);
        allData.map(a=> result.push(a));
        setAllData(result);
        var divisionValues = [...new Set(result.map(a => a.division))];
        divisionValues.sort(
          function(a, b){                
              return sortOrder.indexOf(a) - sortOrder.indexOf(b); 
          }
        );
        setDivisions(divisionValues);      
        //setDivisions([...new Set(result.map(a => a.division))]);       

        setDataWB(result.filter(a=>a.procedureGroup === 'WB' && a.division === oneDivision).map(a=>a.appointments));        
        setDataDRBC(result.filter(a=>a.procedureGroup === 'DRBC' && a.division === oneDivision).map(a=>a.appointments));
        setDataPlasma(result.filter(a=>a.procedureGroup === 'Plasma' && a.division === oneDivision).map(a=>a.appointments));
        setDataPlatelets(result.filter(a=>a.procedureGroup === 'Platelets' && a.division === oneDivision).map(a=>a.appointments));
        // setDataOthers(result.filter(a=>a.procedureGroup === 'Other' && a.division === oneDivision).map(a=>a.appointments));
        
    }).catch((err) => {
      console.log("Landing Page Error:" + err);
    })
    // .finally(()=>{

    // });
  }, []);

  function onChangeDivision (e) {
    setOneDivision(e.target.value);
    const divisionData = allData.filter(a=>a.division === e.target.value);
    // setLabels([...new Set(divisionData.map(a => moment(a.collectionWeek).format('MM-DD')))]);
    // setDataPLT(divisionData.filter(a=>a.phlebotomy === 'Platelets').map(a=>a.units));        
    // setDataRBC([...new Set(divisionData.filter(a=>a.phlebotomy === 'Red Blood Cells').map(a=>a.units))]);
    // setDataPlasma([...new Set(divisionData.filter(a=>a.phlebotomy === 'Plasma').map(a=>a.units))]);
    setDataWB(divisionData.filter(a=>a.procedureGroup === 'WB').map(a=>a.appointments));        
    setDataDRBC(divisionData.filter(a=>a.procedureGroup === 'DRBC').map(a=>a.appointments));
    setDataPlasma(divisionData.filter(a=>a.procedureGroup === 'Plasma').map(a=>a.appointments));
    setDataPlatelets(divisionData.filter(a=>a.procedureGroup === 'Platelets').map(a=>a.appointments));
    // setDataOthers(divisionData.filter(a=>a.procedureGroup === 'Other').map(a=>a.appointments));
  }

  return (
    <Card className="h-md-100">      
      <Background image={bg1} className="p-card bg-card" />
      <div className="position-relative z-index-0">
        <FalconCardHeader
            title= { <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>Shows all appointments for today by the phlebotomy group. Excludes appointments with the status of Canceled, Rescheduled, and Invalid.</Tooltip>
            }
          >
            <span> Appointments Today
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                transform="shrink-1"
                className="ms-1 text-400"
                id="weeklySalesTooltip"
              />
            </span>
          </OverlayTrigger>}
            titleTag="h6"          
            className="border-200 border-bottom py-2"
            light
            endEl={
              <Flex>
                <Form.Select
                  size="sm"
                  value={oneDivision}
                  onChange={onChangeDivision}
                  //className="w-auto"
                >
                  {divisions.map((label) => (
                    <option value={label} key={label}>
                      {label}
                    </option>
                  ))}
                </Form.Select>
              </Flex>
            }
          />
        </div>
        {/* <Card.Header>
          <div>
          <Row className="flex-between-center g-0">
            <Col xs="auto">
                <h6 className="mb-0">Appointments Today</h6>
              </Col>
              <Col xs="auto" className="d-flex">
                <Form.Select
                  size="sm"
                  value={oneDivision}
                  onChange={onChangeDivision}
                  className="me-2"
                >
                  {divisions.map((label) => (
                    <option value={label} key={label}>
                      {label}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
          </div>
        </Card.Header> */}
      <Card.Body className="pb-2">
        <div className="position-relative z-index-2">
        {/* <div>
          <h6 className="mb-2 mt-0">Appointments Today</h6>
        </div> */}
          <Row className="py-2">
            <Col className="px-4">
              <h6 className='text-warning'>
                WB {dataWB == 0 ? 0 : dataWB.toLocaleString('en-US')}
              {/* <CountUp
                start={0}
                end={dataWB}
                duration={2.75}
                //suffix={suffix}
                prefix= 'WB  '
                separator=","
                decimals={0}
                decimal="."
              /> */}
              {/* {dataWB.length > 0 && dataWB.filter(a => a.procedureGroup == "RBC")[0].appointments} */}
              </h6>
            </Col>
            <Col className="pe-2">
              <h6 className='text-warning'>
                DRBC {dataDRBC == 0 ? 0 : dataDRBC.toLocaleString('en-US')}
                {/* <CountUp
                  start={0}
                  end={dataDRBC}
                  duration={2.75}
                  //suffix={suffix}
                  prefix= 'DRBC  '
                  separator=","
                  decimals={0}
                  decimal="."
                /> */}
              {/* {inventorySupply.length > 0 && inventorySupply.filter(a => a.productType == "RBC")[0].daySupply} */}
              </h6>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col className="px-4">
              <h6 className='pt-2 text-warning'>
              Platelets {dataPlatelets == 0 ? 0 : dataPlatelets.toLocaleString('en-US')}
              {/* <CountUp
                start={0}
                end={dataPlatelets}
                duration={2.75}
                //suffix={suffix}
                prefix= 'Platelets  '
                separator=","
                decimals={0}
                decimal="."
              /> */}
               {/* {appointments.length > 0 && appointments.filter(a => a.procedureGroup == "PLT")[0].appointments} */}
               </h6>
            </Col>
            <Col className="pe-2">
              <h6 className='pt-2 text-warning'>
                Plasma {dataPlasma == 0 ? 0 : dataPlasma.toLocaleString('en-US')}
                {/* <CountUp
                  start={0}
                 end={dataPlasma}
                  duration={2.75}
                  //suffix={suffix}
                  prefix= 'Plasma  '
                  separator=","
                  decimals={0}
                  decimal="."
                /> */}
              {/* {inventorySupply.length > 0 && inventorySupply.filter(a => a.productType == "RBC")[0].daySupply} */}
              </h6>
            </Col>
          </Row>
          <Row className="g-0 align-items-center">
            {/* <Col xs="auto" className='px-3' size="sm">
            <small>Others {dataOthers.toLocaleString('en-US')}</small>
            </Col> */}
            <Col className="text-end">
              <Link to="/dashboards/14312" className="fw-semi-bold fs--1 text-nowrap" size="sm">
                View<FontAwesomeIcon
                  icon= {faAngleDoubleRight}
                  className="ms-1"
                  transform="down-1"
                />
              </Link>
            </Col>
          </Row>
        </div>
      </Card.Body>
            
      {/* <Card.Body>
        <div>
        
        </div>
      </Card.Body> */}
    </Card>
  );
};

export default BookedAppointments;
