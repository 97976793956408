/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleBarReact from 'simplebar-react';
import homeService from 'services/home-service';
import { faPieChart, faPoll, faUserCog, faWrench} from '@fortawesome/free-solid-svg-icons';

const Activity = ({
  activity: { contentName, contentDesc, contentType, timeLagDay, contentStatus },
  isLast
}) => {
  return (
    <Row
      className={classNames(
        'g-3 recent-activity-timeline recent-activity-timeline-primary',
        {
          'pb-card': !isLast,
          'recent-activity-timeline-past': contentStatus === 'Completed'
          //'recent-activity-timeline-current': contentStatus === 'current'
        }
      )}
    >
      <Col xs="auto" className="ps-4 ms-2">
        <div className="ps-2">
          <div className="icon-item icon-item-sm rounded-circle bg-200 shadow-none">
            <FontAwesomeIcon icon={contentType == "Dashboard" ? faPieChart : contentType == "Report" ? faPoll : contentType == "Application" ? faUserCog : faWrench } className="text-primary" />
          </div>
        </div>
      </Col>
      <Col>
        <Row
          className={classNames('g-3', { 'border-bottom pb-card': !isLast })}
        >
          <Col>
            <h6 className="text-800 mb-1">{contentName}</h6>
            <p className="fs--1 text-600 mb-0">{contentDesc}</p>
          </Col>
          <Col xs="auto">
            <p className="fs--2 text-500 mb-0">{timeLagDay + " days ago"}</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const RecentActivityCard = () => {

  const [portalActivity, setPortalActivity] = useState([]);

  useEffect(() => {
    homeService.getPortalActivity().then(setPortalActivity);
  }, []);

  return (
    <Card className="h-100 recent-activity-card">
      <FalconCardHeader title="Recent Portal Activity" titleTag="h6" />
      <SimpleBarReact style={{ height: '26rem' }}>
        <Card.Body className="ps-2 pe-3 recent-activity-body-height">
          {portalActivity.map((activity, index) => (
            <Activity
              key={index}
              activity={activity}
              isLast={index === portalActivity.length - 1}
            />
          ))}
        </Card.Body>
      </SimpleBarReact>
    </Card>
  );
};

Activity.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.number,
    contentName: PropTypes.string,
    contentDesc: PropTypes.string,
    contentType: PropTypes.string,
    timeLagDay: PropTypes.number,
    contentStatus: PropTypes.string
  }),
  isLast: PropTypes.bool
};

export default RecentActivityCard;
