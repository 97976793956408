/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-undef */
import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Card, Row, Col, Breadcrumb } from 'react-bootstrap';
import AdvanceTableWrapper from '../../components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../../components/common/advance-table/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableSearchBoxPlug from '../../components/common/advance-table/AdvanceTableSearchBoxPlug';
// import IconButton from 'components/common/IconButton';
import AdvanceTableFooter from '../../components/common/advance-table/AdvanceTableFooter';
import service from 'services/catalog-service';
import { settings } from '../../config';
import { getItemFromStore } from 'helpers/utils';
import PageHeader from 'components/common/PageHeader';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './admin.scss';
import Enum from 'common/enum';
import { Helmet } from 'react-helmet';

const CatalogBrowser = () => {
  const [catalogs, setCatalogs] = useState([]);
  const configState = {
    gridRowsCount: getItemFromStore('gridRowsCount', settings.gridRowsCount)
  };
  //const [isLoading, setIsLoading] = useState(true); 
  useEffect(() => {
    service
      .fetchCatalogList()
      .then(res => {
        //setIsLoading(false);
        setCatalogs(res);
      })
      .catch(err => {
        //setIsLoading(false);
        console.log(err);
      });
  }, []);

  const onClickNew = () => {
    const catalogId = catalogs.map(a => a.id);
    sessionStorage.setItem(Enum.CACHED_CATALOG_ID, JSON.stringify(catalogId));

    // const catalogId = [];
    // catalogId.push(catalogs.map(catalog => catalog.id));
    // sessionStorage.setItem('allCatalogIds', JSON.stringify(catalogId[0]));
  };
  return (
    <>
    <Helmet>
      <title>Catalog Manager</title>
    </Helmet>
      <PageHeader title="Manage Catalogs" className="mb-2">
      <Breadcrumb>  
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Manage Catalogs</Breadcrumb.Item>
      </Breadcrumb>
      </PageHeader>
      <AdvanceTableWrapper
        columns={columns}
        data={catalogs}
        sortable
        pagination
        perPage={configState.gridRowsCount}
      >
        <Card className="h-100">
          <Card.Header>
            <Row className="flex-between-center">
              <Col xs={9} sm={6} lg={3}>
                <AdvanceTableSearchBoxPlug table placeholder="Search" />
              </Col>
              <Col xs="auto" sm={3} lg={3} className="text-end marginTop">
                <Link
                  className="btn btn-outline-primary"
                  to={'/admin/catalogs/new'}
                  onClick={onClickNew}
                >
                  <FontAwesomeIcon icon={faPlus} /> New
                </Link>
              </Col>
            </Row>
          </Card.Header>
          {/* {
            isLoading ? <div className='text-center'><Spinner animation="border" /></div> : */}
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
            </Card.Body>
          {/* } */}
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={catalogs.length}
              table
              rowInfo
              navButtons
            />
          </Card.Footer>
        </Card>
        <br />
      </AdvanceTableWrapper>
    </>
  );
};
const columns = [
  {
    accessor: 'id',
    Header: 'ID',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'name',
    Header: 'Name',
    Cell: rowData => (
      <Link
        to={`${rowData.row.original.id}`}
        className="text-primary fw-semi-bold"
      >
        {rowData.row.original.name}
      </Link>
    )
  },
  {
    accessor: 'type',
    Header: 'Type',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'domain',
    Header: 'Domain',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'subDomain',
    Header: 'Sub-Domain',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'host',
    Header: 'Host',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  }
];

export default CatalogBrowser;
