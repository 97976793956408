/* eslint-disable prettier/prettier */
//Report Viewer source
import '@boldreports/javascript-reporting-controls/Scripts/bold.report-designer.min';
import '@boldreports/javascript-reporting-controls/Scripts/bold.report-viewer.min';
import '@boldreports/javascript-reporting-controls/Content/material/bold.reports.all.compatibility.min.css';
import '@boldreports/javascript-reporting-controls/Content/material/bold.reportdesigner.compatibility.min.css';

//Data-Visualization
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.bulletgraph.min';
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.chart.min';
//Reports react base
import '@boldreports/react-reporting-components/Scripts/bold.reports.react.min';

import PageHeader from '../../components/common/PageHeader';
import boldReportService from '../../services/boldReport-service';
import { Card, Breadcrumb, Row, Col } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import './blood-loss.scss';
// import  PropTypes  from 'prop-types';
import { useParams } from 'react-router-dom';
// import bloodLossService from 'services/blood-loss-service';
import { Helmet } from 'react-helmet';

const BloodLossHistoryReport = () => {
  const [boldReportToken, setBoldReportToken] = useState('');
  const [isMobile, SetIsMobile] = useState(false);
  const { id, reportDate } = useParams();
  // const [parameters, setParameters] = useState([]);
  
  const breadcrumbLink = `/blood-loss/report/${id}`;
  var parameters = [{
    Name: 'ReportDate',
    Values: [reportDate.replaceAll('-', '/')]
  }];

  // const isFirstRender = useRef(true);
  if (window.innerWidth < 700) {
    SetIsMobile(true);
  }

  useEffect(() => {    
    boldReportService.fetchBoldReportToken().then(response => {
        setBoldReportToken(response.token);
      }).catch(error => {
        console.log(error);
      });

    if (window.innerWidth < 700) {
        SetIsMobile(true);          
      }
}, []);

  const reportPath = 'Blood Loss/Blood Loss Audit';
  const reportServiceUrl = 'https://hemetrics-report.azurewebsites.net/reporting/reportservice/api/Viewer';
  const reportServerUrl = 'https://hemetrics-report.azurewebsites.net/reporting/api/site/hemprod';

  return (    
      <div>
        <Helmet>
          <title>Audit History</title>
        </Helmet>
        {reportPath && (
          <PageHeader            title="Audit"            className="mb-2"            col="12"          >
            {reportPath && !isMobile && (
              <Row>
                <Col className="text-start mt-1">
                  <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/blood-loss">NYBC Blood Loss</Breadcrumb.Item>
                    <Breadcrumb.Item href={breadcrumbLink}>Report</Breadcrumb.Item>                    
                    <Breadcrumb.Item active>Audit</Breadcrumb.Item>
                  </Breadcrumb>
                </Col>
               
              </Row>
            )}
            {reportPath && isMobile && (
              <>
                <Row>
                  <Col className="text-start">
                    <Breadcrumb>
                      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                      <Breadcrumb.Item active>Report Browser</Breadcrumb.Item>
                    </Breadcrumb>
                  </Col>
                </Row>                
              </>
            )}
          </PageHeader>
        )}
        {boldReportToken && reportPath && (
          <Card className="h-100">
            <Card.Body className="p-0">
              {/* eslint-disable-next-line */}
             <BoldReportViewerComponent 
                id="reportviewer-container"
                parameters={parameters}
                reportServiceUrl={reportServiceUrl}
                reportServerUrl={reportServerUrl}
                serviceAuthorizationToken={boldReportToken}
                reportPath={reportPath}
              />
            </Card.Body>
          </Card>
        )}
      </div>
      
    
  );
};

// BloodLossAuditReport.propTypes = {
//   reportDate: PropTypes.string.isRequired,
//   // reportId: PropTypes.string.isRequired
// };

export default BloodLossHistoryReport;
