/* eslint-disable prettier/prettier */
import { faExclamationCircle, faTimes, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import authService from '../../services/auth-service';
import { Form, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './blood-loss.scss';

const VALIDATION = {
  fullName: value => {
    return value && value.length > 0 && value === GetUserName()
      ? { isValid: true, message: '' }
      : {
          isValid: false,
          message: `The name field is required and must match your name. (${GetUserName()})`
        };
  },
  todaysDate: value => {
    return value &&
      value.length > 0 &&
      value ===
        new Date(Date.now()).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
        })
      ? { isValid: true, message: '' }
      : {
          isValid: false,
          message: `The date field is required and must match today's date.  (${new Date(
            Date.now()
          ).toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
          })})`
        };
  }
};

function GetUserName() {
  const profile = authService.getCurrentUserProfile();
  return `${profile.firstName} ${profile.lastName}`;
}

const ReportApprovalModal = props => {

    const [fullName, setFullName] = React.useState(GetUserName());    
    const [todaysDate, setTodaysDate] = React.useState(null);
    const [validateFullName, setValidateFullName] = React.useState(true);
    const [validateTodaysDate, setValidateTodaysDate] = React.useState(false);
    const [comment, setComment] = React.useState('');
    const [validationErrors, setValidationErrors] = React.useState({});
    const [disableApprove, setDisableApprove] = React.useState(true);
//   const handleChange = event => {
//     const name = event.target.name;
//     const value =
//       event.target.type === 'checkbox'
//         ? event.target.checked
//         : event.target.value;

//     // this.setState({
//     //   [name]: value
//     // });
//   };


const onBlurName = event => {
    const name = event.target.name;
    const validationMethod = VALIDATION[name];
    if (validationMethod) {
      const result = validationMethod(event.target.value);
      if (!result.isValid) {
        setValidationErrors({ ...validationErrors, [name]: result.message });
        setValidateFullName(false);
        setDisableApprove(true);
      } else {
        setValidationErrors({ ...validationErrors, [name]: '' });
        setValidateFullName(true);
        if (validateTodaysDate) {
            setDisableApprove(false);
        }
        else
        {
            setDisableApprove(true);
        }      
      }
    }
  }; // end onBlur
  const onBlurDate = event => {
    const name = event.target.name;

    const validationMethod = VALIDATION[name];
    if (validationMethod) {
      const result = validationMethod(event.target.value);
      if (!result.isValid) {
        setValidationErrors({ ...validationErrors, [name]: result.message });
        setValidateTodaysDate(false);
        setDisableApprove(true);
      } else {
        setValidationErrors({ ...validationErrors, [name]: '' });
        setValidateTodaysDate(true);        
        if (validateFullName) {
            setDisableApprove(false);
        }
        else
        {
            setDisableApprove(true);
        }
      }
    }
  }; // end onBlur

  const submitForApproval = () => {
    if (validateFullName && validateTodaysDate) {      
        props.approve(comment);
    } 
  }; // end submitForApproval

    return (
        <Modal show={props.visible} backdrop="static" keyboard={false}>
        <Modal.Header  className="d-flex justify-content-center">
        <Modal.Title>
                        <Row><span  className='text-center' style={{ fontSize: "68px" }}> <FontAwesomeIcon className="modal-icon" icon={faExclamationCircle} /> </span> </Row>
                        <Row>Warning!</Row>                        
                    </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="no-margins">
            Please enter details below to confirm that you want to approve this
            Blood Loss Report.
          </p>
          <div
            className={`form-group${
              validationErrors['fullName'] ? ' has-error' : ''
            }`}
          >
            <label>Enter your name. (*First name* *Last name*)</label>
            <input
              type="text"
              name="fullName"
              onChange={(e) => setFullName(e.target.value)}
              onBlur={onBlurName}
              value={fullName || ''}
              className="form-control"
            />
            {validationErrors['fullName'] && (<span className="form-text m-b-none validation-error-msg"> {validationErrors['fullName']} </span>   )}
          </div>
          <div
            className={`form-group${
              validationErrors['todaysDate'] ? ' has-error' : ''
            }`}
          >
            <label>Enter today's date. (MM/DD/YYYY)</label>
            <input
              type="text"
              name="todaysDate"
              onChange={(e) => setTodaysDate(e.target.value)}   
              onBlur={onBlurDate}
              value={todaysDate || ''}
              className="form-control"
            />
            {validationErrors['todaysDate'] && (
              <span className="form-text m-b-none">
                {validationErrors['todaysDate']}
              </span>
            )}
          </div>
          <Form.Group>
            <Form.Label>Comments</Form.Label>
            <Form.Control
                 type='textarea'
                    name="comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    rows="6"
                    style={{ resize: 'none' }}
                    >
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
        <button          className="btn btn-outline-primary me-1 mb-1"  onClick={props.hide}            >
              <FontAwesomeIcon icon={faTimes} /> Cancel
            </button>
          <button            type="button"   disabled={disableApprove}         className="btn btn-primary"            onClick={submitForApproval}          >
             <FontAwesomeIcon icon={faThumbsUp} />             Approve
          </button>
        </Modal.Footer>
      </Modal>
    );
}; // end ReportApprovalModal

  ReportApprovalModal.propTypes = {
    // props: PropTypes.shape({
    visible: PropTypes.bool,
    hide: PropTypes.func,
    approve: PropTypes.func
    };
    // )};
export default ReportApprovalModal;
