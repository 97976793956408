import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Breadcrumb } from 'react-bootstrap';
import AdvanceTableWrapper from '../../components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../../components/common/advance-table/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableSearchBox from '../../components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from '../../components/common/advance-table/AdvanceTableFooter';
import userService from 'services/user-service';
import { settings } from '../../config';
import { getItemFromStore } from '../../helpers/utils';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageHeader from 'components/common/PageHeader';
import './admin.scss';
import { Helmet } from 'react-helmet';

const columns = [
  {
    accessor: 'roleId',
    Header: 'RoleId',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'roleName',
    Header: 'RoleName',
    Cell: rowData => (
      <Link
        to={`${rowData.row.original.roleId}`}
        className="text-primary fw-semi-bold"
      >
        {rowData.row.original.roleName}
      </Link>
    )
  },
  {
    accessor: 'domainName',
    Header: 'DomainName',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  }
];

const UserRoleBrowser = () => {
  const [roles, setRoles] = useState([]);
  const configState = {
    gridRowsCount: getItemFromStore('gridRowsCount', settings.gridRowsCount)
  };
  useEffect(() => {
    userService
      .fetchManageRoles()
      .then(res => {
        setRoles(res);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Manage Roles</title>
      </Helmet>
      <PageHeader title="Manage Roles" className="mb-2">
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Manage Roles</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>
      <AdvanceTableWrapper
        columns={columns}
        data={roles}
        sortable
        pagination
        perPage={configState.gridRowsCount}
      >
        <Card className="h-100">
          {/* <Card.Header>
          <Row className="flex-between-center">
            <Col xs="auto" sm={6} lg={2}>
              <h6 className="mb-0 text-nowrap py-2 py-xl-0">Manage Roles</h6>
            </Col>
            <Col xs="auto" sm={6} lg={2}>
              <Link
                className="btn btn-outline-primary me-1 mb-1"
                to={'/admin/NewUserRoles'}
              >
                <FontAwesomeIcon icon={faPlus} /> New
              </Link>
            </Col>
            <Col xs="auto" sm={6} lg={5}>
              <AdvanceTableSearchBox table placeholder="Search for a page" />
            </Col>
          </Row>
        </Card.Header> */}
          <Card.Header>
            <Row className="flex-between-center">
              <Col xs={9} sm={6} lg={3}>
                <AdvanceTableSearchBox table placeholder="Search" />
              </Col>
              <Col xs="auto" sm={3} lg={3} className="text-end marginTop">
                <Link
                  className="btn btn-outline-primary me-1 mb-1"
                  to={'/admin/NewUserRoles'}
                >
                  <FontAwesomeIcon icon={faPlus} /> New
                </Link>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={roles.length}
              table
              rowInfo
              navButtons
            />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

UserRoleBrowser.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      roleId: PropTypes.number.isRequired,
      roleName: PropTypes.string.isRequired,
      domainName: PropTypes.string.isRequired
    })
  ),
  perPage: PropTypes.number
};

export default UserRoleBrowser;
