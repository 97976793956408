import React from 'react';
import ProfileBanner from './Banner';
import ProfileSettings from '././ProfileSettings';
import ProfileRole from '././ProfileRole';
import { Col, Row } from 'react-bootstrap';

const Profile = () => {
  return (
    <>
      <ProfileBanner />
      <Row className="g-3 mb-3">
        <Col lg={8}></Col>
        <Col lg={4}></Col>
      </Row>
      <ProfileSettings />
      <Row className="g-3 mb-3">
        <Col lg={8}></Col>
        <Col lg={4}></Col>
      </Row>

      <ProfileRole />
    </>
  );
};

export default Profile;
