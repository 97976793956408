/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Breadcrumb } from 'react-bootstrap';
import userService from '../../services/user-service';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { faSave, faUser, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import PageHeader from 'components/common/PageHeader';
import catalogService from 'services/catalog-service';
import notificationService from 'services/notification-service';
import ConfirmationMessage from 'components/common/ConfirmationMessage';
import { Helmet } from 'react-helmet';

const UserEditor = () => {
const profile = JSON.parse(localStorage.getItem('userProfile'));
  let params = useParams();
  const [users, setUsers] = useState([]);
  const [assignedRoles, setAssignedRoles] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    //loadUsers
    const selectedUserPromise = userService.getUser(params.userId);
    //loadRoles
    const allRolesPromise = userService.fetchAllRoles();
    //LoadUserRoles
    const allAssignedRolesPromise = userService.fetchUserRoles(params.userId);
    Promise.all([
      selectedUserPromise,
      allRolesPromise,
      allAssignedRolesPromise
    ]).then(([selectedUser, allRoles, allAssignedRoles]) => {
      selectedUser['middleName'] =
        selectedUser['middleName'] == null ? '' : selectedUser['middleName'];
      selectedUser['assignedRoles'] =
        selectedUser['assignedRoles'] == null
          ? ''
          : selectedUser['assignedRoles'];
      allRoles.forEach(item => {
        renameKey(item, 'id', 'value');
        renameKey(item, 'name', 'label');
      });
      allAssignedRoles.forEach(item => {
        renameKey(item, 'id', 'value');
        renameKey(item, 'name', 'label');
      });

      setUsers(selectedUser);
      setRoles(allRoles);
      setAssignedRoles(allAssignedRoles);
      //setValue(allAssignedRoles);
    });
  }, []);

  function renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }

  const handleSubmit = e => {
    e.preventDefault();
    const validate = validateFields();
    if (validate === false) {
      return;
    }
    if (e.target.value == 'save') {
      assignedRoles.forEach(item => {
        renameKey(item, 'value', 'id');
        renameKey(item, 'label', 'name');
        item['rolePermissions'] = [];
        item['users'] = [];
      });
      userService.addUserRoles(assignedRoles, params.userId).then(() => {
        // toast('User Role(s) Saved Successfully!', {
        //   autoClose: true,
        //   className: 'toast-container success'
        // });
        toast(ConfirmationMessage("User Roles Saved", "User Role(s) Saved Successfully!", "bg-success", "faCheckCircle"), {
          autoClose: true,
          //className: 'transparent',
          //style: {{background-color: transparent !important}},
          position: 'bottom-right',
          hideProgressBar: true,
          closeButton: false,
        });

        const listOfRoles =assignedRoles.reduce(function (a, b) { return (a.name || a) + ', ' +  b.name});
        const messageBody = `Your current roles are ${listOfRoles}.` ;       
        let recieptients = [];
        recieptients.push(users.email);
        const notificationRequest = { applicationName:"Hemetrics", type: "role", messageHeader: "Role(s) has Changed. Please refresh your page", messageContent: messageBody, createdBy:profile.email, recipientEmailList: recieptients , recipientEmailAddress: ""};
        
        notificationService.AddNotificationToSpecificUser(notificationRequest)
        .then(() => {
          catalogService.refreshCatalogCache()
          .then(()=>{          
              window.location.href = '/admin/userBrowser';
          })
        })        
      });
    }

    if (e.target.value == 'delete') {
      // Delete User Functionality
      userService
        .deleteUser(users)
        .then(() => {
          // toast(
          //   `User ${users.isActive ? 'Deleted' : 'Activated'} Successfully!`,
          //   {
          //     autoClose: true,
          //     className: 'toast-container success'
          //   }
          // );
          toast(ConfirmationMessage(`User ${users.isActive ? 'Deleted' : 'Activated'}`, `User ${users.isActive ? 'Deleted' : 'Activated'} Successfully!`, "bg-success", "faCheckCircle"), {
            autoClose: true,
            //className: 'transparent',
            //style: {{background-color: transparent !important}},
            position: 'bottom-right',
            hideProgressBar: true,
            closeButton: false,
          });
        })
        .then(() => {
          window.location.href = '/admin/userBrowser';
        });
    }
  };

  const [validateRoles, setValidateRoles] = useState(true);
  const validateFields = () => {
    if (assignedRoles.length > 0) {
      setValidateRoles(true);
    } else {
      setValidateRoles(false);
      return false;
    }
    return true;
  };

  return (
    <>
      <Helmet>
        <title>Manage Users</title>
      </Helmet>    
      <Card>
        {/* <FalconCardHeader title="Edit User" /> */}
        <PageHeader title="Edit User" className="mb-2">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/admin/userbrowser">
              Manage Users
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Edit</Breadcrumb.Item>
          </Breadcrumb>
        </PageHeader>
        <Card.Body className="bg-light">
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6} controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  value={users.firstName}
                  name="firstName"
                  //onChange={handleChange}
                  disabled
                  readOnly
                />
              </Form.Group>

              <Form.Group as={Col} lg={6} controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  value={users.lastName}
                  name="lastName"
                  //onChange={handleChange}
                  disabled
                  readOnly
                />
              </Form.Group>
            </Row>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6} controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={users.email}
                  name="email"
                  //onChange={handleChange}
                  disabled
                  readOnly
                />
              </Form.Group>
            </Row>
            <Form.Label>Assign Roles</Form.Label>
            <Select
              className={validateRoles ? '' : 'validation-error'}
              closeMenuOnSelect={false}
              options={roles}
              placeholder="Select User Roles"
              isMulti
              //classNamePrefix="react-select"
              value={assignedRoles}
              onChange={value => setAssignedRoles(value)}
            ></Select>
            {!validateRoles && (
              <span className="validation-error-msg">
                Please fill out this field.
              </span>
            )}
            <br />
            <div className="text-end">
              <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={handleSubmit}
                value="save"
              >
                <FontAwesomeIcon icon={faSave} /> Save
              </button>

              <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={handleSubmit}
                value="delete"
              >
                <FontAwesomeIcon icon={users.isActive ? faTrashAlt : faUser} />
                {users.isActive ? 'Delete' : 'Activate'}
              </button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default UserEditor;
