/* eslint-disable no-dupe-keys */
/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Nav, Navbar, Row, Col } from 'react-bootstrap';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../../config';
import AppContext from '../../../context/Context';
import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton';
import routes from 'components/navbar/routes';
import { capitalize } from 'helpers/utils';
import NavbarTopDropDownMenus from 'components/navbar/top/NavbarTopDropDownMenus';
import bgNavbar from 'assets/img/generic/bg-navbar.png';
//import authService from 'services/auth-service';
import {setServicesRoutes, setSettingsRoutes} from 'components/navbar/helper';
import  catalogService from 'services/catalog-service';
import Enum from 'common/enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPieChart, faChartLine } from '@fortawesome/free-solid-svg-icons';
import './NavbarVertical.scss';

const NavbarVertical = () => {
const [allRoutes, setAllRoutes] = useState({main:[]});

  useEffect(() => {
    const dashboardRoutes = JSON.parse(window.sessionStorage.getItem(Enum.CACHED_DASHBOARDMENU));
    const analyticsRoutes = JSON.parse(window.sessionStorage.getItem(Enum.CACHED_ANALYTICSMENU));
    //var fixedRoutes = JSON.parse(window.sessionStorage.getItem(Enum.CACHED_FIXED_MENU));
    var fixedRoutes = routes;
    const servicesRoutes = setServicesRoutes(routes[1]);        
    const settingsRoutes = setSettingsRoutes(routes[2]);
    if (servicesRoutes.length === 0 || !servicesRoutes.length)
    {
      fixedRoutes.splice(1,1);
      if (settingsRoutes.length === 0 || !settingsRoutes.length)
      {
        fixedRoutes.splice(1,1);
      }      
      else
      {
        fixedRoutes[1] =  servicesRoutes;
      }
    }
    else
    {
      fixedRoutes[1].children = servicesRoutes;
      if (settingsRoutes.length === 0 || !settingsRoutes.length)
      {
        fixedRoutes.splice(2,1);
      }      
      else
      {
        fixedRoutes[2].children =  settingsRoutes;
      }
    }

  
    if (dashboardRoutes){
       const menu = {main: [].concat(dashboardRoutes).concat(analyticsRoutes).concat(fixedRoutes)}
       setAllRoutes(menu);
       sessionStorage.setItem(Enum.CACHED_VERTICAL_FULLMENU, JSON.stringify(menu));
    }
    else {
              catalogService.fetchDashboardMenu()
                            .then(data => {
                              const menu = {main: [].concat(data.dashboardRoutes[0]).concat(data.dashboardRoutes[1]).concat(fixedRoutes)}
                              setAllRoutes(menu);                           
                              data.dashboardRoutes[0].icon=faPieChart;
                              data.dashboardRoutes[1].icon=faChartLine;
                              sessionStorage.setItem(Enum.CACHED_DASHBOARDMENU, JSON.stringify(data.dashboardRoutes[0]));  
                              sessionStorage.setItem(Enum.CACHED_ANALYTICSMENU, JSON.stringify(data.dashboardRoutes[1])); 
                            }).catch(err => {
                              setAllRoutes(()=> { return {main: routes}});
                              console.log(err);
                            });                                     
    }


}, []);

// const setSettingsRoutes=(routes) => {
//   var result = [];
//   routes.children.forEach(route => {    
//       const hasPermission = authService.hasOneOfPermissions(route.permissions);
//       if ((!route.permissions)
//           || (route.permissions && route.permissions.length === 1 && route.permissions[0] === 'all')
//           || (route.permissions && route.permissions.length && hasPermission)) {
//             const child = {  name: route.name, icon: route.icon, to: route.to, active: route.active, permissions: route.permissions };
//             result.push(child);
//       }    
//   })
//   return result;
// };

// const setServicesRoutes=(routes) => {
//   var result = [];
//   routes.children.forEach(node => {
//     const topLevel = node
//     var item = {   name: topLevel.name, to: topLevel.to, active: topLevel.active, children: []};
//     // const temp = {};
//     // temp.push(item);
//     topLevel.children.forEach(route => {
//       const hasPermission = authService.hasOneOfPermissions(route.permissions);
//       if ((!route.permissions)
//           || (route.permissions && route.permissions.length === 1 && route.permissions[0] === 'all')
//           || (route.permissions && route.permissions.length && hasPermission)) {
//             const child = {  name: route.name, icon: route.icon, to: route.to, active: route.active, permissions: route.permissions };
//             item.children.push(child);
//       }}
//     )
//     if (item.children.length > 0) {
//       result.push(item);
//     }
//   })

//   return result;
// };


// const menuHasVisibleItem=(routes) => {
//   if (routes.children) { 
//     return menuHasVisibleItem(routes.children);
//   }
//   else {
//   routes.every(node => {
//     node.children.every(route => {
//     const hasPermission = authService.hasOneOfPermissions(route.permissions);
//     if ((!route.permissions)
//          || (route.permissions && route.permissions.length === 1 && route.permissions[0] === 'all')
//          || (route.permissions && route.permissions.length && hasPermission)) {
//      return true;
//     }      
//   })
//   });
//   }
//   return false;
// };
  // const showMenu=(routes) => {
  //   const result =[];
  //   routes.children.forEach(route => {
  //   if ((!route.permissions)
  //          || (route.permissions && route.permissions.length === 1 && route.permissions[0] === 'all')
  //          || (route.permissions && route.permissions.length && authService.hasOneOfPermissions(route.permissions))) {
  //      if (route.to) {
  //         result.push(route);
  //       }
  //      if (route.children) {
  //         const children = showMenu(route);
  //         if (children.length > 0) {
  //           const item = {   name: route.name, icon: route.icon, to: route.to, active: route.active, permissions: route.permissions, children: children };
  //           result.push(item);
  //         }
  //       }
  //     }      
  //   });
  //   return result;
  // };

  const {
    config: {
      navbarPosition,
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu
    }
  } = useContext(AppContext);

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add('navbar-vertical-collapsed');
    } else {
      HTMLClassList.remove('navbar-vertical-collapsed');
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    clearTimeout(time);
    HTMLClassList.remove('navbar-vertical-collapsed-hover');
  };

  const NavbarLabel = ({ icon, label }) => (    

    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">        
        <Col xs="auto">
        {icon && (
          <span className='nav-link-icon' style={{paddingRight: '5px'}}>
            <FontAwesomeIcon icon={icon}  />
          </span>)}          
         <span className='vertical-menu-title'> {label}</span>
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames('navbar-vertical', {
        [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
      })}
      variant="light"
    >
      <Flex alignItems="center">
        <ToggleButton />
        <Logo at="navbar-vertical" width={107} />
      </Flex>
      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          backgroundImage:
            navbarStyle === 'vibrant'
              ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
              : 'none'
        }}
      >
        <div className="navbar-vertical-content scrollbar">          
          <Nav className="flex-column" as="ul">
            {allRoutes.main.map(route => (
             route.children && route.children.length > 0 && route.label && <Fragment key={route.label}>
                  <NavbarLabel label={capitalize(route.label)} icon={route.icon} />
                <NavbarVerticalMenu routes={route.children} />
              </Fragment>
            ))}
          </Nav>
          <>
            {navbarPosition === 'combo' && (
              <div className={`d-${topNavbarBreakpoint}-none`}>
                <div className="navbar-vertical-divider">
                  <hr className="navbar-vertical-hr my-2" />
                </div>
                <Nav navbar>
                  <NavbarTopDropDownMenus />
                </Nav>
              </div>
            )}
          </>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

NavbarVertical.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string
};

export default NavbarVertical;
