/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-undef */
import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Card, Row, Col, Breadcrumb, Spinner } from 'react-bootstrap';
import AdvanceTableWrapper from '../../components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../../components/common/advance-table/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableSearchBoxPlug from '../../components/common/advance-table/AdvanceTableSearchBoxPlug';
// import IconButton from 'components/common/IconButton';
import AdvanceTableFooter from '../../components/common/advance-table/AdvanceTableFooter';
import kpiService from 'services/kpi-service';
import { settings } from '../../config';
import { getItemFromStore } from 'helpers/utils';
import PageHeader from 'components/common/PageHeader';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../admin/admin.scss';
import Enum from 'common/enum';
import  PopoverComponent from 'pages/common/popover';
import { Helmet } from 'react-helmet';
import SoftBadge from 'components/common/SoftBadge';

const KPIBrowser = () => {
  const [kpiData, setKpiData] = useState([]);
  const configState = {
    gridRowsCount: getItemFromStore('gridRowsCount', settings.gridRowsCount)
  };
  const [isLoading, setIsLoading] = useState(true); 
  useEffect(() => {
    kpiService.getKpiList()
      .then(res => {
        setIsLoading(false);
        setKpiData(res);
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  const onClickNew = () => {
    const catalogId = kpiData.map(a => a.kpiName);
    sessionStorage.setItem(Enum.CACHED_CATALOG_ID, JSON.stringify(catalogId));

    // const catalogId = [];
    // catalogId.push(catalogs.map(catalog => catalog.id));
    // sessionStorage.setItem('allCatalogIds', JSON.stringify(catalogId[0]));
  };
  const kpiBrowserdesc= "Browse for dictionary item in the table below based on your current permissions profile. You can use the sort feature next to column names or search for dictionary item directly.";
  return (
    <>
      <Helmet>
        <title>Manage Data Dictionary</title>
      </Helmet>
      <PageHeader title="Manage Data Dictionary" className="mb-2" col="12">
          <Row>
            <Col className="text-start mt-1">
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>
                  Manage Data Dictionary
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col className="text-end mt-n2">
              <PopoverComponent 
                header = "Enterprise Data Governance"
                body = {kpiBrowserdesc}
              />
          </Col>
        </Row>
      {/* <Breadcrumb>  
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Manage KPI</Breadcrumb.Item>
      </Breadcrumb> */}
      </PageHeader>
      <AdvanceTableWrapper
        columns={columns}
        data={kpiData}
        sortable
        pagination
        perPage={configState.gridRowsCount}
      >
        <Card className="h-100">
          <Card.Header>
            <Row className="flex-between-center">
              <Col xs={9} sm={6} lg={3}>
                <AdvanceTableSearchBoxPlug table placeholder="Search" />
              </Col>
              <Col xs="auto" sm={3} lg={3} className="text-end marginTop">
                <Link
                  className="btn btn-outline-primary"
                  to={'/kpi/kpi/new'}
                  onClick={onClickNew}
                >
                  <FontAwesomeIcon icon={faPlus} /> New
                </Link>
              </Col>
            </Row>
          </Card.Header>
          {
            isLoading ? <div className='text-center'><Spinner animation="border" /></div> :
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
            </Card.Body>
          }
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={kpiData.length}
              table
              rowInfo
              navButtons
            />
          </Card.Footer>
        </Card>
        <br />
      </AdvanceTableWrapper>
    </>
  );
};
const columns = [
  {
    accessor: 'dictionaryId',
    Header: 'Dictionary Id',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'dictionaryName',
    Header: 'Name',
    Cell: rowData => (
      <Link
        to={`${rowData.row.original.dictionaryId}`}
        className="text-primary fw-semi-bold"
      >
        {rowData.row.original.dictionaryName}
      </Link>
    )
  },
  {
    accessor: 'dictionaryTypeName',
    Header: 'Dictionary Type',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'divisionCode',
    Header: 'Division',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'domainName',
    Header: 'Domain',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'dictionaryClassName',
    Header: 'Class',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'reviewPeriodDays',
    Header: 'Review Period',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'lastReviewedOn',
    Header: 'Last Reviewed On',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'nextReviewOn',
    Header: 'Next Review On',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'lastModifiedBy',
    Header: 'Last Updated By',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'dictionaryStatus',
    Header: 'Dictionary Status',
    Cell: rowData => (
      <SoftBadge
      bg={rowData.row.original.dictionaryStatus === 'Released'? 'success' : 'warning'}
      className='rounded-pill'
      // className="fw-medium text-decoration-none me-1 "
    >     
     <big> {rowData.row.original.dictionaryStatus}</big>
    </SoftBadge>
    )
  }

];

export default KPIBrowser;
