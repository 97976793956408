/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Breadcrumb } from 'react-bootstrap';
import userService from '../../services/user-service';
import hemService from '../../services/hem-service';
import Select from 'react-select';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import TinymceEditor from 'components/common/TinymceEditor';
import PageHeader from 'components/common/PageHeader';
import ConfirmationMessage from 'components/common/ConfirmationMessage';
import { Helmet } from 'react-helmet';
const ReleaseNew = () => {
  //let params = useParams();
  const [roles, setRoles] = useState([]);
  const [assignedRoles, setAssignedRoles] = useState([]);
  //const [emailNotification, setEmailNotification] = useState(false);
  //const [portalNotification, setPortalNotification] = useState(false);
  var [releases, setReleases] = useState({
    title: '',
    versionId: '',
    isMajorRelease: false,
    releaseSummary: '',
    releaseNotes: '',
    emailNotificationsEnabled: false,
    portalNotificationsEnabled: false,
    releaseEmailRoleIds: []
  });
  const fillMsg = 'Please fill in this field';
  const [validateTitle, setValidateTitle] = useState(true);
  const [validateVersion, setValidateVersion] = useState(true);
  const [validateMsg, setValidateMsg] = useState(fillMsg);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    //loadRoles
    userService.fetchAllRoles().then(roles => {
      roles.forEach(item => {
        renameKey(item, 'id', 'value');
        renameKey(item, 'name', 'label');
      });
      setRoles(roles);
    });
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    const validate = validateFields();
    if (validate === false) {
      return;
    }

    if (e.target.value == 'save') {
      // assignedRoles.forEach(item => {
      //   renameKey(item, 'value', 'id');
      //   renameKey(item, 'label', 'name');
      // });
      var release = {
        title: releases.title,
        versionId: releases.versionId,
        isMajorRelease: releases.isMajorRelease,
        releaseSummary: releases.releaseSummary,
        releaseNotes: releases.releaseNotes,
        emailNotificationsEnabled: releases.emailNotificationsEnabled,
        portalNotificationsEnabled: releases.portalNotificationsEnabled,
        releaseEmailRoleIds: releases.releaseEmailRoleIds
      };
      //console.log(release);
      hemService
        .createRelease(release)
        .then(() => {
          // toast('Release Saved Successfully!', {
          //   autoClose: true,
          //   className: 'toast-container success'
          // });
          toast(ConfirmationMessage("Release Saved", "Release Saved Successfully!", "bg-success", "faCheckCircle"), {
            autoClose: true,
            //className: 'transparent',
            //style: {{background-color: transparent !important}},
            position: 'bottom-right',
            hideProgressBar: true,
            closeButton: false,
          });
        })
        .then(() => {
          window.location.href = '/release/browser';
          //history.back();
        });
      setDisable(true);
    }
  };

  const handleCancel = () => {
    history.back();
  };

  const validateFields = () => {
    if (releases.title) {
      setValidateTitle(true);
    } else {
      setValidateMsg(fillMsg);
      setValidateTitle(false);
      return false;
    }
    if (releases.versionId) {
      setValidateVersion(true);
    } else {
      setValidateMsg(fillMsg);
      setValidateVersion(false);
      return false;
    }
    return true;
  };

  function renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }

  function handleEmailChange(value) {
    //console.log(value.target.checked);
    //setEmailNotification(value.target.checked);
    setReleases({
      ...releases,
      emailNotificationsEnabled: value.target.checked
    });
  }

  function handlePortalChange(value) {
    //console.log(value.target.checked);
    //setPortalNotification(value.target.checked);
    setReleases({
      ...releases,
      portalNotificationsEnabled: value.target.checked
    });
    //setDisable(false);
  }

  function handleRolesChange(value) {
    //console.log(value.target.checked);
    setAssignedRoles(value);
    var roleIds = [];
    value.map(role => roleIds.push(role.value));
    setReleases({ ...releases, releaseEmailRoleIds: roleIds });
  }

  return (
    <>
      <Helmet>
        <title>New Release</title>
      </Helmet>
      <PageHeader title="New Release" className="mb-2">
      <Breadcrumb>  
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/release/browser">Release Manager</Breadcrumb.Item>
        <Breadcrumb.Item active>New</Breadcrumb.Item>  
      </Breadcrumb>
      </PageHeader>
      <Card>
        <Card.Body className="bg-light">
          <Form onSubmit={handleSubmit}>
            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={5} controlId="title">
                <Form.Label>
                  Title<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!validateTitle}
                  type="text"
                  placeholder="Title"
                  //value={releases.title}
                  name="title"
                  onChange={({ target }) => {
                    setReleases({ ...releases, title: target.value });
                    setValidateTitle(target.value ? true : false);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {validateMsg}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} lg={5} controlId="version">
                <Form.Label>
                  Version<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!validateVersion}
                  type="text"
                  placeholder="Version"
                  //value={roles.name}
                  name="version"
                  onChange={({ target }) => {
                    setReleases({ ...releases, versionId: target.value });
                    setValidateVersion(target.value ? true : false);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {validateMsg}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} lg={2}>
                <Form.Label>
                  <br />
                  <br />
                  <br />
                </Form.Label>
                <Form.Check
                  inline
                  //checked={releases.isMajorRelease}
                  defaultChecked={releases.isMajorRelease}
                  type="checkbox"
                  id="isMajorRelease"
                  label="Is Major Release"
                  onChange={e =>
                    setReleases({ ...releases, isMajorRelease: e.target.checked })
                  }
                />
              </Form.Group>
            </Row>
            <Row className="mb-2 g-2">
              <Form.Label>Notes</Form.Label>
              <TinymceEditor
                handleChange={newValue =>
                  setReleases({ ...releases, releaseNotes: newValue })
                }
              />
            </Row>

            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={6}>
                <Form.Check
                  inline
                  //checked={emailNotification}
                  defaultChecked={releases.emailNotificationsEnabled}
                  type="checkbox"
                  id="isEmail"
                  label="Email Notifications Enabled"
                  onChange={e => handleEmailChange(e)}
                />
              </Form.Group>
              <Form.Group as={Col} lg={6}>
                <Form.Check
                  inline
                  //checked={portalNotification}
                  defaultChecked={releases.portalNotificationsEnabled}
                  type="checkbox"
                  id="isNotification"
                  label="Portal Alert Enabled"
                  onChange={e => handlePortalChange(e)}
                  //onChange={({value}) => setReleases({...releases, emailNotificationsEnabled :value.target.checked})}
                />
              </Form.Group>
            </Row>
            <div>
              <Row className="mb-2 g-2">
                <Form.Label>Summary</Form.Label>
                <TinymceEditor
                  handleChange={newValue =>
                    setReleases({ ...releases, releaseSummary: newValue })
                  }
                />
              </Row>

              <Form.Label>Assign Roles</Form.Label>
              <Select
                closeMenuOnSelect={false}
                options={roles}
                placeholder="Select Release Roles"
                isMulti
                //classNamePrefix="react-select"
                value={assignedRoles}
                onChange={value => handleRolesChange(value)}
              ></Select>
            </div>
            <br />
            <div className="text-end">
              <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={handleCancel}
                value="cancel"
              >
                <FontAwesomeIcon icon={faTimes} /> Cancel
              </button>
              <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={handleSubmit}
                value="save"
                disabled={disable}
              >
                <FontAwesomeIcon icon={faSave} /> Save
              </button>
              {/* <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={handleSubmit}
                value="publish"
              >
                <FontAwesomeIcon icon={faShareSquare} /> Publish
              </button> */}
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default ReleaseNew;
