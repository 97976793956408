/* eslint-disable prettier/prettier */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
//import CardDropdown from 'components/common/CardDropdown';
//import IconButton from 'components/common/IconButton';
import LeafletMap from './LeafletMap';
import { Link } from 'react-router-dom';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';

const ProjectLocation = ({ data, drivesDetails }) => {

  const profile = JSON.parse(localStorage.getItem('userProfile'));
  var emailSplit = profile.email.split('@');
  const NameForTotal = 'NYBCe';
  const [divisions, setDivisions] = useState([]); //['NYBCe','NYBC','IBR','RIBC'];
  const [allData, setAllData] = useState([]);
  const [oneDivision, setOneDivision] = useState(profile.isEnterpriseView ? NameForTotal : emailSplit[1].split('.')[0].toUpperCase());
  const [totalDrives, setTotalDrives] = useState(0);
  const [dataDRBC, setDataDRBC] = useState(0);
  const [dataWB, setDataWB] = useState(0);
  const [dataPLT, setDataPLT] = useState(0);
  const [dataPLS, setDataPLS] = useState(0);
  const sortOrder = [NameForTotal,'NYBC','CBC', 'IBR', 'RIBC', 'BBD'];
  const [dataByLocation, setDataByLocation] = useState([]); 

  useEffect(() => {
    const temp = drivesDetails.map(a=> { return {driveDT: a.driveDT, drives: a.drives, drbcProjected: a.drbcProjected, wbProjected: a.wbProjected, plsProjected: a.plsProjected, pltProjected: a.pltProjected} } );

      //sum up all units by phlebotomy and collectionWeek
    const sumOfUnits = Array.from(temp.reduce((acc, {drives, drbcProjected, wbProjected, plsProjected, pltProjected, ...r}) => {
      const key = JSON.stringify(r);
      const current = acc.get(key) || {...r, drives: 0, drbcProjected:0, wbProjected:0, plsProjected:0, pltProjected:0 };
      return acc.set(key, {...current, drives: current.drives + drives, drbcProjected: current.drbcProjected + drbcProjected, wbProjected: current.wbProjected + wbProjected, plsProjected: current.plsProjected + plsProjected, pltProjected: current.pltProjected + pltProjected });
    }, new Map).values());

    const allData = sumOfUnits.map(a => ({ division: NameForTotal, ...a }));
    // console.log(allData);
    allData.map(a=> drivesDetails.push(a));
    setAllData(drivesDetails);
    
    if(oneDivision == 'NYBCe')
    {
      setDataByLocation(data);
    }
    else
    {
      setDataByLocation(data.filter(a=> a.division === oneDivision));
    }
    

    if(oneDivision == 'NYBCe')
    {
      setDataByLocation(data);
    }
    else
    {
      setDataByLocation(data.filter(a=> a.division === oneDivision));
    }

    var divisionValues = [...new Set(drivesDetails.map(a => a.division))];
        divisionValues.sort(
          function(a, b){                
              return sortOrder.indexOf(a) - sortOrder.indexOf(b); 
          }
        );
        setDivisions(divisionValues);

        setTotalDrives(drivesDetails.filter(a=>a.division === oneDivision).map(a=>a.drives));
        setDataDRBC(drivesDetails.filter(a=>a.division === oneDivision).map(a=>a.drbcProjected));    
        setDataWB(drivesDetails.filter(a=>a.division === oneDivision).map(a=>a.wbProjected));
        setDataPLS(drivesDetails.filter(a=>a.division === oneDivision).map(a=>a.plsProjected));
        setDataPLT(drivesDetails.filter(a=>a.division === oneDivision).map(a=>a.pltProjected));
        
  }, [drivesDetails]);

  function onChangeDivision (e) {
    setOneDivision(e.target.value);
    const divisionData = allData.filter(a=>a.division === e.target.value);
    if(e.target.value == 'NYBCe')
    {
      setDataByLocation(data);
    }
    else
    {
      setDataByLocation(data.filter(a=> a.division === e.target.value));
    }
    setTotalDrives(divisionData.map(a=>a.drives));
    setDataDRBC(divisionData.map(a=>a.drbcProjected));    
    setDataWB(divisionData.map(a=>a.wbProjected));
    setDataPLS(divisionData.map(a=>a.plsProjected));
    setDataPLT(divisionData.map(a=>a.pltProjected));
  }
  return (
    <Card className="h-100">
      {/* <FalconCardHeader
        title="Drives Scheduled Today"
        titleTag="h5"
        // endEl={<CardDropdown />}
        light={true}
      /> */}
      <FalconCardHeader title={ <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>Shows all drives scheduled for today. Excludes Canceled drives. Click on drive counts to see individual drive locations.</Tooltip>
            }
          >
            <span> Drives Scheduled Today
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                transform="shrink-1"
                className="ms-1 text-400"
                id="weeklySalesTooltip"
              />
            </span>
          </OverlayTrigger>}  titleTag="h6" 
           endEl={
            <Flex>
              <Form.Select
                size="sm"
                value={oneDivision}
                onChange={onChangeDivision}
                //className="w-auto"
              >
                {divisions.map((label) => (
                  <option value={label} key={label}>
                    {label}
                  </option>
                ))}
              </Form.Select>
            </Flex>
          }
      />
        <Card.Body className="h-100 p-0" dir="ltr">
          <LeafletMap
            data={dataByLocation}
            className="h-100 bg-white"
            style={{ minHeight: '300px' }}
          />
        </Card.Body>
        <Card.Footer className="bg-light">
          <Row className="justify-content-between">
            <Col xs="auto" className='pt-1'>
              <h6>Total Drives {totalDrives.toLocaleString('en-US')}</h6>
            </Col>
            <Col xs="auto" className='pt-1'>
              <h6>Hemasphere Projections: DRBC({dataDRBC.toLocaleString('en-US')}),
                              WB({dataWB.toLocaleString('en-US')}),
                              PLT({dataPLT.toLocaleString('en-US')}),
                              PLS({dataPLS.toLocaleString('en-US')})</h6>
            </Col>
            <Col className="text-end">
              {/* <FalconLink title="View" className="px-0" /> */}
              <Link to="/dashboards/14063" className="fw-semi-bold fs--1 text-nowrap pb-6" size="sm">
                View<FontAwesomeIcon
                  icon= {faAngleDoubleRight}
                  className="ms-1"
                  transform="down-1"
                />
              </Link>
            </Col>
            {/* <Col xs="auto">
              <Form.Select size="sm" defaultValue="week">
                <option value="week">Last 7 days</option>
                <option value="month">Last month</option>
                <option value="year">Last Year</option>
              </Form.Select>
            </Col>
            <Col xs="auto">
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="chevron-right"
                iconClassName="ms-1 fs--1"
                iconAlign="right"
              >
                <span className="d-none d-sm-inline-block">
                  Location overview
                </span>
              </IconButton>
            </Col> */}
        </Row>
      </Card.Footer>
    </Card>
  );
};

ProjectLocation.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  drivesDetails: PropTypes.arrayOf(PropTypes.object)
};

export default ProjectLocation;
