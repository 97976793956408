/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useState } from 'react';
import './BlobFileShare.css';
import AuthService from './services/auth-service';
import PageHeader from './components/common/PageHeader';
import {
  DetailsView,
  FileManagerComponent,
  NavigationPane,
  Toolbar,
  Inject
} from '@syncfusion/ej2-react-filemanager';
import { Ajax } from '@syncfusion/ej2-base';
import AppContext from './context/Context';
import { Card, Breadcrumb, Row, Col } from 'react-bootstrap';
import { getFileShareAPIToken } from './msalToken';
import  PopoverComponent from 'pages/common/popover';
import { Helmet } from 'react-helmet';

const BlobFileShare = () => {
  const hostPath = process.env.REACT_APP_FS_ENDPOINT;
  const [fileShareToken, setFileShareToken] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const {
    config: { isDark }
  } = useContext(AppContext);

  useEffect(() => {
    if (window.innerWidth < 1050) {
      setIsMobile(true);
    }
    getFileShareAPIToken().then(token => {
      setFileShareToken(token);
    });

    isDark ? applyThemes('tailwind-dark') : applyThemes('tailwind');
  }, [isDark]);

  const applyThemes = theme => {
    const ajax: Ajax = new Ajax('styles/' + theme + '.css', 'GET', true);
    ajax.send().then((result: any) => {
      const styleTag: any = document.getElementById('theme');
      styleTag.innerHTML = `/*${theme}*/` + result;
    });
  };
  const onSuccess = args => {
    if (args.action == 'Upload') {
      document
        .getElementById('azure-file_upload_dialog')
        .classList.add('e-popup-close');
      document
        .getElementById('azure-file_upload_dialog')
        .classList.remove('e-popup-open');
      document.getElementsByClassName('e-upload-file-list')[0].remove();
      document.getElementsByClassName('e-dlg-container')[0].style.display =
        'none';
    }
  };
  const onFailure = () => {
    console.log('Ajax request has failed');
  };
  const beforeSend = args => {
    var data = JSON.parse(args.ajaxSettings.data);
    data['userId'] = AuthService.getCurrentUserId();
    args.ajaxSettings.beforeSend = function (args) {
      //Setting authorization header
      args.httpRequest.setRequestHeader(
        'authorization',
        `Bearer ${fileShareToken}`
      );
    };
    args.ajaxSettings.data = JSON.stringify(data);
  };
  const beforeDownload = args => {
    var downloadUserId = args.data;
    downloadUserId.userId = AuthService.getCurrentUserId();
    args.data = downloadUserId;
  };
  const FileShareDesc = "File Share is Hemetrics repository for inbound (going to Hemetrics) and outbound (coming from Hemetrics) files. Browse, select, download or upload files based on your current permissions profile in appropriate folders. Cost Center reports can be found under the Container/finance root folder.";

  return (
    <div className="control-section">
      <Helmet>
          <title>File Share</title>
      </Helmet>
      {!isMobile && (
        <PageHeader title="File Share" className="mb-2"col="12" >
          <>
            <Row>
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>File Share</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col className="text-end mt-n2">
                <PopoverComponent 
                  header = "FileShare"
                  body = {FileShareDesc}
                />
              </Col>
            </Row>
          </>
        </PageHeader>
      )}
      {isMobile && (
          <>
            <Row>
              <Col className="text-start mx-1">
                <h5>File Share </h5>
              </Col>
              <Col className="text-end mt-n1">
              <PopoverComponent 
                header = "FileShare"
                body = {FileShareDesc}
              />
              </Col>
            </Row>
          </>
      )}
          
      <Card className="h-100">
          <Card.Body className="p-0">

            {fileShareToken && (
              <FileManagerComponent
                id="azure-file"
                className="windowHeight"
                view="Details"
                sortOrder="Descending"
                enablePersistence={true}
                beforeSend={beforeSend.bind(this)}
                beforeDownload={beforeDownload.bind(this)}
                uploadSettings={{
                  autoClose: true,
                  autoUpload: true,
                  maxFileSize: 30000000
                }}
                ajaxSettings={{
                  downloadUrl: hostPath + '/AzureProvider/AzureDownload',
                  //getImageUrl: this.hostPath + "/AzureProvider/AzureGetImage",
                  uploadUrl: hostPath + '/AzureProvider/AzureUpload',
                  url: hostPath + '/AzureProvider/AzureFileOperations'
                }}
                success={onSuccess}
                failure={onFailure.bind(this)}
                toolbarSettings={{
                  items: [
                    'Upload',
                    'Cut',
                    'Copy',
                    'Paste',
                    'Delete',
                    'Download',
                    'Rename',
                    'SortBy',
                    'Refresh',
                    'Selection',
                    'View'
                  ],
                  visible: true
                }}
                contextMenuSettings={{
                  file: [
                    'Download',
                    '|',
                    'Cut',
                    'Copy',
                    '|',
                    'Delete',
                    'Rename',
                    '|',
                    'Details'
                  ],
                  folder: [
                    'Open',
                    'Download',
                    '|',
                    'Cut',
                    'Copy',
                    'Paste',
                    '|',
                    'Delete',
                    'Rename',
                    '|',
                    'Details'
                  ],
                  layout: [
                    'SortBy',
                    'View',
                    'Refresh',
                    '|',
                    'Paste',
                    '|',
                    'Upload',
                    '|',
                    'Details',
                    '|',
                    'SelectAll'
                  ],
                  visible: true
                }}
              >
                <Inject services={[NavigationPane, DetailsView, Toolbar]} />
              </FileManagerComponent>
            )}

          </Card.Body>
      </Card>
    </div>
  );
};
export default BlobFileShare;
