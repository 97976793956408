/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Dropdown, Card, OverlayTrigger, Tooltip, ListGroup } from 'react-bootstrap';
import { faStar } from '@fortawesome/free-solid-svg-icons';
//import FalconCardHeader from 'components/common/FalconCardHeader';
import SoftBadge from '../../../components/common/SoftBadge';
import Flex from '../../../components/common/Flex';
import catalogService from 'services/catalog-service';

const profile = JSON.parse(localStorage.getItem('userProfile'));

const Favorites = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [screenWidth] = useState(window.innerWidth);

  useEffect(() => {    
    catalogService
      .fetchFavorites(profile.id)
      .then(favorite => {
        setFavorites(favorite);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const handleToggle = () => {
    catalogService
      .fetchFavorites(profile.id)
      .then(favorite => {
        setFavorites(favorite);
      })
      .catch(err => {
        console.log(err);
      });
    setIsOpen(!isOpen);
  };

  return (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle bsPrefix="toggle" as={Link} to="#!">
        <OverlayTrigger
          placement={'left'}
          overlay={<Tooltip id="myFavorites">My Favorites</Tooltip>}
        >
          <div className="theme-control-toggle-label px-0 nav-link">
            <FontAwesomeIcon  icon={faStar} transform="shrink-5"  className="fs-3" />
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>      
        <Dropdown.Menu          
          className="dropdown-menu-card dropdown-menu-end dropdown-caret-bg"
        >
          <Card className="dropdown-menu-notification dropdown-menu-end shadow-none py-3" style={{ maxWidth: screenWidth > 700 ?'25rem' : '19rem' }}>
            {/* <FalconCardHeader
              className="card-header"
              title="MY FAVORITES"
              titleTag="h6"
              light={false}
            /> */}
            <Dropdown.Header
              as="h6"
              className="px-card pt-0 pb-2 fw-medium text-dark"
            >
              MY FAVORITES
            </Dropdown.Header>
            <ListGroup
              variant="flush"
              className="fw-normal fs--1 scrollbar"
              style={{ maxHeight: '19rem' }}
            >
            {favorites.map(item => (
              <Dropdown.Item
                as={Link}
                to={
                  item.type.split('-')[1] == 'Report'
                    ? `/boldreports/${item.id}`
                    : `/dashboards/${item.id}`
                }
                className="fs-0 px-card py-1 hover-primary "
                key={item.id}
                onClick={()=> setIsOpen(false)}
              >
                <Flex alignItems="center">
                  <SoftBadge
                    bg={
                      item.type.split('-')[1] == 'Report'
                        ? 'success'
                        : item.type.split('-')[1] == 'Dashboard'
                        ? 'info'
                        : 'secondary'
                    }
                    className="fw-medium text-decoration-none me-2"
                  >
                    {item.type.split('-')[1] == 'Analytics' ? 
                      item.type.split('-')[1]
                      .replace('Analytics', 'Analytics' + '\u200A'.repeat(16)) : 
                      item.type.split('-')[1]
                      .replace('Report', 'Report' + '\u200A'.repeat(37))}{' '}
                  </SoftBadge>
                  <div className="flex-1 fs--1">{item.catalogName}</div>
                </Flex>
              </Dropdown.Item>
            ))}
            </ListGroup>
            {favorites.length === 0 && (
              <p className="fs-1 fw-bold text-center mb-0">No Favorites</p>
            )}
            <div className="d-flex justify-content-end p-2"></div>
          </Card>
        </Dropdown.Menu>
      {/* )} */}
    </Dropdown>
  );
};

export default Favorites;
