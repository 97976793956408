/* eslint-disable prettier/prettier */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Nav, ProgressBar } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // faChartBar,
  faEdit,
  faFileExcel,
  faInfo,
  // faSearch,
  faCalculator
} from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import Success from './Success';
import AppContext, { AuthWizardContext } from '../../context/Context';
import IconButton from 'components/common/IconButton';
import WizardModal from 'components/wizard/WizardModal';
import WIFAPage1 from './WIFAPage1';
// import BaseScenario from './BaseScenario';
import DemandOverrides from './DemandOverrides';
import ApptOverrides from './ApptOverrides'; 
import ImportOverrides from './ImportOverrides';
import ExportOverrides from './ExportOverrides';
// import Review from './Review';
import ReviewNotes from './ReviewNotes';
import ReviewModel from './ReviewModel';
import Publish from './Publish';
import authService from 'services/auth-service';
import Enum from 'common/enum';
import WifaService from 'services/wifa-service';

const userProfile = authService.getCurrentUserProfile();

const WizardLayout = ({ variant, validation, progressBar }) => {
  const { isRTL } = useContext(AppContext);
  const { user, setUser, step, setStep } = useContext(AuthWizardContext);
  const {
    handleSubmit,
    // formState: { errors },
    // watch,
    // setValue,
    reset,
    clearErrors
  } = useForm();

  const [modal, setModal] = useState(false);
  const [baseScenarioHome, setBaseScenarioHome] = useState([]); 
  const [reviewScenario, setReviewScenario] = useState([]);
  const [bloodCenterKey, setBloodCenterKey] = useState(0);
  const [bloodCenterCode, setBloodCenterCode] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [scenarioKey, setScenarioKey] = useState(0);
  const [wifaSessionId, setWifaSessionId] = useState('');
  // const [wifaParam] = useState('WIFASessionId');
  // const [wifaPath] = useState('/Inventory/WIFA Projection Scenario');

  function transferBaseScenario(baseScenario) {
    setBaseScenarioHome(baseScenario);
    setWifaSessionId(baseScenario[0].wifaSessionId)
  }

  function transferReviewScenario(rScenario) {
    setReviewScenario(rScenario);
  }

  function transferWifaKeys(bloodCenterKey, scenarioKey, bloodCenterCode, selectedDate) {
    setBloodCenterKey(bloodCenterKey);
    setScenarioKey(scenarioKey);
    setBloodCenterCode(bloodCenterCode);
    setSelectedDate(selectedDate);
  }

  const navItems = [
    {
      icon: 'thumbs-up',
      label: 'WIFA'
    },
    // {
    //   icon: 'faHistory',
    //   label: 'Current Projection'
    // },
    {
      icon: 'dollar-sign',
      label: 'Demand Overrides'
    },
    {
      icon: 'thumbs-up',
      label: 'Appointment Overides'
    },
    {
      icon: 'faHistory',
      label: 'Import Overides'
    },
    {
      icon: 'faHistory',
      label: 'Export Overides'
    },
    // {
    //   icon: 'thumbs-up',
    //   label: 'Review'
    // },
    {
      icon: 'thumbs-up',
      label: 'Review Model'
    },
    {
      icon: 'thumbs-up',
      label: 'Add Notes'
    },
    {
      icon: 'thumbs-up',
      label: 'Publish'
    },
    {
      icon: 'thumbs-up',
      label: 'Done'
    }
  ];

  const onSubmitData = data => {  
    if (step === 1) {
      // create session here (UserId + Year + 2-digit Month + 2-digit day + time) 
      var wifaSessionId = userProfile.id 
                          + ''
                          + new Date().getFullYear()
                          + new Date().toLocaleString("en-US", { month: "2-digit" }) 
                          +  new Date().toLocaleString("en-US", { day : '2-digit'})
                          + new Date().toLocaleString().split(',')[1].replace(':', '').replace(':', '').replace('PM', '').replace('AM', '').trim()
      
                           setWifaSessionId(wifaSessionId);               
      // Save session in the session storage 
      sessionStorage.removeItem(Enum.CACHED_WIFA_USER_SESSION);
      sessionStorage.setItem(Enum.CACHED_WIFA_USER_SESSION, JSON.stringify(wifaSessionId));

      WifaService.createWifaSession(wifaSessionId, bloodCenterKey, scenarioKey)
      .then(() => {
        setUser({ ...user, ...data });
        setStep(step + 1);
      });
    } else {
      setUser({ ...user, ...data });
      setStep(step + 1);
    }
  };
  const onError = () => {
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const toggle = () => setModal(!modal);

  const handleNavs = targetStep => {
    if (step !== 9) {
      if (targetStep < step) {
        setStep(targetStep);
      } else {
        handleSubmit(onSubmitData, onError)();
      }
    } else {
      toggle();
    }
  };

  return (
    <>
      <WizardModal modal={modal} setModal={setModal} />
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard"
      >
        <Card.Header
          className={classNames('bg-light', {
            'px-4 py-1': variant === 'pills',
            'pb-1 pt-1': !variant
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {navItems.map((item, index) => (
                  <NavItem
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))}
          </Nav>
        </Card.Header>
        {progressBar && <ProgressBar now={step * 25} style={{ height: 2 }} />}
        <Card.Body className="fw-normal px-md-6">
          {step === 1 && (
            <WIFAPage1 transferWifaKeys = {transferWifaKeys} />
          )}
          {/* {step === 2 && (
            <BaseScenario 
              transferBaseScenario = { transferBaseScenario }
              bloodCenterKey = {bloodCenterKey}
              scenarioKey = {scenarioKey}
            />
          )} */}
          {step === 2 && (
           <DemandOverrides 
              transferBaseScenario = { transferBaseScenario }
              // baseScenarioHome = {baseScenarioHome}
              wifaSessionId = {wifaSessionId} 
              bloodCenterKey = {bloodCenterKey}
              bloodCenterCode = {bloodCenterCode}
              scenarioKey = {scenarioKey}
              selectedDate = {selectedDate}
              // wifaSessionId = {wifaSessionId}
           />
          )}
          {step === 3 && (
            <ApptOverrides 
              baseScenarioHome = {baseScenarioHome}
              bloodCenterKey = {bloodCenterKey}
              bloodCenterCode = {bloodCenterCode}
              scenarioKey = {scenarioKey}
              wifaSessionId = {wifaSessionId} 
            />
          )}
          {step === 4 && (
            <ImportOverrides 
              baseScenarioHome = {baseScenarioHome}
              bloodCenterKey = {bloodCenterKey}
              bloodCenterCode = {bloodCenterCode}
              scenarioKey = {scenarioKey}
              wifaSessionId = {wifaSessionId} 
            />
          )}
          {step === 5 && (
            <ExportOverrides 
              baseScenarioHome = {baseScenarioHome}
              bloodCenterKey = {bloodCenterKey}
              bloodCenterCode = {bloodCenterCode}
              scenarioKey = {scenarioKey}
              wifaSessionId = {wifaSessionId}
            />
          )}
          {/* {step === 7 && (
            <Review 
              wifaSessionId = {wifaSessionId}
              bloodCenterKey = {bloodCenterKey}
              scenarioKey = {scenarioKey}
              transferReviewScenario = {transferReviewScenario} 
            />
          )} */}
           {step === 6 && (
            <ReviewModel 
              reviewScenario = {reviewScenario}
              bloodCenterKey = {bloodCenterKey}
              bloodCenterCode = {bloodCenterCode}
              scenarioKey = {scenarioKey}
              wifaSessionId = {wifaSessionId}
              transferReviewScenario = {transferReviewScenario}
            />
           
          )}
          {step === 7 && (
            <ReviewNotes 
            reviewScenario = {reviewScenario}
            bloodCenterKey = {bloodCenterKey}
            bloodCenterCode = {bloodCenterCode}
            scenarioKey = {scenarioKey}
            wifaSessionId = {wifaSessionId}
            transferReviewScenario = {transferReviewScenario}
            />
          )}
          {step === 8 && (
            <Publish 
              bloodCenterKey = {bloodCenterKey}
              bloodCenterCode = {bloodCenterCode}
              scenarioKey = {scenarioKey}
              wifaSessionId = {wifaSessionId}
            />
          )}
          {step === 9 && <Success reset={reset} />}
        </Card.Body>
        <Card.Footer
          className={classNames('px-md-6 bg-light pt-1 pb-2', {
            'd-none': step === 9,
            ' d-flex': step < 9
          })}
        >
          <IconButton
            variant="link"
            size="sm"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semi-bold', {
              'd-none': step === 1
            })}
            onClick={() => {
              setStep(step - 1);
            }}
          >
            Prev
          </IconButton>

            <IconButton
              variant="primary" size="sm"
              className={classNames('ms-auto px-2', {
                'd-none': step === 8
              })}
             // className="ms-auto px-5"
              type="submit"
              icon={isRTL ? 'chevron-left' : 'chevron-right'}
              iconAlign="right"
              transform="down-1 shrink-4"
            >
              Next
            </IconButton>
        
        </Card.Footer>
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: index < 9 ? step > index : step > 8,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        {index === 1 && (
          <>
            <span className="nav-item-circle-parent">
            <span className="nav-item-circle">
              <FontAwesomeIcon icon={faInfo} />
            </span>
            </span>
            <span className="d-none d-md-block mt-1 fs--1">{label}</span>
          </>
        )}
        {/* {index == 2 && (
          <>
            <span className="nav-item-circle-parent">
            <span className="nav-item-circle">
              <FontAwesomeIcon icon={faChartBar} />
            </span>
            </span>
            <span className="d-none d-md-block mt-1 fs--1">{label}</span>
          </>
        )} */}
        {(index > 1 && index < 6)  && (
          <>
            <span className="nav-item-circle-parent">
            <span className="nav-item-circle">
              <FontAwesomeIcon icon={faEdit} />
            </span>
            </span>
            <span className="d-none d-md-block mt-1 fs--1">{label}</span>
          </>
        )}
            {/* {index == 7 && (
          <>
            <span className="nav-item-circle-parent">
            <span className="nav-item-circle">
              <FontAwesomeIcon icon={faSearch} />
            </span>
            </span>
            <span className="d-none d-md-block mt-1 fs--1">{label}</span>
          </>
        )} */}
        {index == 6 && (
          <>
            <span className="nav-item-circle-parent">
            <span className="nav-item-circle">
              <FontAwesomeIcon icon={faCalculator} />
            </span>
            </span>
            <span className="d-none d-md-block mt-1 fs--1">{label}</span>
          </>
        )}
        {index == 7 && (
          <>
            <span className="nav-item-circle-parent">
            <span className="nav-item-circle">
              <FontAwesomeIcon icon={faEdit} />
            </span>
            </span>
            <span className="d-none d-md-block mt-1 fs--1">{label}</span>
          </>
        )}
        {index == 8 && (
          <>
            <span className="nav-item-circle-parent">
            <span className="nav-item-circle">
              <FontAwesomeIcon icon={faFileExcel} />
            </span>
            </span>
            <span className="d-none d-md-block mt-1 fs--1">{label}</span>
          </>
        )}
          {index == 9 && (
          <>
            <span className="nav-item-circle-parent">
            <span className="nav-item-circle">
              <FontAwesomeIcon icon={icon} />
            </span>
            </span>
            <span className="d-none d-md-block mt-1 fs--1">{label}</span>
          </>
        )}
        
      </Nav.Link>
    </Nav.Item>
  );
};

WizardLayout.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

NavItem.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

// NavItem.propTypes = NavItemPill.propTypes;

export default WizardLayout;
