/* eslint-disable prettier/prettier */
// import queryString from 'querystring';
// import stringParser from '../common/string-parser';
import BaseService from './base-service';
import authService from './auth-service';
//import searchService from './search-service';
import Enum from '../common/enum';
import { faPieChart, faChartLine } from '@fortawesome/free-solid-svg-icons';
// import mockReports from 'mock-report-cache';
class CatalogService extends BaseService {
    fetchCatalog = (catalogId) => {
        return this._apiGet({
            path: `/catalog/fetch/${catalogId}`
        });     
    }
    deleteCatalog = (catalogId) => {
        return this._apiPut({
            path: `/catalog/delete/${catalogId}`
        });
    }
   saveCatalog = (catalog, isNew) => {
       return isNew ? 
        this._apiPost({
            path: `/catalog/create`,
            data: catalog
        }) : 
        this._apiPut({
           path: `/catalog/update`,
           data: catalog
       });     
    }
   fetchCatalogType = () => {
        return this._apiGet({
            path: `/catalog/type`
        });     
   }
   fetchCatalogHost = () => {
    return this._apiGet({
        path: `/catalog/host`
        });     
    }
    fetchCatalogDomain = () => {
        return this._apiGet({
            path: `/catalog/domain`
        });     
    }
    // fetchCatalogDomainById = (domainId) => {
    //     return this._apiGet({
    //         path: `/catalog/domain/${domainId}`
    //     });     
    // }
    fetchCatalogSubdomainDropdown = (domainId) => {
        return this._apiGet({
            path: `/catalog/subdomain/${domainId}`
        });     
   }
   fetchCatalogSubdomainByParent = (parentDomainId) => {
       return this._apiGet({
           path: `/catalog/subdomain/${parentDomainId}/byparent`
       });     
    }
   fetchCatalogList = () => {
        return this._apiGet({
            path: `/catalog/List`
        });     
    }      
    fetchDashboardMenu = () => {
        const userId = authService.getCurrentUserId();
        return this._apiGet({
            path: `/catalog/${userId}/dashboardmenu`
        });
    }
    fetchReports = () => {
        return this._apiGet({
            path: `/catalog/reports`
        });
    }
    // fetchUserCatalogs = () => {
    //     // return mockReports.reports;        
    //     const userId = authService.getCurrentUserId();
    //     return this._apiGet({
    //         path: `/catalog/${userId}/all`
    //     });
    // }
    fetchCatalogDomain = () => {
        return this._apiGet({
            path: `/catalog/domain`
        });
    }

    fetchAllAssignedUsers = (roleId) => {
        return this._apiGet({
            path: `/users/notificationUsers/${roleId}`
        })
    }

    findUserCatalog = (userId, catalogId) => {
        return this._apiGet({
            path: `/catalog/users/${userId}/report/${catalogId || 'default'}`
        });
    }

    findUserReport = (userId, catalogId) => {
        return this._apiGet({
            path: `/catalog/users/${userId}/report/${catalogId || 'default'}`
        });
    }

    InsertFavoritesReports = (userId, catalogId) => {
        return this._apiGet({
            path: `/catalog/users/${userId}/insertFavorites/${catalogId}`
        });
    }

    DeleteFavoritesReports = (userId, catalogId) => {
        return this._apiDelete({
            path: `/catalog/users/${userId}/deleteFavorites/${catalogId}`
        });
    }

    fetchFavorites = (userId) => {
        return this._apiGet({
            path: `/catalog/favoritesReports/${userId}`
        });
    }

    fetchCatalogRoles = (catalogId) => {
        return this._apiGet({
            path: `/catalog/fetchCatalogRoles/${catalogId}`
        });
    }
    refreshCatalogCache = () => {
        return this.fetchDashboardMenu().then(catalogs => {            
            catalogs.dashboardRoutes[0].icon=faPieChart;
            catalogs.dashboardRoutes[1].icon = faChartLine;
            sessionStorage.removeItem(Enum.CACHED_DASHBOARDMENU);
            sessionStorage.removeItem(Enum.CACHED_SEARCH);
            sessionStorage.setItem(Enum.CACHED_DASHBOARDMENU, JSON.stringify(catalogs.dashboardRoutes[0])); 
            sessionStorage.setItem(Enum.CACHED_ANALYTICSMENU, JSON.stringify(catalogs.dashboardRoutes[1]));    
            // searchService.getAllItemsForSearch()
            // .then(result => {
            //   sessionStorage.removeItem(Enum.CACHED_SEARCH);
            //   sessionStorage.setItem(Enum.CACHED_SEARCH, JSON.stringify(result));                 
            // });          
        });
    }
    fetchCatalogEmailNotificationList = () => {
        return this._apiGet({
            path: `/catalog/catalogEmailNotificationList`
        });     
    }
    
    fetchCatalogSchedule = () => {
        return this._apiGet({
            path: `/catalog/catalogSchedule`
        });
    }

    fetchCatalogEmailNotification = (catEmailNotificationId) => {
        return this._apiGet({
            path: `/catalog/fetchCatalogEmailNotificationById/${catEmailNotificationId}`
        }); 
    }

    saveCatalogEmailNotification = (catEmailNotification, isNew) => {
        return isNew ? 
         this._apiPost({
             path: `/catalog/createCatalogEmailNotification`,
             data: catEmailNotification
         }) : 
         this._apiPut({
            path: `/catalog/updateCatalogEmailNotification`,
            data: catEmailNotification
        });     
     }
     deleteCatalogEmailNotification = (catalogEmailNotificationId) => {
        return this._apiDelete({
            path: `/catalog/deleteCatalogEmailNotification/${catalogEmailNotificationId}`
        });
    }
}
export default new CatalogService();