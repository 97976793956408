/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Modal, Button, Breadcrumb, Spinner } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import Select from 'react-select';
import { faSave, faTrashAlt, faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import catalogService from 'services/catalog-service';
import kpiService from 'services/kpi-service';
import PageHeader from '../../components/common/PageHeader';
import '../admin/admin.scss';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { toast } from 'react-toastify';
import ConfirmationMessage from 'components/common/ConfirmationMessage';
import DynamicKPITarget from './dynamic-kpi-target';
import  PopoverComponent from 'pages/common/popover';
import { Helmet } from 'react-helmet';

const KPIEditor = () => {
    let params = useParams();
    const [formData, setFormData] = useState({
      kpiId: 0,
      name: '',
      dictionaryStatus: 'In Validation',
      reviewPeiod: 0,
      description: '',
      domainId: '',
      catalogs: [],
      divisionCode: '',
      dictionaryType: '',
      dictionaryTypeName: '',
      kpiClassId: null,
      assignedTargetComponent: []
    });
    const [assignedTarget, setAssignedTarget] = useState ({
      serialId: 0,
      kpiTargetId: 0,
      bloodCenterCode: '',
      kpiId: 0,
      rangeTypeId: 0,
      recCreatedDT:'',
      targetEnd: 0.0,
      targetIdentifier: '',
      targetStart: 0.0
    });

    const [disable, setDisable] = useState(false);
    const [domain, setDomains] = useState([]);
    const [subdomain, setSubdomains] = useState({
      parentId: '',
      domains: []
    });
    const [isLoading, setIsLoading] = useState(true);
    const [divisions, setDivisions] = useState([]);
    const [kpiClasses, setKpiClasses] = useState([]);
    const [dictionaryTypes, setDictionaryTypes] = useState([]);
    const [isKPI, setIsKPI] = useState(true);
    const [allCatalogs, setAllCatalogs] = useState([]);
    const [assignedCatalogs, setAssignedCatalogs] = useState([]);
    const [targetRangeTypes, setTargetRangeTypes] = useState([]);
    const [targetComponent, setTarrgetComponent] = useState([]);
    const [formDataAssignedTarget, setFormDataAssignedTarget] = useState([]);

    useEffect(() => {
      const kpiId = params.id ? params.id : -1;
      const domainPromise = catalogService.fetchCatalogDomain();
      const divisionPromise = kpiService.getKpiDivisions();
      // const kpiClassPromise = kpiService.getKpiClasses();
      const dictionaryTypePromise = kpiService.getDictionaryTypes();
      const allCatalogPromise = kpiService.getAllCatalogs();
      const assignedCatalogPromise = kpiService.getCatalogByKpi(kpiId);
      const targetRangePromise = kpiService.getTargetRangeTypes();
      Promise.all([domainPromise, divisionPromise, dictionaryTypePromise, allCatalogPromise, assignedCatalogPromise, targetRangePromise])
      .then(([domain, divisions, dictionaryTypes, allCatalogs, assignedCatalog, targetRangeTypes]) => {
        setDomains(domain);
        setDivisions(divisions);
        //setKpiClasses(kpiClasses);
        setDictionaryTypes(dictionaryTypes);
        setAllCatalogs(allCatalogs);
        setAssignedCatalogs(assignedCatalog);
        setTargetRangeTypes(targetRangeTypes);
      })
      .then(() => {
        if (kpiId !== -1) {
            kpiService.getKpiById(kpiId)
            .then(kpi => {
              return kpi;
            })
            .then((kpi) => {
              var result = [];
              if (kpi.kpiAssignedTargetModel.length > 0) {
                kpi.kpiAssignedTargetModel.map ((ks, index) => {
                  const editTargetObject = {
                    serialId: index + 1,
                    kpiTargetId: ks.kpiTargetId,
                    bloodCenterCode: ks.bloodCenterCode,
                    kpiId: ks.kpiId,
                    rangeTypeId: ks.rangeTypeId,
                    recCreatedDT: ks.recCreatedDT,
                    targetEnd: ks.targetEnd,
                    targetIdentifier: ks.targetIdentifier,
                    targetStart: ks.targetStart
                  }
                  result.push(editTargetObject);
                })
                setTarrgetComponent(result);
                setFormDataAssignedTarget(result);           
              }
                    const domainId = kpi.kpiListModel[0].domainId;
                    kpiService.getKpiClasses(domainId)
                    .then(classes => {
                      setKpiClasses(classes);
                    });
                    setFormData({
                      kpiId: kpi.kpiListModel[0].dictionaryId,
                      name: kpi.kpiListModel[0].dictionaryName,
                      description: kpi.kpiListModel[0].dictionaryDesc,
                      dictionaryType: kpi.kpiListModel[0].dictionaryTypeId,
                      dictionaryTypeName: kpi.kpiListModel[0].dictionaryTypeName,
                      domainId: domainId,
                      kpiClassId: kpi.kpiListModel[0].dictionaryClassId,
                      reviewPeiod: kpi.kpiListModel[0].reviewPeriodDays,
                      divisionCode: kpi.kpiListModel[0].divisionCode,
                      catalogs: assignedCatalogs,
                      assignedTargetComponent: result,
                      dictionaryStatus: kpi.kpiListModel[0].dictionaryStatus
                    });
                    setIsLoading(false);
                    if (kpi.kpiListModel[0].dictionaryTypeId > 1) {
                      setIsKPI(false);
                    }
            })
            .catch(err => console.log(err)) ; // end of fetchCatalog

        } // end if
        else {
          setIsLoading(false);
        }
      })
      .catch(err => console.log(err)); // end of Promise.all
      }, []);    // end of useEffect

      function onChangeDivisison (e) {
        setFormData({...formData, divisionCode: e.label});
        setValidateDivision(true);
      }

      function onChangeDictionaryType (e) {
        setFormData({...formData, dictionaryType: e.value, dictionaryTypeName: e.label});
        if (e.label === 'Vocabulary') {
          setIsKPI(false);
        } else {
          setIsKPI(true);
        }
        setValidateDictionaryType(true);
      }

      function onChangeDomain(e) {
        catalogService.fetchCatalogSubdomainByParent(e.value)
        .then(subdomain => {
            setSubdomains(subdomain);
        });
        // Build the array of class dropdown based on 
        kpiService.getKpiClasses(e.value)
        .then(classes => {
          setKpiClasses(classes);
        });
        setValidateDomain(true);
        setFormData({...formData, domainId: e.value, kpiClassId: null});
        // setSubdomainValue(null);
      }

      function onchangeKpiClass (e) {
        setFormData({...formData, kpiClassId: e.value});
        // setValidateClass(true);
      }

      // Delete catalog
      function deleteKpi() {
        setDisable(true);
        kpiService.deleteKpi(formData.kpiId)
          .then(() => {
            toast(ConfirmationMessage("Kpi Deleted", "The kpi is deleted successfully!", "bg-success", "faCheckCircle"), {
              autoClose: true,
              position: 'bottom-right',
              hideProgressBar: true,
              closeButton: false,
            });         
          }).then (() => {
            kpiService.refreshKpiCache();
          }).then (() => {
              location.href = `/kpi/kpi`;
          })
          .catch(err => console.log(err));
      }

      function handleCancel  (e) {
        e.preventDefault();
        location.href = `/kpi/kpi`;
      };

      function onClickNewTarget () {
        const isNew = params.id?false:true;

        if (isNew && targetComponent.length > 0) {
          var mainlist = formData.assignedTargetComponent.concat(assignedTarget);
          setFormData({...formData, assignedTargetComponent: mainlist});
          setFormDataAssignedTarget(mainlist);
        }
        if (!isNew && targetComponent.length > formData.assignedTargetComponent.length) {
          var mainlistUpdate = formData.assignedTargetComponent.concat(assignedTarget);
          setFormData({...formData, assignedTargetComponent: mainlistUpdate});
          setFormDataAssignedTarget(mainlistUpdate);
        }
        var serialNumber = 0;
        if (targetComponent.length > 0) {
          var latest = Math.max(...targetComponent.map(o=> o.serialId));
          serialNumber = latest + 1;
        } else {
          serialNumber = 1;
        }

        // Empty out the previous assigned target array
        const newassignedTarget = {
          serialId: serialNumber,
          kpiTargetId: '',
          bloodCenterCode: '',
          kpiId: '',
          rangeTypeId: 0,
          recCreatedDT:'',
          targetEnd: 0.0,
          targetIdentifier: '',
          targetStart: 0.0
        }
        const list = targetComponent.concat(newassignedTarget); 
        setTarrgetComponent(list);
      }

      // Save catalog
      function onClickSave(e) {
        e.preventDefault();
        const validate = validateFields();
        if (validate===false) {
          return;
        } ;
        let finalAssignedtargetList = [];
        if (formDataAssignedTarget.length < targetComponent.length) {
          finalAssignedtargetList = formDataAssignedTarget.concat(assignedTarget);
        } else if (formDataAssignedTarget.length === targetComponent.length) {
          finalAssignedtargetList = formDataAssignedTarget;
        }
         //Target Tange Validation
         if (isKPI) {
          if (finalAssignedtargetList.length > 0) {
            var emptyFilterRange = finalAssignedtargetList.filter(f=> f.rangeTypeId === 0);
            if (emptyFilterRange.length > 0) {
              setValidateTargetRange(false);
              return;
            }
            var zeroTargetEnd = finalAssignedtargetList.filter(f=> f.targetEnd === 0 || f.targetEnd === "0");
            if (zeroTargetEnd.length > 0) {
              setValidateTargetEnd(false);
              return;
            } 
          }   
         }
         
        setDisable(true);
        const kpi = {
          kpiId: formData.kpiId,
          dictionaryType: formData.dictionaryType,
          kpiName: formData.name,
          kpiDivisionCode: formData.divisionCode,
          kpiClassId: formData.kpiClassId,
          kpiDesc: formData.description,
          domainId: formData.domainId,
          catalogs: assignedCatalogs,
          reviewPeriodDays: formData.reviewPeiod,
          dictionaryStatus: formData.dictionaryStatus,
          assignedTargetComponent: finalAssignedtargetList 
        };
        const isNew = params.id?false:true;
        kpiService.saveKpi(kpi, isNew).then(() => {
          toast(ConfirmationMessage("Kpi Saved", "The kpi is saved successfully!", "bg-success", "faCheckCircle"), {
            autoClose: true,
            position: 'bottom-right',
            hideProgressBar: true,
            closeButton: false,
          });
          setDisable(false);
          } // end if isNew
        ).then (() => {
          kpiService.refreshKpiCache();
        }).then (() => {
            location.href = `/kpi/kpi`;
        })
        .catch((err) => {
          setDisable(false);
          console.log(err.errors);
        }); // end of saveCatalog
      } // end onClickSave

      // Validation
      const fillMsgRange = "Please add target range";
      const fillMsgTargetEnd = "Please add target end";
      const [validateName, setValidateName] = useState(true);
      // const [validateClass, setValidateClass] = useState(true);
      const [validateDivision, setValidateDivision] = useState(true);
      const [validateDictionaryType, setValidateDictionaryType] = useState(true);
      const [validateReviewPeriod, setValidateReviewPeriod] = useState(true);
      const [validateDomain, setValidateDomain] = useState(true);
      const [validateDescription, setValidateDescription] = useState(true);
      const [validateTargetRange, setValidateTargetRange] = useState(true);
      const [validateTargetEnd, setValidateTargetEnd] = useState(true);
      const validateFields = () => {
        if (formData.name) { setValidateName(true);} else { setValidateName(false); return false;}
        if (formData.divisionCode) { setValidateDivision(true);} else { setValidateDivision(false); return false;}
        if (formData.domainId) { setValidateDomain(true);} else { setValidateDomain(false); return false;}
        // if (formData.kpiClassId) { setValidateClass(true);} else { setValidateClass(false); return false;}
        if (formData.reviewPeiod > 0) { setValidateReviewPeriod(true);} else { setValidateReviewPeriod(false); return false;}
        if (formData.description) { setValidateDescription(true);} else { setValidateDescription(false); return false;}
        return true;
      } 
      // end validateFields

      //Confirmation Modal
      function onClickDelete(e) {
        e.preventDefault();
        setShowConfirm(true);
      }
      const [showConfirm, setShowConfirm] = useState(false);
      const onClickCancel = () => setShowConfirm(false);
      const onClickOk = () => { setShowConfirm(false); deleteKpi(); }

      function handleCatalogChange(e) {
        setFormData({...formData, catalogs: e});
        setAssignedCatalogs(e);
      }
      function onClickTargetIdentifierChange(serialId, value){
        if (serialId == 0) { // for new
          setAssignedTarget ({...assignedTarget, targetIdentifier: value});
        } else {
          const index = formDataAssignedTarget.findIndex(target => target.serialId === serialId);
          var object = formDataAssignedTarget[index];
          object.targetIdentifier = value;
          var save = [...formDataAssignedTarget]; // important to create a copy, otherwise you'll modify state outside of setState call
          save[index] = object;
          setFormDataAssignedTarget(save);
        }
      }
      function onClickTargetChange(serialId, value){
        if (serialId == 0) { // for new
          setAssignedTarget ({...assignedTarget, targetStart: value});
        } else {
          const index = formDataAssignedTarget.findIndex(target => target.serialId === serialId);
          var object = formDataAssignedTarget[index];
          object.targetStart = value;
          var save = [...formDataAssignedTarget]; // important to create a copy, otherwise you'll modify state outside of setState call
          save[index] = object;
          setFormDataAssignedTarget(save);
        }
      }
      function onClickTargetEndChange(serialId, value){
        if (value > 0){
          setValidateTargetEnd(true);
        }
        if (serialId == 0) { // for new
          setAssignedTarget ({...assignedTarget, targetEnd: value});
        } else {
          const index = formDataAssignedTarget.findIndex(target => target.serialId === serialId);
          var object = formDataAssignedTarget[index];
          object.targetEnd = value;
          var save = [...formDataAssignedTarget]; // important to create a copy, otherwise you'll modify state outside of setState call
          save[index] = object;
          setFormDataAssignedTarget(save);
        }
      }
      function dynamicTargetRangeChange(serialId, value) {
        if (value > 0){
          setValidateTargetRange(true);
        }
        if (serialId == 0) { // for new
          setAssignedTarget ({...assignedTarget, rangeTypeId: value});
        } else {
          const index = formDataAssignedTarget.findIndex(target => target.serialId === serialId);
          var object = formDataAssignedTarget[index];
          object.rangeTypeId = value;
          var save = [...formDataAssignedTarget]; // important to create a copy, otherwise you'll modify state outside of setState call
          save[index] = object;
          setFormDataAssignedTarget(save);
        }
      }
      function onClickRemoveTarget (e) {
        setTarrgetComponent(current =>
          current.filter(targetComponent => {
            return targetComponent.serialId !== e;
          })
        );

        setFormDataAssignedTarget(current =>
          current.filter(formDataAssignedTarget => {
            return formDataAssignedTarget.serialId !== e;
          })
        );
      }
    const kpiEditordesc = "Create a data dictionary by populating the fields in the page. The Assigned target section is dynamic and available only for KPI. Create as many targets as needed."

      return(
      <>
        <Helmet>
          <title>{params.id ? "Edit Data Dictionary" : "New Data Dictionary"}</title>
        </Helmet>
      <PageHeader title={params.id? "Data Dictionary: " + formData.name : "New Data Dictionary"} className="mb-2" col="12">
          <Row>
            <Col className="text-start mt-1">
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/kpi/kpi">Manage Data Dictionary</Breadcrumb.Item>
                <Breadcrumb.Item active>Edit</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col className="text-end mt-n2">
              <PopoverComponent 
                body = {kpiEditordesc}
              />
          </Col>
        </Row>
      </PageHeader>
      <Card>
        <Card.Body className="bg-light">
          {isLoading? <div className='text-center'><Spinner animation="border" /></div> :
            <Form onSubmit={onClickSave}>
               <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg={6} >
                    <Form.Check
                      inline
                      checked={params.id ? (formData.dictionaryStatus==='In Validation'?true:false) : true}
                      type='checkbox'
                      id='isInValidation'
                      label='Is In Validation'
                      onChange={(e)=>e.target.checked? setFormData({...formData, dictionaryStatus: 'In Validation'}):setFormData({...formData, dictionaryStatus: 'Released'})}
                    />
                  </Form.Group>
              </Row>
              <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={4} controlId="division">
                  <Form.Label>DictionaryType</Form.Label>
                  <Select controlId="selectType"
                    className={validateDictionaryType?'':'validation-error'}
                    closeMenuOnSelect={true}
                    options={dictionaryTypes}
                    placeholder='Select...'
                    value={dictionaryTypes.find(item => item.label === formData.dictionaryTypeName)}
                     onChange={onChangeDictionaryType}
                  />
                {!validateDictionaryType && (<span className='validation-error-msg'>Please fill out this field.</span>)}
                </Form.Group>
              <Form.Group as={Col} lg={4} controlId="division">
                  <Form.Label>Division</Form.Label>
                  <Select controlId="selectType"
                    className={validateDivision?'':'validation-error'}
                    closeMenuOnSelect={true}
                    options={divisions}
                    placeholder='Select...'
                    value={divisions.find(item => item.label === formData.divisionCode)}
                     onChange={onChangeDivisison}
                  />
                {!validateDivision && (<span className='validation-error-msg'>Please fill out this field.</span>)}
                </Form.Group>
                <Form.Group as={Col} lg={4} controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    isInvalid={!validateName}
                    type="text"
                    value={formData.name}
                    name="name"
                    onChange={(e)=>{setFormData({...formData, name: e.target.value}); setValidateName(e.target.value?true:false)}}
                  />
                  <Form.Control.Feedback type="invalid" >Please fill out this field.</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={4} controlId="domain">
                  <Form.Label>Domain</Form.Label>
                  <Select
                    className={validateDomain?'':'validation-error'}
                    closeMenuOnSelect={true}
                    options={domain}
                    placeholder='Select...'
                    value={domain.find(item => item.value === formData.domainId)}
                    onChange={onChangeDomain}
                  />
                {!validateDomain && (<span className='validation-error-msg'>Please fill out this field.</span>)}
                </Form.Group>
                <Form.Group as={Col} lg={4} controlId="subdomain">
                  <Form.Label>Class</Form.Label>
                  <Select
                    // className={validateClass?'':'validation-error'}
                    closeMenuOnSelect={true}l
                    options={kpiClasses}
                    placeholder='Select...'
                    value={kpiClasses.find(item => item.value === formData.kpiClassId)??null}
                    onChange={onchangeKpiClass}
                  />
                  {/* {!validateClass && (<span className='validation-error-msg'>Please fill out this field.</span>)} */}
                </Form.Group>
                <Form.Group as={Col} lg={4} controlId="name">
                  <Form.Label>Review Period (in days)</Form.Label>
                  <Form.Control
                    isInvalid={!validateReviewPeriod}
                    type="text"
                    value={formData.reviewPeiod}
                    name="name"
                    onChange={(e)=>{setFormData({...formData, reviewPeiod: e.target.value}); setValidateReviewPeriod(e.target.value?true:false)}}
                  />
                  <Form.Control.Feedback type="invalid" >Please fill out this field.</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3 g-3">
                <Form.Group as={Col} lg={12} controlId="description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    isInvalid={!validateDescription}
                    as="textarea"
                    rows={4}
                    value={formData.description}
                    name="description"
                    onChange={(e)=>{setFormData({...formData, description: e.target.value}); setValidateDescription(e.target.value?true:false)}}
                  />
                  <Form.Control.Feedback type="invalid">Please fill out this field.</Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3 g-3">
                <Form.Group as={Col} lg={12} controlId="role">
                  <Form.Label>Assigned Catalogs</Form.Label>
                  <Select
                    closeMenuOnSelect={false}
                    options={allCatalogs}
                    placeholder='Select...'
                    isMulti
                    value={assignedCatalogs}
                    onChange={(e)=> handleCatalogChange(e)}
                  />
                </Form.Group>
              </Row>
              <br/>
              {isKPI && 
                 <Row className="flex-between-center">
                 <Col xs={9} sm={6} lg={3}>
                   <Form.Group >
                     <Form.Label>Assigned Targets</Form.Label>
                   </Form.Group>
                 </Col>
                 <Col xs="auto" sm={3} lg={3} className="text-end marginTop">
                 <button className="btn btn-outline-primary me-1 mb-1" type="button" onClick={onClickNewTarget} >
                     <FontAwesomeIcon icon={faPlus} /> New Target
                   </button>
                 </Col>
               </Row>
              }
             
              {isKPI && targetComponent.map(tr =>{
                { return (
                    <DynamicKPITarget
                    divisions= {divisions}
                    subdomain = {subdomain}
                    range = {targetRangeTypes}
                    targetDivision = {tr.bloodCenterCode}
                    targetRange = {tr.rangeTypeId}
                    targetIdentifier = {tr.targetIdentifier}
                    targetStart = {tr.targetStart}
                    targetEnd = {tr.targetEnd}
                    kpiTargetId = {tr.kpiTargetId}
                    dynamicTargetRangeChange = {dynamicTargetRangeChange}
                    onClickTargetIdentifierChange = {onClickTargetIdentifierChange}
                    onClickTargetChange = {onClickTargetChange}
                    onClickTargetEndChange = {onClickTargetEndChange}
                    onClickRemoveTarget = {onClickRemoveTarget}
                    serialId = {tr.serialId}
                    validateTargetRange = {validateTargetRange}
                    validateTargetEnd= {validateTargetEnd}
                    />
                  )
                }
                })}
                  <br/>
                {isKPI && !validateTargetRange && (
                <Row>
                  <Col>
                    <span style = {{fontSize: '15px'}} className='validation-error-msg'>{fillMsgRange}</span>
                  </Col>
                </Row>
              )}
               {isKPI && !validateTargetEnd && (
                <Row>
                  <Col>
                    <span style = {{fontSize: '15px'}} className='validation-error-msg'>{fillMsgTargetEnd}</span>
                  </Col>
                </Row>
              )}
              {isKPI && (<br/> )}
              <Row className="mb-3 g-3">
              <div className="text-end">
              <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={handleCancel}
                value="cancel"
              >
                <FontAwesomeIcon icon={faTimes} /> Cancel
              </button>
                <button className="btn btn-outline-primary me-1 mb-1" disabled={disable} type="submit">
                  <FontAwesomeIcon icon={faSave} /> Save
                </button>
                {params.id &&
                <button className="btn btn-outline-primary me-1 mb-1" disabled={disable} onClick={onClickDelete} type="button">
                <FontAwesomeIcon icon={faTrashAlt} /> Delete
                </button>}
              </div>
              </Row>
            </Form>
           }
        </Card.Body>
      </Card>
      <Modal show={showConfirm} onHide={onClickCancel} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Hemetrics</Modal.Title>
          <FalconCloseButton onClick={onClickCancel}/>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete it?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onClickOk}>
            OK
          </Button>
          <Button variant="secondary" onClick={onClickCancel} >Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
    ); // return
} // CatalogForm
export default KPIEditor;