/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React from 'react';
import tableau from 'tableau-api-js';
import { NavLink } from 'react-bootstrap';
import {
  faImage,
  faFilePdf,
   faFileExcel,
 //  faFileCsv,
  faFilePowerpoint,
  faExpand
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default class TableauEmbed extends React.Component {
  componentDidMount() {
    this.initViz();
  }

  initViz = () => {
    const vizUrl = this.props.trustedTicket + this.props.tableauPath;
    const vizContainer = this.vizContainer;
    const options = {
      device: this.props.device,
      width: '100%',
      height: '100%',
      hideTabs: false,
      hideToolbar: true
    };
    this.viz = new tableau.Viz(vizContainer, vizUrl, options);
  };
  
  exportToExcel = () => {
    this.viz.showDownloadDialog();
  }
  // exportToCSV = () => {
  //   this.viz.showExportCrossTabDialog();
  // }
  exportToPDF = () => {
    this.viz.showExportPDFDialog();
  }
  exportToPowerPoint = () => {
    this.viz.showExportPowerPointDialog();
  }
  exportToImage = () => {
    this.viz.showExportImageDialog();
  }
  setFrameSize = () => {
    this.viz.setFrameSize(window.innerWidth, window.innerHeight);
  }
  launchIntoFullscreen = () => {
    var element = document.getElementById("tab-container");
    element.requestFullscreen();
  }
  render() {
    return (
      <>
      <div id="tab-container" className="tab-container" ref={viz => (this.vizContainer = viz)} />
      <div className="d-flex justify-content-end btn-sm">      
      <NavLink onClick={this.exportToPDF}> <FontAwesomeIcon color={'grey'} size={ 'lg' } icon={ faFilePdf } title={'Download PDF'}/></NavLink>              
        <NavLink onClick={this.exportToExcel}><FontAwesomeIcon color={'grey'} size={ 'lg' } icon={ faFileExcel } title={'Download Excel'}/></NavLink>          
        <NavLink onClick={this.exportToPowerPoint}><FontAwesomeIcon color={'grey'} size={ 'lg' } icon={ faFilePowerpoint } title={'Download PowerPoint'}/></NavLink>      
        {/* <NavLink onClick={this.exportToCSV}><FontAwesomeIcon color={'grey'} size={ 'lg' } icon={ faFileCsv } title={'Download CSV'}/></NavLink> */}
        <NavLink onClick={this.exportToImage}> <FontAwesomeIcon color={'grey'} size={ 'lg' } icon={ faImage } title={'Download Image'}/></NavLink>
        <NavLink onClick={this.launchIntoFullscreen}> <FontAwesomeIcon color={'grey'} size={ 'lg' } icon={ faExpand } title={'Get full screen'}/></NavLink>       
      </div>
      </>
    );
  }
}
