/* eslint-disable prettier/prettier */
import { getNotificationAPIToken } from '../msalToken';

const notificationHostPath = process.env.REACT_APP_NOTIFICATION_ENDPOINT;
// const notificationHostPath = 'https://notification-qa-api.azurewebsites.net/api';

class NotificationService {
  fetchUnreadNotification = (applicationName, userEmail) => {
    var readIndicator = false;
    return getNotificationAPIToken().then(token => {
      return fetch(
        `${notificationHostPath}/notification/GetUnreadNotifications/${applicationName}/${userEmail}/${readIndicator}`,
        {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then((response) => { 
        if (!response.ok) {
          return new Promise((resolve, reject) => {
            response.json().then((json) => {
                var errorResponse = {
                    headers: {},
                    status: response.status,
                    message: json.message || '',
                    code: json.code
                };
                for (var header of response.headers.entries()) {
                    errorResponse.headers[header[0]] = header[1];
                }
                if (json.errors && json.errors.length) {
                    errorResponse.message += json.errors.join(',');
                }
                reject(errorResponse);
            });
        })}
        return response.json();
      });
    });
  };

  fetchNotification = (applicationName, userEmail) => {
    return getNotificationAPIToken().then(token => {
      return fetch(
        `${notificationHostPath}/notification/GetNotifications/${applicationName}/${userEmail}`,
        {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then((response) => { 
        if (!response.ok) {
          return new Promise((resolve, reject) => {
            response.json().then((json) => {
                var errorResponse = {
                    headers: {},
                    status: response.status,
                    message: json.message || '',
                    code: json.code
                };
                for (var header of response.headers.entries()) {
                    errorResponse.headers[header[0]] = header[1];
                }
                if (json.errors && json.errors.length) {
                    errorResponse.message += json.errors.join(',');
                }
                reject(errorResponse);
            });
        })}
        return response.json();
      });
    });
  };

  getHubConnectionToUser = userId => {
    return notificationHostPath + `/NotificationHubConnection?userId=${userId}`;
  };

  GetMessageDetailsById = messageId => {
    return getNotificationAPIToken().then(token => {
      return fetch(
        `${notificationHostPath}/notification/GetMessageDetailsById/${messageId}`,
        {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` }
        }
      ).then(response => response.json());
    });
  };

  AddNotificationToSpecificUser = message => {
    return getNotificationAPIToken().then(token => {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(message)
      };
      return fetch(
        notificationHostPath + '/notification/CreateNotification',
        requestOptions
      );
    });
  };

  UpdateAllNotificationAsync = userEmail => {
    return getNotificationAPIToken().then(token => {
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
        //body: JSON.stringify(message)
      };
      return fetch(
        notificationHostPath +
          `/notification/updateAllNotification/${userEmail}`,
        requestOptions
      );
    });
  };

  UpdateNotificationAsync = (messageId, userEmail) => {
    return getNotificationAPIToken().then(token => {
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
        //body: JSON.stringify(message)
      };
      return fetch(
        notificationHostPath +
          `/notification/updateNotification/${messageId}?userEmail=${userEmail}`,
        requestOptions
      );
    });
  };
}

export default new NotificationService();
