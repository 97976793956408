/* eslint-disable prettier/prettier */
import React from 'react';
// import { Modal, ModalBody, ModalFooter, ModalHeader, ModalSize } from '../StyleGuide/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faGlobeAmericas, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ReportSubmitModal = props => {
    
    const [comment, setComment] = React.useState('');



        return (
            <Modal show={props.visible} backdrop="static" keyboard={false}>
                <Modal.Header className="d-flex justify-content-center">
                    <Modal.Title>
                        <Row><span  className='text-center' style={{ fontSize: "68px" }}> <FontAwesomeIcon className="modal-icon" icon={faExclamationCircle} /> </span> </Row>
                        <Row>Warning!</Row>                        
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure that you want to submit this Blood Loss Report?</p>
                    <div className="form-group">
                        <label>Enter a comment.</label>
                        <textarea className="form-control"
                            name="comment"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            rows="6"
                            style={{ resize: 'none' }}>
                        </textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <button          className="btn btn-outline-primary me-1 mb-1"  onClick={props.hide}            >
                        <FontAwesomeIcon icon={faTimes} /> Cancel
                    </button>
                    <button type="button" className="btn btn-primary" onClick={() => props.submit(comment)}>
                    <FontAwesomeIcon icon={faGlobeAmericas} />   
                    
                        Submit
                    </button>
                </Modal.Footer>
            </Modal>
        )
    } // end of ReportSubmitModal


ReportSubmitModal.propTypes = {
    visible: PropTypes.bool,
    submit: PropTypes.func,
    hide: PropTypes.func
}
export default ReportSubmitModal;