/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Breadcrumb } from 'react-bootstrap';
import userService from '../../services/user-service';
import catalogService from '../../services/catalog-service';
import Select from 'react-select';
import { faSave, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import PageHeader from 'components/common/PageHeader';
import notificationService from '../../services/notification-service';
import ConfirmationMessage from 'components/common/ConfirmationMessage';
import { Helmet } from 'react-helmet';
import FileSharePermission from './fileShare_permission';

const organizerOptions = [
  { value: '', label: 'None', selected: true },
  { value: 'auth', label: 'Auth' },
  { value: 'ih', label: 'Immunohematology' },
  { value: 'dg', label: 'Data Governance' },
  { value: 'finance', label: 'Finance' }
];

const UserRoleNew = () => {
  const profile = JSON.parse(localStorage.getItem('userProfile'));
  const [assignedPermissions, setAssignedPermission] = useState([]);
  const [roleName, setRoleName] = useState();
  const [value, setValue] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [domain, setDomains] = useState([]);
  const [subdomain, setSubdomains] = useState({ parentId: '', domains: [] });
  const [tempPermissions, setTempPermissions] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState('');
  const [selectedSubdomain, setSelectedSubdomain] = useState('');
  const [disable, setDisable] = useState(true);
  const fillMsg = 'Please fill in this field';
  const [validateName, setValidateName] = useState(true);
  const [validateMsg, setValidateMsg] = useState(fillMsg);
  const [validateDomain, setValidateDomain] = useState(true);
  const [usersDropdown, setUsersDropdown] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [validatePermission, setValidatePermission] = useState(true);
  const [validateAddPermission, setValidateAddPermission] = useState(true);
  const [pathValue, setPathValue] = useState("");
  const [resourceValue, setResourceValue] = useState("");
  const [filePermissions, setFilePermissions] = useState([]);

  useEffect(() => {
    const allPermissionsPromise = userService.fetchPermissions();
    const domainPromise = catalogService.fetchCatalogDomain();
    const usersDropdownPromise = userService.getAllUsersDropdown();
    Promise.all([allPermissionsPromise, domainPromise, usersDropdownPromise]).then(
      ([allPermissions, domain, allUsers]) => {
        allPermissions.forEach(item => {
          renameKey(item, 'id', 'value');
          renameKey(item, 'title', 'label');
        });

        setUsersDropdown(allUsers);
        setPermissions(allPermissions);
        setTempPermissions(allPermissions);
        setDomains(domain);
      }
    );
    setDisable(true);
  }, []);

  function renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }

  const handleSubmit = e => {
    e.preventDefault();
    setDisable(true);
    const validate = validateFields();
    if (validate === false) {
      return;
    }

    var check = false;
    filePermissions.find(item => {
    let mapped = filePermissions.filter(ele => ele.path == item.path && ele.resource == item.resource && ele.state != "Delete");
    if(mapped && mapped.length > 1 )
    {
      //setDisable(true);
      setValidatePermission(false);
      setValidateAddPermission(false);
      check = true;
    }
    });

    if(check)
    {
      return;    
    }
    else{
      setValidatePermission(true);
      setValidateAddPermission(true);
    }
    if (e.target.value == 'save') {
      assignedPermissions.forEach(item => {
        renameKey(item, 'value', 'id');
        renameKey(item, 'label', 'name');
        item['title'] = '';            //required for permisison model in API
        item['rolePermissions'] = [];  //required for Permission model in API
      });
      selectedUsers.forEach(item => {
        renameKey(item, 'value', 'id');
        // renameKey(item, 'label', 'name');
      });
      var role = { name: roleName, domainId: selectedSubdomain? selectedSubdomain : selectedDomain, users: selectedUsers };
      userService.createRoles(role).then(rol => {
        userService.AddRolePermissions(assignedPermissions, rol.id)
        .then(() => {
          userService.addUpdateFilePermissions(rol.id, filePermissions);
          setFilePermissions(permission => {
          return permission.filter(item => item.state != "Delete")
          });
        })
        .then(() => {
          toast(ConfirmationMessage("Role(s) Saved", "Role(s) Permission Saved Successfully!", "bg-success", "faCheckCircle"), {
            autoClose: true,
            //className: 'transparent',
            //style: {{background-color: transparent !important}},
            position: 'bottom-right',
            hideProgressBar: true,
            closeButton: false,
          });          
          
          userService.fetchAllAssignedUsers(rol.id).then ((user) => {
            let reciepients = [];
            user.map(u => {reciepients.push(u.email);} )
            const messageBody = `You has been assigned to this Hemetrics roles ${roleName}.`;        
            const notificationRequest = { applicationName:"Hemetrics", type: "role", messageHeader: roleName, messageContent: messageBody, createdBy:profile.email, recipientEmailList: reciepients, recipientEmailAddress: ""};
            
            notificationService.AddNotificationToSpecificUser(notificationRequest)            
            .then(() => {
              catalogService.refreshCatalogCache()
              .then(()=>{          
                  window.location.href = '/admin/UserRoles';
              })              
            })            
          });          
        });
      });
    }
  };

  function onChangeDomain(e) {
    catalogService
      .fetchCatalogSubdomainByParent(e.value)
      .then(subdomain => {
        setSubdomains(subdomain);
      })
      .catch(err => console.log(err));
    setSelectedDomain(e.value);
    setSelectedSubdomain(null);
    setDisable(false);
  }

  const handleChange = value => {
    setRoleName(value);
    setDisable(false);
  };

  function onDomainFilterChange(e) {
    setPermissions(
      tempPermissions.filter(dom => dom.permission.includes(e.value))
    );
    setDisable(false);
  }
  function onChangeSubdomain(e) {
    setValue(e.value);
    setSelectedSubdomain(e.value);
  }
  const [validateRoles, setValidateRoles] = useState(true);
  const validateFields = () => {
    if (roleName) {
      setValidateName(true);
    } else {
      setValidateMsg(fillMsg);
      setValidateName(false);
      return false;
    }
    if (selectedDomain) {
      setValidateDomain(true);
    } else {
      setValidateDomain(false);
      return false;
    }
    if (assignedPermissions.length > 0) {
      setValidateRoles(true);
    } else {
      setValidateRoles(false);
      return false;
    }
    return true;
  };
  function onClickNewTarget () {
    
    var serialNumber = 1;
    if (filePermissions.length > 0) {
      var latest = Math.max(...filePermissions.map(o=> o.serialId));
      serialNumber = latest + 1;
    } else {
      serialNumber = 1;
    }

    // Empty out the previous assigned target array
    const newassignedTarget = {
      serialId: serialNumber,
      dataAccessPolicyId: 0,
      path: '',
      resource: '',
      state: 'Add'
    }
    const list = filePermissions.concat(newassignedTarget); 
    setFilePermissions(list);
    setDisable(false);
  }
  function onClickRemoveTarget (serialId, dataAccessPolicyId) {    
    console.log(serialId);
    setFilePermissions(permission => permission.map(item => {
      if(item.serialId == serialId && item.dataAccessPolicyId == dataAccessPolicyId)
      {
        return {
          ...item,
          state: 'Delete'
      }
      }else {
        return item;
      }
    }));

    let mapped = filePermissions.filter(ele => ele.path == pathValue && ele.serialId != serialId && ele.resource == resourceValue);
    if(mapped && mapped.length > 1 )
    {
      setDisable(true);
      setValidatePermission(false);
      setValidateAddPermission(false);
    }
    else
    {
      setDisable(false);
      setValidatePermission(true);
      setValidateAddPermission(true);  
    }
    
  }

  function onClickPathChange (serialId, dataAccessPolicyId, value) {
    setPathValue(value);
    let mapped = filePermissions.filter(ele => ele.path == value && ele.serialId != serialId && ele.resource == filePermissions.filter(item => item.serialId == serialId)[0].resource && ele.state != "Delete");
    if(mapped && mapped.length > 0 )
    {
      setDisable(true);
      setValidatePermission(false);
      setValidateAddPermission(false);
      return;
    }
    else
    {
      setDisable(false);
      setValidatePermission(true);
      setValidateAddPermission(true);    
    }
    
    if(dataAccessPolicyId == 0)
    {
      setFilePermissions(permission => permission.map(item => {
        if(item.serialId == serialId)
        {
          return {
            ...item,
            path: value,
            state: 'Add'
        }
        }else {
          return item;
        }
      }));
    }
    else
    {
      setFilePermissions(permission => permission.map(item => {
        if(item.serialId == serialId)
        {
          return {
            ...item,
            path: value,
            state: 'Update'
        }
        }else {
          return item;
        }
      }));
    }    
  }
  function onClickResourceChange (serialId, dataAccessPolicyId, value) {
    setResourceValue(value);
    let mapped = filePermissions.filter(ele => ele.path == filePermissions.filter(item => item.serialId == serialId)[0].path && ele.serialId != serialId && ele.resource == value && ele.state != "Delete");
    if(mapped && mapped.length > 0 )
    {
      setDisable(true);
      setValidatePermission(false);
      setValidateAddPermission(false);
      return;
    }
    else
    {
      setDisable(false);
      setValidatePermission(true);
      setValidateAddPermission(true);    
    }

    if(dataAccessPolicyId == 0)
    {
      setFilePermissions(permission => permission.map(item => {
        if(item.serialId == serialId)
        {
          return {
            ...item,
            resource: value,
            state: 'Add'
        }
        }else {
          return item;
        }
      }));
    }
    else
    {
      setFilePermissions(permission => permission.map(item => {
        if(item.serialId == serialId)
        {
          return {
            ...item,
            resource: value,
            state: 'Update'
        }
        }else {
          return item;
        }
      }));
    }
    setDisable(false);
  }

  return (
    <>
      <Helmet>
        <title>New Roles</title>
      </Helmet>
      <Card>
        <PageHeader title="Add Roles" className="mb-2">
        <Breadcrumb>  
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/admin/userroles">Manage Roles</Breadcrumb.Item>
          <Breadcrumb.Item active>New</Breadcrumb.Item>  
        </Breadcrumb>
        </PageHeader>
        <Card.Body className="bg-light">
          <Form onSubmit={handleSubmit}>
            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={8} controlId="roleName">
                <Form.Label>
                  Role Name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!validateName}
                  type="text"
                  placeholder="Role Name"
                  //value={roleName}
                  name="roleName"
                  onChange={({ target }) => handleChange(target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {validateMsg}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-2 g-2">
              <Form.Group as={Col} lg={4} controlId="domain">
                <Form.Label>
                  Domain<span className="text-danger">*</span>
                </Form.Label>
                <Select
                  className={validateDomain ? '' : 'validation-error'}
                  closeMenuOnSelect={true}
                  options={domain.filter(dom => dom.domainParentId == null)}
                  placeholder="Select"
                  //classNamePrefix="react-select"
                  value={domain.find(item => item.value == selectedDomain)}
                  onChange={onChangeDomain}
                />
                {!validateDomain && (
                  <span className="validation-error-msg">
                    Please fill out this field.
                  </span>
                )}
              </Form.Group>
              <Form.Group as={Col} lg={4} controlId="subdomain">
                <Form.Label>Sub Domain</Form.Label>
                <Select
                  // className={validateSubdomain ? '' : 'validation-error'}
                  closeMenuOnSelect={true}
                  options={subdomain.domains}
                  placeholder="Select"
                  //classNamePrefix="react-select"
                  value={subdomain.domains.find(item => item.value === selectedSubdomain)? subdomain.domains.find(item => item.value === selectedSubdomain) : null}
                  onChange={onChangeSubdomain}
                />
              </Form.Group>
              <Form.Group as={Col} lg={4}>
                <Form.Label>Filter by domain</Form.Label>
                <Select
                  closeMenuOnSelect={true}
                  options={organizerOptions}
                  placeholder="Filter by Domain"
                  //classNamePrefix="react-select"
                  value={organizerOptions.find(item => item.value === value)}
                  onChange={value => onDomainFilterChange(value)}
                  //isDisabled={true}
                />
              </Form.Group>
              <Form.Group>
                  <Form.Label>
                  Assign permissions<span className="text-danger">*</span>
                </Form.Label>
                <Select
                  className={validateRoles ? '' : 'validation-error'}
                  closeMenuOnSelect={false}
                  options={permissions}
                  placeholder="Select"
                  isMulti
                  //classNamePrefix="react-select"
                  value={assignedPermissions}
                  onChange={value => {
                    setAssignedPermission(value);
                    setDisable(false);
                  }}
                />
                {!validateRoles && (
                  <span className="validation-error-msg">
                    Please fill out this field.
                  </span>
                )}
            </Form.Group>
            <Form.Group>
                  <Form.Label>
                  Users
                </Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={usersDropdown}
                  placeholder="Select"
                  isMulti
                  //classNamePrefix="react-select"
                  value={selectedUsers}
                  onChange={value => {
                    setSelectedUsers(value);
                    setDisable(false);
                  }}
                />
            </Form.Group>
            </Row>
            <br/>
            <div>
              { 
                 <Row className="flex-between-center">
                 <Col xs={9} sm={6} lg={3}>
                   <Form.Group >
                     <Form.Label>File Share Permissions</Form.Label>
                   </Form.Group>
                   {!validatePermission && (
                  <span className="validation-error-msg">
                    Some Permissions are duplicate.
                  </span>
                )}
                 </Col>
                 <Col xs="auto" sm={3} lg={3} className="text-end marginTop">
                 <button className="btn btn-outline-primary me-1 mb-1" type="button" disabled={!validateAddPermission} onClick={onClickNewTarget} >
                     <FontAwesomeIcon icon={faPlus} /> Add New Permission
                   </button>
                 </Col>
               </Row>

              }

              { filePermissions && filePermissions.map(tr =>{
                   { if(tr.state !== "Delete"){
                    return ( <FileSharePermission
                    serialId = {tr.serialId}
                    path = {tr.path}
                    resource = {tr.resource}
                    dataAccessPolicyId = {tr.dataAccessPolicyId}
                    onClickRemoveTarget = {onClickRemoveTarget}
                    onClickPathChange = {onClickPathChange}
                    onClickResourceChange = {onClickResourceChange}
                    />
              )}}}
                )}
                  <br/>
          </div>
            <br />
            <div className="text-end">
              <button
                className="btn btn-outline-primary me-1 mb-1"
                onClick={handleSubmit}
                value="save"
                disabled={disable}
              >
                <FontAwesomeIcon icon={faSave} /> Save
              </button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default UserRoleNew;