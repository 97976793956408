/* eslint-disable prettier/prettier */
import React from 'react';
import './ReportStatusPill.css';
import PropTypes from 'prop-types';


const ReportStatusPill = ({ status }) => {
    const className = getStatusPillClassName(status);

    if (className) {
        return <span className={className}>{status}</span>
    } else {
        return null;
    }
}

function getStatusPillClassName(status) {
    switch (status) {
        case "Draft":
            return "report-status-pill report-status-pill-draft"
        case "Submitted":
            return "report-status-pill report-status-pill-submitted"
        case "Approved":
            return "report-status-pill report-status-pill-approved"
        case "Rejected":
            return "report-status-pill report-status-pill-rejected"
        default:
            return null;
    }
}

ReportStatusPill.propTypes = {
    status: PropTypes.string
}
export default ReportStatusPill;