/* eslint-disable react/jsx-key */
/* eslint-disable prettier/prettier */
import React, {useState, useContext, useEffect } from 'react';
import NavbarDropdown from './NavbarDropdown';
import catalogService from 'services/catalog-service';
import {
  // fileShareRoutes,
  reportsRoutes,
  // dataGovernanceRoutes,
  documentationRoutes,
  servicesRoutes,
  settingsRoutes
} from 'components/navbar/routes';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AppContext from 'context/Context'; 
import DashboardDropdown from './DashboardDropdown';
import DocumentationDropdown from './DocumentationDropdown';
import ServicesDropdown from './ServicesDropdown';
// import NavbarDropdownModules from './NavbarDropdownModules';
import authService from 'services/auth-service';
import Enum from 'common/enum';
import {setServicesRoutes, setSettingsRoutes} from 'components/navbar/helper';
const NavbarTopDropDownMenus = () => {
  
  const [dashboardRoutes, setDashboardRoutes] = useState({});
  const [analyticsRoutes, setAnalyticsRoutes] = useState({});
  const [showServicesRoutes, setShowServicesRoutes] = useState([]);
  const [showSettingsRoutes, setShowSettingsRoutes] = useState([]);

  useEffect(() => {        
        setShowServicesRoutes(setServicesRoutes(servicesRoutes));
        setShowSettingsRoutes(setSettingsRoutes(settingsRoutes));
        const result = JSON.parse(window.sessionStorage.getItem(Enum.CACHED_DASHBOARDMENU));
        if (result) {
          setDashboardRoutes(result);  
        }
        else {
        catalogService.fetchDashboardMenu()
                        .then(res => {
                          setDashboardRoutes(res.dashboardRoutes[0]);              
                      }).catch(err => {
                        setDashboardRoutes(()=> { return {label: 'Dashboards', children: [] }});                       
                        console.log(err);
                      });;
      }
      const resultAnalytics = JSON.parse(window.sessionStorage.getItem(Enum.CACHED_ANALYTICSMENU));
      if (resultAnalytics) {
        setAnalyticsRoutes(resultAnalytics);  
      } else {
      catalogService.fetchDashboardMenu()
                      .then(res => {
                        setAnalyticsRoutes(res.dashboardRoutes[1]);              
                    }).catch(err => {
                      setAnalyticsRoutes(()=> { return {label: 'Analytics', children: [] }});                       
                      console.log(err);
                    });;
    }
  }, []);
 
  const {
    config: { navbarCollapsed, showBurgerMenu },
    setConfig
  } = useContext(AppContext);

  const handleDropdownItemClick = () => {
    if (navbarCollapsed) {
      setConfig('navbarCollapsed', !navbarCollapsed);
    }
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };  
  
  return (
    <>     
      {dashboardRoutes && (
      <NavbarDropdown title="Dashboards">
        <DashboardDropdown items={dashboardRoutes.children} />
      </NavbarDropdown>)}

      {analyticsRoutes.children?.length > 0 && (
      <NavbarDropdown title="Analytics">
        <DashboardDropdown items={analyticsRoutes.children} />
      </NavbarDropdown>)}
 
      {reportsRoutes &&
        <Dropdown.Item
        key={reportsRoutes.children[0].name}
        as={Link}
        className="nav-link fw-semi-bold"
        to={reportsRoutes.children[0].to}
        onClick={handleDropdownItemClick}
        >
        {reportsRoutes.children[0].name}
        </Dropdown.Item>}
      
 
      {(showServicesRoutes.length || showServicesRoutes.children) && 
      <NavbarDropdown title={servicesRoutes.label}>
        {showServicesRoutes.map(route =>  {         
           return (              
              <ServicesDropdown items={route} />        
             )
        })}
      </NavbarDropdown>}

      {(showSettingsRoutes.length || showSettingsRoutes.children) && 
      <NavbarDropdown title={settingsRoutes.label}>
        {settingsRoutes.children.map(route =>  {         
        if ((!route.permissions && !route.to && route.children)
            || (route.permissions && route.permissions.length === 1 && route.permissions[0] === 'all')
            || (route.permissions && route.permissions.length && authService.hasOneOfPermissions(route.permissions))) { return (
          <Dropdown.Item
            key={route.name}
            as={Link}
            className={route.active ? 'link-600' : 'text-500'}
            to={route.to}
            onClick={handleDropdownItemClick}
          >
            {route.name}
          </Dropdown.Item>
            )}}
        )}
      </NavbarDropdown>}
      
      <NavbarDropdown title={documentationRoutes.label}>
        <DocumentationDropdown routes={documentationRoutes.children} />      
      </NavbarDropdown>
   </>
  );
};

export default NavbarTopDropDownMenus;
