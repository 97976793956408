import React, { Fragment } from 'react';
import { Card, Nav } from 'react-bootstrap';
import Enum from 'common/enum';
import FalconCardHeader from '../../../components/common/FalconCardHeader';

const ProfileRole = () => {
  const userProfile = JSON.parse(
    window.localStorage.getItem(Enum.CACHED_USER_PROFILE)
  );

  return (
    <Card>
      <FalconCardHeader title="Functional Groups" />
      <Card.Body className="bg-light">
        <Nav className="flex-column" as="ul">
          {userProfile.roles.map(role => (
            <Fragment key={role.id}>
              <span> {role.name} </span>
            </Fragment>
          ))}
        </Nav>
      </Card.Body>
    </Card>
  );
};

export default ProfileRole;
