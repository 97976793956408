import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import createMarkup from '../../helpers/createMarkup';
import Avatar from '../../components/common/Avatar';
import activeDirectoryService from 'services/activeDirectory-service';

const Notification = ({
  createdBy,
  initial,
  size,
  time,
  className,
  unread,
  flush,
  emoji,
  children,
  to
}) => {
  const [photo, setPhoto] = useState(null);
  useEffect(() => {
    activeDirectoryService.getUserProfilePhotoFromEmail(createdBy).then(o => {
      const url = window.URL || window.webkitURL;
      const blobUrl = url.createObjectURL(o.data);
      setPhoto(blobUrl);
    });
  }, []);

  return (
    <Link
      className={classNames(
        'notification',
        { 'notification-unread': unread, 'notification-flush': flush },
        className
      )}
      to={to}
    >
      <div className="notification-avatar">
        <Avatar src={photo} name={initial} size={size} className="me-3" />
      </div>

      <div className="notification-body">
        <p className="mb-1" dangerouslySetInnerHTML={createMarkup(children)} />
        <span className="notification-time">
          {emoji && (
            <span className="me-2" role="img" aria-label="Emoji">
              {emoji}
            </span>
          )}
          {time}
        </span>
      </div>
    </Link>
  );
};
Notification.propTypes = {
  createdBy: PropTypes.string,
  initial: PropTypes.string,
  size: PropTypes.string,
  time: PropTypes.string.isRequired,
  className: PropTypes.string,
  unread: PropTypes.bool,
  flush: PropTypes.bool,
  emoji: PropTypes.string,
  children: PropTypes.node,
  to: PropTypes.string
};

Notification.defaultProps = { unread: false, flush: false };

export default Notification;
