/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Card, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
//import { Link } from 'react-router-dom';
import homeService from 'services/home-service';
//import Background from 'components/common/Background';
//import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';
//import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import DistributionFunnelChart from './DistributionFunnelChart';
import Flex from 'components/common/Flex';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';


const DistributionBudget = () => {
  //const profile = JSON.parse(localStorage.getItem('userProfile'));
  //var myDate = new Date();
  //var hrs = myDate.getHours();
  const profile = JSON.parse(localStorage.getItem('userProfile'));
  const [distributionData, setDistributionData] = useState({
    rbcShipped:0,
    distributionMonth:'',
    rbcBudget:0,
    rbcPercent:0,
    pltShipped:0,
    pltBudget:0,
    pltPercent:0
  });
  var emailSplit = profile.email.split('@');
  const NameForTotal = 'NYBCe';
  const [divisions, setDivisions] = useState([]); //['NYBCe','NYBC','IBR','RIBC'];
  const [allData, setAllData] = useState([]);
  const [distributionMonth, setDistributionMonth] = useState('');
  const [oneDivision, setOneDivision] = useState(profile.isEnterpriseView ? NameForTotal : emailSplit[1].split('.')[0].toUpperCase());
  const sortOrder = [NameForTotal,'NYBC','CBC', 'IBR', 'RIBC', 'BBD'];

  useEffect(() => {
    homeService.getDistributionBudget().then(result => {
      const temp = result.map(a=> { return {
         rbcShipped: a.rbcShipped,
         distributionMonth: a.distributionMonth, 
          rbcBudget: a.rbcBudget,
           rbcPercent: a.rbcPercent,
            pltShipped: a.pltShipped,
             pltBudget: a.pltBudget,
             pltPercent: a.pltPercent} } );

          //sum up all units by phlebotomy and collectionWeek
        const sumOfUnits = Array.from(temp.reduce((acc, {rbcShipped, rbcBudget, rbcPercent, pltShipped, pltBudget, pltPercent, ...r}) => {
          const key = JSON.stringify(r);
          const current = acc.get(key) || {...r, rbcShipped: 0, rbcBudget: 0, rbcPercent: 0, pltShipped: 0, pltBudget: 0, pltPercent: 0 };
          return acc.set(key, {...current,
            rbcShipped: current.rbcShipped + rbcShipped,
            rbcBudget: current.rbcBudget + rbcBudget,
            rbcPercent: current.rbcPercent + rbcPercent,
            pltShipped: current.pltShipped + pltShipped,
            pltBudget: current.pltBudget + pltBudget,
            pltPercent: current.pltPercent + pltPercent});
        }, new Map).values());

        const allData = sumOfUnits.map(a => ({ division: NameForTotal, ...a }));
        // console.log(allData);
        allData.map(a=> result.push(a));
        setAllData(result);
        var divisionValues = [...new Set(result.map(a => a.division))];
        divisionValues.sort(
          function(a, b){                
              return sortOrder.indexOf(a) - sortOrder.indexOf(b); 
          }
        );
        setDivisions(divisionValues); 

        //setCollection(result);
        //setBudget(result.filter(a=>a.division === oneDivision).map(a=>a.rbcBudget));
        var data = result.filter(a=>a.division === oneDivision);
        if(data.length > 0){
        setDistributionMonth(data[0].distributionMonth);
        setDistributionData({
          rbcShipped: data[0].rbcShipped,
          rbcBudget: data[0].rbcBudget,
          distributionMonth: data[0].distributionMonth,
          rbcPercent: data[0].rbcShipped/data[0].rbcBudget,
          pltShipped: data[0].pltShipped,
          pltBudget: data[0].pltBudget,
          pltPercent: data[0].pltShipped/data[0].pltBudget
        });
      }
    });
  }, []);

  function onChangeDivision (e) {
    setOneDivision(e.target.value);
    //const divisionData = allData.filter(a=>a.division === e.target.value);
    var data = allData.filter(a=>a.division === e.target.value);
      if(data.length > 0){
        setDistributionData({
          rbcShipped: data[0].rbcShipped,
          rbcBudget: data[0].rbcBudget,
          rbcPercent: data[0].rbcShipped/data[0].rbcBudget,
          pltShipped: data[0].pltShipped,
          pltBudget: data[0].pltBudget,
          pltPercent: data[0].pltShipped/data[0].pltBudget
        });
      }
  }

  return (
    <Card className="h-md-100">
      {/* <Background image={bg2} className="p-card bg-card" /> */}
      <FalconCardHeader
        title={ <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>Shows total hospital distribution for the current month against stated budget. Distribution excludes internal transfers, returned units, and customer-to-customer transfers. * Note, NYBC Budget currently includes Cooper, though the account is serviced by BBD. For the first 3 days of a month, the previous completed month will be displayed.</Tooltip>
          }
        >
        <span> {distributionMonth} Hospital Distribution
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              transform="shrink-1"
              className="ms-1 text-400"
              id="weeklySalesTooltip"
            />
          </span>
        </OverlayTrigger>}
        titleTag="h6"
        className="border-200 border-bottom py-2"
        endEl={
          <Flex>
            <Form.Select
              size="sm"
              value={oneDivision}
              onChange={onChangeDivision}
              //className="w-auto"
            >
              {divisions.map((label) => (
                <option value={label} key={label}>
                  {label}
                </option>
              ))}
            </Form.Select>
          </Flex>
        }
      />
      {/* <Card.Header>
          <div>
          <Row className="flex-between-center g-0">
            <div>
                <h6 className="mb-0">YTD Distribution Budget</h6>
              </div>             
            </Row>
          </div>
        </Card.Header> */}
      <Card.Body className="pb-2">
        <Flex
            justifyContent="between"
            alignItems="center"
            className="rtl-row-reverse"
            >
            <h6 className="fs--2 text-500 flex-1 px-2">Product</h6>           
            <h6 className="fs--2 text-500 mx-4">Actual</h6>
             <h6 className="fs--2 text-500 mx-1">Distribution %</h6>
            </Flex>
            
            <DistributionFunnelChart data={distributionData} />

            <div className="text-end">
                <Link to="/dashboards/14320" className="fw-semi-bold fs--1 text-nowrap" size="sm">
                View<FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    className="ms-1"
                    transform="down-1"
                />
            </Link>
          </div>
      </Card.Body>
            
      {/* <Card.Body>
        <div>
        
        </div>
      </Card.Body> */}
    </Card>
  );
};

export default DistributionBudget;
