/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import Flex from 'components/common/Flex';
import { Card, Row, Col, Breadcrumb, Spinner } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import notificationService from '../../services/notification-service';
import userService from '../../services/user-service';
import { useParams } from 'react-router-dom';
import DateFormat from '../../components/common/date-format';
import activeDirectoryService from 'services/activeDirectory-service';
import PageHeader from 'components/common/PageHeader';

const profile = JSON.parse(localStorage.getItem('userProfile'));
const NotificationDetail = () => {
  const [emailDetails, setEmailDetails] = useState({
    sender: {
      name: '',
      avatar: '',
      time: '',
      email: ''
    },
    subject: ''
  });
  const [avatar, setAvatar] = useState({
    src: '',
    name: '',
    size: ''
  });

  let params = useParams();
  var [message, setMessage] = useState([]);
  const messageId = params.messageId;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    //const messageDetailsPromise =
    notificationService
      .GetMessageDetailsById(messageId)
      .then(messageDetails => {
        setMessage(messageDetails);
        //createdBy
        userService
          .GetUsersByEmailId(messageDetails.messageCreatedBy)
          .then(user => {
            setEmailDetails({
              sender: {
                name: user.name,
                //avatarSrc: messageDetails.messageCreatedBy.substring(0, 2),
                time: (
                  <DateFormat
                    format="MM-DD-YYYY HH:mm"
                    date={messageDetails.messageCreatedOn}
                  />
                ),
                email: messageDetails.messageCreatedBy
              },
              subject: messageDetails.messageHeader
            });
            activeDirectoryService
              .getUserProfilePhotoFromEmailDetails(messageDetails.messageCreatedBy)
              .then(o => {
                const url = window.URL || window.webkitURL;
                const blobUrl = o != undefined ?  url.createObjectURL(o.data) : '';
                setAvatar({
                  src: blobUrl,
                  name: messageDetails.messageCreatedBy.substring(0, 2),
                  size: 'xl'
                });
              });
              setIsLoading(false);
          }).catch(err => {
            setIsLoading(false);
            console.log(err);
          });
      });
    notificationService.UpdateNotificationAsync(messageId, profile.email);
  }, [messageId]);

  return (
    <>
      {/* <EmailDetailHeader /> */}
      <PageHeader title="Notification Details" className="mb-2">
      <Breadcrumb>  
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/notification/Notification">Notifications</Breadcrumb.Item>
        <Breadcrumb.Item active>Notification Details</Breadcrumb.Item>
      </Breadcrumb>
      </PageHeader>
      <Card>
        {isLoading ? <div className='text-center'><Spinner animation="border" /></div> :
        <Card.Header>
          <Row>
            <Col md as={Flex}>
              {avatar.name != '' && <Avatar {...avatar} />}
              <div className="flex-1 ms-2">
                <h5 className="mb-0">{emailDetails.subject}</h5>
                {avatar.name != '' && (
                  <a
                    className="text-800 fs--1"
                    href={`mailto:${emailDetails.sender.email}`}
                  >
                    <span className="fw-semi-bold">
                      {emailDetails.sender.name}
                    </span>
                    <span className="ms-1 text-500">
                      &lt;{emailDetails.sender.email}&gt;
                    </span>
                  </a>
                )}
              </div>
            </Col>
            <Col
              md="auto"
              as={Flex}
              alignItems="center"
              className="ps-6 ps-md-3"
            >
              <small>{emailDetails.sender.time}</small>
              {/* <FontAwesomeIcon
                onClick={() => setMarked(!marked)}
                icon={marked ? 'star' : ['far', 'star']}
                className={classNames(
                  'ms-2 fs--1',
                  { 'text-warning': marked, 'text-300': !marked },
                  'cursor-pointer'
                )}
              /> */}
            </Col>
          </Row>
        </Card.Header>}
        <Card.Body className="bg-light">
          <Row>
            <Col>
              <Card className="shadow-none mb-3">
                <Card.Body>
                {avatar.name != '' &&
                  <div
                    dangerouslySetInnerHTML={{ __html: message.messageContent }}
                  />
                  }
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default NotificationDetail;
