/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
  Badge,
  Card
} from 'react-bootstrap';
import { faQuestionCircle, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import catalogService from 'services/catalog-service';
import authService from 'services/auth-service';
import DateFormat from 'components/common/date-format';
import PropTypes from 'prop-types';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Background from 'components/common/Background';
import corner4 from '../../assets/img/illustrations/corner-4.png';

const DashboardReportPageHeader = ({
  isMobile,
  isPad,
  catalogName,
  reportStatus,
  reportStatusVariant,
  developer,
  stakeholder,
  description,
  type,
  lastModified,
  catalogId,
  favoriteFlag
}) => {
  const [isFavorite, setIsFavorite] = useState(favoriteFlag);
  const [informationModalVisible, setInformationModalVisible] = useState(false);
  const [catalogRoles, setCatalogRoles] = useState([]);
  const userProfile = authService.getCurrentUserProfile();
  const releaseTooltip =
    'The content of this view has been validated and signed off by the data stewards. For questions please reach out to the data-product stewards.';
  const validationTooltip =
    'The content of this view is currently under review by the data-product stewards. Please use the data appropriately.';

  useEffect(() => {
    // Get Data for Catalog Roles
    catalogService.fetchCatalogRoles(catalogId)
          .then((catalogRoles) => {
            setCatalogRoles(catalogRoles);
          });
  }, []);

  function MarkAsFavorite() {
    if (!isFavorite) {
      // Do an api call to mark isFavorite
      catalogService.InsertFavoritesReports(userProfile.id, catalogId);
      setIsFavorite(true);
    } else {
      // Do an api call to mark as not favorite
      catalogService.DeleteFavoritesReports(userProfile.id, catalogId);
      setIsFavorite(false);
    }
  }
  return (
    <>
      {!isMobile && !isPad && catalogName && (
         <Card className="mb-2">
        <Background
        image={corner4}
        className="bg-card"
        style={{
          borderTopRightRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem'
        }}
      />
      <Card.Body className="position-relative">
        <Row>
        <Col className="text-start">
          <h3>{catalogName} </h3>
        </Col>
        <Col className="text-end mt-n1">
          <OverlayTrigger
            placement={'bottom'}
            overlay={
              <Tooltip id="status">
                {reportStatus === 'In Validation'
                  ? validationTooltip
                  : releaseTooltip}
              </Tooltip>
            }
          >
            <span
              style={{
                fontSize: '15px'
              }}
            >
              <Badge pill bg={reportStatusVariant} className="fs--2">
                {reportStatus}
              </Badge>
            </span>
          </OverlayTrigger>
          &nbsp;&nbsp;
          <Button
            className="btn-sm"
            variant=""
            onClick={() => MarkAsFavorite()}
          >
            {isFavorite && (
              <FontAwesomeIcon icon={faStar} className="icon-star" />
            )}
            {!isFavorite && <FontAwesomeIcon icon={faStar} />}
            Favorite
          </Button>
          <Button
            className="btn-sm"
            onClick={() => setInformationModalVisible(true)}
            variant=""
          >
            <FontAwesomeIcon icon={faQuestionCircle} /> Properties
          </Button>
        </Col>
      </Row>
      </Card.Body>
      </Card>
     
      )}
      {isPad && (
        <>
          <Row className="mb-2">
            <Col className="text-start">
              <h5>{catalogName} </h5>
            </Col>
            <Col className="text-end mt-n1">
              <OverlayTrigger
                placement={'bottom'}
                overlay={
                  <Tooltip id="status">
                    {reportStatus === 'In Validation'
                      ? validationTooltip
                      : releaseTooltip}
                  </Tooltip>
                }
              >
                <span
                  style={{
                    fontSize: '15px'
                  }}
                >
                  <Badge pill bg={reportStatusVariant} className="fs--2">
                    {reportStatus}
                  </Badge>
                </span>
              </OverlayTrigger>
              &nbsp;&nbsp;
              <Button
                className="btn-sm"
                variant=""
                onClick={() => MarkAsFavorite()}
              >
                {isFavorite && (
                  <FontAwesomeIcon icon={faStar} className="icon-star" />
                )}
                {!isFavorite && <FontAwesomeIcon icon={faStar} />}
                Favorite
              </Button>
              <Button
                className="btn-sm"
                onClick={() => setInformationModalVisible(true)}
                variant=""
              >
                <FontAwesomeIcon icon={faQuestionCircle} /> Properties
              </Button>
            </Col>
          </Row>
        </>
      )}
      {isMobile && (
        <>
          <Row className="mb-3 mt-2">
            <Col xs={7} className="text-start mx-1">
              <h6>{catalogName}</h6>
            </Col>
            <Col xs={4} className="text-end mt-n1 " style={{paddingRight: 0 }}>
              <OverlayTrigger
                placement={'bottom'}
                overlay={
                  <Tooltip id="status">
                    {reportStatus === 'In Validation'
                      ? validationTooltip
                      : releaseTooltip}
                  </Tooltip>
                }
              >
                <span
                  style={{
                    fontSize: '15px'
                  }}
                >
                  <Badge pill bg={reportStatusVariant} className="fs--2">
                    {reportStatus === 'In Validation' ? 'V' : 'R'}
                  </Badge>
                </span>
              </OverlayTrigger>
              &nbsp;&nbsp;
               <span
                onClick={() => MarkAsFavorite()}
                style={{
                  fontSize: '13px',
                  marginRight: '0.7rem',
                  cursor: 'pointer'
                }}
              >
                {isFavorite && (
                  <FontAwesomeIcon icon={faStar} className="icon-star" />
                )}
                {!isFavorite && <FontAwesomeIcon icon={faStar} />}
                
              </span>
               <span
                onClick={() => setInformationModalVisible(true)}
                style={{
                  fontSize: '13px',
                  cursor: 'pointer'
                }}
              >
                <FontAwesomeIcon icon={faQuestionCircle}/>
              </span>
            </Col>
          </Row>
        </>
      )}
      <Modal size="lg" show={informationModalVisible}>
        <Modal.Header>
          <h4 className="modal-title">{catalogName}</h4>
          <FalconCloseButton onClick={() => setInformationModalVisible(false)} />
        </Modal.Header>
        <Modal.Body>
          <h6 className="text-primary">Catalog Id</h6>
          <div className="mb-0 ps-3"> {catalogId} </div>
          <hr className="my-1" />
          <h6 className="text-primary">Description</h6>
          <div className="mb-0 ps-3" dangerouslySetInnerHTML={{ __html: description }} />
          <hr className="my-1" />
          <h6 className="text-primary">Type</h6>
          <div className="mb-0 ps-3"> {type} </div>
          <hr className="my-1" />
          <h6 className="text-primary">Data Steward(s)</h6>   
          <div className="mb-0 ps-3">       
          {stakeholder.map(item => (
            <div key={item}> {item} </div>
          ))}
          </div>
          <hr className="my-1" />
          <h6 className="text-primary">Developed By</h6>
          <div className="mb-0 ps-3"> {developer} </div>
          <hr className="my-1" />
          <h6 className="text-primary">Last Modified</h6>
          <div className="mb-0 ps-3">
            <DateFormat format="MM-DD-YYYY" date={lastModified} />
          </div>
          <hr className="my-1" />
          <h6 className="text-primary">Permissions (by role)</h6>
          <div className="mb-0 ps-3">
          {catalogRoles.map(role => (
                <div key={role} > {role} </div>
              ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            className="btn btn-primary"
            onClick={() => setInformationModalVisible(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
DashboardReportPageHeader.propTypes = {
  isMobile: PropTypes.bool,
  isPad: PropTypes.bool,
  breadcrumbTitle: PropTypes.string,
  catalogName: PropTypes.string,
  reportStatus: PropTypes.string,
  reportStatusVariant: PropTypes.string,
  developer: PropTypes.string,
  stakeholder: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  lastModified: PropTypes.string,
  roles: PropTypes.string,
  catalogId: PropTypes.number,
  favoriteFlag: PropTypes.bool
};

export default DashboardReportPageHeader;
