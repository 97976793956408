/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import FalconCloseButton from 'components/common/FalconCloseButton';
//import Select from 'react-select';
import { Row, Col, Form, Modal, Button } from 'react-bootstrap';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

  const FileSharePermission = (prop) => {
    const [showConfirmTarget, setShowConfirmTarget] = useState(false);
    //const [validateTargetRange, setValidateTargetRange] = useState(false);
    //const [validateTargetEnd, setValidateTargetEnd] = useState(false);
    const [fileSharePermissions, setFileSharePermissions] = useState({
      serialId: 0,
      dataAccessPolicyId: 0,
      path: '',
      resource: '',
      state: ''
    });

    //let ifExist = false;
    //Find if existing record
    // if (prop.kpiTargetId > 0) {
    //   ifExist = true;
    // } else if (prop.targetIdentifier != '' || prop.targetStart != '' || prop.targetEnd != '' || prop.targetRange) {
    //   ifExist = true;
    // } else {
    //   ifExist = false;
    // }
    useEffect(() => {
     setFileSharePermissions ({...fileSharePermissions,
       serialId: prop.serialId,
       dataAccessPolicyId: prop.dataAccessPolicyId,
       path: prop.path,
       resource: prop.resource,
       state: prop.state });
     //setValidateTargetRange(prop.validateTargetRange);
     //setValidateTargetEnd(prop.validateTargetEnd);
    //  if (prop.targetRange < 0) {
    //   setValidateTargetRange(false);
    //  } else {
    //   setValidateTargetRange(true);
    //  }
    //  if (prop.targetEnd < 0) {
    //   setValidateTargetEnd(false);
    //  } else {
    //   setValidateTargetEnd(true);
    //  }
    }, []);
    const onClickTargetCancel = () => setShowConfirmTarget(false);
    const onClickTargetOk = () => { setShowConfirmTarget(false); deleteAssignedTarget(); }

  function onClickPathChange(e) {
    setFileSharePermissions ({...fileSharePermissions, path: e.target.value});
    prop.onClickPathChange(prop.serialId, prop.dataAccessPolicyId, e.target.value);
    // if (ifExist) {  // if existing records
    //   if (prop.onClickTargetIdentifierChange) {
    //     prop.onClickTargetIdentifierChange(prop.serialId, e.target.value);
    //   }
    // } else { // If new record
    //   if (prop.onClickTargetIdentifierChange) {
    //     prop.onClickTargetIdentifierChange(0, e.target.value);
    //   }
    // }

  }
  function onClickResourceChange(e) {
    setFileSharePermissions ({...fileSharePermissions, resource: e.target.value});
    prop.onClickResourceChange(prop.serialId, prop.dataAccessPolicyId, e.target.value);
    // // if (e.target.value > 0) {
    // //   setValidateTargetStart(true);
    // // } else {
    // //   setValidateTargetStart(false);
    // // }
    // if (ifExist) {  // if existing records
    //   if (prop.onClickTargetChange) {
    //     prop.onClickTargetChange(prop.serialId, e.target.value);
    //   }
    // } else { // If new record
    //   if (prop.onClickTargetChange) {
    //     prop.onClickTargetChange(0, e.target.value);
    //   }
    // }
  }
  
  function onClickRemovePermission (e) {
    e.preventDefault();
    setShowConfirmTarget(true);
  }
  function deleteAssignedTarget() {
    //if (prop.onClickRemoveTarget) {
      prop.onClickRemovePermission(prop.serialId, prop.dataAccessPolicyId);
    //}
  }

    return (
      <>
            <Row>
                <Form.Group as={Col} lg={6} controlId="path">
                  <Form.Label>Path</Form.Label>
                  <Form.Control
                    // isInvalid={!validateName}
                    type="text"
                    value={fileSharePermissions.path}
                    name="path"
                    onChange={(e)=>{onClickPathChange(e)}}
                  />
                  {/* <Form.Control.Feedback type="invalid" >Please fill out this field.</Form.Control.Feedback> */}
                </Form.Group>

                <Form.Group as={Col} lg={4} controlId="resource">
                  <Form.Label>Resource</Form.Label>
                  <Form.Control
                    // isInvalid={!validateName}
                    type="text"
                    value={fileSharePermissions.resource}
                    name="resource"
                    onChange={(e)=>{onClickResourceChange(e)}}
                  />
                  {/* {!validateTargetRange && (<span className='validation-error-msg'>Please fill out this field.</span>)} */}
                </Form.Group>
                
                <Form.Group as={Col} lg={1} controlId="name">
                <br />
                {/* {showSave && (
                  <button className="btn btn-outline-primary me-1 mb-1 mt-2" type="button" onClick={onClickTargetSave} >
                  <FontAwesomeIcon icon={faSave} />
                  </button>)
                } */}
                <button className="btn btn-outline-primary me-1 mb-1 mt-2" type="button"  onClick={onClickRemovePermission}>
                  <FontAwesomeIcon icon={faTrash} />
                </button>
                </Form.Group>

            </Row>

        <Modal show={showConfirmTarget} onHide={onClickTargetCancel} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Hemetrics</Modal.Title>
          <FalconCloseButton onClick={onClickTargetCancel}/>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this file permission?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onClickTargetOk}>
            OK
          </Button>
          <Button variant="secondary" onClick={onClickTargetCancel} >Cancel</Button> 
        </Modal.Footer>
        </Modal>
  </>
    )
}

  export default FileSharePermission;
