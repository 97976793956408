/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Nav, Collapse } from 'react-bootstrap';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';
import classNames from 'classnames';
import AppContext from '../../../context/Context';
import authService from 'services/auth-service';
// import { reactBootstrapDocsUrl } from 'helpers/utils';

const CollapseItems = ({ route }) => {
  const { pathname } = useLocation();
  // route.propTypes = {
  //   permissions: PropTypes.arrayOf(PropTypes.string),
  // };
  const openCollapse = childrens => {
    const checkLink = children => {
      if (children.to === pathname) {
        return true;
      }
      return (
        children.children &&
        Object.prototype.hasOwnProperty.call(children, 'children') &&
        children.children.some(checkLink)
      );
    };
    return childrens.some(checkLink);
  };

  const [open, setOpen] = useState(openCollapse(route.children));

  if ((!route.permissions )
    || (route.permissions && route.permissions.length === 1 && route.permissions[0] === 'all')
    || (route.permissions && route.permissions.length && authService.hasOneOfPermissions(route.permissions))) {    
        return (
          <Nav.Item as="li">
            <Nav.Link
              onClick={() => {
                setOpen(!open);
              }}
              className={classNames('dropdown-indicator cursor-pointer', {
                'text-500': !route.active
              })}
              aria-expanded={open}
              // {...route}
            >
              <NavbarVerticalMenuItem route={route} />
            </Nav.Link>
            <Collapse in={open}>
              <Nav className="flex-column nav" as="ul">
                <NavbarVerticalMenu routes={route.children} />
              </Nav>
            </Collapse>
          </Nav.Item>
        );
  } else {
    return null;
  }
};


CollapseItems.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    children: PropTypes.array.isRequired,
    active: PropTypes.bool,    
    permissions: PropTypes.array
  }).isRequired
};

const NavbarVerticalMenu = ({ routes }) => {
  const {
    config: { showBurgerMenu },
    setConfig
  } = useContext(AppContext);

  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };
  return routes.map(route => {
    if (!route.children) {
      if ((!route.permissions )
    || (route.permissions && route.permissions.length === 1 && route.permissions[0] === 'all')
    || (route.permissions && route.permissions.length && authService.hasOneOfPermissions(route.permissions))) {           
      return (
        <Nav.Item as="li" key={route.name} onClick={handleNavItemClick}>
          <NavLink
            end={route.exact}
            to={route.to}
            state={{ open: route.to === '/authentication-modal' }}
            className={({ isActive }) =>
              isActive ? 'active nav-link' : 'nav-link'
            }
          >
            <NavbarVerticalMenuItem route={route} />
          </NavLink>
        </Nav.Item>
      );
    } else {
      return null;
    }
  }
    return <CollapseItems route={route} key={route.name} />;
  });
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape(NavbarVerticalMenuItem.propTypes))
    .isRequired
};

export default NavbarVerticalMenu;
