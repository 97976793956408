/* eslint-disable prettier/prettier */
import BaseService from './base-service';
class SearchService extends BaseService {
    getAllItemsForSearch = () => {
        return this._apiGet({
            path: `/common/getAllItemsForSearch`
        });
    }
    getAllItemsForAdvancedSearch = () => {
        return this._apiGet({
            path: `/common/getAllItemsForAdvancedSearch`
        });
    }
}
export default new SearchService();