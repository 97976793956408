/* eslint-disable prettier/prettier */
import PageHeader from 'components/common/PageHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { Card, Breadcrumb, Row, Col, Modal, Button } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  faTrash,
  faPlus,
  faPlay,
  faPause
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import GovernanceService from 'services/governance-service';
import AdvanceTableSearchBox from '../../components/common/advance-table/AdvanceTableSearchBox';
import { useParams } from 'react-router-dom';
import { getItemFromStore } from '../../helpers/utils';
import { settings } from '../../config';
import FalconCloseButton from 'components/common/FalconCloseButton';
import  PopoverComponent from 'pages/common/popover';
import { Helmet } from 'react-helmet';

const dataRulesView = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  let params = useParams();
  const databaseId = params.databaseId;
  const tableConfigId = params.tableConfigId;
  var columns = [
    {
      accessor: 'ruleId',
      Header: 'Rule Id',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start'
      }
    },
    {
      accessor: 'ruleName',
      Header: 'Rule Name',
      Cell: rowData => (
        <Link
          to={`/governance/governanceDataRule/${databaseId}/${tableConfigId}/${rowData.row.original.ruleId}`}
          className="text-primary fw-semi-bold"
        >
          {rowData.row.original.ruleName}
        </Link>
      )
    },
    {
      accessor: 'createdBy',
      Header: 'Created By',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start'
      }
    },
    {
      accessor: 'ruleStatus',
      Header: 'Active',
      headerProps: {
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start'
      }
    },
    {
      accessor: 'action',
      Header: 'Action',
      Cell: rowData => (
        <div>
          <Link
            className="px-2"
            to="#!"
            onClick={() =>
              deleteRule(
                rowData.row.original.ruleId,
                rowData.row.original.ruleStatus == 'Active' ? 3 : 1
              )
            }
          >
            <FontAwesomeIcon
              color={
                rowData.row.original.ruleStatus == 'Active'
                  ? 'RolyalBlue'
                  : 'Green'
              }
              icon={
                rowData.row.original.ruleStatus == 'Active' ? faPause : faPlay
              }
              title={
                rowData.row.original.ruleStatus == 'Active'
                  ? 'In Activate'
                  : 'Activate'
              }
            />
          </Link>
          {rowData.row.original.ruleStatus !== 'Deleted' && (
            <Link
              className="px-2"
              to="#!"
              onClick={() => deleteRule(rowData.row.original.ruleId, 2)}
            >
              <FontAwesomeIcon color="Crimson" icon={faTrash} title="Delete" />
            </Link>
          )}
        </div>
      )
    }
  ];

  const [data, setData] = useState([]);
  //const [dataTemp, setDataTemp] = useState([]);
  const [tableName, setTableName] = useState('');
  const [selectRuleId, setselectRuleId] = useState('');
  const [statusId, setStatusId] = useState('');
  const [message, setMessage] = useState('');
  const configState = {
    gridRowsCount: getItemFromStore('gridRowsCount', settings.gridRowsCount)
  };

  const onClickCancel = () => setShowConfirm(false);
  const onClickOk = () => {
    GovernanceService.deleteRule(selectRuleId, statusId).then(() =>
      GovernanceService.getDataRules(tableConfigId).then(setData)
    );
    setShowConfirm(false);
  };

  useEffect(() => {
    const getDataRule = GovernanceService.getDataRules(tableConfigId);
    const selectedTableData =
      GovernanceService.getSelectedConfigTableData(tableConfigId);
    Promise.all([getDataRule, selectedTableData]).then(
      ([datarules, tableData]) => {
        setData(datarules);
        //setDataTemp(datarules);
        setTableName('Display Data Rules <' + tableData[0].TableName + '>');
      }
    );
  }, []);

  const deleteRule = (dataRuleId, statusId) => {
    if (statusId == 2) {
      setMessage('Are you sure you want to delete it?');
    } else if (statusId == 1) {
      setMessage('Are you sure you want to activate it?');
    } else {
      setMessage('Are you sure you want to inactivate it?');
    }
    setselectRuleId(dataRuleId);
    setStatusId(statusId);
    setShowConfirm(true);
  };

  const dataRuleViewdesc = "Data Rules allows designated data stewards to create business rules against key dimensions for validation and data quality tracking. To create a new business rule, click on the New botton to get started. To edit an existing rule, click on the rule name below. To Activate, Pause or Delete an existing rule, use the appropriate buttons under the Actions column.";

  return (
    <>
      <Helmet>
        <title>Display Data Rules</title>
      </Helmet>
      <PageHeader title={tableName} className="mb-2" col="12">
        <Row>
          <Col className="text-start mt-1">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/governance/activeGovernanceLandPage">
                Enterprise Data Governance
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Display Data Rules</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="text-end mt-n2">
            <PopoverComponent 
              header = "Governance Data Rule Management"
              body = {dataRuleViewdesc}
            />
          </Col>
        </Row>
      </PageHeader>

      <AdvanceTableWrapper
        columns={columns}
        data={data}
        //selection
        sortable
        pagination
        perPage={configState.gridRowsCount}
        rowCount={data.length}
      >
        <Card className="h-100">
          <Card.Header>
            <Row className="flex-between-center">
              <Col xs={9} sm={6} lg={3} className="text-end">
                <AdvanceTableSearchBox table placeholder="Search" />
              </Col>
              {/* <Col xs="auto" sm={3} lg={3} className="text-end">
                <Form.Select
                  size="sm"
                  //value={month}
                  onChange={handleChange}
                  className="me-2"
                >
                  <option value="all" key="0">
                    All
                  </option>

                  <option value="active" key="1">
                    Active
                  </option>

                  <option value="inActive" key="2">
                    InActive
                  </option>
                  <option value="delete" key="2">
                    Delete
                  </option>
                </Form.Select>
              </Col> */}
              <Col xs="auto" sm={3} lg={3} className="text-end">
                <Link
                  className="btn btn-outline-primary me-1 mb-1"
                  to={`/governance/governanceDataRule/${databaseId}/${tableConfigId}/0`}
                >
                  <FontAwesomeIcon icon={faPlus} /> New
                </Link>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={data.length}
              table
              rowInfo
              navButtons
            />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>

      <Modal
        show={showConfirm}
        onHide={onClickCancel}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Hemetrics</Modal.Title>
          <FalconCloseButton onClick={onClickCancel} />
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onClickOk}>
            OK
          </Button>
          <Button variant="secondary" onClick={onClickCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default dataRulesView;
