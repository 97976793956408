/* eslint-disable prettier/prettier */
//Report Viewer source
import '@boldreports/javascript-reporting-controls/Scripts/bold.report-designer.min';
import '@boldreports/javascript-reporting-controls/Scripts/bold.report-viewer.min';
import '@boldreports/javascript-reporting-controls/Content/material/bold.reports.all.compatibility.min.css';
import '@boldreports/javascript-reporting-controls/Content/material/bold.reportdesigner.compatibility.min.css';

//Data-Visualization
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.bulletgraph.min';
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.chart.min';
//Reports react base
import '@boldreports/react-reporting-components/Scripts/bold.reports.react.min';

import boldReportService from '../../services/boldReport-service';
import { Card } from 'react-bootstrap';
import React, { useState, useEffect, useRef } from 'react';
//import { useParams } from 'react-router-dom';
 import './wifaScenarioViewer.css';

const BoldReportWIFASessions = (prop) => {
 // let params = useParams();
  const scenarioId = prop.wifaParamId;//1;//params.id;
 // const wifaParam = prop.wifaParam;
  const isFirstRender = useRef(true);
  const [boldReportToken, setBoldReportToken] = useState('');

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      boldReportService.fetchBoldReportToken()
        .then((boldReportTokenResponse) => {
          setBoldReportToken(boldReportTokenResponse.token);
        })
    } else {
      window.location.reload();
    }
  }, [scenarioId]);

  const reportServiceUrl =
    'https://hemetrics-report.azurewebsites.net/reporting/reportservice/api/Viewer';
  const reportServerUrl =
    'https://hemetrics-report.azurewebsites.net/reporting/api/site/hemprod';
    const reportPathKpi = prop.wifaPath;//'/Inventory/Platelet Projection Scenario';
    var parameters = [{
      Name: prop.wifaParam,
      Values: [scenarioId]
      }];

  return (
    <>
      <div>
        {boldReportToken && (
          <Card className="h-100">
            <Card.Body className="p-0">
              {/* eslint-disable-next-line */}
             <BoldReportViewerComponent
                id="reportviewer-container"
                reportServiceUrl={reportServiceUrl}
                reportServerUrl={reportServerUrl}
                serviceAuthorizationToken={boldReportToken}
                reportPath={reportPathKpi}
                parameters = {parameters}
              />
            </Card.Body>
          </Card>
        )}
      </div>
    </>
  );
};

export default BoldReportWIFASessions;
