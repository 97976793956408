/* eslint-disable prettier/prettier */
import React from 'react';
//import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
//import { Toast, ToastContainer } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { faInfoCircle, faCheckCircle, faExclamationCircle, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { toast } from 'react-toastify';
//import FalconComponentCard from 'components/common/FalconComponentCard';

const ConfirmationMessage = (header, message, color, toastIcon) => {

  //bg-primary , bg-success, bg-danger, bg-warning
  return(
    <div>
    <div className={"toast-header text-white " + color}>
        <strong className="me-auto"><FontAwesomeIcon icon={toastIcon == "faInfoCircle" ? faInfoCircle : toastIcon == "faCheckCircle" ? faCheckCircle : toastIcon == "faExclamationCircle" ? faExclamationCircle : toastIcon == "faExclamationTriangle" ? faExclamationTriangle : faInfoCircle} /> {header}</strong>
        {/* <small>11 mins ago</small> */}
        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div className="toast-body">
        {message}
    </div>
</div>
    // <ToastContainer position="bottom-right" className="mb-5" >
    //   <Toast bg="Primary">
    //     <Toast.Header>
    //       <strong className="me-auto">Bootstrap</strong>
    //       <small className="text-muted">just now</small>
    //     </Toast.Header>
    //     <Toast.Body>See? Just like this.</Toast.Body>
    //   </Toast>
    // </ToastContainer>
    
    // <FalconComponentCard>
    //   <FalconComponentCard.Header title="Stacking" light={false}>
    //     <p className="mb-0 mt-2">
    //       When you have multiple toasts, we   default to vertically stacking them
    //       in a readable manner.
    //     </p>
    //   </FalconComponentCard.Header>
    //   <FalconComponentCard.Body code={stackingCode} language="jsx" />
    // </FalconComponentCard>

  // <ToastContainer show={show} position="bottom-right" className="p-3">
  //   <Toast show={show} position="bottom-right">
  //   <Toast.Header>
  //     {/* <img
  //       src="holder.js/20x20?text=%20"
  //       className="rounded me-2"
  //       alt=""
  //     /> */}
  //     <strong className="me-auto">Bootstrap</strong>
  //     <small>11 mins ago</small>
  //     </Toast.Header>
  //   <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
  //   </Toast>
  // </ToastContainer>
      // toast(`Data Rules Saved Successfully!`, {
      //   autoClose: true,
      //   className: 'toast-container success',
      //   position:'bottom-right',
      //   hideProgressBar:true
      // })

      // <ToastContainer position="bottom-right" className="p-3">
      //   <Toast show={true} delay={3000} autohide>
      //     <Toast.Header closeButton={false}>
      //       <strong className="me-auto">Notification</strong>
      //       {/* <small className="text-muted">2 seconds ago</small> */}
      //     </Toast.Header>
      //     <Toast.Body>{message}</Toast.Body>
      //   </Toast>
      // </ToastContainer>

    //   <Toast
    //   //onClose={() => setShow(false)}
    //   show={show}
    //   className="notice shadow-none bg-transparent"
    //   style={{
    //     maxWidth: '35rem'
    //   }}
    // >
    //   <Toast.Body className="my-3 ms-0 ms-md-5">{message}</Toast.Body>
    // </Toast>
  )
  
}

ConfirmationMessage.propTypes = {
  //show: PropTypes.string,
  //title: PropTypes.object,
  message: PropTypes.string
};

export default ConfirmationMessage;
