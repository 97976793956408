/* eslint-disable react/jsx-no-undef */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Form, Breadcrumb } from 'react-bootstrap';
import AdvanceTableWrapper from '../../components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../../components/common/advance-table/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableSearchBox from '../../components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from '../../components/common/advance-table/AdvanceTableFooter';
import userService from 'services/user-service';
import { settings } from '../../config';
import { getItemFromStore } from '../../helpers/utils';
import PageHeader from 'components/common/PageHeader';
import './admin.scss';
import { Helmet } from 'react-helmet';

const columns = [
  {
    accessor: 'id',
    Header: 'UserId',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'firstName',
    Header: 'Name',
    Cell: rowData => (
      <Link
        to={`${rowData.row.original.id}`}
        className="text-primary fw-semi-bold"
      >
        {rowData.row.original.firstName} {rowData.row.original.lastName}
      </Link>
    )
  },
  {
    accessor: 'email',
    Header: 'Email',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  },
  {
    accessor: 'assignedRoles',
    Header: 'Roles',
    headerProps: {
      className: 'text-start'
    },
    cellProps: {
      className: 'text-start'
    }
  }
];

const UserBrowser = () => {
  const [users, setUsers] = useState([]);
  const [usersTemp, setUsersTemp] = useState([]);
  const configState = {
    gridRowsCount: getItemFromStore('gridRowsCount', settings.gridRowsCount)
  };

  useEffect(() => {
    userService
      .fetchManageUsers()
      .then(res => {
        setUsers(res);
        setUsersTemp(res);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const handleChange = e => {
    if (e.target.value == 'all') {
      setUsers(usersTemp);
    }
    if (e.target.value == 'active') {
      setUsers(usersTemp.filter(user => user.isActive == true));
    }
    if (e.target.value == 'inActive') {
      setUsers(usersTemp.filter(user => user.isActive == false));
    }
  };

  return (
    <>
      <Helmet>
        <title>Manage Users</title>
      </Helmet>
      <PageHeader title="Manage Users" className="mb-2">
      <Breadcrumb>  
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Manage Users</Breadcrumb.Item>
      </Breadcrumb>
      </PageHeader>
      <AdvanceTableWrapper
        columns={columns}
        data={users}
        sortable
        pagination
        perPage={configState.gridRowsCount}
      >
        <Card className="h-100">
          <Card.Header>
            <Row className="flex-between-center">
              <Col xs={9} sm={6} lg={3}>
                <AdvanceTableSearchBox table placeholder="Search" />
              </Col>
              <Col xs="auto" sm={3} lg={2} className='text-end marginTop'>
                <Form.Select
                  size="sm"
                  //value={month}
                  onChange={handleChange}
                  className="me-2"
                >
                  <option value="all" key="0">
                    All
                  </option>

                  <option value="active" key="1">
                    Active
                  </option>

                  <option value="inActive" key="2">
                    InActive
                  </option>
                </Form.Select>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={users.length}
              table
              rowInfo
              navButtons
            />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

UserBrowser.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      assignedRoles: PropTypes.string.isRequired
    })
  ),
  perPage: PropTypes.number
};

export default UserBrowser;
