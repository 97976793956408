/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Lottie from 'react-lottie';
import animationData from 'components/wizard/lottie/celebration.json';
// import { AuthWizardContext } from 'context/Context';
import { Link } from 'react-router-dom';

const Success = () => {
  // const { setStep, setUser } = useContext(AuthWizardContext);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  // const emptyData = () => {
  //   setStep(1);
  //   setUser({});
  //   reset();
  // };

  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <Lottie options={defaultOptions} />
            </div>
          </div>
          <h4 className="mb-1">Your WIFA has been published!</h4>
          <p className="fs-0">Now you can view the published model</p>
          {/* <Button color="primary" className="px-5 my-3" onClick={emptyData}>
            Start Over
          </Button> */}
          <Link
                  className="btn btn-outline-primary"
                  to={'/dashboards/42'}
                >
                View Projection
            </Link> <br/><br/>
           <Link
                  className="btn btn-outline-primary"
                  to={'/plateletWIFA/WIFAHome'}
                >
                WIFA Home
            </Link>
        </Col>
      </Row>
    </>
  );
};

Success.propTypes = {
  reset: PropTypes.func.isRequired
};

export default Success;
