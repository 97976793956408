/* eslint-disable prettier/prettier */
import BaseService from './base-service';
class HomeService extends BaseService {
    getBookingAppointments = () => {
        return this._apiGet({
            path: `/home/getBookingAppointments`
        });     
    }
    getCollectionsWeeklyTrends = () => {
        return this._apiGet({
            path: `/home/collectionsWeeklyTrends`
        });     
    }
    getFirstTimeDonors = () => {
        return this._apiGet({
            path: `/home/firstTimeDonors`
        });     
    }
    getDistributionWeeklyTrends = () => {
        return this._apiGet({
            path: `/home/distributionWeeklyTrends`
        });     
    }
    getCollectionToBudget = () => {
        return this._apiGet({
            path: `/home/collectionsToBudget`
        });
    }
    getDistributionToBudget = () => {
        return this._apiGet({
            path: `/home/distributionToBudget`
        });
    }
    getActiveDonors = () => {
        return this._apiGet({
            path: `/home/activeDonors`
        });
    }
    getWeeklyTrend = () => {
        return this._apiGet({
            path: `/home/weeklyTrend`
        });     
    }
    getAppointmentsScheduled = () => {
        return this._apiGet({
            path: `/home/appointmentsScheduled`
        });     
    }
    getDrivesScheduled = () => {
        return this._apiGet({
            path: `/home/drivesScheduled`
        });     
    }
    getInventorySupply = () => {
        return this._apiGet({
            path: `/home/inventorySupply`
        });     
    }
    getPortalActivity = () => {
        return this._apiGet({
            path: `/home/portalActivity`
        });     
    }
    getDrivesLocation = () => {
        return this._apiGet({
            path: `/home/drivesByLocation`
        });     
    }
    getCollectionBudget = () => {
        return this._apiGet({
            path: `/home/collectionBudget`
        });     
    }
    getDistributionBudget = () => {
        return this._apiGet({
            path: `/home/distributionBudget`
        });     
    }
}
export default new HomeService();