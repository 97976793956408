import React, { useState, useEffect } from 'react';
import { Card, Row, Form, Col } from 'react-bootstrap';
import notificationService from '../../services/notification-service';
import { useParams } from 'react-router-dom';
import PageHeader from 'components/common/PageHeader';

const profile = JSON.parse(localStorage.getItem('userProfile'));
const NotificationView = () => {
  let params = useParams();
  var [message, setMessage] = useState([]);
  //const [value, setValue] = useState([]);
  const messageId = params.messageId;

  useEffect(() => {
    // notificationService
    //   .GetMessageDetailsById(messageId)
    //   .then(messageDetails => {
    //     setMessage(messageDetails);
    //     //setValue(messageDetails);
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   });
    const messageDetailsPromise =
      notificationService.GetMessageDetailsById(messageId);
    const updatePromise = notificationService.UpdateNotificationAsync(
      messageId,
      profile.email
    );

    Promise.all([messageDetailsPromise, updatePromise]).then(
      ([messageDetails]) => {
        setMessage(messageDetails);
      }
    );
  }, [messageId]);
  return (
    <>
      <Card>
        <PageHeader title="Notification Details" className="mb-2" />
        <Card.Body className="bg-light">
          <Form>
            <Row className="flex-between-center">
              <Col xs="auto" sm={6} lg={2}>
                <h5 className="mb-0 text-nowrap py-2 py-xl-0">
                  {message.messageHeader}
                </h5>
              </Col>
            </Row>
            <hr />
            <Form.Text>
              <div
                dangerouslySetInnerHTML={{ __html: message.messageContent }}
              />
            </Form.Text>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default NotificationView;
