import PageHeader from 'components/common/PageHeader';
import React from 'react';
import { Breadcrumb, Row, Col } from 'react-bootstrap';
import Wizard from './WIFAWizard';
import { Helmet } from 'react-helmet';

const WIFAWizardLanding = () => {
  return (
    <>
      <Helmet>
        <title>Inventory Projection Wizard</title>
      </Helmet>
      <PageHeader title="Inventory Projection Wizard" className="mb-2" col="12">
        <Row>
          <Col className="text-start mt-1">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/plateletWIFA/WIFAHome">
                Inventory Projection Model
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                Inventory Projection Wizard
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      </PageHeader>
      <Wizard />
    </>
  );
};

export default WIFAWizardLanding;
