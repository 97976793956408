/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { Nav, Row, Col } from 'react-bootstrap';
import NavbarNavLink from './NavbarNavLink';

const DocumentationDropdown = ({ routes }) => {
// const routes = getFlatRoutes(items);

  return ( 
    <>
    <Row style={{width: '300px'}}>
    <Col >
        <Nav className="flex-column">
        <div className= "mx-2"> 
        {routes.slice(0, 1).map(route => (
          <NavbarNavLink key={route.name} route={route} />
        ))}        
        </div>
      </Nav>
    </Col>
    </Row>  
    <Row style={{width: '300px'}}>
    <Col className='mx-2' >
      <Nav className="flex-column">        
      <NavbarNavLink title="Master Control" />
        <div className= "mx-2"> 
        {routes[1].children.slice(0, 5).map(route => (
          <NavbarNavLink key={route.name} route={route} />
        ))}
        </div>
      </Nav>
    </Col>
  </Row>  
  </>
);
};

DocumentationDropdown.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      name: PropTypes.string.isRequired,
      to: PropTypes.string,
      children: PropTypes.array
    }).isRequired
  )
};

export default DocumentationDropdown;
