/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import classNames from 'classnames';
import AppContext from '../../../context/Context.tsx';
import Logo from '../../../components/common/Logo';
import SearchBox from './SearchBox';
import NavbarTopDropDownMenus from './NavbarTopDropDownMenus';
import { navbarBreakPoint, topNavbarBreakpoint, topSearchBreakpoint } from '../../../config';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import { useLocation } from 'react-router';
//NavbarTop -> NavbarTopDropDownMenu -> DashboardDropdown | NavbarDropdown -> NavbarNavLink
//NavbarTop -> TopNavRightSideNavItem -> Favorites | SearchBox | NotificationDropdown | ProfileDropdown
const NavbarTop = () => {
  const SmallScreenSize = 753; // use this value for mobile view: search box displays on the bottom 
  const {
    config: { showBurgerMenu, navbarPosition, navbarCollapsed, searchIconVisible },
    setConfig
  } = useContext(AppContext);

  const { pathname } = useLocation();
  const isChat = pathname.includes('chat');
  // const hideSearch = pathname === '/advanced-search' ? true: false;
  
  const [showDropShadow, setShowDropShadow] = useState(false);

  const handleBurgerMenu = () => {
    navbarPosition === 'top' && setConfig('navbarCollapsed', !navbarCollapsed);
    (navbarPosition === 'vertical' || navbarPosition === 'combo') &&
      setConfig('showBurgerMenu', !showBurgerMenu);
  };

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const detectSize = () => { setScreenWidth(window.innerWidth); }

  useEffect(() => {
    window.addEventListener('resize', detectSize)
    return () => {
      window.removeEventListener('resize', detectSize)
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  return (
    <><Navbar
      className={classNames('navbar-glass  fs--1 navbar-top sticky-kit', {
        'navbar-glass-shadow': showDropShadow && !isChat
      })}
      // set this breakpoint: topNavbarBreakpoint to hide menu and show burge menu
      expand={navbarPosition === 'top' || navbarPosition === 'combo'
        ? topNavbarBreakpoint
        : true}
    >
      <Navbar.Toggle
        className={classNames('toggle-icon-wrapper me-md-3 me-2', {
          'd-lg-none': navbarPosition === 'top',
          [`d-${navbarBreakPoint}-none`]: navbarPosition === 'vertical' || navbarPosition === 'combo'
        })}
        as="div"
      >
        <button className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center" onClick={handleBurgerMenu} id="burgerMenu">
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </Navbar.Toggle>

      <Logo at="navbar-top" width={112} id="topLogo" />

      {navbarPosition === 'top' &&
        <>
          <Navbar.Collapse in={navbarCollapsed} className="scrollbar pb-3 pb-lg-0">
            <Nav navbar>
              <NavbarTopDropDownMenus />
            </Nav>
          </Navbar.Collapse>
          {/* d-none: to hide at breakpoint, ms-auth: adjust space from the left */}
          {screenWidth >= SmallScreenSize &&     
          // <div  className={`align-items-center ms-auto d-none d-${topSearchBreakpoint}-block`}>
          // align-items-center : vertically
          // ms-auto: add space to the left and push all items to the right
          <Nav navbar style={{width: '360px'}} className={`align-items-center ms-auto d-none d-${topSearchBreakpoint}-block`} as="ul">
            <Nav.Item as="li">
              <SearchBox />
            </Nav.Item>
          </Nav>
          // </div>
          }
        </>
     }
      <TopNavRightSideNavItem isSmallScreen={screenWidth < SmallScreenSize? true : false} />
    </Navbar>
    {screenWidth < SmallScreenSize && searchIconVisible &&       
    <div className='d-flex justify-content-center mb-3'>
        <SearchBox />
    </div>}      
    </>
  );
};

export default NavbarTop;
